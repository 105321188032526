import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { comparativeStudyComparisonsActions } from '../../store/comparativeStudyComparisons/actions';
import { getComparativeStudyComparisonsState } from '../../store/comparativeStudyComparisons/reducer';

const useComparativeStudyComparisons = () => {
  const { comparisonsActiveTab, comparisonsResume, lists } = useSelector(
    getComparativeStudyComparisonsState
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !lists[comparisonsActiveTab] &&
      comparisonsResume[comparisonsActiveTab] > 0
    ) {
      dispatch(
        comparativeStudyComparisonsActions.fetchActiveComparativeStudyComparisons.request(
          comparisonsActiveTab
        )
      );
    }
  }, [comparisonsActiveTab]);

  return {
    comparisonsActiveTab,
    comparisonsResume,
    lists,
  };
};

export default useComparativeStudyComparisons;
