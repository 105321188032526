export const EMAIL = {
  type: 'Veuillez entrer un email valide',
  required: 'Email requis',
};

export const PASSWORD = {
  min: 'Le mot de passe doit faire au moins 4 caractères',
  required: 'Mot de passe requis',
};

export const PASSWORD_CHECK = {
  check: 'La confirmation du mot de pass est erronnée',
  required: 'la confirmation du mot de passe requis',
};

export const FULLNAME = {
  required: 'Nom requis',
};

export const first_name = {
  required: 'Prénom requis',
};

export const last_name = {
  required: 'Nom de famille requis',
};

export const NAME = {
  required: 'Nom requis',
};

export const COMPANY_NAME = {
  required: 'Nom de société requis',
};

export const ADDRESS = {
  required: 'Adresse requise',
};

export const CITY = {
  required: 'Ville requise',
  type: 'Ville requise',
};

export const ZIP_CODE = {
  type: 'Veuillez entrer un nombre',
  required: 'Code postal requis',
};

export const PHONE_NUMBER = {
  type: 'Veuillez entrer un nombre',
  required: 'Numéro de téléphone requis',
};

export const TITLE = {
  required: 'Titre requis',
};

export const TYPES = {
  required: 'Type de bien requis',
};

export const PRICE = {
  type: 'Veuillez entrer un nombre',
  required: 'Prix requis',
  min: 'Le prix doit être supérieur à 0',
  moreThan: 'Le prix maximum doit être supérieur au prix minimum',
};

export const ROOM = {
  type: 'Veuillez entrer un nombre',
  min: 'Le nombre de pièces doit être supérieur ou égal à 1',
  moreThanOrEqual:
    'Le nombre maximum de pièces doit être supérieur ou égal au nombre minimum de pièces',
};

export const AREA = {
  required: 'Surface requise',
  type: 'Veuillez entrer un nombre',
  min: 'La surface minimale doit être supérieure ou égale à 0',
  moreThan: 'La surface maximale doit être supérieure à la surface minimale',
};

export const PROPERTY_TYPOLOGY = {
  required: 'Une typologie est requise',
};

export const DPE_GES = (value) => {
  return {
    moreThan: `Le ${value} maximal doit être supérieur au ${value} minimal`,
    lessThan: `Le ${value} minimal doit être inférieur au ${value} maximal`,
  };
};
