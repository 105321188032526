import React from 'react';
import useAuth from '../../../App/hooks/useAuth';

const RegistrationProfil = () => {
  const { isManager } = useAuth();

  return (
    <div className="user-registration-elt">
      <h3 className="user-registration-title">Profil</h3>
      <span className="user-registration-data">
        {isManager ? 'Manager' : 'Prospecteur'}
      </span>
    </div>
  );
};

export default RegistrationProfil;
