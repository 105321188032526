import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeResearchesActions } from './actions';
import { getActiveResearches } from './reducer';

export default function useActiveResearches({
  activeResearchId,
  loadActiveResearches,
  loadAds,
}: {
  activeResearchId?: string | null;
  dashboard?: boolean;
  loadActiveResearches?: boolean;
  loadAds?: boolean;
}) {
  const {
    activeResearches,
    currentActiveResearchAds,
    currentActiveResearch,
    adsQuantity,
  } = useSelector(getActiveResearches);
  const dispatch = useDispatch();

  const currentActiveResearchAdsRequest = (
    itemsPerPage: number,
    page: number,
    sort: string
  ) => {
    if (activeResearchId && loadAds) {
      dispatch(
        activeResearchesActions.fetchCurrentActiveResearchAds.request({
          activeResearchId,
          itemsPerPage,
          page,
          sort,
        })
      );
    }
  };

  useEffect(() => {
    if (loadActiveResearches) {
      dispatch(activeResearchesActions.fetchActiveResearches.request());
    }

    if (activeResearchId) {
      dispatch(
        activeResearchesActions.fetchCurrentActiveResearch.request(activeResearchId)
      );
    }

    return () => {
      dispatch(activeResearchesActions.fetchCurrentActiveResearchAds.reset());
    };
  }, []);

  useEffect(() => {
    if (isEmpty(currentActiveResearchAds)) {
      currentActiveResearchAdsRequest(12, 1, 'createdAt');
    }
  }, [activeResearches, activeResearchId]);

  return {
    activeResearches: activeResearches ?? [],
    currentActiveResearch,
    currentActiveResearchAds,
    adsQuantity,
    loadAdPage: currentActiveResearchAdsRequest,
  } as {
    activeResearches: ActiveResearches;
    currentActiveResearch: any;
    currentActiveResearchAds: Ad[];
    adsQuantity: number;
    loadAdPage: (itemsPerPage: number, page: number, sort: string) => void;
  };
}
