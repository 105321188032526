import { changeApiKeyForStoreKey } from '../common/parserCommon';

export const subscriptionMapping = {
  id: 'id',
  '@id': 'idIri',
  price: 'price',
  cities: 'subscriptionCities',
  departments: 'departments',
  has_active_research: 'hasActiveResearch',
  has_comparative_study: 'hasComparativeStudy',
  has_geolocation: 'hasGeolocation',
  has_market_research: 'hasMarketResearch',
  has_request: 'hasRequest',
  total_authorized_users: 'totalAuthorizedUsers',
  subscription_demands: 'subscriptionDemands',
};

export const cityMapping = {
  '@id': 'idIri',
  name: 'name',
  zip_code: 'zipCode',
  insee_code: 'inseeCode',
  latitude: 'lat',
  longitude: 'long',
};

export const subscriptionApiToStore = (data) => {
  // const { base } = objectKeyGenericMaping(subscriptionMapping);
  const parsedData = changeApiKeyForStoreKey(data, subscriptionMapping);

  if (parsedData.subscriptionCities) {
    parsedData.subscriptionCities = parsedData.subscriptionCities.map((e) => e.name);
  }

  if (parsedData.departments) {
    parsedData.departments = parsedData.departments.map((e) => e.name);
  }

  return parsedData;
};
