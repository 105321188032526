import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useCollaborators from '../../App/hooks/useCollaborators';

const AutocompleteInputCollaborators = ({
  multiple,
  value,
  name = 'collaborators',
  handleChange,
  error,
  helperText,
}) => {
  const { collaborators } = useCollaborators();

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions
      options={collaborators ?? []}
      getOptionLabel={(option) => {
        return !isEmpty(option) ? `${option.fullName}` : '';
      }}
      getOptionSelected={(option, value) => {
        return option.idIri === value.idIri;
      }}
      name={name}
      value={value ?? []}
      onChange={(evt, value) => {
        handleChange(name, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Collaborateur(s) autorisé(s)"
          placeholder="Collaborateur(s)"
          name={name}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

AutocompleteInputCollaborators.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default AutocompleteInputCollaborators;
