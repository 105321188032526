import { all, fork } from 'redux-saga/effects';
import {
  handleCreatePigeParams,
  handleDeletePigeParams,
  handleFetchCompany,
  handleFetchCompanyStats,
  handleFetchPigeParams,
  handleRegister,
  handleUpdateCompany,
} from '../../store/account/saga';
import {
  handleDeleteAds,
  handleFetchAd,
  handleFetchAds,
  handleFetchParcels,
} from '../../store/ads/saga';
import {
  handleFetchAuthUser,
  handleFirstPassword,
  handleLogin,
  handleLogout,
  handlePasswordChange,
  handlePasswordReset,
  handlePasswordResetInit,
  handleUpdateAuthUser,
} from '../../store/auth/saga';
import {
  handleCreateComparativeStudies,
  handleDeleteComparativeStudies,
  handleFetchComparativeStudies,
  handleFetchComparativeStudy,
  handleUpdateComparativeStudies,
} from '../../store/comparativeStudies/saga';
import {
  handleCreateFolder,
  handleDeleteFolder,
  handleFetchFolders,
  handleUpdateFolder,
} from '../../store/folders/saga';
import {
  handleCreateMarketStudy,
  handleDeleteMarketStudies,
  handleFetchMarketStudies,
  handleFetchMarketStudyResult,
  handleUpdateMarketStudy,
  handleUpdateMarketStudyResultComment,
} from '../../store/marketStudies/saga';

import {
  handleCreateActiveResearches,
  handleDeleteActiveResearch,
  handleFetchActiveResearches,
  handleFetchCurrentActiveResearch,
  handleFetchCurrentActiveResearchAds,
  handleUpdateActiveResearch,
} from '../../features/activeResearch/saga';
import {
  handleCreateProcedureEvents,
  handleDeleteProcedureEvents,
  handleFetchDashboardProcedureEvents,
} from '../../features/procedureEvent/saga';
import { handleFetchAdSources } from '../../store/adSources/saga';
import {
  handleCreateAdStudies,
  handleDeleteAdStudies,
  handleFetchAdStudies,
  handleFetchAdStudy,
  handleMoveAdStudies,
  handleUpdateMultipleAdStudyForm,
} from '../../store/adStudies/saga';
import { handleFetchAnnouncements } from '../../store/announcements/saga';
import {
  handleCreateCollaborator,
  handleFetchCollaborators,
  handleUpdateCollaborator,
} from '../../store/collaborators/saga';
import { handleFetchComparativeStudyComparisons } from '../../store/comparativeStudyComparisons/saga';
import { handleUpdateMarketStudyComment } from '../../store/marketStudyComment/saga';
import { handleFetchPropertyTypologies } from '../../store/propertyTypologies/saga';
import {
  handleAskForSubscriptionUpdate,
  handleFetchSubscription,
  handleFetchSubscriptionCities,
} from '../../store/subscription/saga';

export default function* rootSaga() {
  yield all([
    // auth
    fork(handleLogin),
    fork(handleLogout),
    fork(handleFetchAuthUser),
    fork(handleUpdateAuthUser),
    fork(handlePasswordChange),
    fork(handlePasswordReset),
    fork(handlePasswordResetInit),
    fork(handleFirstPassword),
    // account
    fork(handleRegister),
    // ** pigeParams
    fork(handleFetchPigeParams),
    fork(handleCreatePigeParams),
    fork(handleDeletePigeParams),
    // ** company
    fork(handleFetchCompany),
    fork(handleFetchCompanyStats),
    fork(handleUpdateCompany),
    // subscription
    fork(handleFetchSubscription),
    fork(handleFetchSubscriptionCities),
    fork(handleAskForSubscriptionUpdate),
    // ads
    fork(handleFetchAds),
    fork(handleFetchAd),
    fork(handleDeleteAds),
    fork(handleFetchParcels),
    // market studies
    fork(handleFetchMarketStudies),
    fork(handleCreateMarketStudy),
    fork(handleUpdateMarketStudy),
    fork(handleDeleteMarketStudies),
    fork(handleUpdateMarketStudyComment),
    fork(handleFetchMarketStudyResult),
    fork(handleUpdateMarketStudyResultComment),
    // comparatives studies
    fork(handleFetchComparativeStudies),
    fork(handleCreateComparativeStudies),
    fork(handleUpdateComparativeStudies),
    fork(handleDeleteComparativeStudies),
    fork(handleFetchComparativeStudy),
    fork(handleFetchComparativeStudyComparisons),
    // folders
    fork(handleFetchFolders),
    fork(handleCreateFolder),
    fork(handleUpdateFolder),
    fork(handleDeleteFolder),
    // fork(handleAddAdsToFolder),
    // collaborators
    fork(handleFetchCollaborators),
    fork(handleCreateCollaborator),
    fork(handleUpdateCollaborator),
    // activeSearches
    fork(handleFetchActiveResearches),
    fork(handleFetchCurrentActiveResearch),
    fork(handleCreateActiveResearches),
    fork(handleUpdateActiveResearch),
    fork(handleDeleteActiveResearch),
    // fork(handleDeleteSelectedActiveSearchesAds),
    fork(handleFetchCurrentActiveResearchAds),
    // property typologies
    fork(handleFetchPropertyTypologies),
    // ad Studies
    fork(handleMoveAdStudies),
    fork(handleFetchAdStudies),
    fork(handleFetchAdStudy),
    fork(handleDeleteAdStudies),
    fork(handleUpdateMultipleAdStudyForm),
    fork(handleCreateAdStudies),
    // procedure events
    fork(handleCreateProcedureEvents),
    fork(handleDeleteProcedureEvents),
    fork(handleFetchDashboardProcedureEvents),
    // adSources
    fork(handleFetchAdSources),
    // announcements
    fork(handleFetchAnnouncements),
  ]);
}
