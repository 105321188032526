import { isEmpty } from 'lodash';
import getIdFromIdIri from '../../shared/parsers/getIdFromIdIri';
import {
  nestedCollaboratorsApiToStore,
  nestedCollaboratorsFormToApi,
} from '../../store/collaborators/parser';
import { propertyTypologiesApiToStore } from '../../store/propertyTypologies/parser';
import { citiesApiToStoreParser } from '../../utils/cityParser';

const checkZeroValue = (value: number | null) => (value === 0 ? null : value);
// ***********************
// ** FROM API
// ***********************

// common parser
const activeResearchBaseParse = (data: any) => {
  const owner = {
    idIri: data.owner['@id'],
    firstName: data.owner.first_name,
    lastName: data.owner.last_name,
  };
  const propertyTypologies = isEmpty(data.property_typologies)
    ? [{ idIri: '', name: 'Tous' }]
    : data.property_typologies.map((p: any) => propertyTypologiesApiToStore(p));

  return {
    idIri: data['@id'],
    id: getIdFromIdIri(data['@id']),
    cities: citiesApiToStoreParser(data.cities),
    cityIdRadiusOrigin: data.city_id_radius_origin,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    isAlertEnabled: data.is_alert_enabled,
    name: data.name,
    owner,
    radius: data.radius,
    propertyTypologies,
    totalAds: 0,
    totalNewAds: 0,
  };
};
// for cards
export const activeResearchesApiToStoreParser = (datas: any[]): ActiveResearches => {
  const parsedData = datas.map((m) => activeResearchBaseParse(m));

  return parsedData;
};
export const activeResearchDetailApiToStore = (data: any) => {
  const base = activeResearchBaseParse(data);

  const collaborators = !isEmpty(data.collaborators)
    ? nestedCollaboratorsApiToStore(data.collaborators)
    : [];

  const roomValue = (value: number | null) => {
    return value === 0 || value === null ? null : value > 6 ? 6 : value;
  };

  const sellerTypes =
    isEmpty(data.seller_types) ||
    (data.seller_types && data.seller_types[0] === null)
      ? [0]
      : data.seller_types;

  const parsedData: IActiveResearchDetail = {
    ...base,
    builtAreaMax: checkZeroValue(data.built_area_max),
    builtAreaMin: checkZeroValue(data.built_area_min),
    collaborators,
    dpeMax: data.energy_rank_max,
    dpeMin: data.energy_rank_min,
    limitFromDate: data.limit_from_date,
    includedKeywords: data.included_keywords_description,
    includedKeywordsOperator: data.included_keywords_description_operator,
    includedSellerName: data.included_keywords_seller_name,
    includedSellerNameOperator: data.included_keywords_seller_name_operator,
    gesMax: data.ghg_rank_max,
    gesMin: data.ghg_rank_min,
    hasCityRadius: data.has_city_radius,
    excludedKeywords: data.excluded_keywords_description,
    excludedKeywordsOperator: data.excluded_keywords_description_operator,
    excludedSellerName: data.excluded_keywords_seller_name,
    excludedSellerNameOperator: data.excluded_keywords_seller_name_operator,
    isExclusiveOnly: data.is_exclusive_only,
    isGoodDealsOnly: data.is_good_deals_only,
    landAreaMax: checkZeroValue(data.land_area_max),
    landAreaMin: checkZeroValue(data.land_area_min),
    priceMax: checkZeroValue(data.price_max),
    priceMin: checkZeroValue(data.price_min),

    roomMax: roomValue(data.room_max),
    roomMin: roomValue(data.room_min),
    sellerTypes,
    sqmPriceMax: checkZeroValue(data.sqm_price_max),
    sqmPriceMin: checkZeroValue(data.sqm_price_min),
  };

  if (parsedData.cityIdRadiusOrigin) {
    parsedData.cities = parsedData.cities.filter(
      (f) => f.id === parsedData.cityIdRadiusOrigin
    );
  }

  return parsedData;
};

// ***********************
// ** FROM FORM
// ***********************
export const activeResearchFormToApi = (data: IActiveResearchFormValues) => {
  const collaborators = !isEmpty(data.collaborators)
    ? nestedCollaboratorsFormToApi(data.collaborators)
    : [];

  const propertyTypologies = data.propertyTypologies
    ? isEmpty(data.propertyTypologies) ||
      (data.propertyTypologies.length === 1 &&
        data.propertyTypologies[0].idIri === '')
      ? []
      : data.propertyTypologies.map((elt) => elt.idIri)
    : null;

  const owner = data.owner
    ? typeof data.owner === 'string'
      ? data.owner
      : data.owner.idIri
    : null;

  const parsedData: any = {
    built_area_max: data.builtAreaMax ? checkZeroValue(data.builtAreaMax) : null,
    built_area_min: data.builtAreaMin ? checkZeroValue(data.builtAreaMin) : null,
    cities: data.cities ? data.cities.map((c) => c.idIri) : null,
    city_id_radius_origin: data.cityIdRadiusOrigin,
    collaborators,
    energy_rank_max: data.dpeMax,
    energy_rank_min: data.dpeMin,
    excluded_keywords_description: data.excludedKeywords,
    excluded_keywords_description_operator: data.excludedKeywordsOperator,
    excluded_keywords_seller_name: data.excludedSellerName,
    excluded_keywords_seller_name_operator: data.excludedSellerNameOperator,
    ghg_rank_max: data.gesMax,
    ghg_rank_min: data.gesMin,
    has_city_radius: data.hasCityRadius,
    included_keywords_description: data.includedKeywords,
    included_keywords_description_operator: data.includedKeywordsOperator,
    included_keywords_seller_name: data.includedSellerName,
    included_keywords_seller_name_operator: data.includedSellerNameOperator,
    is_alert_enabled: data.isAlertEnabled,
    is_exclusive_only: data.isExclusiveOnly,
    is_good_deals_only: data.isGoodDealsOnly,
    land_area_max: data.landAreaMax ? checkZeroValue(data.landAreaMax) : null,
    land_area_min: data.landAreaMin ? checkZeroValue(data.landAreaMin) : null,
    limit_from_date: data.limitFromDate,
    name: data.name,
    owner,
    price_max: data.priceMax ? checkZeroValue(data.priceMax) : null,
    price_min: data.priceMin ? checkZeroValue(data.priceMin) : null,
    property_typologies: propertyTypologies,
    radius: data.radius,
    room_max: data.roomMax,
    room_min: data.roomMin,
    seller_types: data.sellerTypes,
    sqm_price_max: checkZeroValue(data.sqmPriceMax),
    sqm_price_min: checkZeroValue(data.sqmPriceMin),
  };

  return parsedData;
};
