import { memo } from 'react';
import { Link } from 'react-router-dom';
import './sectionTitle.scss';

interface ISectionTitleProps {
  mainTitle: string;
  subTitle?: string;
  subTitleNav?: string;
  noBar?: boolean;
  link?: string;
}
const SectionTitle = ({
  mainTitle,
  subTitle,
  subTitleNav,
  noBar,
  link,
}: ISectionTitleProps) => {
  return (
    <div className="section-title" style={noBar ? { border: 'none' } : {}}>
      <h3 className="section-title-main">{mainTitle}</h3>
      <p className="section-title-sub">
        {link ? <Link to={{ pathname: link }}>{subTitle}</Link> : subTitle}
        {subTitleNav && (
          <span className="section-title-sub-elt">{` / ${subTitleNav}`}</span>
        )}
      </p>
    </div>
  );
};

SectionTitle.displayName = 'SectionTitle';

export default memo(SectionTitle);
