import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adStudiesActions } from '../../store/adStudies/actions';
import { getAdStudiesState } from '../../store/adStudies/reducer';

export default function useAdStudies(idFolder, id) {
  const {
    adStudies,
    selectedAdStudies,
    activeAdStudy,
    formTouchStates,
    localisationFormValues,
  } = useSelector(getAdStudiesState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(adStudies) && idFolder) {
      dispatch(adStudiesActions.fetchAdStudies.request(`/folders/${idFolder}`));
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        adStudiesActions.fetchAdStudy.request({ idIri: `/ad-studies/${id}` })
      );
    }
  }, [id]);

  return {
    adStudies,
    activeAdStudy,
    adFull: (!isEmpty(activeAdStudy) && activeAdStudy.ad) ?? {},
    selectedAdStudies,
    formTouchStates,
    localisationFormValues,
  };
}
