import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { PDFDownloadLink } from '@react-pdf/renderer';

import LogoButton from '../Assets/LogoButton';

function PDFDownload({ pdf, pdfName }) {
  return (
    <PDFDownloadLink document={pdf} fileName={pdfName}>
      {({ url }) =>
        url ? (
          <LogoButton
            btnType="button"
            handleClick={() => {}}
            btnLogo="printer"
            tooltip={`Imprimer`}
          />
        ) : (
          <CircularProgress />
        )
      }
    </PDFDownloadLink>
  );
}

PDFDownload.propTypes = {
  pdf: PropTypes.element.isRequired,
  pdfName: PropTypes.string.isRequired,
};

export default PDFDownload;
