import { useParams } from 'react-router';
import useMarketStudies from '../../App/hooks/useMarketStudies';
import Button from '../Assets/Button';
import SectionTitle from '../Assets/sectionTitle';
import PrintButton from './PrintButton';

interface IHeaderProps {
  handleSubmit: () => void;
  handleCancel: () => void;
}
const Header = ({ handleSubmit, handleCancel }: IHeaderProps) => {
  const { id } = useParams<{ id: string }>();
  const { selectedMarketStudy, pdfDatas = [] } = useMarketStudies(id, id);

  return (
    <header className="market-studies-result-header">
      <div className="market-studies-result-header-titles">
        <SectionTitle
          mainTitle="Résultats de l'étude"
          subTitle="Visualisez et selectionnez vos études"
        />
        <span className="market-studies-result-header-sub">
          {selectedMarketStudy && selectedMarketStudy.name}
        </span>
      </div>
      <div className="market-studies-result-header-btns">
        <PrintButton selectedMarketStudy={selectedMarketStudy} pdfDatas={pdfDatas} />

        <Button
          btnType="button"
          btnContent="ENREGISTRER"
          handleClick={handleSubmit}
          primary
        />
        <Button
          btnType="button"
          btnContent="RETOUR"
          handleClick={handleCancel}
          textColor="violet"
          bgColor="grey"
          borderColor="grey"
        />
      </div>
    </header>
  );
};

export default Header;
