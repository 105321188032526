import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as MyFolderViolet } from '../../images/my-folder-violet.svg';
import { ReactComponent as FolderBlue } from '../../images/folder-blue.svg';
import { ReactComponent as FolderBlueUser } from '../../images/folder-blue-user.svg';
import { useHistory } from 'react-router-dom';

const FolderLineIcon = ({
  collaborators,
  name,
  isDefault,
  activeFolder,
  id,
  idIri,
}) => {
  const history = useHistory();
  return (
    <div className="my-folders-title">
      {isDefault ? (
        <MyFolderViolet />
      ) : collaborators > 0 ? (
        <FolderBlueUser className="my-folders-picto-folder" />
      ) : (
        <FolderBlue className="my-folders-picto-folder" />
      )}
      <span
        className={activeFolder === idIri ? 'folder-name-selected' : ''}
        onClick={() => history.push(`/details-dossier/${id}`)}
      >
        {name}
      </span>
    </div>
  );
};

FolderLineIcon.propTypes = {
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  activeFolder: PropTypes.string,
  collaborators: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  idIri: PropTypes.string.isRequired,
};
export default FolderLineIcon;
