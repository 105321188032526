import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import MultiLineGraph from '../Graphs/MultiLineGraph';
import useMarketStudies from '../../App/hooks/useMarketStudies';
import { twoNumberDigitDisplay } from '../../utils/jsFunctions';

const getDateLabels = () => {
  const date = new Date();
  const labels = [];

  date.setMonth(date.getMonth() - 12);

  for (let i = 0; i < 12; i += 1) {
    const month = twoNumberDigitDisplay(date.getMonth() + 1);
    const year = date.getFullYear().toString().substring(2);

    labels.push(`${month}/${year}`);
    date.setMonth(date.getMonth() + 1);
  }

  return labels;
};

const MultiGraphBloc = ({ children, type, id, handleGraph }) => {
  const { statsMultigraph } = useMarketStudies(id, id);

  return (
    <section className="market-studies-result-evolution">
      <h4 className="market-studies-result-section-title">{children}</h4>
      <div className="market-studies-result-evolution-elt">
        {!isEmpty(statsMultigraph(type)) && (
          <MultiLineGraph
            labels={getDateLabels()}
            datasets={statsMultigraph(type)}
            type={type}
            handleGraph={handleGraph}
          />
        )}
      </div>
    </section>
  );
};

MultiGraphBloc.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  handleGraph: PropTypes.func,
};

export default MultiGraphBloc;
