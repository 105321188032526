import { LatLngExpression } from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface IChangeViewProps {
  center: LatLngExpression;
  zoom: number;
}
function ChangeView({ center, zoom }: IChangeViewProps) {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom]);

  return null;
}

export default ChangeView;
