import { useSelector } from 'react-redux';

import { adActions } from '../../store/ads/actions';
import { getAdsState } from '../../store/ads/reducer';
import ActionModal from '../Modals/ActionModal';

import './styles/adsDeleteModal.scss';

const AdsDeleteModal = () => {
  const { selectedAds, deleteModal, activeAd } = useSelector(getAdsState);

  return (
    <ActionModal
      closeAction={adActions.deleteAds.reset()}
      validAction={
        activeAd
          ? adActions.deleteAds.request([activeAd])
          : adActions.deleteAds.request(selectedAds)
      }
      validText="SUPPRIMER"
      modalType="suppression"
    >
      <p>
        Confirmez-vous la suppression
        {deleteModal.quantity > 1
          ? ` des ${deleteModal.quantity} annonces sélectionnées `
          : ` de l'annonce sélectionnée `}
        sur votre pige classique et vos recherches actives ?
      </p>
      <p>La suppression sera définitive.</p>
    </ActionModal>
  );
};

export default AdsDeleteModal;
