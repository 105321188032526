import { call, put, select, take } from '@redux-saga/core/effects';
import {
  createEntity,
  deleteEntity,
  fetchDataById,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { adApiToStore } from '../../store/ads/parser';
import { appManagerActions } from '../../store/appManager/actions';
import { getAuthState } from '../../store/auth/reducer';
import { REQUEST } from '../../store/common/actions';
import { mapObjectParser } from '../../store/common/parserCommon';
import onErrorLogout from '../../store/common/sagaCommon';
import { loaderActions } from '../../store/loader/actions';
import {
  CREATE_ACTIVE_RESEARCH,
  DELETE_ACTIVE_RESEARCH,
  FETCH_ACTIVE_RESEARCHES,
  FETCH_CURRENT_ACTIVE_RESEARCH,
  FETCH_CURRENT_ACTIVE_RESEARCH_ADS,
  UPDATE_ACTIVE_RESEARCH,
  activeResearchesActions,
} from './actions';
import {
  activeResearchDetailApiToStore,
  activeResearchFormToApi,
  activeResearchesApiToStoreParser,
} from './activeResearchParser';
import { activeSearchesNotifications } from './notifications';
import { getActiveResearches } from './reducer';

// *******************************************************
// ** FETCH ACTIVE RESEARCHES
// *******************************************************
export function* handleFetchActiveResearches() {
  while (true) {
    try {
      yield take(FETCH_ACTIVE_RESEARCHES[REQUEST]);
      const { userId } = yield select(getAuthState);

      const response = yield call(fetchDatas, `/users/${userId}/active-researches`);
      if (response) {
        const parsedResponse = activeResearchesApiToStoreParser(
          response['hydra:member']
        );

        yield put(
          activeResearchesActions.fetchActiveResearches.success(parsedResponse)
        );

        let index = 0;
        while (parsedResponse.length > index) {
          const temp = yield call(
            fetchDatas,
            `/active-researches/${parsedResponse[index].id}/total-ads`
          );

          yield put(
            activeResearchesActions.fetchSingleAdsAndNewAdsInfos.success({
              idIri: parsedResponse[index].idIri,
              totalAds: temp.total_ads,
              totalNewAds: temp.total_new_ads,
            })
          );

          index += 1;
        }
      }
    } catch (error) {
      console.log('error fetchActiveResearches', error);
      yield onErrorLogout(error);
      yield put(activeResearchesActions.fetchActiveResearches.failure(error));
    }
  }
}

// *******************************************************
// ** FETCH CURRENT ACTIVE RESEARCH
// *******************************************************
export function* handleFetchCurrentActiveResearch() {
  while (true) {
    try {
      const { payload } = yield take(FETCH_CURRENT_ACTIVE_RESEARCH[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDataById, `/active-researches/${payload}`);

      if (response) {
        const parsedResponse = activeResearchDetailApiToStore(response);
        const temp = yield call(
          fetchDatas,
          `/active-researches/${parsedResponse.id}/total-ads`
        );

        parsedResponse.totalAds = temp.total_ads;
        parsedResponse.totalNewAds = temp.total_new_ads;

        yield put(
          activeResearchesActions.fetchCurrentActiveResearch.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchActiveResearches', error);
      yield onErrorLogout(error);
      yield put(activeResearchesActions.fetchCurrentActiveResearch.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// *******************************************************
// ** CREATE ACTIVE RESEARCH
// *******************************************************
export function* handleCreateActiveResearches() {
  while (true) {
    try {
      const {
        payload: { body, history },
      } = yield take(CREATE_ACTIVE_RESEARCH[REQUEST]);

      const parsedBody = activeResearchFormToApi(body);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(createEntity, {
        endpoint: '/active-researches',
        body: parsedBody,
      });

      if (response) {
        const parsedResponse = activeResearchDetailApiToStore(response);

        if (history) {
          yield call(
            history.push,
            `/recherche-active-resultat/${parsedResponse.id}`
          );
        }
        yield put(
          activeResearchesActions.createActiveResearch.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            activeSearchesNotifications.create(response.title).success()
          )
        );
      }
    } catch (error) {
      console.log('error createActiveResearch', error);
      yield onErrorLogout(error);
      yield put(activeResearchesActions.createActiveResearch.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.create().success()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// *******************************************************
// ** UPDATE ACTIVE RESEARCH
// *******************************************************
export function* handleUpdateActiveResearch() {
  while (true) {
    try {
      const {
        payload: { idIri, body, history, isAlertOnly },
      } = yield take(UPDATE_ACTIVE_RESEARCH[REQUEST]);

      let parsedBody;

      if (isAlertOnly) {
        // if only alert switch
        parsedBody = { is_alert_enabled: body.isAlertEnabled };
      } else {
        parsedBody = activeResearchFormToApi(body);
      }

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateEntity, {
        idIri,
        body: parsedBody,
      });

      if (response) {
        const parsedResponse = yield call(activeResearchDetailApiToStore, response);
        if (history) {
          yield call(
            history.push,
            `/recherche-active-resultat/${parsedResponse.id}`
          );
        }
        yield put(
          activeResearchesActions.updateActiveResearch.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            activeSearchesNotifications.update(response.title).success()
          )
        );
      }
    } catch (error) {
      console.log('error updateActiveResearch', error);
      yield onErrorLogout(error);
      yield put(activeResearchesActions.updateActiveResearch.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.update().failure()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// *******************************************************
// ** DELETE ACTIVE RESEARCH
// *******************************************************
export function* handleDeleteActiveResearch() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_ACTIVE_RESEARCH[REQUEST]);

      const { activeResearches } = yield select(getActiveResearches);
      const activeSearch = activeResearches.find((elt) => elt.idIri === payload);

      yield put(loaderActions.loaderDisplay.visible());
      yield call(deleteEntity, payload);
      yield put(activeResearchesActions.deleteActiveResearch.success(payload));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.delete(activeSearch.title).success()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    } catch (error) {
      console.log('error deleteActiveResearch', error);
      yield onErrorLogout(error);
      yield put(activeResearchesActions.deleteActiveResearch.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          activeSearchesNotifications.delete().success()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

// *******************************************************
// ** FETCH CURRENT ACTIVE RESEARCH ADS
// *******************************************************
export function* handleFetchCurrentActiveResearchAds() {
  while (true) {
    try {
      const {
        payload: { activeResearchId, page, itemsPerPage, sort },
      } = yield take(FETCH_CURRENT_ACTIVE_RESEARCH_ADS[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());

      // add parser when request will run
      const response = yield call(
        fetchDatas,
        `/active-researches/${activeResearchId}/ads`,
        {
          page,
          itemsPerPage,
          [`orderBy[${sort}]`]: sort === 'createdAt' ? 'desc' : 'asc',
        }
      );
      const parsedResponse = mapObjectParser(response['hydra:member'], adApiToStore);
      yield put(
        activeResearchesActions.fetchCurrentActiveResearchAds.success({
          ads: parsedResponse,
          qty: response['hydra:totalItems'],
        })
      );
      yield put(loaderActions.loaderDisplay.hidden());
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      console.log('error deleteActiveResearch', error);
      yield onErrorLogout(error);
      yield put(
        activeResearchesActions.fetchCurrentActiveResearchAds.failure(error)
      );
    }
  }
}
