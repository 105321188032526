import { useDispatch, useSelector } from 'react-redux';
import Badge from '../../components/badge';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../../components/badge/enums';
import { ReactComponent as CheckViolet } from '../../images/check-violet.svg';
import { adActions } from '../../store/ads/actions';
import { getAdsState } from '../../store/ads/reducer';
import { adStudiesActions } from '../../store/adStudies/actions';
import { getAdStudiesState } from '../../store/adStudies/reducer';
import { getActiveResearches } from '../activeResearch/reducer';

enum SellerTypeEnum {
  TOUS,
  PARTICULIER,
  PRO,
  ENCHERES,
  ETAT,
  AUTRES,
}

const sellerTypeDisplay = (sellerType: SellerTypeEnum) => {
  let badgeContent = BadgeContentEnum.AUTRE;
  let bgColor = BadgeBackgroundColorEnum.VIOLET;

  switch (sellerType) {
    case SellerTypeEnum.TOUS:
      badgeContent = BadgeContentEnum.TOUS;
      bgColor = BadgeBackgroundColorEnum.BLUE;
      break;
    case SellerTypeEnum.PARTICULIER:
      badgeContent = BadgeContentEnum.PARTICULIER;
      bgColor = BadgeBackgroundColorEnum.BLUE;
      break;
    case SellerTypeEnum.PRO:
      badgeContent = BadgeContentEnum.PRO;
      break;
    case SellerTypeEnum.ENCHERES:
      badgeContent = BadgeContentEnum.ENCHERES;
      break;
    case SellerTypeEnum.ETAT:
      badgeContent = BadgeContentEnum.ETAT;
      break;
    default:
  }

  return (
    <Badge
      badgeContent={badgeContent}
      bgColor={bgColor}
      textColor={BadgeTextColorEnum.WHITE}
    />
  );
};

interface IThumbHeaderPRops {
  dataRealId: string;
  sellerType: SellerTypeEnum;
  status: any;
  type: any;
  data: any;
  isNew?: boolean;
}
const ThumbHeader = ({
  dataRealId,
  sellerType,
  status,
  type,
  data,
  isNew,
}: IThumbHeaderPRops) => {
  const { selectedAds }: { selectedAds: Ad[] } = useSelector(getAdsState);
  const { selectedAdStudies } = useSelector(getAdStudiesState);
  const {
    selectedActiveResearchesAds,
  }: { selectedActiveResearchesAds: Ad[] } = useSelector(getActiveResearches);
  // console.log(isNew);
  const dispatch = useDispatch();

  const handleChecked = () => {
    switch (type) {
      case 'ads':
      case 'activeResearches':
        dispatch(adActions.selectedAds.add(data));
        break;
      case 'adStudies':
        dispatch(adStudiesActions.selectedAdStudies.add(dataRealId));
        break;

      default:
        break;
    }
  };

  return (
    <header className="ad-thumb-top">
      <div className="ad-thumb-check" onClick={handleChecked}>
        {(selectedAds.map((e) => e.idIri).includes(dataRealId) ||
          selectedAdStudies.includes(dataRealId) ||
          selectedActiveResearchesAds.map((e) => e.idIri).includes(dataRealId)) && (
          <CheckViolet />
        )}
      </div>
      {isNew && (
        <Badge
          badgeContent={BadgeContentEnum.NOUVEAU}
          bgColor={BadgeBackgroundColorEnum.RED}
          textColor={BadgeTextColorEnum.WHITE}
        />
      )}
      {status !== null && (
        <div className="ad-thumb-top-status">
          {status === 1 ? (
            <>
              <div className="ad-thumb-top-status-on" />
              <span className="ad-thumb-top-status-on-text">En ligne</span>
            </>
          ) : (
            <>
              <div className="ad-thumb-top-status-off" />
              <span className="ad-thumb-top-status-off-text">Hors ligne</span>
            </>
          )}
        </div>
      )}
      {sellerTypeDisplay(sellerType)}
    </header>
  );
};

export default ThumbHeader;
