import {
  createRequestTypes,
  FAILURE,
  REQUEST,
  SUCCESS,
  action,
} from '../common/actions';

export const FETCH_AD_SOURCES = createRequestTypes('FETCH_AD_SOURCES');

export const adSourcesActions = {
  fetchAdSources: {
    request: (data) => action(FETCH_AD_SOURCES[REQUEST], { payload: data }),
    success: (data) => action(FETCH_AD_SOURCES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_AD_SOURCES[FAILURE], { payload: data }),
  },
};
