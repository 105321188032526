import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import Header from './Header';

import { styles } from './styles';

const Multigraph = ({ sqmTypo, stockTypo, name }) => (
  <Page size="A4">
    <Header name={name} />
    <View>
      <View style={styles.section}>
        <Text style={styles.statsTitle}>
          Évolution des prix m² par typologies sur 1 an
        </Text>
        <Image src={sqmTypo} style={styles.graph} />
        <Text style={styles.statsTitle}>
          Évolution des stocks par typologies sur 1 an
        </Text>
        <Image src={stockTypo} style={styles.graph} />
      </View>
    </View>
  </Page>
);

Multigraph.propTypes = {
  sqmTypo: PropTypes.string,
  stockTypo: PropTypes.string,
  name: PropTypes.string,
};

export default Multigraph;
