import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import useFolders from '../../../App/hooks/useFolders';
import TableRowCustom from './TableRowCustom';

const useStyles = makeStyles({
  table: {
    marginBottom: '20px',
  },
  body: {
    padding: 0,
    textAlign: 'center',
  },
});

const AdTableCustom = ({
  columnTitles,
  adsArray,
  needCheck,
  display,
  minIndexTab,
}) => {
  const classes = useStyles();
  const { folders } = useFolders();
  return (
    <TableContainer className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            {columnTitles.map((c) => (
              <TableCell key={uuidV4()} className={classes.body}>
                {c}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {adsArray
            .filter((elt, index) => {
              if (minIndexTab) {
                return (
                  index >= minIndexTab && index < minIndexTab + parseInt(display)
                );
              } else {
                return true;
              }
            })
            .map((r) => (
              <TableRowCustom
                key={uuidV4()}
                data={r}
                folders={folders}
                needCheck={needCheck}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AdTableCustom.propTypes = {
  columnTitles: PropTypes.array,
  adsArray: PropTypes.array,
  needCheck: PropTypes.bool,
  display: PropTypes.number,
  minIndexTab: PropTypes.number,
};
export default AdTableCustom;
