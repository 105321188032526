import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propertyTypologyActions } from '../../store/propertyTypologies/actions';
import { getPropertyTypologiesState } from '../../store/propertyTypologies/reducer';

export default function usePropertyTypologies() {
  const { propertyTypologies } = useSelector(getPropertyTypologiesState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(propertyTypologies)) {
      dispatch(propertyTypologyActions.fetchPropertyTypologies.request());
    }
  }, []);

  const reducedPropertyTypologies = () =>
    isEmpty(propertyTypologies)
      ? []
      : propertyTypologies.filter((e) =>
          ['Maison', 'Appartement', 'Terrain'].includes(e.name)
        );

  return { propertyTypologies, reducedPropertyTypologies };
}
