import SectionTitle from '../components/Assets/sectionTitle';
import MarketStudyForm from '../components/MarketStudies/MarketStudyForm';

function MarketStudiesFormPage() {
  return (
    <main className="market-studies-create">
      <header className="market-studies-create-header">
        <SectionTitle
          mainTitle="Créer une nouvelle étude de marché"
          subTitle="Visualisez et selectionnez vos études"
        />
      </header>
      <MarketStudyForm />
    </main>
  );
}

export default MarketStudiesFormPage;
