import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAds from '../App/hooks/useAds';
import ActionNotification from '../components/Assets/ActionNotification';
import SectionTitle from '../components/Assets/sectionTitle';
import SelectInput from '../components/Forms/SelectInput';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import InfoBox from '../components/Piecework/InfoBox';
import { sortValues } from '../components/Piecework/constant';
import ThumbContainer from '../features/adThumb/ThumbContainer';
import { nbDisplay } from '../lib/constants';
import { adActions } from '../store/ads/actions';
import './styles/pieceworkPage.scss';

function Piecework() {
  const [input, setInput] = useState({ sort: 'createdAt', display: 12 });
  const { ads, totalAds } = useAds();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(adActions.newAdsCount.reset());
      dispatch(adActions.selectedAdsReset.success());
    };
  }, []);

  return (
    <main className="piecework">
      <ActionNotification />
      <header className="piecework-header">
        <SectionTitle
          mainTitle="Pige classique"
          subTitle="Visualisez et sélectionnez vos annonces"
        />
        <InfoBox />
      </header>

      <div className="piecework-options">
        <SelectInput
          name="sort"
          value={input.sort}
          label="Trier par"
          nameKey="text"
          handleChange={(e) => setInput({ ...input, sort: e.target.value })}
          items={sortValues}
          colorDisplay="reverse"
        />

        <SelectInput
          name="display"
          value={input.display}
          label="Affichage par page"
          handleChange={(e) => setInput({ ...input, display: e.target.value })}
          items={nbDisplay}
          colorDisplay="reverse"
        />
      </div>

      {!ads ? (
        <IndividualCircularLoader size={200} />
      ) : (
        <ThumbContainer
          sort={input.sort}
          itemsPerPage={parseInt(input.display, 10)}
          pageQuantity={Math.ceil(totalAds / input.display)}
          type="ads"
          ads={ads}
        />
      )}
    </main>
  );
}

export default Piecework;
