export const assistanceTabArray = [
  { index: 0, name: `Vidéos`, onlyAdmin: false },
  { index: 1, name: `FAQ`, onlyAdmin: false },
];

export const faqArrayProspec = [
  {
    question: `<p class="faq-question">Je vois des doublons pour des annonces est-ce normal ?</p>`,
    answer: `<p>Afin d’identifier les doublons pour une même annonce nous nous basons sur les critères suivants : </p>
    <ul>
    <li>Typologie du bien</li>
    <li>Les surfaces</li>
    <li>La ville du bien</li>
    <Li>Le texte de sa description</li>
    </ul>
    <p>Si jamais une de ces informations cruciales est discordante d’une annonce à l’autre alors nos algorithmes les dissocient.
    Cela permet souvent d’avoir une information plus fine sur le bien en question en vous permettant de comparer ces annonces discordantes.</p>`,
  },
  {
    question: `<p class="faq-question">Hier il y avait une annonce dans un flux de prospection, mais aujourd’hui je ne la vois plus. Pourquoi ?</p>`,
    answer: `<p>Cela signifie que l’annonce en question n’est plus en ligne ou n’est plus publiée sur le site originel.
    Nos algorithmes vérifient en permanence la permanence des annonces sur le site de publication d’origine.</p>
    <p>Ayant des dizaines de millions d’annonces en base, ce travail prend un certain temps.
    Il est en effet nécessaire de repasser sur l’ensemble des annonces toujours réputées en ligne, ce travail peut prendre plusieurs jours en fonction des supports. </p>`,
  },
  {
    question: `<p class="faq-question">Je clique sur une annonce qui se trouve dans un flux de prospection, mais une fois arrivé sur le site d’origine, elle est inactive. Pourquoi ?</p>`,
    answer: `<p>Nos algorithmes vérifient en permanence la permanence des annonces sur les sites de publication d’origine.
    Ayant des dizaines de millions d’annonces en base, ce travail prend un certain temps.</p>
    <p>Il est en effet nécessaire de repasser sur l’ensemble des annonces toujours réputées en ligne, et ce travail peut prendre plusieurs jours en fonction des supports.</p>
    <p>Il peut donc arriver qu’une annonce soit mise hors ligne de son site d’origine dans l’intervalle des vérifications, et nous nous en excusons.</p>
    <p>Soyez assurés de nos efforts continuels dans l’objectif de réduire au maximum ces délais.</p>`,
  },
  {
    question: `<p class="faq-question">Comment fonctionne le système de « mots-clés » lorsque je créé une Recherche Active ?</p>`,
    answer: `<p>Le système de « mots-clés » et « mots-clés interdits » fonctionne comme une recherche de texte dans votre outil mail.</p>
    <p>Nos algorithmes vont chercher les annonces qui mentionnent exclusivement les termes renseignés.</p>
    <p>Ainsi dans le cas de l’ajout d’un ou plusieurs mots clés, les résultats ne présenteront QUE des annonces mentionnant les mots souhaités, et écartera toutes les autres annonces.</p>
    <p>Et inversement dans le cas des « mots-clés interdits », les résultats ne présenteront QUE des annonces ne mentionnant PAS les mots à exclure.</p>`,
  },
  {
    question: `<p class="faq-question">Je souhaite modifier mon abonnement, comment faire ?</p>`,
    answer: `<p>Il vous suffit de vous rendre sur la page « Mon Compte », puis dans le menu « Abonnement » et de cliquer sur le bouton « Modifier mon abonnement ».
    Vous n’aurez ensuite plus qu’à sélectionner l’option souhaitée.
    </p>`,
  },
  {
    question: `<p class="faq-question">Lorsque je laisse mon ordinateur allumé sur la page de Prospec’Immo, je suis automatiquement déconnecté au bout d’un certain temps.</p>`,
    answer: `<p>Il se peut que vous ayez un problème de connexion internent car Prospec’Immo est normalement programmé pour sauvegarder votre connexion tant que vous ne changez pas de navigateur internet ou que vous ne supprimez pas votre historique et vos « cookies ».</p>`,
  },
  {
    question: `<p class="faq-question">Que se passe-t-il si je supprime une annonce dans un Flux de prospection (Pige Clasique ou une Recherche Active) ?</p>`,
    answer: `<ul>
    <li>Cette annonce ne sera plus visible pour vous. Néanmoins, vos collègues utilisateurs qui sont sur le même abonnement pourront toujours retrouver cette annonce dans leur flux.</li>
    <li>Par ailleurs si une annonce présentant le même bien mais disposant de caractéristiques différentes existe, et qui n’aura donc pas pu être identifiée comme « doublons » de ce dernier, alors je serais toujours en mesure de voir cette nouvelle annonce.</li>
    <li>Enfin, si le même bien est remis à la vente par un autre vendeur, ou le même vendeur mais avec des caractéristiques essentielles (typologie du bien, les surfaces, la ville du bien et le texte) différentes, alors cette annonce pourra apparaître dans mes flux de prospection</li>
    </ul>`,
  },
];

export const assistanceVideo = [
  {
    title: 'Interface',
    description: '',
    url: 'https://youtu.be/bsMyoa7fZCo',
  },
  {
    title: 'Pige Classique et infos sur les annonces',
    description: '',
    url: 'https://youtu.be/fl3nNABBe2o',
  },
  {
    title: 'Recherches Actives',
    description: '',
    url: 'https://youtu.be/sZjx6jouHhs',
  },
  {
    title: 'Mes Dossiers et suivi des annonces',
    description: '',
    url: 'https://youtu.be/KFqpj7DTJj0',
  },
  {
    title: 'Etudes de Marché',
    description: '',
    url: 'https://youtu.be/6UwWYNmaJJA',
  },
  {
    title: 'Etudes Comparatives',
    description: '',
    url: 'https://youtu.be/JLzrfw8KFws',
  },
];
