const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(token) {
    try {
      localStorage.setItem('prospec_token', token);
    } catch (error) {
      localStorage.setItem('prospec_token', null);
    }
  }

  function _setRefreshToken(refresh_token) {
    try {
      localStorage.setItem('prospec_refresh_token', refresh_token);
    } catch (error) {
      localStorage.setItem('prospec_refresh_token', null);
    }
  }

  function _setId(id) {
    try {
      localStorage.setItem('prospec_user_id', id);
    } catch (error) {
      localStorage.setItem('prospec_user_id', null);
    }
  }

  function _setCompanyId(companyId) {
    try {
      localStorage.setItem('prospec_company_id', companyId);
    } catch (error) {
      localStorage.setItem('prospec_company_id', null);
    }
  }

  function _getToken() {
    try {
      const token = localStorage.getItem('prospec_token');
      if (!token || token === 'null') {
        return null;
      }
      return token;
    } catch (err) {
      return null;
    }
  }

  function _getRefreshToken() {
    try {
      const refreshToken = localStorage.getItem('prospec_refresh_token');
      if (!refreshToken || refreshToken === null) {
        return null;
      }
      return refreshToken;
    } catch (err) {
      return null;
    }
  }

  function _getId() {
    try {
      const id = localStorage.getItem('prospec_user_id');
      if (id === null) {
        return null;
      }
      return id;
    } catch (err) {
      return {};
    }
  }

  function _getCompanyId() {
    try {
      const companyId = localStorage.getItem('prospec_company_id');
      if (!companyId || companyId === null) {
        return null;
      }
      return companyId;
    } catch (err) {
      return {};
    }
  }

  function _clear() {
    localStorage.removeItem('prospec_token');
    localStorage.removeItem('prospec_refresh_token');
    localStorage.removeItem('prospec_user_id');
    localStorage.removeItem('prospec_company_id');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setRefreshToken: _setRefreshToken,
    setId: _setId,
    setCompanyId: _setCompanyId,
    getToken: _getToken,
    getRefreshToken: _getRefreshToken,
    getId: _getId,
    getCompanyId: _getCompanyId,
    clear: _clear,
  };
})();

export default LocalStorageService;
