import Button from '../../../components/Assets/Button';
import styles from './signChoiceButton.module.scss';

interface ISignChoiceButtonProps {
  hasAccount: boolean;
  className: string;
  onClick: (value: boolean) => void;
}
function SignChoiceButton({
  hasAccount,
  onClick,
  className,
}: ISignChoiceButtonProps) {
  const handleButtonClick = () => {
    onClick(!hasAccount);
  };

  return (
    <div className={className}>
      <div className={styles.signChoiceButton}>
        <p>{hasAccount ? 'Pas encore client ?' : 'Déjà client ?'}</p>

        <Button
          btnType="button"
          btnContent={hasAccount ? 'CREEZ UN COMPTE' : 'CONNECTEZ-VOUS'}
          handleClick={handleButtonClick}
          primary
        />
      </div>
    </div>
  );
}

export default SignChoiceButton;
