import {
  axiosInstance,
  axiosTokenInstance,
} from '../../services/axiosInstanceService';

export const getLogin = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post('/login', payload)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getLogout = () => {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post('/logout')
      .then((response) => resolve(response))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};

export const fetchAuthUser = (endpoint) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(endpoint)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export function updateAuthUser({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function passwordChange({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function passwordReset({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function firstPassword({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function passwordResetInit({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
