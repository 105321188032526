import LocalStorageService from '../../services/localStorageService';
import { FETCH_ADS, RESET_NEW_ADS_COUNT } from '../ads/actions';
import { FAILURE, RESET, SET, SUCCESS } from '../common/actions';
import {
  FETCH_AUTH_USER,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  UPDATE_AUTH_ADS_COUNT,
  UPDATE_AUTH_USER,
} from './actions';

const logoutState = {
  token: null,
  refresh_token: null,
  userId: null,
  isLogged: false,
  companyId: null,
  isManager: false,
  user: {},
};

const initialState = {
  token: LocalStorageService.getToken(),
  refresh_token: LocalStorageService.getRefreshToken(),
  isLogged: LocalStorageService.getToken() ? true : false,
  userId: LocalStorageService.getId(),
  companyId: LocalStorageService.getCompanyId(),
  isManager: false,
  isBadCredentials: false,
  user: {},
  totalAds: 0,
  totalAdsToday: 0,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case LOGIN[SUCCESS]:
      return {
        ...state,
        ...payload,
        isLogged: true,
        isBadCredentials: false,
      };
    case LOGIN[FAILURE]:
      return {
        ...state,
        isLogged: false,
        isBadCredentials: true,
      };
    case FETCH_AUTH_USER[SUCCESS]:
    case UPDATE_AUTH_USER[SUCCESS]:
      return {
        ...state,
        user: payload.user,
        totalAds: payload.totalAds,
        totalAdsToday: payload.totalAdsToday,
      };

    case UPDATE_AUTH_ADS_COUNT[SET]:
    case FETCH_ADS[SUCCESS]:
      return {
        ...state,
        totalAds: payload.totalAds,
        totalAdsToday: payload.totalAdsToday,
      };
    case RESET_NEW_ADS_COUNT[RESET]:
      return { ...state, totalAdsToday: 0 };
    case LOGOUT[SUCCESS]:
      return { ...logoutState };
    case REFRESH_TOKEN[SUCCESS]:
      return {
        ...state,
        token: payload.token,
        refresh_token: payload.refresh_token,
      };
    default:
      return state;
  }
};

export const getIsLogged = (state) => state.auth.isLogged;
export const getIsManager = (state) => state.auth.isManager;
export const getAuthState = (state) => state.auth;
