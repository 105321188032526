import { useHistory } from 'react-router';
import useAuth from '../../App/hooks/useAuth';
import Badge from '../badge';
import { BadgeBackgroundColorEnum, BadgeTextColorEnum } from '../badge/enums';

const AdsInfos = () => {
  const { totalAds, totalAdsToday } = useAuth();
  const history = useHistory();
  return (
    <div className="dashboard-section-content">
      <div
        className="dashboard-info-card"
        style={{ backgroundImage: 'url(./images/frame-building.png)' }}
        onClick={() => history.push('/pige-classique')}
      >
        <div className="dashboard-info-card-data">
          <span className="dashboard-info-card-nb">{totalAds}</span>
          <span className="dashboard-info-card-type">Annonces publiées</span>
        </div>
        <Badge
          badgeDynamicContent={`${
            totalAdsToday === 200 ? `+ de ${totalAdsToday}` : totalAdsToday
          } nouvelles annonces`}
          bgColor={BadgeBackgroundColorEnum.RED}
          textColor={BadgeTextColorEnum.WHITE}
          isMini
        />
      </div>
    </div>
  );
};

export default AdsInfos;
