import { useState } from 'react';
import ActionNotification from '../../../components/Assets/ActionNotification';
import PasswordResetInit from '../../../components/SignPage/PasswordResetInit';
import PasswordRecoveryLink from './PasswordRecoveryLink';
import styles from './signIn.module.scss';
import SignInForm from './signInForm';
// import SignInForm from './SignInForm';

interface ISigniInProps {
  className: string;
}

function SignIn({ className }: ISigniInProps) {
  const [passwordResetInit, setPasswordResetInit] = useState(false);

  const handlePasswordRecoveryClick = () => {
    setPasswordResetInit(!passwordResetInit);
  };

  return (
    <div className={className}>
      <div className={styles.signIn}>
        <ActionNotification />
        {passwordResetInit ? (
          <>
            <PasswordResetInit />
            <PasswordRecoveryLink onClick={handlePasswordRecoveryClick} />
          </>
        ) : (
          <>
            <SignInForm />
            <PasswordRecoveryLink
              onClick={handlePasswordRecoveryClick}
              isSignInForm
            />
          </>
        )}
      </div>
    </div>
  );
}

export default SignIn;
