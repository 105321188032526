import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import useAdStudies from '../App/hooks/useAdStudies';
import useAuth from '../App/hooks/useAuth';
import useFolders from '../App/hooks/useFolders';
import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import PaginationCustom from '../components/Assets/PaginationCustom';
import SectionTitle from '../components/Assets/sectionTitle';
import SelectInput from '../components/Forms/SelectInput';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import { actionItems } from '../components/MyFoldersContent/constants';
import AdThumb from '../features/adThumb';
import { ReactComponent as PictoPerson } from '../images/picto-person.svg';
import { nbDisplay } from '../lib/constants';
import { adStudiesActions } from '../store/adStudies/actions';
import { foldersActions } from '../store/folders/actions';
import { modalsActions } from '../store/modals/actions';
import { getModalState } from '../store/modals/reducer';
import './styles/myFoldersContentPage.scss';

const collaboratorsDisplay = (collaborators) => {
  if (isEmpty(collaborators) || !collaborators) {
    return 'Aucuns';
  }
  return collaborators.map((elt) => `${elt.firstName} ${elt.lastName}`).join(', ');
};

function MyFolderContentPage() {
  const { id } = useParams();
  const [selectedAction, setSelectedAction] = useState('');
  const [minIndexTab, setMinIndexTab] = useState(0);
  const [display, setDisplay] = useState(12);
  const { selectedFolder } = useFolders(id);
  const { adStudies = [], selectedAdStudies } = useAdStudies(id);
  const { userIri } = useAuth();
  const { chooseFolder } = useSelector(getModalState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!chooseFolder) {
      setSelectedAction('');
    }
  }, [chooseFolder]);

  useEffect(() => {
    dispatch(foldersActions.setActiveFolder.set(`/folders/${id}`));

    return () => {
      dispatch(foldersActions.setActiveFolder.reset());
      dispatch(adStudiesActions.resetAdStudies.success());
      dispatch(adStudiesActions.resetSelectedAdStudies.success());
    };
  }, []);

  useEffect(() => {
    if (isEmpty(selectedAdStudies)) {
      setSelectedAction('');
    }
  }, [selectedAction]);

  const handleActionChange = (e) => {
    switch (e.target.value) {
      case 'edit':
        break;
      case 'move':
        dispatch(adStudiesActions.moveAdStudies.modal());
        break;
      case 'delete':
        dispatch(adStudiesActions.deleteAdStudies.request());
        break;
      default:
        break;
    }
    setSelectedAction(e.target.value);
  };

  const dynamicActionList = () => {
    return selectedAdStudies.length > 1
      ? actionItems.filter((e) => e.value !== 'edit')
      : actionItems;
  };

  return (
    <>
      {selectedFolder && (
        <main className="my-folders-details">
          <ActionNotification />
          <header className="my-folders-details-header">
            <SectionTitle
              mainTitle={`Dossier ${selectedFolder.name}`}
              subTitle="Dossiers de prospection"
              subTitleNav={`Gestion du dossier ${selectedFolder.name}`}
              link="/mes-dossiers"
            />
            <div className="my-folders-details-right-header">
              <PictoPerson />
              <p>
                {selectedFolder.collaborators &&
                  collaboratorsDisplay(selectedFolder.collaborators)}
              </p>
              {selectedFolder.owner.idIri === userIri && (
                <Button
                  btnType="button"
                  btnContent="MODIFIER LE DOSSIER"
                  handleClick={() => dispatch(modalsActions.modifyFolders.open())}
                  primary
                />
              )}
            </div>
          </header>

          <div className="my-folders-details-actions">
            <SelectInput
              name="display"
              label="Affichage par page"
              value={display}
              handleChange={(e) => setDisplay(e.target.value)}
              items={nbDisplay}
            />
            <SelectInput
              name="action"
              label="Actions"
              nameKey="text"
              value={selectedAction}
              disabled={selectedAdStudies.length === 0}
              handleChange={handleActionChange}
              items={dynamicActionList()}
            />
          </div>

          <section className="my-folders-details-content">
            <div className="my-folders-details-cards">
              {!adStudies ? (
                <IndividualCircularLoader size={200} />
              ) : !isEmpty(adStudies) ? (
                adStudies

                  .filter(
                    (elt, index) =>
                      index >= minIndexTab && index < minIndexTab + display
                  )
                  .map((elt) => (
                    <AdThumb
                      data={elt.ad}
                      status={elt.status}
                      key={uuidV4()}
                      dataRealId={elt.idIri}
                      type="adStudies"
                      folderId={id}
                    />
                  ))
              ) : (
                <p className="no-content">Aucune opportunité suivie</p>
              )}
            </div>
          </section>

          <PaginationCustom
            elementsQty={adStudies.length}
            itemsPerPage={parseInt(display)}
            handleMinIndexTab={setMinIndexTab}
          />
        </main>
      )}
    </>
  );
}

export default MyFolderContentPage;
