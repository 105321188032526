import { SUCCESS } from '../common/actions';
import { FETCH_PROPERTY_TYPOLOGY } from './actions';

const initialState = { propertyTypologies: [] };
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROPERTY_TYPOLOGY[SUCCESS]:
      return { ...state, propertyTypologies: payload };

    default:
      return state;
  }
};

export const getPropertyTypologiesState = (state) => state.propertyTypologies;
