import genericSort from '../../../utils/genericSort';

import { v4 as uuidV4 } from 'uuid';
import RecentDemarchesRow from './RecentDemarchesRow';
interface IRecentDemarchesContainerProps {
  procedureEvents: ProcedureEvents;
}

function RecentDemarchesContainer({
  procedureEvents,
}: IRecentDemarchesContainerProps) {
  return (
    <>
      {genericSort(procedureEvents, 'asc', 'date').map(
        (procedureEvent: ProcedureEvent, index: number) => {
          return (
            <div key={uuidV4()} className="my-folders-ad-creation-side-line">
              {index > 0 && (
                <div className="my-folders-ad-creation-side-line-deco"></div>
              )}
              <RecentDemarchesRow procedureEvent={procedureEvent} />
            </div>
          );
        }
      )}
    </>
  );
}

export default RecentDemarchesContainer;
