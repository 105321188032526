import { Route, Switch } from 'react-router-dom';

import HelmetCustom from '../components/Assets/HelmetCustom';
import Loader from '../components/Assets/Loader';
import PasswordResetPage from '../components/PasswordResetPage/PasswordReset';
import Sign from '../features/sign';

import Layout from '../layout';
import AuthRoutes from '../router/AuthRoutes';

import './app.scss';

function App() {
  return (
    <div className="app">
      <HelmetCustom />
      <Loader />
      <Layout>
        <Switch>
          <Route path="/inscription" component={Sign} />
          <Route path="/recuperation-mot-de-passe" component={PasswordResetPage} />
          <Route path="/premier-mot-de-passe" component={PasswordResetPage} />
          <AuthRoutes />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
