import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../common/actions';

// ********************************
// ** COMPANY
// ********************************
export const REGISTER = createRequestTypes('REGISTER');
export const FETCH_COMPANY = createRequestTypes('FETCH_COMPANY');
export const FETCH_COMPANY_STATS = createRequestTypes('FETCH_COMPANY_STATS');
export const UPDATE_COMPANY = createRequestTypes('UPDATE_COMPANY');

export const FETCH_PIGE_CITIES = createRequestTypes('FETCH_PIGE_CITIES');
export const CREATE_PIGE_CITIES = createRequestTypes('CREATE_PIGE_CITIES');
export const DELETE_PIGE_CITIES = createRequestTypes('DELETE_PIGE_CITIES');

export const accountActions = {
  register: {
    request: (data) => action(REGISTER[REQUEST], { payload: data }),
    success: (data) => action(REGISTER[SUCCESS], { payload: data }),
    failure: (data) => action(REGISTER[FAILURE], { payload: data }),
  },
  fetchCompany: {
    request: (data) => action(FETCH_COMPANY[REQUEST], { payload: data }),
    success: (data) => action(FETCH_COMPANY[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_COMPANY[FAILURE], { payload: data }),
  },
  fetchCompanyStats: {
    request: (data) => action(FETCH_COMPANY_STATS[REQUEST], { payload: data }),
    success: (data) => action(FETCH_COMPANY_STATS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_COMPANY_STATS[FAILURE], { payload: data }),
  },
  updateCompany: {
    request: (data) => action(UPDATE_COMPANY[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_COMPANY[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_COMPANY[FAILURE], { payload: data }),
  },
  fetchPigeParams: {
    request: (data) => action(FETCH_PIGE_CITIES[REQUEST], { payload: data }),
    success: (data) => action(FETCH_PIGE_CITIES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_PIGE_CITIES[FAILURE], { payload: data }),
  },
  createPigeParams: {
    request: (data) => action(CREATE_PIGE_CITIES[REQUEST], { payload: data }),
    success: (data) => action(CREATE_PIGE_CITIES[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_PIGE_CITIES[FAILURE], { payload: data }),
  },
  deletePigeParams: {
    request: (data) => action(DELETE_PIGE_CITIES[REQUEST], { payload: data }),
    success: (data) => action(DELETE_PIGE_CITIES[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_PIGE_CITIES[FAILURE], { payload: data }),
  },
};
