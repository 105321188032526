import React from 'react';
import PropTypes from 'prop-types';

const TableBodyRow = ({ data }) => {
  const {
    user,
    events: { activeResearch, adStudy, connexion, comparativeStudy, procedureEvent },
  } = data;
  return (
    <tr>
      <td>{user ?? ''}</td>
      <td>{connexion ?? ''}</td>
      <td>{adStudy ?? ''}</td>
      <td>{procedureEvent ?? ''}</td>
      <td>{activeResearch ?? ''}</td>
      <td>{comparativeStudy ?? ''}</td>
    </tr>
  );
};

TableBodyRow.propTypes = {
  data: PropTypes.object,
};
export default TableBodyRow;
