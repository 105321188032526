import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import LocalStorageService from '../../services/localStorageService';
import SessionStorageService from '../../services/sessionStorageService';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  LocalStorageService.setToken(store.getState().auth.token);
});

store.subscribe(() => {
  LocalStorageService.setRefreshToken(store.getState().auth.refresh_token);
});

store.subscribe(() => {
  LocalStorageService.setId(store.getState().auth.userId);
});

store.subscribe(() => {
  LocalStorageService.setCompanyId(store.getState().auth.companyId);
});
store.subscribe(() => {
  SessionStorageService.setAccountTab(
    store.getState().appManager.selectedAccountTab
  );
});
store.subscribe(() => {
  SessionStorageService.setAssistanceTab(
    store.getState().appManager.selectedAssistanceTab
  );
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
