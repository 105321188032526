import { axiosTokenInstance } from '../../services/axiosInstanceService';

export function moveAdStudies({ idIri, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(idIri, body)
      .then((response) => resolve(response.status))
      .catch((error) => reject(error));
  });
}
