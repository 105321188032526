import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { getLoaderState } from '../../store/loader/reducer';
import { getModalState } from '../../store/modals/reducer';
import './styles/Loader.scss';

function Loader() {
  const isLoading = useSelector(getLoaderState);
  const { isLateralOpen } = useSelector(getModalState);

  return isLoading ? (
    <div className={isLateralOpen ? 'loader-modal' : 'loader-container'}>
      <CircularProgress size={200} className="loader" />
    </div>
  ) : null;
}

export default Loader;
