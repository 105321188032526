import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';

import { ReactComponent as MyFolderViolet } from '../../images/my-folder-violet.svg';
import { ReactComponent as FolderBlue } from '../../images/folder-blue.svg';
import { ReactComponent as FolderBlueUser } from '../../images/folder-blue-user.svg';

function ChoseFolderElt({ elt }) {
  const folderDisplay = (isDefault, totalCollaborators) => {
    if (isDefault) {
      return <MyFolderViolet />;
    }

    if (totalCollaborators > 0) {
      return <FolderBlueUser />;
    } else if (totalCollaborators === 0) {
      return <FolderBlue />;
    }
  };
  return (
    <div className="chose-folder-line">
      <Radio value={elt.idIri} />
      {folderDisplay(elt.isDefault, elt.totalCollaborators)}
      <span>{`${elt.name}`}</span>
    </div>
  );
}

ChoseFolderElt.propTypes = {
  elt: PropTypes.object.isRequired,
};

export default ChoseFolderElt;
