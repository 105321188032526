import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getAccountState } from '../../../store/account/reducer';
import TableBodyRow from './TableBodyRow';

const TableBody = () => {
  const { companyStats } = useSelector(getAccountState);
  return (
    <>
      {companyStats.map((e) => (
        <TableBodyRow key={uuidv4()} data={e} />
      ))}
    </>
  );
};

export default TableBody;
