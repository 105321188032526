import { SUCCESS } from '../common/actions';
import { FETCH_AD_SOURCES } from './actions';

const initialState = {
  adSources: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_AD_SOURCES[SUCCESS]:
      return { ...state, adSources: payload };
    default:
      return state;
  }
}

export const getAdSourcesState = (state) => state.adSourcesReducer;
