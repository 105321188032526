import { take, call, put } from '@redux-saga/core/effects';
import { fetchDatas } from '../../services/axiosInstanceService';

import { REQUEST } from '../common/actions';
import { mapObjectParser } from '../common/parserCommon';
import onErrorLogout from '../common/sagaCommon';
// import { loaderActions } from '../loader/actions';
import { announcementsActions, FETCH_ANNOUNCEMENTS } from './action';
import { announcementsApiToStore } from './parser';

export function* handleFetchAnnouncements() {
  while (true) {
    try {
      yield take(FETCH_ANNOUNCEMENTS[REQUEST]);
      // yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDatas, '/announcements', {
        page: 1,
        itemsPerPage: 1,
      });
      if (response) {
        const parsedResponse = mapObjectParser(
          response['hydra:member'],
          announcementsApiToStore
        );

        // yield put(loaderActions.loaderDisplay.hidden());
        yield put(announcementsActions.fetchAnnouncements.success(parsedResponse));
      }
    } catch (error) {
      yield put(announcementsActions.fetchAnnouncements.failure(error));
      console.log('error login', error);
      yield onErrorLogout(error);
      // yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
