import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoDark } from '../images/logo-dark.svg';

import './styles/ErrorPage.scss';

function ErrorPage() {
  const errorCode = '404';
  const errorMessage = 'Page non trouvée !';

  return (
    <div className="error-page">
      <header className="error-page-header">
        <a href="https://prospec-immo.com/" alt="Prospec Immo" title="prospec Immo">
          <LogoDark className="error-page-logo" />
        </a>
      </header>

      <main
        className="error-page-content"
        style={{ backgroundImage: 'url(./images/bg-map.png)' }}
      >
        <h1 className="error-page-title">{`Erreur ${errorCode}`}</h1>
        <h2 className="error-page-subtitle">{errorMessage}</h2>

        <div className="error-page-links">
          <a
            href="https://prospec-immo.com/"
            alt="Prospec Immo"
            title="prospec Immo"
            className="error-page-link"
          >
            Retourner sur le site vitrine
          </a>
          <Link to="/dashboard" className="error-page-link" title="Dashboard">
            Retourner sur le dashboard
          </Link>
        </div>
      </main>
    </div>
  );
}

export default ErrorPage;
