import {
  action,
  ADD,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../../store/common/actions';

export const ACTIVE_RESEARCH_SWITCH_ALERT_UPDATE =
  'ACTIVE_RESEARCH_SWITCH_ALERT_UPDATE';
export const ACTIVE_SEARCHES_SET_CURRENT = 'ACTIVE_SEARCHES_SET_CURRENT';

export const FETCH_ACTIVE_RESEARCHES = createRequestTypes('FETCH_ACTIVE_RESEARCHES');
export const FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS = createRequestTypes(
  'FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS'
);
export const CREATE_ACTIVE_RESEARCH = createRequestTypes('CREATE_ACTIVE_RESEARCH');
export const UPDATE_ACTIVE_RESEARCH = createRequestTypes('UPDATE_ACTIVE_RESEARCH');
export const DELETE_ACTIVE_RESEARCH = createRequestTypes('DELETE_ACTIVE_RESEARCH');

export const FETCH_CURRENT_ACTIVE_RESEARCH_ADS = createRequestTypes(
  'FETCH_CURRENT_ACTIVE_RESEARCH_ADS'
);
export const RESET_CURRENT_ACTIVE_RESEARCH = createRequestTypes(
  'RESET_CURRENT_ACTIVE_RESEARCH'
);
export const FETCH_CURRENT_ACTIVE_RESEARCH = createRequestTypes(
  'FETCH_CURRENT_ACTIVE_RESEARCH'
);
export const SELECTED_ACTIVE_RESEARCHES_ADS_RESET = createRequestTypes(
  'SELECTED_ACTIVE_RESEARCHES_ADS_RESET'
);
export const SELECTED_ACTIVE_RESEARCHES_ADS = createRequestTypes(
  'SELECTED_ACTIVE_RESEARCHES_ADS'
);
export const DECREMENT_TOTAL_ADS_COUNT = createRequestTypes(
  'SELECTED_ACTIVE_RESEARCHES_ADS'
);

export const setCurrentActiveResearch = (data) => ({
  type: ACTIVE_SEARCHES_SET_CURRENT,
  payload: data,
});

export const activeResearchesActions = {
  fetchActiveResearches: {
    request: () => action(FETCH_ACTIVE_RESEARCHES[REQUEST]),
    success: (data) => action(FETCH_ACTIVE_RESEARCHES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_ACTIVE_RESEARCHES[FAILURE], { payload: data }),
  },
  fetchSingleAdsAndNewAdsInfos: {
    success: (data) =>
      action(FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS[FAILURE], { payload: data }),
  },
  fetchCurrentActiveResearch: {
    request: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH[REQUEST], { payload: data }),
    success: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH[FAILURE], { payload: data }),
  },
  resetCurrentActiveResearch: {
    reset: () => action(RESET_CURRENT_ACTIVE_RESEARCH[RESET]),
  },
  createActiveResearch: {
    request: (data) => action(CREATE_ACTIVE_RESEARCH[REQUEST], { payload: data }),
    success: (data) => action(CREATE_ACTIVE_RESEARCH[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_ACTIVE_RESEARCH[FAILURE], { payload: data }),
  },
  updateActiveResearch: {
    success: (data) => action(UPDATE_ACTIVE_RESEARCH[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_ACTIVE_RESEARCH[FAILURE], { payload: data }),
    request: (data) => action(UPDATE_ACTIVE_RESEARCH[REQUEST], { payload: data }),
  },
  decrementAd: (data) => action(DECREMENT_TOTAL_ADS_COUNT[SET], { payload: data }),
  activeResearchAlertUpdate: {
    request: (data) => action(UPDATE_ACTIVE_RESEARCH[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_ACTIVE_RESEARCH[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_ACTIVE_RESEARCH[FAILURE], { payload: data }),
  },

  deleteActiveResearch: {
    request: (data) => action(DELETE_ACTIVE_RESEARCH[REQUEST], { payload: data }),
    success: (data) => action(DELETE_ACTIVE_RESEARCH[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_ACTIVE_RESEARCH[FAILURE], { payload: data }),
  },
  selectedActiveResearches: {
    add: (data) => action(SELECTED_ACTIVE_RESEARCHES_ADS[ADD], { payload: data }),
    failure: (data) =>
      action(SELECTED_ACTIVE_RESEARCHES_ADS[FAILURE], { payload: data }),
  },
  fetchCurrentActiveResearchAds: {
    request: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH_ADS[REQUEST], { payload: data }),
    success: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH_ADS[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH_ADS[FAILURE], { payload: data }),
    reset: () =>
      action(FETCH_CURRENT_ACTIVE_RESEARCH_ADS[RESET], {
        payload: { ads: [], qty: 0 },
      }),
  },
  selectedActiveResearchesAdsReset: {
    success: () => action(SELECTED_ACTIVE_RESEARCHES_ADS_RESET[SUCCESS]),
    failure: () => action(SELECTED_ACTIVE_RESEARCHES_ADS_RESET[FAILURE]),
  },
};
