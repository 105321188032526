import { LOGOUT } from '../auth/actions';
import { MODAL_DISPLAY, RESET, SUCCESS } from '../common/actions';
import { UPDATE_MARKET_STUDY_COMMENT } from '../marketStudyComment/actions';
import {
  CREATE_MARKET_STUDY,
  DELETE_MARKET_STUDY,
  FETCH_MARKET_STUDIES,
  FETCH_MARKET_STUDY_RESULT,
  RESET_ACTIVE_MARKET_STUDY,
  SET_ACTIVE_MARKET_STUDIES,
  UPDATE_MARKET_STUDY,
  UPDATE_MARKET_STUDY_RESULT_COMMENT,
} from './actions';

const initialState = {
  deleteModal: { idIri: null, name: null },
  marketStudies: null,
  marketStudyResult: null,
  pdfDatas: null,
  marketStudyActive: null,
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_MARKET_STUDIES[SUCCESS]:
      return { ...state, marketStudies: payload };
    case CREATE_MARKET_STUDY[SUCCESS]:
      return { ...state, marketStudies: state.marketStudies.concat(payload) };
    case UPDATE_MARKET_STUDY_COMMENT[SUCCESS]:
    case UPDATE_MARKET_STUDY[SUCCESS]:
      return {
        ...state,
        marketStudies: state.marketStudies.map((elmt) => {
          return elmt.idIri === payload.idIri ? payload : elmt;
        }),
      };
    case DELETE_MARKET_STUDY[MODAL_DISPLAY]:
      return { ...state, deleteModal: { idIri: payload.idIri, name: payload.name } };
    case DELETE_MARKET_STUDY[RESET]:
      return {
        ...state,
        deleteModal: { idIri: null, name: null },
      };
    case DELETE_MARKET_STUDY[SUCCESS]:
      return {
        ...state,
        marketStudies: state.marketStudies.filter((elmt) => elmt.idIri !== payload),
        deleteModal: { idIri: null, name: null },
      };
    case SET_ACTIVE_MARKET_STUDIES[SUCCESS]:
      return { ...state, marketStudyActive: payload };
    case RESET_ACTIVE_MARKET_STUDY[SUCCESS]:
      return { ...state, marketStudyActive: null, marketStudyResult: [] };
    case FETCH_MARKET_STUDY_RESULT[SUCCESS]:
      return {
        ...state,
        marketStudyResult: payload.result,
        pdfDatas: payload.pdfDatas,
      };
    case UPDATE_MARKET_STUDY_RESULT_COMMENT[SUCCESS]:
      return {
        ...state,
        marketStudyResult: {
          ...state.marketStudyResult,
          comment: payload.comment,
        },
      };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getMarketStudiesState = (state) => state.marketStudies;
