import React from 'react';
const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th className="user-column">Utilisateur</th>
        <th>Connexions</th>
        <th>Annonces étudiées</th>
        <th>Démarches entreprises</th>
        <th>Recherches actives</th>
        <th>Etudes comparatives</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
