import * as yup from 'yup';

import {
  NAME,
  ZIP_CODE,
  EMAIL,
  PHONE_NUMBER,
  PASSWORD,
} from '../../utils/validation';

// SIGN UP
export const singUpInitForm = {
  name: '',
  company: '',
  address: '',
  city: '',
  zipCode: '',
  email: '',
  landlinePhone: '',
  mobilePhone: '',
};

export const signUpSchema = yup.object().shape(
  {
    name: yup.string().required(NAME.required),
    company: yup.string(),
    address: yup.string(),
    city: yup.string(),
    zipCode: yup.string().typeError(ZIP_CODE.type),
    email: yup.string().email(EMAIL.type).required(EMAIL.required),
    landlinePhone: yup.string().when(['name'], {
      is: (secondPhoneNumber) => !secondPhoneNumber,
      then: yup.string().typeError(PHONE_NUMBER.type),
    }),
    mobilePhone: yup.string().when(['phone_number'], {
      is: (phoneNumber) => !phoneNumber,
      then: yup.string().typeError(PHONE_NUMBER.type),
    }),
  },
  [
    ['username', 'name'],
    ['phone_number', 'second_phone_number'],
  ]
);

export const notifSignupTitle = `Demande d'adhésion`;
export const notifSignupContent = `Votre demande a été envoyée avec succès`;

// SIGN IN
export const signInInitForm = {
  username: '',
  password: '',
};

export const signInSchema = yup.object({
  username: yup.string().email(EMAIL.type).required(EMAIL.required),
  password: yup.string().min(4, PASSWORD.min).required(PASSWORD.required),
});

// PASSWORD CHANGE
export const passwordResetInitForm = {
  username: '',
};

export const passwordResetInitSchema = yup.object({
  username: yup.string().email(EMAIL.type).required(EMAIL.required),
});
