import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function CheckBox({ checked, handleChange, label, name, labelPlacement }) {
  if (label) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={name}
            color="primary"
          />
        }
        label={label}
        labelPlacement={labelPlacement ? labelPlacement : 'end'}
      />
    );
  }
  return <Checkbox checked={checked} onChange={handleChange} name={name} />;
}

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CheckBox;
