import { action, createRequestTypes, RESET, SET } from '../common/actions';

export const SET_AD_COMPARISONS_SELECTED = createRequestTypes(
  'SET_AD_COMPARISONS_SELECTED'
);

export const comparativeStudyComparisonsSelectActions = {
  setAdSelected: {
    set: (data) => action(SET_AD_COMPARISONS_SELECTED[SET], { payload: data }),
    reset: () => action(SET_AD_COMPARISONS_SELECTED[RESET]),
  },
};
