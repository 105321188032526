import { take, call, put } from '@redux-saga/core/effects';
import { REQUEST } from '../common/actions';
import { loaderActions } from '../loader/actions';
import {
  collaboratorsActions,
  CREATE_COLLABORATOR,
  FETCH_COLLABORATORS,
  UPDATE_COLLABORATOR,
} from './actions';
import onErrorLogout from '../common/sagaCommon';
import { select } from 'redux-saga/effects';
import { getAuthState } from '../auth/reducer';
import { appManagerActions } from '../appManager/actions';
import { collaboratorNotifications } from './notifications';
import {
  createEntity,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { collaboratorApiToStore, collaboratorFormToApi } from './parser';

export function* handleFetchCollaborators() {
  while (true) {
    try {
      yield take(FETCH_COLLABORATORS[REQUEST]);
      const { user } = yield select(getAuthState);
      yield put(loaderActions.loaderDisplay.visible());

      const response = yield call(fetchDatas, `${user.company.idIri}/users`);

      if (response) {
        const parsedResponse = response['hydra:member'].map((e) =>
          collaboratorApiToStore(e)
        );

        const collaborators = parsedResponse.filter(
          (elmt) => elmt.idIri !== user.idIri
        );
        yield put(collaboratorsActions.fetchCollaborators.success(collaborators));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchUsers', error);
      yield onErrorLogout(error);
      yield put(collaboratorsActions.fetchCollaborators.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleCreateCollaborator() {
  while (true) {
    try {
      const { payload } = yield take(CREATE_COLLABORATOR[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());

      const parsedBody = collaboratorFormToApi(payload);
      const response = yield call(createEntity, {
        endpoint: '/users',
        body: parsedBody,
      });

      if (response) {
        const parsedResponse = collaboratorApiToStore(response);

        yield put(collaboratorsActions.createCollaborator.success(parsedResponse));
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            collaboratorNotifications
              .create(`${response.first_name} ${response.last_name}`)
              .success()
          )
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error createUser', error);
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          collaboratorNotifications.create().failure()
        )
      );
      yield put(collaboratorsActions.createCollaborator.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleUpdateCollaborator() {
  while (true) {
    try {
      const {
        payload: { idIri, body },
      } = yield take(UPDATE_COLLABORATOR[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());

      const parsedBody = collaboratorFormToApi(body);
      const response = yield call(updateEntity, { idIri, body: parsedBody });

      if (response) {
        const parsedResponse = collaboratorApiToStore(response);

        yield put(collaboratorsActions.updateCollaborator.success(parsedResponse));
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            collaboratorNotifications
              .update(`${response.first_name} ${response.last_name}`)
              .success()
          )
        );

        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error updateUser', error);
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          collaboratorNotifications.update().failure()
        )
      );
      yield put(collaboratorsActions.updateCollaborator.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
