import ActionNotification from '../components/Assets/ActionNotification';
import SectionTitle from '../components/Assets/sectionTitle';
import ComparativeStudyForm from '../components/ComparativeStudies/ComparativeStudyForm';

import './styles/comparativeStudyFormPage.scss';

const ComparativeStudyFormPage = () => {
  return (
    <main className="comparative-studies-form-wrapper">
      <header>
        <ActionNotification />
        <SectionTitle
          mainTitle={`Etude comparative`}
          subTitle="Visualisez et selectionnez vos études"
        />
      </header>
      <ComparativeStudyForm />
    </main>
  );
};

export default ComparativeStudyFormPage;
