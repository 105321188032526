const CardHeader = ({
  totalNewAds,
  name,
  owner,
  cityIdRadiusOrigin,
  radius,
}: Pick<
  IActiveResearch,
  'totalNewAds' | 'name' | 'owner' | 'cityIdRadiusOrigin' | 'radius'
>) => {
  const withAround = cityIdRadiusOrigin ? `et ${radius} kms alentours` : '';

  return (
    <header className="active-search-card-header">
      <div className="active-search-card-header-elt">
        <h4 className="active-search-card-title">{`${name} ${withAround}`}</h4>
        <span className="active-search-card-subtitle">
          {owner.firstName ? `${owner.firstName} ${owner.lastName}` : 'Indéfini'}
        </span>
      </div>
      {totalNewAds > 0 && (
        <div className="active-search-card-updates">{totalNewAds}</div>
      )}
    </header>
  );
};

export default CardHeader;
