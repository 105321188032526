import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';
import KeywordsBloc from '../components/ActiveResearches/ActiveResearchForm/KeywordsBloc';
import SellerNameBloc from '../components/ActiveResearches/ActiveResearchForm/SellerNameBloc';
import useActiveResearches from '../features/activeResearch/useActiveResearches';
// import AutocompleteInputAdSources from '../components/Forms/AutocompleteInputAdSources';
import {
  createInitialValues,
  dpeGes,
  radiusValues,
  schema,
} from '../components/ActiveResearches/Form/constant';
import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';
import AutocompleteInputCities from '../components/Forms/AutocompleteInputCities';
import AutocompleteInputCollaborators from '../components/Forms/AutocompleteInputCollaborators';
import AutocompleteInputPropertyTypology from '../components/Forms/AutocompleteInputPropertyTypology';
import AutocompleteInputSellerType from '../components/Forms/AutocompleteInputSellerTypes';
import CheckBox from '../components/Forms/CheckBox';
import DateInput from '../components/Forms/DateInput';
import SelectInput from '../components/Forms/SelectInput';
import SwitchButton from '../components/Forms/SwitchButton';
import TextInput from '../components/Forms/TextInput';
import { activeResearchesActions } from '../features/activeResearch/actions';
import { roomsMax, roomsMin } from '../lib/constants';
import { subscriptionActions } from '../store/subscription/actions';
import './styles/activeResearchFormPage.scss';

const ActiveResearchFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { currentActiveResearch } = useActiveResearches({ activeResearchId: id });
  const dispatch = useDispatch();
  const { userIri } = useAuth();
  const history = useHistory();
  useEffect(() => {
    return () => {
      dispatch(activeResearchesActions.resetCurrentActiveResearch.reset());
      dispatch(subscriptionActions.resetSubscriptionCities.reset());
    };
  }, []);

  const formik = useFormik<IActiveResearchFormValues>({
    initialValues: !isEmpty(currentActiveResearch)
      ? currentActiveResearch
      : {
          ...createInitialValues,
          owner: userIri,
        },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        dispatch(
          activeResearchesActions.updateActiveResearch.request({
            idIri: currentActiveResearch.idIri,
            body: values,
            history,
          })
        );
      } else {
        dispatch(
          activeResearchesActions.createActiveResearch.request({
            body: values,
            history,
          })
        );
      }
    },
  });

  const handleNullOrIntValue = (e: any) => {
    const intValue = parseInt(e.target.value, 10);
    const value =
      e.target.value === '' ? null : isNaN(intValue) ? e.target.value : intValue;
    formik.setFieldValue(e.target.name, value);
  };
  const handleCancel = () => {
    history.goBack();
  };

  const { values, errors, touched } = formik;

  const handleChangeCity = (name: string, values: Cities) => {
    formik.setFieldValue(name, values);
    if (values.length !== 1) {
      formik.setFieldValue('radius', null);
      formik.setFieldValue('hasCityRadius', false);
      formik.setFieldValue('cityIdRadiusOrigin', null);
    }
  };

  const handleChangeRadius = (value: any) => {
    if (value) {
      formik.setFieldValue('radius', value);
      formik.setFieldValue('hasCityRadius', true);
      formik.setFieldValue('cityIdRadiusOrigin', values.cities[0].id);
    } else {
      formik.setFieldValue('radius', null);
      formik.setFieldValue('hasCityRadius', false);
      formik.setFieldValue('cityIdRadiusOrigin', null);
    }
  };

  return (
    <main className="active-search-edit">
      <ActionNotification />
      <header>
        <SectionTitle mainTitle="Modifiez une recherche active" />
      </header>
      <section className="active-search-edit-form">
        <form
          noValidate
          className="active-search-form"
          onKeyDown={(e) => {
            if (e.code === 'Enter') e.preventDefault();
          }}
        >
          <div className="active-search-form-group">
            <div className="active-search-form-column">
              <TextInput
                name="name"
                label="Titre de la recherche active"
                value={values.name}
                handleChange={formik.handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name ? (errors.name as string) : ''}
                required
              />
              <AutocompleteInputCities
                name="cities"
                value={values.cities ?? []}
                handleChange={handleChangeCity}
                error={touched.cities && Boolean(errors.cities)}
                helperText={(touched.cities && (errors.cities as string)) ?? ''}
                multiple
                isRa
              />
              <div className="active-search-form-group">
                <div className="radius-label">
                  Recherche dans un périmètre autours d’une commune
                </div>
                <div className="radius-select">
                  <SelectInput
                    label="Rayon"
                    name="radius"
                    value={values.radius ?? ''}
                    nameKey="text"
                    handleChange={(e) => handleChangeRadius(e.target.value)}
                    error={Boolean(errors.radius)}
                    helperText={errors.radius as string}
                    items={radiusValues}
                    disabled={values.cities.length > 1}
                  />
                </div>
              </div>
              <AutocompleteInputPropertyTypology
                name="propertyTypologies"
                value={values.propertyTypologies ?? []}
                handleChange={formik.setFieldValue}
                error={
                  touched.propertyTypologies && Boolean(errors.propertyTypologies)
                }
                helperText={
                  touched.propertyTypologies && (errors.propertyTypologies as string)
                }
                multiple
              />
              <div className="active-search-form">
                <div className="active-search-form-group">
                  <SelectInput
                    label="Nombre de pièces minimum"
                    name="roomMin"
                    value={values.roomMin ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.roomMin && Boolean(errors.roomMin)}
                    helperText={touched.roomMin ? (errors.roomMin as string) : ''}
                    items={roomsMin}
                  />
                  <SelectInput
                    label="Nombre de pièces maximum"
                    name="roomMax"
                    value={values.roomMax ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.roomMax && Boolean(errors.roomMax)}
                    helperText={touched.roomMax ? (errors.roomMax as string) : ''}
                    items={roomsMax}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="priceMin"
                    label="Prix minimum"
                    value={values.priceMin}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.priceMin && Boolean(errors.priceMin)}
                    helperText={touched.priceMin ? (errors.priceMin as string) : ''}
                  />
                  <TextInput
                    name="priceMax"
                    label="Prix maximum"
                    value={values.priceMax}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.priceMax)}
                    helperText={errors.priceMax as string}
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="sqmPriceMin"
                    label="Prix/m² minimum"
                    value={values.sqmPriceMin}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.sqmPriceMin && Boolean(errors.sqmPriceMin)}
                    helperText={
                      touched.sqmPriceMin ? (errors.sqmPriceMin as string) : ''
                    }
                  />
                  <TextInput
                    name="sqmPriceMax"
                    label="Prix/m² maximum"
                    value={values.sqmPriceMax}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={Boolean(errors.sqmPriceMax)}
                    helperText={
                      touched.sqmPriceMax ? (errors.sqmPriceMax as string) : ''
                    }
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="builtAreaMin"
                    label="Surface bâtie minimale"
                    value={values.builtAreaMin}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.builtAreaMin && Boolean(errors.builtAreaMin)}
                    helperText={
                      touched.builtAreaMin ? (errors.builtAreaMin as string) : ''
                    }
                  />
                  <TextInput
                    name="builtAreaMax"
                    label="Surface bâtie maximale"
                    value={values.builtAreaMax}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.builtAreaMax && Boolean(errors.builtAreaMax)}
                    helperText={
                      touched.builtAreaMax ? (errors.builtAreaMax as string) : ''
                    }
                  />
                </div>
                <div className="active-search-form-group">
                  <TextInput
                    name="landAreaMin"
                    label="Surface terrain minimale"
                    value={values.landAreaMin}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.landAreaMin && Boolean(errors.landAreaMin)}
                    helperText={
                      touched.landAreaMin ? (errors.landAreaMin as string) : ''
                    }
                  />
                  <TextInput
                    name="landAreaMax"
                    label="Surface terrain maximale"
                    value={values.landAreaMax}
                    type="number"
                    handleChange={handleNullOrIntValue}
                    error={touched.landAreaMax && Boolean(errors.landAreaMax)}
                    helperText={
                      touched.landAreaMax ? (errors.landAreaMax as string) : ''
                    }
                  />
                </div>
                <div className="active-search-form-group">
                  <SelectInput
                    label="DPE minimum"
                    name="dpeMin"
                    value={values.dpeMin ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.dpeMin && Boolean(errors.dpeMin)}
                    helperText={touched.dpeMin ? (errors.dpeMin as string) : ''}
                    items={dpeGes('dpe')}
                  />
                  <SelectInput
                    label="DPE maximum"
                    name="dpeMax"
                    value={values.dpeMax ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.dpeMax && Boolean(errors.dpeMax)}
                    helperText={touched.dpeMax ? (errors.dpeMax as string) : ''}
                    items={dpeGes('dpe')}
                  />
                </div>
                <div className="active-search-form-group">
                  <SelectInput
                    label="GES minimum"
                    name="gesMin"
                    value={values.gesMin ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.gesMin && Boolean(errors.gesMin)}
                    helperText={touched.gesMin ? (errors.gesMin as string) : ''}
                    items={dpeGes('ges')}
                  />
                  <SelectInput
                    label="GES maximum"
                    name="gesMax"
                    value={values.gesMax ?? ''}
                    nameKey="text"
                    handleChange={handleNullOrIntValue}
                    error={touched.gesMax && Boolean(errors.gesMax)}
                    helperText={touched.gesMax ? (errors.gesMax as string) : ''}
                    items={dpeGes('ges')}
                  />
                </div>
              </div>
            </div>
            <div className="active-search-form-column">
              <AutocompleteInputCollaborators
                name="collaborators"
                value={values.collaborators ?? []}
                handleChange={formik.setFieldValue}
                multiple
              />

              <AutocompleteInputSellerType
                name="sellerTypes"
                onChange={formik.setFieldValue}
                value={values.sellerTypes}
                multiple
              />
              <DateInput
                name="limitFromDate"
                label="Annonces émises depuis le"
                value={values.limitFromDate}
                handleChange={formik.setFieldValue}
                helperText={errors.limitFromDate as string}
              />

              <SellerNameBloc formik={formik} />

              <KeywordsBloc formik={formik} />

              <div className="active-search-form-checkbox">
                <SwitchButton
                  name="isAlertEnabled"
                  checked={values.isAlertEnabled ?? false}
                  handleChange={formik.handleChange}
                  label="Recevoir des alertes"
                />
                <CheckBox
                  name="isGoodDealsOnly"
                  checked={values.isGoodDealsOnly ?? false}
                  handleChange={formik.handleChange}
                  label="Les bons coups uniquement"
                />
                <CheckBox
                  name="isExclusiveOnly"
                  checked={values.isExclusiveOnly ?? false}
                  handleChange={formik.handleChange}
                  label="Bien en exclu"
                />
              </div>
            </div>
          </div>

          <div className="active-search-form-btn-group">
            <Button
              btnType="button"
              btnContent="ANNULER"
              handleClick={handleCancel}
              textColor="violet"
              bgColor="white"
              borderColor="grey"
            />
            <Button
              btnType="submit"
              btnContent="ENREGISTRER"
              handleClick={formik.handleSubmit}
              primary
            />
          </div>
        </form>
      </section>
    </main>
  );
};

export default ActiveResearchFormPage;
