import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import useAuth from '../../App/hooks/useAuth';
import useAds from '../../App/hooks/useAds';

import SelectInput from '../Forms/SelectInput';

import { adActions } from '../../store/ads/actions';
import { adStudiesActions } from '../../store/adStudies/actions';
import { pieceworksActions } from './constant';

import { ReactComponent as CheckWhite } from '../../images/check-white.svg';

function InfoBox() {
  const [action, setAction] = useState('');
  const { totalAds, totalAdsToday } = useAuth();
  const { selectedAds } = useAds();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setAction(e.target.value);
    switch (e.target.value) {
      case 'study':
        dispatch(adStudiesActions.createAdStudies.modal());
        break;
      case 'delete':
        dispatch(
          adActions.deleteAds.modal({
            display: true,
            quantity: selectedAds.length,
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedAds.length === 0) {
      setAction('');
    }
  }, [selectedAds]);

  return (
    <div className="piecework-info-box">
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-nb">{totalAds}</span>
        <span className="piecework-info-box-type">Annonces</span>
      </div>
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-nb">{totalAdsToday}</span>
        <span className="piecework-info-box-type">{`Aujourd'hui`}</span>
      </div>
      <div className="piecework-info-box-elt">
        <span className="piecework-info-box-feedback">
          <CheckWhite />
          {` ${selectedAds.length} annonce(s) sélectionnée(s)`}
        </span>
        <form className="piecework-info-box-form">
          <SelectInput
            name="actions"
            value={action}
            label="ACTIONS"
            nameKey="text"
            handleChange={handleChange}
            disabled={isEmpty(selectedAds)}
            items={pieceworksActions(isEmpty(selectedAds))}
            colorDisplay="negative"
          />
        </form>
      </div>
    </div>
  );
}

export default InfoBox;
