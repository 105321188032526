import { v4 as uuidv4 } from 'uuid';
import PlotMarker from './PlotMarker';

interface PlotMarkerContainerProps {
  adPlots: AdPlot[];
}
const PlotMarkerContainer = ({ adPlots = [] }: PlotMarkerContainerProps) => {
  return (
    <>
      {adPlots.map((elmt) => (
        <PlotMarker
          key={uuidv4()}
          lat={elmt.lat}
          lng={elmt.long}
          address={elmt.address}
        />
      ))}
    </>
  );
};

export default PlotMarkerContainer;
