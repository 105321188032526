import { call, put, select, take } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import { createEntity, fetchDatas } from '../../services/axiosInstanceService';
import { citiesApiToStoreParser } from '../../utils/cityParser';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import { modalsActions } from '../modals/actions';
import {
  ASK_FOR_SUBSCRIPTION_UPDATE,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_CITIES,
  subscriptionActions,
} from './actions';
import { fetchSubsciption } from './api';
import { subscriptionApiToStore } from './parser';

export function* handleFetchSubscription() {
  while (true) {
    try {
      yield take(FETCH_SUBSCRIPTION[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      const { user } = yield select(getAuthState);
      if (!isEmpty(user)) {
        const response = yield call(fetchSubsciption, user.company.subscriptionIri);

        if (response) {
          const parsedData = subscriptionApiToStore(response);
          yield put(loaderActions.loaderDisplay.hidden());
          yield put(subscriptionActions.fetchSubscription.success(parsedData));
        }
      } else {
        subscriptionActions.fetchSubscriptionCities.failure();
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(subscriptionActions.fetchSubscription.failure(error));
    }
  }
}

export function* handleFetchSubscriptionCities() {
  while (true) {
    try {
      const {
        payload: { endpoint, pattern },
      } = yield take(FETCH_SUBSCRIPTION_CITIES[REQUEST]);

      const response = yield call(fetchDatas, `${endpoint}/cities`, {
        name: pattern,
        page: 1,
      });

      const parsedResponse = citiesApiToStoreParser(response['hydra:member']);

      if (response) {
        yield put(
          subscriptionActions.fetchSubscriptionCities.success(parsedResponse)
        );
      } else {
        yield put(subscriptionActions.fetchSubscriptionCities.failure());
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(subscriptionActions.fetchSubscriptionCities.failure(error));
    }
  }
}

export function* handleAskForSubscriptionUpdate() {
  while (true) {
    try {
      const { payload } = yield take(ASK_FOR_SUBSCRIPTION_UPDATE[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(createEntity, {
        endpoint: '/subscription-demands',
        body: payload,
      });

      if (response) {
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(subscriptionActions.askForSubscriptionUpdate.success(payload));
        yield put(modalsActions.subscriptionSummary.open());
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(subscriptionActions.askForSubscriptionUpdate.failure());
    }
  }
}
