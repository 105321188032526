import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import './loader.scss';

const IndividualCircularLoader = ({ size = 200 }) => {
  return (
    <div className="loader-circular">
      <CircularProgress size={size} />
    </div>
  );
};

IndividualCircularLoader.propTypes = {
  size: PropTypes.number,
};
export default IndividualCircularLoader;
