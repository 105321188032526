import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';
import usePropertyTypologies from '../../App/hooks/usePropertyTypologies';

const AutocompleteInputPropertyTypology = ({
  multiple = false,
  value,
  name = 'property_typology',
  handleChange,
  error,
  helperText,
  placeholder = 'Choisir un type de bien',
  idReducedPropertyTypologies = false,
  required,
}) => {
  const {
    propertyTypologies = [],
    reducedPropertyTypologies = {},
  } = usePropertyTypologies();

  const handleChangeValue = (name, value) => {
    let parsedValue;

    if (multiple) {
      if (isEmpty(value)) {
        parsedValue = [{ idIri: '', name: 'Tous' }];
      } else if (value.length > 1 && value.map((e) => e.idIri).includes('')) {
        parsedValue = value.filter((e) => e.idIri !== '');
      } else parsedValue = value;
    } else {
      parsedValue = value;
    }
    handleChange(name, parsedValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      filterSelectedOptions
      options={
        idReducedPropertyTypologies
          ? reducedPropertyTypologies()
          : propertyTypologies
      }
      getOptionLabel={(option) => {
        // console.log(option, !isEmpty(option) ? option.name : '');
        return !isEmpty(option) ? option.name : '';
      }}
      getOptionSelected={(option, value) => {
        // console.log(option, value);
        return option.idIri === value.idIri;
      }}
      name={name}
      value={!isEmpty(value) ? value : multiple ? [] : {}}
      onChange={(evt, value) => handleChangeValue(name, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label="Type(s) de bien(s)"
          placeholder={placeholder}
          name={name}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

AutocompleteInputPropertyTypology.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  idReducedPropertyTypologies: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default AutocompleteInputPropertyTypology;
