import { LOGOUT } from '../auth/actions';
import { HIDDEN, SUCCESS, VISIBLE } from '../common/actions';
import { LOADER_SPINNER } from './actions';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADER_SPINNER[VISIBLE]:
      return { ...state, isLoading: true };
    case LOADER_SPINNER[HIDDEN]:
      return { ...state, isLoading: false };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getLoaderState = (state) => state.loader.isLoading;
