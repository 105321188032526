import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { initialValues, schema } from './PigeParametersTab/form';
import { accountActions } from '../../store/account/actions';
import usePigeParams from '../../App/hooks/usePigeParams';
import useAuth from '../../App/hooks/useAuth';

import './PigeParametersTab/pigeParametersTab.scss';
import AutocompleteInputCities from '../Forms/AutocompleteInputCities';
import PigeParametersCitiesList from './PigeParametersTab/PigeParametersCitiesList';
import Button from '../Assets/Button';

const PigeParametersTab = () => {
  const dispatch = useDispatch();
  const { pigeParams } = usePigeParams();
  const { marketResearchIri } = useAuth();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.cities = values.cities
        .map((item) => item['idIri'])
        .concat(pigeParams.map((item) => item['@id']));

      dispatch(
        accountActions.createPigeParams.request({
          endpoint: marketResearchIri,
          body: values,
        })
      );
      formik.resetForm();
    },
  });

  const { values, touched, errors } = formik;

  return (
    <section className="my-account-content user-pige ">
      <h2>Paramètres Pige</h2>
      <div className="user-pige-wrapper">
        <form className="user-pige-section">
          <div className="user-pige-form-elt">
            <AutocompleteInputCities
              name="cities"
              value={values.cities ?? []}
              handleChange={formik.setFieldValue}
              error={touched.cities && Boolean(errors.cities)}
              helperText={touched.cities && errors.cities}
              multiple
            />
          </div>
          <Button
            btnType="submit"
            btnContent="RAJOUTER"
            primary
            handleClick={formik.handleSubmit}
          />
        </form>
        <div className="user-pige-section">
          <PigeParametersCitiesList />
        </div>
      </div>
    </section>
  );
};

export default PigeParametersTab;
