import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCitiesByPattern } from '../../services/fetchCities';
import { subscriptionActions } from '../../store/subscription/actions';
import useAuth from './useAuth';

const PATTERN_LENGTH = 3;

export default function useCities(full = false) {
  const [cities, setCities] = useState<Cities | null>(null);
  const [pattern, setPattern] = useState<string | null>(null);
  const { subscriptionIri } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(cities) && subscriptionIri && full) {
      dispatch(
        subscriptionActions.fetchSubscriptionCities.request({
          endpoint: subscriptionIri,
        })
      );
    }
  }, [cities, subscriptionIri]);

  const searchPattern = async (pattern: string) => {
    setPattern(pattern ?? null);
    if (pattern.length >= PATTERN_LENGTH && !full) {
      const result = await fetchCitiesByPattern(subscriptionIri, pattern);

      setCities(result);
    }
  };

  return { cities, searchPattern, pattern, setPattern };
}
