import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles/ActionCard.scss';

import { ReactComponent as Criteria } from '../../images/criteria.svg';
import { ReactComponent as FolderViolet } from '../../images/folder-violet.svg';
import { ReactComponent as Add } from '../../images/add.svg';

function ActionCard({
  cardType,
  cardTitle,
  cardInfo,
  cardUpdates,
  cardState,
  linkTo,
  handleClick,
}) {
  const iconDisplay = (ct) => {
    switch (ct) {
      case 'active-search':
        return <Criteria />;
      case 'prospection':
        return <FolderViolet />;
      default:
        break;
    }
  };
  if (linkTo) {
    return (
      <Link className="action-card" to={linkTo}>
        {cardState === 'filled' ? (
          <div className="action-card-content">
            <div className="action-card-elts">
              {iconDisplay(cardType)}
              <span className="action-card-title">{cardTitle}</span>
              <span className="action-card-infos">{`${cardInfo} annonces`}</span>
            </div>
            <div className="action-card-elts">
              <div className="action-card-update-nb">{cardUpdates}</div>
              <span className="action-card-text">Mise à jour</span>
            </div>
          </div>
        ) : (
          <div className="action-card-content-add">
            <div className="action-card-add">
              <Add />
            </div>
            <span className="action-card-text">{cardTitle}</span>
          </div>
        )}
      </Link>
    );
  } else {
    return (
      <div className="action-card" onClick={handleClick}>
        {cardState === 'filled' ? (
          <div className="action-card-content">
            <div className="action-card-elts">
              {iconDisplay(cardType)}
              <span className="action-card-title">{cardTitle}</span>
              <span className="action-card-infos">{`${cardInfo} annonces`}</span>
            </div>
            <div className="action-card-elts">
              <div className="action-card-update-nb">{cardUpdates}</div>
              <span className="action-card-text">nouvelles annonces</span>
            </div>
          </div>
        ) : (
          <div className="action-card-content-add">
            <div className="action-card-add">
              <Add />
            </div>
            <span className="action-card-text">{cardTitle}</span>
          </div>
        )}
      </div>
    );
  }
}

ActionCard.propTypes = {
  cardType: PropTypes.string,
  cardTitle: PropTypes.string.isRequired,
  cardInfo: PropTypes.number,
  cardUpdates: PropTypes.number,
  cardState: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  handleClick: PropTypes.func,
};

export default ActionCard;
