import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { foldersActions } from '../../store/folders/actions';

import LogoButton from '../Assets/LogoButton';
import Badge from '../badge';

import useAuth from '../../App/hooks/useAuth';
import useFolders from '../../App/hooks/useFolders';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../badge/enums';
import FolderLineIcon from './ForlderLineIcon';

function FolderLine(props) {
  const {
    collaborators,
    name,
    ads,
    id,
    idIri,
    hasUpdates,
    ownerId,
    isDefault,
  } = props;
  const { userIri } = useAuth();

  const { activeFolder } = useFolders();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSetActive = () => {
    if (activeFolder && activeFolder === idIri) {
      dispatch(foldersActions.setActiveFolder.reset());
    } else {
      dispatch(foldersActions.setActiveFolder.set(idIri));
    }
  };

  const isOwner = ownerId === userIri;

  return (
    <tr className={isDefault ? 'my-folders-content default' : 'my-folders-content'}>
      <td>
        <FolderLineIcon {...props} />
      </td>
      <td className="my-folders-desktop">
        {hasUpdates && (
          <Badge
            badgeContent={BadgeContentEnum.MISE_A_JOUR}
            bgColor={BadgeBackgroundColorEnum.GREEN}
            textColor={
              isDefault ? BadgeTextColorEnum.VIOLET : BadgeTextColorEnum.WHITE
            }
            isMini
          />
        )}
      </td>
      {isDefault ? (
        <td>Par défaut</td>
      ) : (
        <td className="my-folders-desktop">{`${collaborators} collaborateur${
          collaborators > 1 ? 's' : ''
        }`}</td>
      )}
      <td className="my-folders-desktop">
        <Badge
          badgeDynamicContent={`${ads} annonces`}
          bgColor={
            isDefault
              ? BadgeBackgroundColorEnum.VIOLET
              : BadgeBackgroundColorEnum.GREY
          }
          textColor={
            isDefault ? BadgeTextColorEnum.WHITE : BadgeTextColorEnum.DARKER_GREY
          }
          isMini
        />
      </td>
      <td className="my-folders-mobile">
        <LogoButton
          btnType="button"
          handleClick={handleSetActive}
          btnLogo="threePoints"
          tooltip={`Actions`}
        />
      </td>
      <td className="my-folders-desktop">
        {isOwner && !isDefault && (
          <div className="my-folders-actions">
            <LogoButton
              btnType="button"
              handleClick={() => history.push(`/mes-dossiers-modification/${id}`)}
              btnLogo="pen-violet"
              tooltip={`Modifier`}
            />
            <LogoButton
              btnType="button"
              handleClick={() =>
                dispatch(
                  foldersActions.deleteFolder.modal({
                    display: true,
                    idIri,
                    name,
                  })
                )
              }
              btnLogo="delete-red"
              tooltip={`Supprimer`}
            />
          </div>
        )}
      </td>
    </tr>
  );
}

FolderLine.propTypes = {
  name: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  collaborators: PropTypes.number.isRequired,
  ads: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  idIri: PropTypes.string.isRequired,
  hasUpdates: PropTypes.bool,
  isDefault: PropTypes.bool,
};

export default FolderLine;
