import { RESET, SET } from '../common/actions';
import { SET_AD_COMPARISONS_SELECTED } from './actions';

const initialState = {
  lower: [],
  equal: [],
  higher: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AD_COMPARISONS_SELECTED[SET]: {
      const temp = state[payload.tab];
      let exists = Boolean(temp.find((e) => e.idIri === payload.data.idIri));

      return {
        ...state,
        [payload.tab]: exists
          ? temp.filter((e) => e.idIri !== payload.data.idIri)
          : temp.concat(payload.data),
      };
    }
    case SET_AD_COMPARISONS_SELECTED[RESET]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getComparativeStudyComparisonsSelectState = (state) =>
  state.comparativeStudyComparisonsSelect;
