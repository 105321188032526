import { changeApiKeyForStoreKey } from '../common/parserCommon';

const authUserMapping = {
  '@id': 'idIri',
  company: 'company',
  created_at: 'createdAt',
  deleted_at: 'deletedAt',
  email: 'email',
  enabled: 'enabled',
  first_name: 'firstName',
  full_name: 'fullName',
  has_password: 'hasPassword',
  last_name: 'lastName',
  market_research: 'marketResearch',
  phone_number: 'phoneNumber',
  picture_path: 'picturePath',
  roles: 'roles',
  second_phone_number: 'secondPhoneNumber',
  updated_at: 'updatedAt',
  username: 'username',
  enable_newsletter: 'enableNewsLetter',
};

const marketResearchMapping = {
  '@id': 'idIri',
  total_ads: 'totalAds',
  total_ads_today: 'totalAdsToday',
};

const companyMapping = {
  '@id': 'idIri',
  name: 'name',
  subscription: 'subscriptionIdIri',
};

export const authUserApiToStore = (data: any) => {
  try {
    const parsedData = changeApiKeyForStoreKey<UserDetail>(data, authUserMapping);

    parsedData.id = parseInt(parsedData.idIri.split('/')[2]);

    parsedData.fullNameWithInitial = `${
      parsedData.firstName
    } ${parsedData.lastName.charAt(0).toUpperCase()}`;
    parsedData.initials = `${parsedData.firstName.charAt(
      0
    )}${parsedData.lastName.charAt(0)}`.toUpperCase();

    parsedData.company = changeApiKeyForStoreKey(parsedData.company, companyMapping);

    parsedData.marketResearch = changeApiKeyForStoreKey<MarketResearch>(
      parsedData.marketResearch,
      marketResearchMapping
    );

    return parsedData;
  } catch (error) {
    // console.log('authUserParser', error);
  }
};
