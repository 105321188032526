const SessionStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setAccountTab(index) {
    try {
      if (index || index === 0) {
        sessionStorage.setItem('AccountTab', index);
      }
    } catch (error) {
      sessionStorage.setItem('AccountTab', null);
    }
  }

  function _getAccountTab() {
    try {
      const index = sessionStorage.getItem('AccountTab');
      if (!index) {
        return 0;
      }
      return parseInt(index, 10);
    } catch (err) {
      return null;
    }
  }

  function _setAssistanceTab(index) {
    try {
      if (index || index === 0) {
        sessionStorage.setItem('AsssitanceTab', index);
      }
    } catch (error) {
      sessionStorage.setItem('AsssitanceTab', null);
    }
  }

  function _getAssistanceTab() {
    try {
      const index = sessionStorage.getItem('AsssitanceTab');
      if (!index) {
        return 0;
      }
      return parseInt(index, 10);
    } catch (err) {
      return null;
    }
  }

  return {
    getService: _getService,
    setAccountTab: _setAccountTab,
    getAccountTab: _getAccountTab,
    setAssistanceTab: _setAssistanceTab,
    getAssistanceTab: _getAssistanceTab,
  };
})();

export default SessionStorageService;
