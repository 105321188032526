import React from 'react';
import PropTypes from 'prop-types';
import { Page, View, Text } from '@react-pdf/renderer';
import Header from './Header';
import GoodStat from './GoodStat';
import { styles } from './styles';
import BarGraph from './BarGraph';
import { isEmpty } from 'lodash';

const GoodStatPage = ({
  goodsDatas,
  name,
  barGraphAvgSrc,
  barGraphSqmSrc,
  comment,
}) => {
  return (
    <>
      {goodsDatas &&
        goodsDatas.map((g, index) => (
          <Page size="A4" key={g.name}>
            <Header name={name} />
            <View style={styles.goodSection}>
              <View style={styles.goodStatSection}>
                <Text style={styles.goodSectionTitle}>{`Type "${g.name}"`}</Text>
                <GoodStat
                  title={g.oldSale.title}
                  totalStock={g.oldSale.totalStock}
                  exploitableStock={g.oldSale.exploitableStock}
                  avgPrice={g.oldSale.avgPrice}
                  minPrice={g.oldSale.minPrice}
                  maxPrice={g.oldSale.maxPrice}
                  sqmAvgPrice={g.oldSale.sqmAvgPrice}
                  avgArea={g.oldSale.avgArea}
                />

                <GoodStat
                  title={g.oldSold.title}
                  totalStock={g.oldSold.totalStock}
                  exploitableStock={g.oldSold.exploitableStock}
                  avgPrice={g.oldSold.avgPrice}
                  minPrice={g.oldSold.minPrice}
                  maxPrice={g.oldSold.maxPrice}
                  sqmAvgPrice={g.oldSold.sqmAvgPrice}
                  avgArea={g.oldSold.avgArea}
                />

                {g.newSale && g.newSold ? (
                  <>
                    <GoodStat
                      title={g.newSale.title}
                      totalStock={g.newSale.totalStock}
                      exploitableStock={g.newSale.exploitableStock}
                      avgPrice={g.newSale.avgPrice}
                      minPrice={g.newSale.minPrice}
                      maxPrice={g.newSale.maxPrice}
                      sqmAvgPrice={g.newSale.sqmAvgPrice}
                      avgArea={g.newSale.avgArea}
                    />

                    <GoodStat
                      title={g.newSold.title}
                      totalStock={g.newSold.totalStock}
                      exploitableStock={g.newSold.exploitableStock}
                      avgPrice={g.newSold.avgPrice}
                      minPrice={g.newSold.minPrice}
                      maxPrice={g.newSold.maxPrice}
                      sqmAvgPrice={g.newSold.sqmAvgPrice}
                      avgArea={g.newSold.avgArea}
                    />
                  </>
                ) : (
                  <></>
                )}
              </View>
              <View style={styles.goodGraphSection}>
                <BarGraph
                  title="Prix moyen"
                  type="AvgPrice"
                  graphSrc={barGraphAvgSrc[index]}
                />
                <BarGraph
                  title="Prix moyen/m²"
                  type="sqmAvgPrice"
                  graphSrc={barGraphSqmSrc[index]}
                />

                <View style={styles.comment}>
                  <Text style={styles.commentLabel}>Commentaire</Text>
                  <Text>
                    {!isEmpty(comment)
                      ? comment['comment_' + (index + 1)]
                      : 'Aucun commentaire'}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        ))}
    </>
  );
};

GoodStatPage.propTypes = {
  goodsDatas: PropTypes.array,
  name: PropTypes.string,
  barGraphAvgSrc: PropTypes.array,
  barGraphSqmSrc: PropTypes.array,
  comment: PropTypes.object,
};

export default GoodStatPage;
