import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    fontSize: '18px',
    color: '#5C2670',
  },

  headerLogo: {
    marginLeft: 30,
    width: 100,
    height: 'auto',
  },

  goodSection: {
    boxSizing: 'border-box',
    flexDirection: 'row',
    margin: '10px',
    overflow: 'hidden',
    border: '1px solid #5C2670',
    borderRadius: '15px',
    fontSize: '8px',
    // width: '70%',
  },

  goodSectionTitle: {
    color: '#5C2670',
    fontWeight: 700,
    fontSize: '18px',
  },

  goodStatSection: {
    padding: 10,
    width: '70%',
  },

  comment: { marginLeft: '10px' },

  commentLabel: {
    fontWeight: 700,
    marginBottom: '10px',
  },

  goodGraphSection: {
    backgroundColor: '#f0f4f8',
    flexGrow: 1,
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    // margin: '10px',
  },

  barGraphTitle: { margin: '10px' },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2px',
  },
  statsBadge: {
    color: '#fff',
    backgroundColor: '#5C2670',
    padding: '2px',
    borderRadius: 10,
  },
  statsItem: {
    width: '100px',
  },
  statsTitle: {
    color: '#5C2670',
    // fontSize: 13,
    marginBottom: 10,
    marginTop: 5,
  },
  statsSubTitle: {
    // fontSize: 12,
    marginBottom: 5,
  },

  section: { margin: '10px' },
  graph: {
    width: '550px',
    height: '300px',
    alignSelf: 'center',
  },
});
