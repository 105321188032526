import { call, put } from '@redux-saga/core/effects';
import { appManagerActions } from '../appManager/actions';
import { authActions } from '../auth/actions';

export const createRequestQuery = (queries) => {
  let query = '?';
  const keys = Object.keys(queries);

  keys.forEach((element, i) => {
    query += element + '=' + queries[element];
    if (i < keys.length - 1) {
      query += '&';
    }
  });

  return query;
};

export default function* onErrorLogout(error) {
  if (!error) {
    return null;
  }
  if (error.request) {
    const {
      request: { status },
    } = error;

    if (status === 401) {
      yield put(authActions.logout.success());
    } else {
      return;
    }
  } else {
    return;
  }
}

export function* historyRedirect(history, link) {
  // disable prompt form on routing
  yield put(appManagerActions.formIsSubmitted.true());

  yield call(history.push, link);

  // enable prompt form on routing
  yield put(appManagerActions.formIsSubmitted.false());
}
