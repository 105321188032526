import { take, call, put, select } from '@redux-saga/core/effects';
import { fetchDataById } from '../../services/axiosInstanceService';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON } from '../comparativeStudyComparisons/actions';
import { getComparativeStudiesState } from '../comparativeStudies/reducer';
import { loaderActions } from '../loader/actions';
import { comparativeStudyComparisonsActions } from './actions';
import { comparativeStudyComparisonsApiToStore } from './parser';

export function* handleFetchComparativeStudyComparisons() {
  while (true) {
    try {
      const { payload } = yield take(
        FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON[REQUEST]
      );

      const params = {
        priceRange: payload,
        page: 1,
        itemsPerPage: 40,
        'orderBy[published_at]': 'asc',
      };

      const { comparativeStudyActive } = yield select(getComparativeStudiesState);

      const response = yield call(
        fetchDataById,
        `/comparative-studies/${comparativeStudyActive}/ads`,
        params
      );

      if (response) {
        const parsedData = response['hydra:member'].map((e) =>
          comparativeStudyComparisonsApiToStore(e)
        );

        yield put(
          comparativeStudyComparisonsActions.fetchActiveComparativeStudyComparisons.success(
            { key: payload, list: parsedData }
          )
        );
      }
    } catch (error) {
      console.log('error fetchComparativesStudyComparisons', error);
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        comparativeStudyComparisonsActions.fetchActiveComparativeStudyComparisons.failure(
          error
        )
      );
    }
  }
}
