import React from 'react';
import useFolders from '../../App/hooks/useFolders';
import { foldersActions } from '../../store/folders/actions';
import ActionModal from '../Modals/ActionModal';

import './myFoldersDeleteModal.scss';

const MyFoldersDeleteModal = () => {
  const { deleteModal } = useFolders();

  return (
    <ActionModal
      closeAction={foldersActions.deleteFolder.reset()}
      validAction={foldersActions.deleteFolder.request(deleteModal.idIri)}
      validText="SUPPRIMER"
      modalType="suppression"
    >
      <p>
        Confirmez-vous la suppression du dossier
        <span>{` ${deleteModal.name} `}</span> et de tout son contenu ?
      </p>
      <p>La suppression sera définitive.</p>
    </ActionModal>
  );
};

export default MyFoldersDeleteModal;
