import { ReactComponent as LogoWhite } from '../../../images/logo-white.svg';
import styles from './leftBand.module.scss';

const CGU_LINK = 'https://prospec-immo.com/cgu.html';
const URL_SITE = 'https://prospec-immo.com/';

const LeftBand = () => {
  return (
    <div className={styles.presentation}>
      <a href={URL_SITE} title="prospec Immo">
        <LogoWhite className={styles.logo} />
      </a>

      <h1 className={styles.title}>La prospection immobilière pour les pros</h1>

      <img src="./images/building-tree-group.png" alt="" className={styles.deco} />

      <span className={styles.copyright}>
        {`Copyright 2022 - `}
        <a className={styles.link} href={CGU_LINK} title="Mentions légales">
          {`Mentions légales - `}
        </a>
        <a className={styles.link} href={CGU_LINK} title="CGV">
          CGV
        </a>
      </span>
    </div>
  );
};

export default LeftBand;
