interface IResultLineProps {
  label: string;
  value: string | number | null;
  unit?: string;
  isFontWeight?: boolean;
}

function SummaryLine({ label, value, unit, isFontWeight }: IResultLineProps) {
  const filterNullValue = (value: string | number | null, unit = '') => {
    return <span>{value ? `${value} ${unit}` : 'non défini(e)'}</span>;
  };

  return (
    <div className="active-search-result-search-line">
      <span>{`${label} : `} </span>
      <span className={isFontWeight ? 'font-weight-line' : ''}>
        {filterNullValue(value, unit)}
      </span>
    </div>
  );
}

export default SummaryLine;
