export const subscriptionUpdateType = [
  {
    value: 'service',
    label: 'Je veux ajouter un service',
    summaryType: 'Ajout de service',
  },
  {
    value: 'geo',
    label: 'Je veux élargir mon secteur géographique',
    summaryType: 'Elargissement de secteur géographique',
  },
  {
    value: 'user',
    label: 'Je veux avoir plus de profils utilisateurs',
    summaryType: 'Ajout de profils utilisateurs',
  },
  {
    value: 'cancel',
    label: 'Je veux résilier mon abonnement',
    summaryType: "Résiliation d'abonnement",
  },
  { value: 'others', label: 'Autres services', summaryType: 'Autres' },
];

export const editSubscriptionInitForm = {
  title: 'service',
  comment: '',
};

export const editSubscriptionRadioBtnsArray = [
  { value: 'service', label: 'Je veux ajouter un service' },
  { value: 'geo', label: 'Je veux élargir mon secteur géographique' },
  { value: 'user', label: 'Je veux avoir plus de profils utilisateurs' },
  { value: 'cancel', label: 'Je veux résilier mon abonnement' },
  { value: 'others', label: 'Autres services' },
];
