import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { modalsActions } from '../../store/modals/actions';

import { ReactComponent as CloseRed } from '../../images/close-red.svg';

import './styles/Modal.scss';

function Modal({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="modal">
      <button
        type="button"
        className="modal-close"
        onClick={() => dispatch(modalsActions.modals.close())}
      >
        <CloseRed />
      </button>

      {children}
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
