import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFormIsSubmittedState } from '../../store/appManager/reducer';
import { appManagerActions } from '../../store/appManager/actions';

const defaultMessage = 'Voulez vous vraiment abandonner la saisie de ce formulaire?';

const PromptCustom = ({ when = true, message = defaultMessage }) => {
  const formIsSubmitted = useSelector(getFormIsSubmittedState);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(appManagerActions.formIsSubmitted.false());
  }, []);

  return <Prompt when={when && !formIsSubmitted} message={message} />;
};

PromptCustom.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string,
};
export default PromptCustom;
