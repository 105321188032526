import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useActiveResearches from '../../features/activeResearch/useActiveResearches';
import ActionCard from '../Assets/ActionCard';
import SectionTitle from '../Assets/sectionTitle';
import IndividualCircularLoader from '../Loader/IndividualCircularLoader';

const ActiveResearchesBloc = memo(() => {
  const history = useHistory();
  const { activeResearches } = useActiveResearches({
    dashboard: true,
    loadActiveResearches: true,
  });

  return (
    <section className="dashboard-section">
      <SectionTitle
        mainTitle="Recherches actives"
        subTitle="Créez votre veille selon vos critères..."
      />

      {!activeResearches ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <div className="dashboard-section-content">
          {activeResearches
            .filter((e) => e.totalNewAds > 0)
            .map((elt) => (
              <ActionCard
                key={uuidv4()}
                cardType="active-search"
                cardTitle={elt.name}
                cardInfo={elt.totalAds ?? 0}
                cardUpdates={elt.totalNewAds ?? 0}
                cardState="filled"
                handleClick={() =>
                  history.push(`/recherche-active-resultat/${elt.id}`)
                }
              />
            ))}
          <ActionCard
            cardTitle="Créez de nouveaux critères de recherche"
            cardState=""
            linkTo="/recherche-active-creation"
          />
        </div>
      )}
    </section>
  );
});

ActiveResearchesBloc.displayName = 'ActiveResearchesBloc';
export default ActiveResearchesBloc;
