import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const MARKET_STUDY_COMMENT = createRequestTypes('MARKET_STUDY_COMMENT');
export const MARKET_STUDY_COMMENT_INIT = createRequestTypes(
  'MARKET_STUDY_COMMENT_INIT'
);
export const UPDATE_MARKET_STUDY_COMMENT = createRequestTypes(
  'UPDATE_MARKET_STUDY_COMMENT'
);

export const marketStudyCommentActions = {
  marketStudyCommentInit: {
    set: (data) => action(MARKET_STUDY_COMMENT_INIT[SET], { payload: data }),
  },
  marketStudyComment: {
    set: (data) => action(MARKET_STUDY_COMMENT[SET], { payload: data }),
    reset: () => action(MARKET_STUDY_COMMENT[RESET], { payload: {} }),
  },
  updateMarketStudyComment: {
    request: (data) =>
      action(UPDATE_MARKET_STUDY_COMMENT[REQUEST], { payload: data }),
    success: (data) =>
      action(UPDATE_MARKET_STUDY_COMMENT[SUCCESS], { payload: data }),
    failure: (data) =>
      action(UPDATE_MARKET_STUDY_COMMENT[FAILURE], { payload: data }),
  },
};
