import { changeApiKeyForStoreKey } from '../common/parserCommon';

const propertyTypologiesMapping = {
  '@id': 'idIri',
  name: 'name',
};

export const propertyTypologiesApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, propertyTypologiesMapping);

  return parsedData;
};
