import React, { createContext, useState } from 'react';

export enum SourceTypeEnum {
  AVERAGE,
  SQUARE_METER,
}

export default function CreateMarketStudyResultCtx() {
  const defaultStringValue = '';
  const defaultStringArrayValue: string[] = [];

  type UpdateStringType = React.Dispatch<React.SetStateAction<string>>;
  type UpdateStringArrayType = React.Dispatch<React.SetStateAction<string[]>>;

  type AddSrcType = (src: string, type: SourceTypeEnum) => void;

  const defaultStringUpdate: UpdateStringType = () => defaultStringValue;
  const defaultStringArrayUpdate: UpdateStringArrayType = () =>
    defaultStringArrayValue;
  const addSrcTypeFunction: AddSrcType = (src, type) => {};

  const ctx = createContext({
    sqmTypo: defaultStringValue,
    stockTypo: defaultStringValue,
    setSqmTypo: defaultStringUpdate,
    setStockTypo: defaultStringUpdate,
    barGraphAvgSrc: defaultStringArrayValue,
    setBarGraphAvgSrc: defaultStringArrayUpdate,
    barGraphSqmSrc: defaultStringArrayValue,
    setBarGraphSqmSrc: defaultStringArrayUpdate,

    addSrcToContext: addSrcTypeFunction,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [sqmTypo, setSqmTypo] = useState<string>(defaultStringValue);
    const [stockTypo, setStockTypo] = useState<string>(defaultStringValue);
    const [barGraphAvgSrc, setBarGraphAvgSrc] = useState<string[]>(
      defaultStringArrayValue
    );
    const [barGraphSqmSrc, setBarGraphSqmSrc] = useState<string[]>(
      defaultStringArrayValue
    );

    const addSrcToContext = (src: string, type: SourceTypeEnum) => {
      if (type === SourceTypeEnum.AVERAGE) {
        setBarGraphAvgSrc((prev) => prev.concat(src));
      } else {
        setBarGraphSqmSrc((prev) => prev.concat(src));
      }
    };

    return (
      <ctx.Provider
        value={{
          sqmTypo,
          setSqmTypo,
          stockTypo,
          setStockTypo,
          barGraphAvgSrc,
          setBarGraphAvgSrc,
          barGraphSqmSrc,
          setBarGraphSqmSrc,
          addSrcToContext,
        }}
        {...props}
      />
    );
  };

  return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}
