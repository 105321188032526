import { isEmpty } from 'lodash';
import {
  NEW_GOODS_SALE,
  NEW_GOODS_SOLD,
  OLD_GOODS_SALE,
  OLD_GOODS_SOLD,
} from '../../components/MarketStudyResult/constant';
import { cityApiToStore } from '../../utils/cityParser';
import { dateFormat } from '../../utils/jsFunctions';
import {
  collaboratorApiToStore,
  nestedCollaboratorApiToStore,
  nestedCollaboratorsFormToApi,
} from '../collaborators/parser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  mapObjectParser,
} from '../common/parserCommon';
import { propertyTypologiesApiToStore } from '../propertyTypologies/parser';

const marketStudyMapping = {
  '@id': 'idIri',
  city: 'city',
  created_at: 'createdAt',
  market_study_collaborators: 'collaborators',
  name: 'name',
  owner: 'owner',
  property_typologies: 'propertyTypologies',
  updated_at: 'updatedAt',
  comment: 'comment',
};

const marketStudyResultMapping = {
  average_area: 'averageArea',
  period: 'period',
  prices: 'prices',
  property_category: 'propertyCategory',
  property_typology: 'propertyTypology',
  stat_category: 'statCategory',
  stocks: 'stocks',
  total_room: 'totalRoom',
};

const marketStudyResultPriceMapping = {
  avg_price: 'avgPrice',
  highest: 'highest',
  lowest: 'lowest',
  sqm_avg_price: 'sqmAvgPrice',
  variations: 'variations',
};

const marketStudyResultStocksMapping = {
  exploitable: 'exploitable',
  total: 'total',
  variations: 'variations',
};

const marketStudyResultPeriodMapping = {
  start: 'start',
  end: 'end',
};

export const marketStudyApiToStore = (data) => {
  try {
    const parsedData = changeApiKeyForStoreKey(data, marketStudyMapping);
    parsedData.id = parsedData.idIri.split('/')[2];

    parsedData.collaborators = mapObjectParser(
      parsedData.collaborators,
      nestedCollaboratorApiToStore
    );

    parsedData.owner = collaboratorApiToStore(parsedData.owner);
    try {
      parsedData.comment =
        parsedData.comment === null || parsedData.comment === ''
          ? {}
          : JSON.parse(parsedData.comment);
    } catch (error) {
      parsedData.comment = { comment_1: parsedData.comment };
    }

    parsedData.propertyTypologies = isEmpty(parsedData.propertyTypologies)
      ? []
      : parsedData.propertyTypologies.map((e) => propertyTypologiesApiToStore(e))[0];

    parsedData.city = cityApiToStore(parsedData.city);

    return parsedData;
  } catch (error) {
    console.log('market studies parser', error);
  }
};

export const marketStudyFormToApi = (data) => {
  const parsedData = changeStoreKeyForApiKey(data, marketStudyMapping);

  if (parsedData.city) {
    parsedData.city = parsedData.city.idIri;
  }

  if (parsedData.market_study_collaborators) {
    parsedData.market_study_collaborators = nestedCollaboratorsFormToApi(
      parsedData.market_study_collaborators
    );
  }
  if (parsedData.property_typologies) {
    parsedData.property_typologies = [parsedData.property_typologies.idIri];
  }

  if (isEmpty(data.comment)) {
    delete parsedData.comment;
  }
  // parsedData.comment = isEmpty(data.comment) ? null : data.comment;

  delete parsedData.owner;
  delete parsedData.updated_at;
  delete parsedData.created_at;
  delete parsedData['@id'];

  return parsedData;
};

export const marketStudyResultApiToStore = (data) => {
  if (isEmpty(data)) {
    return [];
  }
  const parsedData = changeApiKeyForStoreKey(data, marketStudyResultMapping);

  parsedData.prices = changeApiKeyForStoreKey(
    data.prices,
    marketStudyResultPriceMapping
  );

  if (parsedData.period) {
    parsedData.period = changeApiKeyForStoreKey(
      data.period,
      marketStudyResultPeriodMapping
    );
  }

  parsedData.propertyTypology = propertyTypologiesApiToStore(data.property_typology);
  parsedData.stocks = changeApiKeyForStoreKey(
    data.stocks,
    marketStudyResultStocksMapping
  );

  return parsedData;
};

const pickupRoomData = (object, data, room) => {
  let stat = { oldSale: {}, oldSold: {}, newSale: {}, newSold: {} };
  stat.name = `${data[0].propertyTypology.name} F${room}`;

  ['old_property', 'new_property'].forEach((e) => {
    const cat = e === 'old_property' ? 'old' : 'new';

    ['sale', 'sold'].forEach((j) => {
      const state = j === 'sold' ? 'Sold' : 'Sale';
      let tempStat = {};

      const temp = data.find(
        (elt) =>
          elt.totalRoom === room &&
          elt.propertyCategory === e &&
          elt.statCategory === j
      );

      switch (cat + state) {
        case 'oldSale':
          tempStat.title = OLD_GOODS_SALE;
          break;
        case 'oldSold':
          {
            if (temp && temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                OLD_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = OLD_GOODS_SOLD + ` - `;
            }
          }
          break;
        case 'newSale':
          tempStat.title = NEW_GOODS_SALE;
          break;
        case 'newSold':
          {
            if (temp && temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                NEW_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = NEW_GOODS_SOLD + ` - `;
            }
          }
          break;
        default:
          break;
      }

      if (temp) {
        tempStat.totalStock = temp.stocks.total || 0;
        tempStat.exploitableStock = temp.stocks.exploitable || 0;
        tempStat.avgPrice = temp.prices.avgPrice || 0;
        tempStat.minPrice = temp.prices.lowest || 0;
        tempStat.maxPrice = temp.prices.highest || 0;
        tempStat.sqmAvgPrice = temp.prices.sqmAvgPrice || 0;
        tempStat.avgArea = temp.averageArea || 0;
      }

      stat[cat + state] = tempStat;
    });
  });
  object.goodsDatas.push(stat);
};

const pickupFieldData = (object, data, index) => {
  let stat = { oldSale: {}, oldSold: {} };
  if (data[index].propertyCategory === 'ground600')
    stat.name = `${data[0].propertyTypology.name} de 0 à 600 m²`;
  if (data[index].propertyCategory === 'ground1000')
    stat.name = `${data[0].propertyTypology.name} de 600 à 1000 m²`;
  if (data[index].propertyCategory === 'ground_more')
    stat.name = `${data[0].propertyTypology.name} supérieur à 1000 m²`;

  ['old_property'].forEach((e) => {
    const cat = e === 'old_property' ? 'old' : 'new';

    ['sale', 'sold'].forEach((j) => {
      const state = j === 'sold' ? 'Sold' : 'Sale';
      let tempStat = {};

      const temp = data.find(
        (elt) =>
          elt.propertyCategory === data[index].propertyCategory &&
          elt.statCategory === j
      );

      switch (cat + state) {
        case 'oldSale':
          tempStat.title = OLD_GOODS_SALE;
          break;
        case 'oldSold':
          {
            if (temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                OLD_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = OLD_GOODS_SOLD + ` - `;
            }
          }
          break;
        default:
          break;
      }

      tempStat.totalStock = temp.stocks.total;
      tempStat.exploitableStock = temp.stocks.exploitable;
      tempStat.avgPrice = temp.prices.avgPrice;
      tempStat.minPrice = temp.prices.lowest;
      tempStat.maxPrice = temp.prices.highest;
      tempStat.sqmAvgPrice = temp.prices.sqmAvgPrice;
      tempStat.avgArea = temp.averageArea;

      stat[cat + state] = tempStat;
    });
  });
  object.goodsDatas.push(stat);
};

export const pdfDataParser = (data) => {
  let parsedData = {};
  parsedData.goodsDatas = [];
  if (data[0].totalRoom) {
    for (let i = 1; i <= 6; i += 1) {
      pickupRoomData(parsedData, data, i);
    }
  }
  if (data[0].propertyTypology.name === 'Terrain') {
    for (let i = 0; i <= 2; i += 1) {
      pickupFieldData(parsedData, data, i);
    }
  }

  parsedData.type = data[0].totalRoom ? data[0].totalRoom : data[0].propertyCategory;

  return parsedData;
};
