import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAdStudies from '../App/hooks/useAdStudies';
import AdStudyDemarcheForm from '../components/AdStudy/AdStudyDemarcheForm';
import BuildingPlotForm from '../components/AdStudy/BuildingPlotForm';
import CommentariesForm from '../components/AdStudy/CommentariesForm';
import GlobalSaveButton from '../components/AdStudy/GlobalSaveButon';
import LocalisationForm from '../components/AdStudy/LocalisationForm';
import PLUForm from '../components/AdStudy/PLUForm';
import ProjectCollaboratorForm from '../components/AdStudy/ProjectCollaboratorForm';
import AdStudyRecentDemarches from '../components/AdStudy/recentDemarches';
import SalesAgentContactForm from '../components/AdStudy/SalesAgentContactForm';
import SellerContactForm from '../components/AdStudy/SellerContactForm';
import YourFolderForm from '../components/AdStudy/YourFolderForm';
import ActionNotification from '../components/Assets/ActionNotification';
import AdBasicDetails from '../components/Assets/AdBasicDetails';
import Button from '../components/Assets/Button';
import Footer from '../components/Assets/Footer';
import SectionTitle from '../components/Assets/sectionTitle';
import './styles/adStudyPage.scss';

function AdStudyPage() {
  const { folderId, id } = useParams();
  const localisationRef = useRef(null);
  const sellerContactRef = useRef(null);
  const salesAgentContactRef = useRef(null);
  const projectCollaboratorRef = useRef(null);
  const pluRef = useRef(null);
  const buildingPlotRef = useRef(null);
  const commentRef = useRef(null);
  const demarcheRef = useRef(null);
  const {
    activeAdStudy,
    adFull,
    formTouchStates: {
      localisationForm,
      sellerContactForm,
      salesAgentContactForm,
      projectCollaboratorsForm,
      buildingPlotForm,
      commentForm,
      demarcheForm,
    },
  } = useAdStudies(folderId, id);

  const history = useHistory();
  const handleSubmit = () => {
    if (localisationForm) localisationRef.current.click();
    if (sellerContactForm) sellerContactRef.current.click();
    if (salesAgentContactForm) salesAgentContactRef.current.click();
    if (projectCollaboratorsForm) projectCollaboratorRef.current.click();
    if (projectCollaboratorsForm) pluRef.current.click();
    if (buildingPlotForm) buildingPlotRef.current.click();
    if (commentForm) commentRef.current.click();
    if (demarcheForm) demarcheRef.current.click();
    // history.push(`/details-dossier/${folderId}`);
  };

  return (
    <>
      <ActionNotification />
      <main className="my-folders-ad-creation">
        <header className="my-folders-ad-creation-header">
          <SectionTitle
            mainTitle={`Dossier`}
            subTitle="Dossier de prospection"
            subTitleNav={`Gestion du dossier`}
            link={`/details-dossier/${folderId}`}
          />
          <div className="my-folders-ad-creation-group">
            {/*!isEmpty(adFull) ? (
              <PDFDownload
                pdf={<PdfAdStudy pdfData={adFull} />}
                pdfName="annonce.pdf"
              />
            ) : (
              ''
            )*/}
            <div className="my-folders-ad-creation-group-btn">
              <Button
                btnType="button"
                btnContent="RETOUR"
                handleClick={() => history.push(`/details-dossier/${folderId}`)}
                textColor="violet"
                bgColor="grey"
                borderColor="grey"
              />
            </div>
            <GlobalSaveButton handleSubmit={handleSubmit} disabled={true} />
          </div>
        </header>

        <section className="my-folders-ad-creation-content">
          <div className="my-folders-ad-creation-content-main">
            {activeAdStudy && !isEmpty(adFull) && (
              <div className="my-folders-ad-creation-content-main-detail">
                <AdBasicDetails data={adFull} />
              </div>
            )}
            <div className="my-folders-ad-creation-content-main-group">
              <SectionTitle mainTitle="Eléments complémentaires sur l'annonce" />
              <LocalisationForm
                ref={localisationRef}
                lat={adFull?.latitude}
                lng={adFull?.longitude}
              />
              <SellerContactForm ref={sellerContactRef} />
              <SalesAgentContactForm ref={salesAgentContactRef} />
              <ProjectCollaboratorForm ref={projectCollaboratorRef} />
              <PLUForm ref={pluRef} />
              <BuildingPlotForm ref={buildingPlotRef} />
              <CommentariesForm ref={commentRef} />
            </div>
          </div>

          <aside className="my-folders-ad-creation-content-side">
            <YourFolderForm />
            <AdStudyDemarcheForm ref={demarcheRef} />
            <AdStudyRecentDemarches />
          </aside>
        </section>
      </main>

      <Footer
        myFoldersAdCreation
        handlePrint={() => {}}
        handleCancel={() => history.push(`/details-dossier/${folderId}`)}
        handleEdit={handleSubmit}
      />
    </>
  );
}

export default AdStudyPage;
