import { citiesApiToStoreParser } from '../utils/cityParser';
import { fetchDatas } from './axiosInstanceService';

export const fetchCitiesByPattern = async (
  subscriptionIdIri: string,
  pattern: string
) => {
  try {
    if (subscriptionIdIri && pattern) {
      if (pattern.length >= 3) {
        const result = await fetchDatas(`${subscriptionIdIri}/cities`, {
          name: pattern,
          page: 1,
        });

        const parsedCities = citiesApiToStoreParser(result['hydra:member']);

        return parsedCities;
      } else {
        return Promise.reject('pattern to short');
      }
    } else {
      return Promise.reject('param not found');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
