import { useFormik } from 'formik';
import { isEmpty, isNumber } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useComparativeStudies from '../../App/hooks/useComparativeStudies';

import Button from '../Assets/Button';
import PromptCustom from '../Assets/PromptCustom';
import AutocompleteInputCities from '../Forms/AutocompleteInputCities';
import AutocompleteInputPropertyTypology from '../Forms/AutocompleteInputPropertyTypology';
import SelectInput from '../Forms/SelectInput';
import TextInput from '../Forms/TextInput';

import { comparativeStudiesActions } from '../../store/comparativeStudies/actions';

import { state } from '../../lib/constants';
import { comparativeStudiesRooms, createInitialValues, schema } from './init';

function ComparativeStudyForm() {
  const { id } = useParams();
  const { selectedComparativeStudy } = useComparativeStudies(id);
  const [typology, setTypology] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: isEmpty(selectedComparativeStudy)
      ? createInitialValues
      : selectedComparativeStudy,
    validationSchema: schema(typology),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        dispatch(
          comparativeStudiesActions.updateComparativeStudy.request({
            history,
            idIri: selectedComparativeStudy.idIri,
            body: values,
          })
        );
      } else {
        dispatch(
          comparativeStudiesActions.createComparativeStudy.request({
            history,
            body: values,
          })
        );
      }
    },
  });

  useEffect(() => {
    if (selectedComparativeStudy) {
      console.log(selectedComparativeStudy);
      setTypology(selectedComparativeStudy.propertyTypology.name);
      if (
        selectedComparativeStudy.propertyTypology.name !== 'Terrain' &&
        (!selectedComparativeStudy.roomMin ||
          !isNumber(selectedComparativeStudy.roomMin))
      ) {
        console.log('je passe');
        formik.setFieldValue('roomMin', 1);
      } else if (
        selectedComparativeStudy.propertyTypology !== 'Terrain' &&
        isNumber(selectedComparativeStudy.roomMin) &&
        selectedComparativeStudy.roomMin > 6
      ) {
        formik.setFieldValue('roomMin', 6);
      }
    }
  }, [selectedComparativeStudy]);

  const { values, touched, errors, dirty } = formik;

  const handlePropertyTypologyChange = (name, value) => {
    switch (value.name) {
      case 'Terrain':
        formik.setFieldValue('roomMin', '');
        formik.setFieldValue('builtArea', 0);
        formik.setFieldValue('condition', '');
        break;
      case 'Appartement':
        formik.setFieldValue('landArea', 0);
        formik.setFieldValue('roomMin', 1);
        break;
      case 'Maison':
        formik.setFieldValue('roomMin', 1);
        break;
      default:
        break;
    }
    setTypology(value.name);
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <PromptCustom when={dirty} />
      <form autoComplete="false" className="comparative-studies-form">
        <div className="comparative-studies-form-group">
          <div className="comparative-studies-form-sub">
            <AutocompleteInputCities
              value={values.city ?? []}
              handleChange={formik.setFieldValue}
              required
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && errors.city}
            />
            <div className="comparative-studies-form-sub-address">
              {/* <TextInput
                label="Adresse"
                name="address"
                value={values.address ?? ''}
                handleChange={formik.handleChange}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              /> */}
              <TextInput
                label="Code postal"
                name="zipCode"
                value={
                  values.city && values.city.postalCode ? values.city.postalCode : ''
                }
                type="number"
                disabled
                handleChange={formik.handleChange}
                error={touched.zipCode && Boolean(errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
              />
            </div>
            <div className="comparative-studies-form-sub-surface">
              <TextInput
                label="Prix en €"
                name="price"
                value={values.price}
                type="number"
                handleChange={formik.handleChange}
                required
                error={touched.price && Boolean(errors.price)}
                helperText={touched.price && errors.price}
              />
              <SelectInput
                label="Nombre de pièce(s)"
                hasNoValue={
                  isEmpty(values.propertyTypology)
                    ? true
                    : values.propertyTypology.name === 'Terrain'
                }
                noValueText="Indéfini"
                name="roomMin"
                nameKey="text"
                value={values.roomMin ?? ''}
                type="number"
                handleChange={formik.handleChange}
                items={comparativeStudiesRooms}
                disabled={
                  isEmpty(values.propertyTypology)
                    ? true
                    : values.propertyTypology.name === 'Terrain'
                }
                required={
                  values.propertyTypology &&
                  values.propertyTypology.name !== 'Terrain'
                }
              />
            </div>
          </div>
          <div className="comparative-studies-form-sub">
            <AutocompleteInputPropertyTypology
              name="propertyTypology"
              value={values.propertyTypology}
              handleChange={handlePropertyTypologyChange}
              required
              idReducedPropertyTypologies
              error={touched.propertyTypology && Boolean(errors.propertyTypology)}
              helperText={touched.propertyTypology && errors.propertyTypology}
            />
            <div className="comparative-studies-form-sub-surface">
              <TextInput
                label="Surface du bien"
                name="builtArea"
                value={values.builtArea}
                type="number"
                handleChange={formik.handleChange}
                error={touched.builtArea && Boolean(errors.builtArea)}
                helperText={touched.builtArea && errors.builtArea}
                disabled={
                  isEmpty(values.propertyTypology)
                    ? true
                    : values.propertyTypology.name === 'Terrain'
                }
                required={
                  values.propertyTypology &&
                  values.propertyTypology.name !== 'Terrain'
                }
              />
              <TextInput
                label="Surface du terrain"
                name="landArea"
                value={values.landArea}
                type="number"
                handleChange={formik.handleChange}
                error={touched.landArea && Boolean(errors.landArea)}
                helperText={touched.landArea && errors.landArea}
                disabled={
                  isEmpty(values.propertyTypology)
                    ? true
                    : values.propertyTypology.name === 'Appartement'
                }
                required={
                  values.propertyTypology &&
                  values.propertyTypology.name === 'Terrain'
                }
              />
            </div>
            <SelectInput
              label="Etat du bien"
              name="condition"
              nameKey="text"
              value={values.condition ?? ''}
              handleChange={formik.handleChange}
              disabled={
                isEmpty(values.propertyTypology)
                  ? true
                  : values.propertyTypology.name === 'Terrain'
              }
              items={state}
            />
          </div>
        </div>
        <div className="comparative-studies-form-btns">
          <Button
            btnType="button"
            btnContent="ANNULER"
            handleClick={() => history.push('/etudes-comparatives')}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
          <Button
            btnType="button"
            btnContent="ENREGISTRER"
            handleClick={formik.handleSubmit}
            primary
          />
        </div>
      </form>
    </>
  );
}

export default ComparativeStudyForm;
