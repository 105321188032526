import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as NavDashboard } from '../images/nav-dashboard.svg';
import { ReactComponent as NavClassic } from '../images/nav-classic.svg';
import { ReactComponent as NavSearches } from '../images/nav-searches.svg';
import { ReactComponent as NavFolders } from '../images/nav-folders.svg';
import { ReactComponent as NavMarket } from '../images/nav-market.svg';
import { ReactComponent as NavCompare } from '../images/nav-compare.svg';
import { ReactComponent as NavAssistance } from '../images/nav-assistance.svg';
import { ReactComponent as NavAccount } from '../images/nav-account.svg';

const NavIcons = ({ title }) => {
  switch (title) {
    case 'Dashboard':
      return <NavDashboard className="nav-logo" />;
    case 'Pige classique':
      return <NavClassic className="nav-logo" />;
    case 'Recherches actives':
      return <NavSearches className="nav-logo" />;
    case 'Mes dossiers':
      return <NavFolders className="nav-logo" />;
    case 'Etudes de marché':
      return <NavMarket className="nav-logo" />;
    case 'Etudes comparatives':
      return <NavCompare className="nav-logo" />;
    case 'Assistance':
      return <NavAssistance className="nav-logo" />;
    case 'Mon compte':
      return <NavAccount className="nav-logo" />;
    default:
      return null;
  }
};

NavIcons.propTypes = {
  title: PropTypes.string.isRequired,
};
export default NavIcons;
