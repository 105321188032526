import { isEmpty } from 'lodash';
import useProcedureEvents from '../../../features/procedureEvent/useProcedureEvents';

import RecentDemarchesContainer from './RecentDemarchesContainer';

function AdStudyRecentDemarches() {
  const {
    adStudyProcedureEvents,
  }: { adStudyProcedureEvents: ProcedureEvents } = useProcedureEvents();

  return (
    <div className="my-folders-ad-creation-content-side-recent">
      <h4>Démarches récentes</h4>
      {isEmpty(adStudyProcedureEvents) ? (
        <div className="my-folders-ad-creation-side-line">Aucunes</div>
      ) : (
        <RecentDemarchesContainer procedureEvents={adStudyProcedureEvents} />
      )}
    </div>
  );
}

export default AdStudyRecentDemarches;
