import {
  action,
  createRequestTypes,
  FALSE,
  HIDDEN,
  RESET,
  SET,
  TRUE,
  VISIBLE,
} from '../common/actions';

export const ACTION_NOTIFICATION_DISPLAY = createRequestTypes(
  'ACTION_NOTIFICATION_DISPLAY'
);
export const FORM_IS_SUBMITTED = createRequestTypes('FORM_IS_SUBMITTED');
export const SELECTED_ACCOUNT_TAB = createRequestTypes('SELECTED_ACCOUNT_TAB');
export const SELECTED_ASSISTANCE_TAB = createRequestTypes('SELECTED_ASSISTANCE_TAB');

export const appManagerActions = {
  actionNotificationDisplay: {
    visible: (data) =>
      action(ACTION_NOTIFICATION_DISPLAY[VISIBLE], { payload: data }),
    hidden: () => action(ACTION_NOTIFICATION_DISPLAY[HIDDEN]),
  },
  formIsSubmitted: {
    true: () => action(FORM_IS_SUBMITTED[TRUE]),
    false: () => action(FORM_IS_SUBMITTED[FALSE]),
  },

  selectedAccountTab: {
    set: (data) => action(SELECTED_ACCOUNT_TAB[SET], { payload: data }),
    reset: () => action(SELECTED_ACCOUNT_TAB[RESET]),
  },

  selectedAssistanceTab: {
    set: (data) => action(SELECTED_ASSISTANCE_TAB[SET], { payload: data }),
    reset: () => action(SELECTED_ASSISTANCE_TAB[RESET]),
  },
};
