import { isEmpty } from 'lodash-es';
import { changeApiKeyForStoreKey } from '../common/parserCommon';

const announcementsMapping = {
  '@id': 'idIri',
  title: 'title',
  description: 'description',
  created_at: 'createdAt',
};

export const announcementsApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, announcementsMapping);
  if (isEmpty(parsedData.announcements)) {
    parsedData.isVisible = false;
  }
  return parsedData;
};
