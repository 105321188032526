import { call, put, take } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import { select } from 'redux-saga/effects';
import { activeResearchesActions } from '../../features/activeResearch/actions';
import { getActiveResearches } from '../../features/activeResearch/reducer';
import { fetchDatas } from '../../services/axiosInstanceService';
import { modalsActions } from '../../store/modals/actions';
import { appManagerActions } from '../appManager/actions';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import { mapObjectParser } from '../common/parserCommon';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import {
  DELETE_ADS,
  FETCH_AD,
  FETCH_ADS,
  FETCH_PARCELS,
  adActions,
} from './actions';
import { deleteAds, fetchAd } from './api';
import { adsNotifications } from './notifications';
import { adApiToStore, adParcelApiToStore } from './parser';
import { getAdsState } from './reducer';

export function* handleFetchAds() {
  while (true) {
    try {
      const {
        payload: { page, itemsPerPage, sort },
      } = yield take(FETCH_ADS[REQUEST]);

      const { user } = yield select(getAuthState);

      if (!isEmpty(user)) {
        const response = yield call(fetchDatas, `${user.marketResearch.idIri}/ads`, {
          page: page,
          itemsPerPage: itemsPerPage,
          [`orderBy[${sort}]`]: sort === 'createdAt' ? 'desc' : 'asc',
        });
        if (response) {
          if (isEmpty(response['hydra:member'])) {
            yield put(modalsActions.errorPiecework.open());
          }
          let parsedResponse = {};
          parsedResponse.ads = response['hydra:member'].map((e) => adApiToStore(e));

          parsedResponse = {
            ...parsedResponse,
            totalAds: response['hydra:totalItems'],
            totalAdsToday: response['hydra:total_ads_today'],
          };

          yield put(adActions.fetchAds.success(parsedResponse));
        }
      }
    } catch (error) {
      const { status, messageHydra } = error;
      if (
        status === 400 &&
        messageHydra === 'MARKET_RESEARCH_NOT_PROPERLY_CONFIGURED'
      ) {
        yield put(modalsActions.errorPiecework.open());
      } else {
        yield onErrorLogout(error);
        console.log('error fetchAds', error);
      }
      yield put(adActions.fetchAds.failure({ ads: [] }));
    }
  }
}

export function* handleFetchAd() {
  while (true) {
    try {
      const { payload } = yield take(FETCH_AD[REQUEST]);
      const { activeAd } = yield select(getAdsState);

      let response = null;
      if (activeAd) {
        response = yield call(fetchAd, activeAd);
      } else {
        response = yield call(fetchAd, payload);
      }

      if (response) {
        const parsedResponse = adApiToStore(response);
        yield put(adActions.fetchAd.success(parsedResponse));
      }
    } catch (error) {
      console.log('error getAd', error);
      yield onErrorLogout(error);
      yield put(adActions.fetchAd.failure(error));
    }
  }
}

export function* handleFetchParcels() {
  while (true) {
    try {
      const {
        payload: { isLand, area, address, tolerance, inseeCode },
      } = yield take(FETCH_PARCELS[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDatas, `/parcels/${inseeCode}/result`, {
        is_land: isLand,
        area,
        address,
        tolerance,
      });

      if (response) {
        const parsedResponse = mapObjectParser(
          response['hydra:member'],
          adParcelApiToStore
        );

        yield put(adActions.fetchParcels.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error getAd', error);
      yield onErrorLogout(error);
      yield put(adActions.fetchParcels.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleDeleteAds() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_ADS[REQUEST]);
      const { adsQuantity } = yield select(getActiveResearches);
      yield put(loaderActions.loaderDisplay.visible());
      let index = 0;
      let status = 201;
      do {
        status = yield call(deleteAds, {
          endpoint: '/unwanted-ads',
          body: { ad: payload[index]?.idIri ?? payload[index] },
        });

        yield put(
          adActions.deleteAds.success(payload[index]?.idIri ?? payload[index])
        );
        index += 1;
      } while (status === 201 && index < payload.length);

      if (status === 201) {
        if (adsQuantity > 0 && adsQuantity > payload.length) {
          yield put(activeResearchesActions.decrementAd(payload.length));
        }
        yield put(
          adActions.fetchAds.request({
            page: 1,
            itemsPerPage: 12,
            sort: 'createdAt',
          })
        );
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            adsNotifications.deleteAds.success()
          )
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error deleteAds', error);
      yield onErrorLogout(error);
      yield put(adActions.deleteAds.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adsNotifications.deleteAds.failure()
        )
      );
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
