import { FETCH_AD_STUDY } from '../../store/adStudies/actions';
import { SUCCESS } from '../../store/common/actions';
import {
  CREATE_PROCEDURE_EVENT,
  DELETE_PROCEDURE_EVENT,
  FETCH_DASHBOARD_PROCEDURE_EVENTS,
} from './actions';

const initialState = {
  adStudyProcedureEvents: [],
  dashboardProcedureEvents: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AD_STUDY[SUCCESS]:
      return {
        ...state,
        adStudyProcedureEvents: payload.demarches,
      };
    case FETCH_DASHBOARD_PROCEDURE_EVENTS[SUCCESS]:
      return { ...state, dashboardProcedureEvents: payload };
    case CREATE_PROCEDURE_EVENT[SUCCESS]:
      return {
        ...state,
        adStudyProcedureEvents: state.adStudyProcedureEvents.concat(payload),
      };
    case DELETE_PROCEDURE_EVENT[SUCCESS]:
      return {
        ...state,
        adStudyProcedureEvents: state.adStudyProcedureEvents.filter(
          (elmt) => elmt.idIri !== payload
        ),
        dashboardProcedureEvents: state.dashboardProcedureEvents.filter(
          (elmt) => elmt.idIri !== payload
        ),
      };
    // case DELETE_AD_STUDIES[SUCCESS]:
    //   return {
    //     ...state,
    //     adStudyProcedureEvents: state.adStudyProcedureEvents.filter((elmt) =>
    //       payload.includes(elmt.adStudy)
    //     ),
    //     dashboardProcedureEvents: state.dashboardProcedureEvents.filter((elmt) =>
    //       payload.includes(elmt.adStudy)
    //     ),
    //   };
    default:
      return state;
  }
};

export const getProcedureEventsState = (state) => state.procedureEvents;
