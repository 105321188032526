import { isEmpty } from 'lodash';
import { changeApiKeyForStoreKey } from '../common/parserCommon';

const comparativeStudyComparisonsMapping = {
  picture: 'picture',
  property_typology: 'propertyTypology',
  built_area: 'builtArea',
  land_area: 'landArea',
  city: 'city',
  source: 'source',
  price: 'price',
  status: 'status',
  customized_data: 'studied',
  '@id': 'idIri',
};

export const comparativeStudyComparisonsApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(
    data,
    comparativeStudyComparisonsMapping
  );
  parsedData.id = parsedData.idIri.split('/')[2];
  parsedData.source = parsedData.source.label;

  parsedData.studied = {
    isStudied: !isEmpty(parsedData.studied.studied_by),
    adStudies: parsedData.studied.studied_by,
  };
  if (isEmpty(parsedData.studied.studied_by)) {
    parsedData.studied = {
      isStudied: false,
      adStudies: [],
    };
  } else {
    // const { folders } = store.getState((state) => state.folders);
    // const folder = folders.find(e=>e.idIri = )
    const temp = {
      isStudied: true,
      adStudies: [],
    };
    parsedData.studied.studied_by.forEach((e) =>
      temp.adStudies.push({
        idIri: e['@id'],
        folderIri: e.folder,
        folderId: e.folder.split('/')[2],
        ownerIri: e.owner,
      })
    );
  }
  parsedData.status = parsedData.status === 1 ? 'actif' : 'inactif';

  return parsedData;
};

export const comparativeStudyComparisonsParser = (data) => {
  let parsedData = data.map((d) =>
    changeApiKeyForStoreKey(d, comparativeStudyComparisonsMapping)
  );

  parsedData = parsedData.map((e) => {
    let temp = e;

    temp.city = e.city.name;
    temp.propertyTypology = e.propertyTypology.name;
    temp.studied = isEmpty(e.studied) ? 'Non' : 'Oui';
    // temp.source = e.source.label;
    temp.status = e.status === 1 ? 'actif' : 'inactif';

    return temp;
  });

  return parsedData;
};
