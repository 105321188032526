// sort comparaison between 2 elements
function compare(elmtA, elmtB, reverse = false) {
  switch (typeof elmtA) {
    case 'number':
      // number sort method
      return !reverse ? elmtA - elmtB : elmtB - elmtA;
    case 'string':
      // string sort method
      if (elmtA.toLowerCase() < elmtB.toLowerCase()) {
        return !reverse ? -1 : 1;
      } else if (elmtA.toLowerCase() > elmtB.toLowerCase()) {
        return !reverse ? 1 : -1;
      }
      return 0;
    default:
      // if elmtA is null, NaN or undefined
      return null;
  }
}

function dateCompare(dateA, dateB) {
  const a = new Date(dateA);
  const b = new Date(dateB);
  return a > b ? -1 : a < b ? 1 : 0;
}

// check for existing key and define existing or default key
function validKeyObject(obj, key) {
  // generate key's array
  const keys = Object.keys(obj);

  // if key don't exist or key is not in key array, then set default key value
  if (!key || !keys.includes(key)) return keys[0];
  return key;
}

function genericSort(tab, order = 'asc', key) {
  let objectKey;
  if (tab && tab.length > 1) {
    switch (typeof tab[0]) {
      case 'number':
        // basic number or string sort method
        return tab.sort((elmtA, elmtB) => compare(elmtA, elmtB, order !== 'asc'));
      case 'string':
        // check for dates
        if (tab[0].includes('/')) {
          return tab.sort((a, b) => dateCompare(a, b));
        } else {
          // basic number or string sort method
          return tab.sort((elmtA, elmtB) => compare(elmtA, elmtB, order !== 'asc'));
        }
      case 'object':
        if (!key) tab;
        // set key
        objectKey = validKeyObject(tab[0], key);
        // when object, sorting by selected key
        return tab.sort((elmtA, elmtB) =>
          compare(elmtA[objectKey], elmtB[objectKey], order !== 'asc')
        );
      default:
        return tab;
    }
  } else {
    return tab;
  }
}

export default genericSort;
