import { changeApiKeyForStoreKey } from '../common/parserCommon';

const adSourceMapping = {
  '@id': 'idIri',
  name: 'name',
  label: 'label',
  url: 'url',
};

export const adSourcesApiToStore = (data) => {
  // const { base } = objectKeyGenericMaping(adSourceMapping);
  const parsedData = changeApiKeyForStoreKey(data, adSourceMapping);
  // console.log(data, parsedData);
  return parsedData;
};
