import * as yup from 'yup';
import { procedureEventTypes } from '../../features/procedureEvent/parser';

import { EMAIL } from '../../utils/validation';

export const procedureEventDatasList = (id) => {
  return id > 0 && id <= 5
    ? procedureEventTypes.find((l) => l.value === parseInt(id, 10)) ?? undefined
    : undefined;
};

export const opportunityState = [
  { value: 0, name: 'En cours' },
  { value: 1, name: 'Validé' },
  { value: 2, name: 'Abandonné' },
];

export const folderInitialState = {
  folderState: 0,
  moveAdTo: '',
};

// Localisation
export const localisationInitialValues = {
  propertyTypology: {},
  city: {},
  address: '',
  builtArea: '',
  landArea: '',
  price: '',
};

export const localisationSchema = yup.object({
  builtArea: yup.number(),
  landArea: yup.number(),
  price: yup.number(),
});

// Seller Contact
export const sellerContactInitialValues = {
  sellerName: '',
  sellerPhone: '',
  sellerMail: '',
  sellerInfo: '',
};

export const sellerContactSchema = yup.object({
  sellerName: yup.string(),
  sellerPhone: yup.string(),
  sellerMail: yup.string().email(EMAIL.type),
  sellerInfo: yup.string(),
});

// Sales Agent Contact
export const salesAgentContactInitialValues = {
  salesAgentName: '',
  salesAgentPhone: '',
  salesAgentMail: '',
  salesAgentInfo: '',
};

export const salesAgentContactSchema = yup.object({
  salesAgentName: yup.string(),
  salesAgentPhone: yup.number(),
  salesAgentMail: yup.string().email(EMAIL.type),
  salesAgentInfo: yup.string(),
});

// Project Collaborators
export const collaboratorTypeLabel = {
  architect: {
    name: 'Architecte du projet',
    phone: "Téléphone de l'architecte",
    mail: "Email de l'architecte",
  },
  notary: {
    name: 'Notaine du projet',
    phone: 'Téléphone du notaire',
    mail: 'Email du notaire',
  },
  surveyor: {
    name: 'Géomètre du projet',
    phone: 'Téléphone du géomètre',
    mail: 'Email du géomètre',
  },
};

export const projectCollaboratorsInitialValues = {
  projectArchitect: '',
  architectPhone: '',
  architectEmail: '',
  projectNotary: '',
  notaryPhone: '',
  notaryEmail: '',
  projectSurveyor: '',
  surveyorPhone: '',
  surveyorEmail: '',
};

export const projectCollaboratorsSchema = yup.object({
  projectArchitect: yup.string(),
  architectPhone: yup.number(),
  architectEmail: yup.string().email(EMAIL.type),
  projectNotary: yup.string(),
  notaryPhone: yup.number(),
  notaryEmail: yup.string().email(EMAIL.type),
  projectSurveyor: yup.string(),
  surveyorPhone: yup.number(),
  surveyorEmail: yup.string().email(EMAIL.type),
});

// PLU
export const pluInitialValues = {
  pluZone: '',
  pluCos: '',
  pluCes: '',
  pluHightMax: '',
  pluSocialQuota: '',
  pluParking: '',
  parcel: '',
  areaFloor: '',
};

export const buildingPlotInitialValues = {
  isBuildable: false,
  comment: '',
};

export const buildingPlotSchema = yup.object({
  isBuildable: yup.boolean(),
  comment: yup.string(),
});

export const pluSchema = yup.object({
  pluZone: yup.string(),
  pluCos: yup.string(),
  pluCes: yup.string(),
  pluHightMax: yup.string(),
  pluSocialQuota: yup.string(),
  pluParking: yup.string(),
  parcel: yup.string(),
  areaFloor: yup.string(),
});

// Company Process
export const procedureEventsInitialValues = {
  contact: '',
  type: '',
  date: new Date().toISOString(),
  comment: '',
};

export const procedureEventsSchema = yup.object({
  contact: yup.string().required('Interlocuteur requis'),
  type: yup
    .number()
    .test('event choice', 'Un événement est requis', (value) => value >= 1),
  date: yup.string(),
  comment: yup.string(),
});
