import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './styles/Confirmation.scss';
import { modalsActions } from '../../store/modals/actions';

import Button from '../Assets/Button';

function Confirmation({ message, cancelBtnText, confirmBtnText, redirectPath }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(modalsActions.confirmation.close());
  };

  const handleConfirm = () => {
    closeModal();
    history.push(`/${redirectPath}`);
  };

  return (
    <div className="confirmation-overlay">
      <div className="confirmation">
        <h3 className="confirmation-title">{message}</h3>

        <div className="confirmation-btns">
          <Button
            btnType="button"
            btnContent={cancelBtnText}
            handleClick={closeModal}
            primary
          />
          <Button
            btnType="button"
            btnContent={confirmBtnText}
            handleClick={handleConfirm}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

export default Confirmation;
