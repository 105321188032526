import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, makeStyles, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getComparativeStudyComparisonsSelectState } from '../../../store/comparativeStudyComparisonsSelect/reducer';
import { comparativeStudyComparisonsSelectActions } from '../../../store/comparativeStudyComparisonsSelect/actions';
import useComparativeStudyComparisons from '../../../App/hooks/useComparativeStudyComparisons';

const useStyles = makeStyles({
  cell: {
    padding: 0,
    textAlign: 'center',
  },
});

const CheckboxCell = ({ data }) => {
  const classes = useStyles();
  const { comparisonsActiveTab } = useComparativeStudyComparisons();
  const adComparisonsSelected = useSelector(
    getComparativeStudyComparisonsSelectState
  );
  const dispatch = useDispatch();

  const checked =
    Boolean(
      adComparisonsSelected[comparisonsActiveTab].find((e) => e.idIri === data.idIri)
    ) ?? false;

  const handleClick = () => {
    if (adComparisonsSelected[comparisonsActiveTab].length < 3) {
      dispatch(
        comparativeStudyComparisonsSelectActions.setAdSelected.set({
          tab: comparisonsActiveTab,
          data,
        })
      );
    }
    if (adComparisonsSelected[comparisonsActiveTab].length === 3 && checked) {
      dispatch(
        comparativeStudyComparisonsSelectActions.setAdSelected.set({
          tab: comparisonsActiveTab,
          data,
        })
      );
    }
  };

  return (
    <TableCell className={classes.cell}>
      <Checkbox onClick={handleClick} checked={checked} />
    </TableCell>
  );
};

CheckboxCell.propTypes = {
  data: PropTypes.object,
};

export default CheckboxCell;
