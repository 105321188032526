import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import SelectInputUseStyles from './InitStyle';
import { isString } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

function SelectInput({
  hasNoValue = false,
  noValueText = '',
  label,
  value,
  name,
  nameKey = 'value',
  valueKey = 'value',
  handleChange,
  items,
  colorDisplay,
  error,
  helperText,
  disabled,
}) {
  const classes = SelectInputUseStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes[colorDisplay] || ''}
      error={error}
    >
      <InputLabel shrink>{label}</InputLabel>
      <Select
        name={name}
        value={value}
        notched
        displayEmpty
        onChange={handleChange}
        disabled={disabled}
        label={label}
      >
        {hasNoValue && <MenuItem value="">{noValueText}</MenuItem>}
        {items &&
          items.map((elt) =>
            isString(elt) ? (
              <MenuItem key={uuidV4()} value={elt}>
                {elt[nameKey]}
              </MenuItem>
            ) : (
              <MenuItem key={uuidV4()} value={elt[valueKey]}>
                {elt[nameKey]}
              </MenuItem>
            )
          )}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  hasNoValue: PropTypes.bool,
  noValueText: PropTypes.string,
  label: PropTypes.string,
  nameKey: PropTypes.string,
  valueKey: PropTypes.string,
  items: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  handleChange: PropTypes.func.isRequired,
  colorDisplay: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

export default SelectInput;
