import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFolders from '../../App/hooks/useFolders';
import useAdStudies from '../../App/hooks/useAdStudies';

import SelectInput from '../Forms/SelectInput';

import { adStudiesActions } from '../../store/adStudies/actions';
import { opportunityState } from './form';
import { useHistory } from 'react-router';

function YourFolderForm() {
  const { folders } = useFolders();
  const history = useHistory();
  const {
    activeAdStudy: { folder, status, idIri, id },
  } = useAdStudies();
  const [input, setInput] = useState({ folder: '', status: 0 });

  const dispatch = useDispatch();

  useEffect(() => {
    setInput({ ...input, status: status ?? 0 });
  }, [status]);

  const handleStatusChange = (e) => {
    setInput({ ...input, status: e.target.value });
    dispatch(
      adStudiesActions.updateAdStudy.request({
        body: { status: e.target.value },
        type: 'status',
        idIri,
      })
    );
  };

  const handleFolderChange = (e) => {
    setInput({ ...input, folder: e.target.value });
    dispatch(
      adStudiesActions.updateAdStudy.request({
        body: { folder: e.target.value },
        type: 'folder',
        history,
        idIri,
        id,
      })
    );
  };

  return (
    <div className="my-folders-ad-creation-content-side-folder">
      <h4>Opportunité</h4>
      <div className="my-folders-ad-creation-select">
        <SelectInput
          name="folderState"
          value={input.status}
          valueKey="value"
          nameKey="name"
          handleChange={handleStatusChange}
          label="Etat de l'opportunité"
          items={opportunityState}
        />
      </div>
      <div className="my-folders-ad-creation-select">
        <SelectInput
          name="moveAdStudyTo"
          value={input.folder}
          valueKey="idIri"
          nameKey="name"
          hasNoValue={true}
          noValueText="Choisir un dossier"
          handleChange={handleFolderChange}
          label="Déplacer l'annonce vers..."
          items={folders && folders.filter((f) => f.idIri !== folder)}
        />
      </div>
    </div>
  );
}

export default YourFolderForm;
