import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Badge from '../badge';
import Button from './Button';

import { getFoldersState } from '../../store/folders/reducer';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../badge/enums';

interface IFooterProps {
  userDetails: any;
  userPassword: any;
  userRegistration: any;
  userTeam: any;
  myFolders: any;
  myFoldersAdCreation: any;
  marketStudies: any;
  handleClick: any;
  handleEdit: any;
  handlePrint: any;
  handleCancel: any;
  handleDelete: any;
}
function Footer({
  userDetails,
  userPassword,
  userRegistration,
  userTeam,
  myFolders,
  myFoldersAdCreation,
  marketStudies,
  handleClick,
  handleEdit,
  handlePrint,
  handleCancel,
  handleDelete,
}: IFooterProps) {
  const { activeFolder } = useSelector(getFoldersState);

  let footerClass = '';
  if (myFolders) footerClass += 'footer-folders';
  if (myFoldersAdCreation) footerClass += 'footer-ad-creation';
  if (marketStudies) footerClass += 'footer-market-result';
  if (userDetails || userPassword || userRegistration || userTeam)
    footerClass += 'footer-account';

  return (
    <>
      <div className="footer-push"></div>
      <footer className={footerClass}>
        {myFolders && activeFolder && (
          <>
            <div className="footer-folders-btn">
              <Button
                btnType="button"
                bgColor="violet"
                textColor="white"
                borderColor="white"
                btnImg="edit-white"
                handleClick={handleEdit}
              />
              <Button
                btnType="button"
                bgColor="red"
                textColor="white"
                btnImg="delete-white"
                handleClick={handleDelete}
              />
            </div>
            <div className="footer-folders-badges">
              <Badge
                badgeDynamicContent={`${activeFolder.ads} annonces`}
                bgColor={BadgeBackgroundColorEnum.GREY}
                textColor={BadgeTextColorEnum.DARKER_GREY}
                isMini
              />
              <Badge
                badgeContent={BadgeContentEnum.MISE_A_JOUR}
                bgColor={BadgeBackgroundColorEnum.GREEN}
                textColor={BadgeTextColorEnum.WHITE}
                isMini
              />
            </div>
          </>
        )}

        {myFoldersAdCreation && (
          <>
            {/* <Button
              btnType="button"
              textColor="violet"
              bgColor="white"
              btnImg="printer"
              handleClick={handlePrint}
            /> */}
            <Button
              btnType="button"
              btnContent="RETOUR"
              textColor="white"
              bgColor="violet"
              borderColor="white"
              handleClick={handleCancel}
            />
            <Button
              btnType="button"
              btnContent="ENREGISTRER"
              textColor="white"
              bgColor="blue"
              handleClick={handleEdit}
              hasShadow
            />
          </>
        )}

        {marketStudies && (
          <>
            <Button
              btnType="button"
              textColor="violet"
              bgColor="white"
              btnImg="printer"
              handleClick={handlePrint}
            />
            <Button
              btnType="button"
              btnContent="CLOTURER"
              textColor="white"
              bgColor="red"
              handleClick={handleClick}
            />
            <Button
              btnType="button"
              btnContent="SAUVEGARDER"
              textColor="white"
              bgColor="blue"
              handleClick={handleEdit}
            />
          </>
        )}

        {userDetails && (
          <>
            <Button
              btnType="button"
              btnContent="ANNULER"
              textColor="white"
              bgColor="violet"
              borderColor="white"
              handleClick={handleCancel}
            />
            <Button
              btnType="button"
              btnContent="METTRE A JOUR"
              textColor="white"
              bgColor="blue"
              handleClick={handleEdit}
              hasShadow
            />
          </>
        )}

        {userPassword && (
          <>
            <Button
              btnType="button"
              btnContent="ANNULER"
              textColor="white"
              bgColor="violet"
              borderColor="white"
              handleClick={handleCancel}
            />
            <Button
              btnType="button"
              btnContent="METTRE A JOUR"
              textColor="white"
              bgColor="blue"
              handleClick={handleEdit}
              hasShadow
            />
          </>
        )}

        {userTeam && (
          <Button
            btnType="button"
            btnContent="+ COLLABORATEUR"
            textColor="white"
            bgColor="blue"
            handleClick={handleClick}
            hasShadow
          />
        )}

        {userRegistration && (
          <Button
            btnType="button"
            btnContent="MODIFIER"
            textColor="white"
            bgColor="blue"
            handleClick={handleEdit}
            hasShadow
          />
        )}
      </footer>
    </>
  );
}

Footer.propTypes = {
  userDetails: PropTypes.bool,
  userPassword: PropTypes.bool,
  userRegistration: PropTypes.bool,
  userTeam: PropTypes.bool,
  myFolders: PropTypes.bool,
  myFoldersAdCreation: PropTypes.bool,
  marketStudies: PropTypes.bool,
  handleClick: PropTypes.func,
  handleEdit: PropTypes.func,
  handlePrint: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default Footer;
