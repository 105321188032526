import { RadioGroup } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { adActions } from '../../store/ads/actions';
import { getAdsState } from '../../store/ads/reducer';
import SingleParcelMap from './SinglePlotMap';

const SinglePlotMapContainer = () => {
  const { adParcels = [], adGeoAddress, ad } = useSelector(getAdsState);

  const dispatch = useDispatch();

  return (
    <>
      {!isEmpty(adParcels) && (
        <section className="ad-details-search-found">
          <h3 className="ad-details-section-title">{`Bien(s) identifié(s) possédant les mêmes carastéristiques à ${ad?.city?.name}`}</h3>
          <div className="ad-details-search-found-cards">
            <div className="ad-details-geo-single-parcel-container">
              <RadioGroup
                value={adGeoAddress}
                onChange={(e) =>
                  dispatch(adActions.adGeoAddress.set(e.target.value))
                }
              >
                {(adParcels as AdPlot[]).map((elmt, index: number) => (
                  <SingleParcelMap
                    key={uuidv4()}
                    marker={elmt}
                    radioKey={`radio-${index + 1}`}
                    department={ad?.city?.postalCode?.substring(0, 2) ?? null}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SinglePlotMapContainer;
