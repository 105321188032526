import * as yup from 'yup';
import {
  EMAIL,
  first_name,
  last_name,
  PHONE_NUMBER,
  ZIP_CODE,
} from '../../../utils/validation';

export const detailsInitialValues = (user, company) => {
  return {
    lastName: user.lastName ?? '',
    firstName: user.firstName ?? '',
    email: user.email ?? '',
    phoneNumber: user.phoneNumber ?? '',
    enableNewsletter: user.enableNewsLetter,
    name: company.name ?? '',
    siret: company.siret ?? '',
    address: company.address ?? '',
    zipcode: company.zipcode ?? '',
    city: company.city ?? '',
    country: company.country ?? '',
  };
};

export const detailsSchema = yup.object({
  lastName: yup.string().required(last_name.required),
  firstName: yup.string().required(first_name.required),
  email: yup.string().email(EMAIL.type).required(EMAIL.required),
  phoneNumber: yup
    .number()
    .typeError(PHONE_NUMBER.type)
    .required(PHONE_NUMBER.required),
  name: yup.string(),
  siret: yup.string(),
  address: yup.string(),
  city: yup.string(),
  country: yup.string(),
  zipcode: yup.number().typeError(ZIP_CODE.type),
  enableNewsLetter: yup.bool(),
});
