import React from 'react';
import useMarketStudies from '../../App/hooks/useMarketStudies';
import { marketStudiesActions } from '../../store/marketStudies/actions';
import ActionModal from '../Modals/ActionModal';

import './marketStudyDeleteModal.scss';

const MarketStudyDeleteModal = () => {
  const { deleteModal } = useMarketStudies();

  return (
    <ActionModal
      closeAction={marketStudiesActions.deleteMarketStudy.reset()}
      validAction={marketStudiesActions.deleteMarketStudy.request(deleteModal.idIri)}
      validText="SUPPRIMER"
      modalType="suppression"
    >
      <p>
        Confirmez-vous la suppression de l&apos;étude de marché
        <span>{` ${deleteModal.name} `}</span>?
      </p>
      <p>La suppression sera définitive.</p>
    </ActionModal>
  );
};

export default MarketStudyDeleteModal;
