const generateId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const getMonths = () => {
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ].map((elt, index) => ({ value: index.toString(), text: elt }));

  return months;
};

const formatString = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

const getPreviousWeekDate = () => {
  const currentDate = new Date();
  const before7Daysdate = new Date(currentDate.setDate(currentDate.getDate() - 7));

  return new Date(before7Daysdate).toISOString();
};

export { generateId, getMonths, formatString, getPreviousWeekDate };
