import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './styles/ErrorPiecework.scss';
import { modalsActions } from '../../store/modals/actions';
import { appManagerActions } from '../../store/appManager/actions';

import Button from '../Assets/Button';

function ErrorPiecework() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(modalsActions.errorPiecework.close());
    dispatch(appManagerActions.selectedAccountTab.set(2));
    history.push(`/mon-compte`);
  };

  return (
    <div className="error-piecework-overlay">
      <div className="error-piecework">
        <h3 className="error-piecework-title">Pige classique</h3>
        <p>
          {`Votre pige n'est pas paramétrée, vous pouvez le faire dans "Mon compte", onglet "Paramètres Pige", ou en cliquant sur le bouton`}
        </p>

        <div className="error-piecework-btns">
          <Button
            btnType="button"
            btnContent="PARAMETRER"
            handleClick={handleClick}
            primary
          />
        </div>
      </div>
    </div>
  );
}

export default ErrorPiecework;
