import { call, put, select, take, takeEvery } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import {
  createEntity,
  deleteEntity,
  fetchDataById,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { adActions } from '../ads/actions';
import { getAdsState } from '../ads/reducer';
import { appManagerActions } from '../appManager/actions';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { foldersActions } from '../folders/actions';
import { folderNotifications } from '../folders/notifications';
import { getFoldersState } from '../folders/reducer';
import { loaderActions } from '../loader/actions';
import { modalsActions } from '../modals/actions';
import {
  adStudiesActions,
  CREATE_AD_STUDIES,
  DELETE_AD_STUDIES,
  FETCH_AD_STUDIES,
  FETCH_AD_STUDY,
  MOVE_AD_STUDIES,
  UPDATE_AD_STUDY,
} from './actions';
import { moveAdStudies } from './api';
import { adStudiesNotifications } from './notifications';
import {
  adStudyApiToStore,
  adStudyFormToApi,
  adStudyShortApiToStore,
  moveAdStudiesBodyParser,
} from './parser';
import { getAdStudiesState } from './reducer';

export function* handleFetchAdStudies() {
  while (true) {
    try {
      const { payload } = yield take(FETCH_AD_STUDIES[REQUEST]);

      let page = 1;
      let loop = false;
      let parsedData = [];

      do {
        const response = yield call(fetchDatas, `${payload}/ad-studies`, { page });
        parsedData.push(
          ...response['hydra:member'].map((e) => adStudyShortApiToStore(e))
        );

        loop =
          response['hydra:view'] && response['hydra:view']['hydra:next']
            ? true
            : false;

        page += 1;
      } while (loop);

      yield put(
        adStudiesActions.fetchAdStudies.success({
          response: parsedData,
          qty: parsedData.length,
        })
      );
    } catch (error) {
      yield onErrorLogout(error);
      yield put(adStudiesActions.fetchAdStudies.failure(error));
    }
  }
}

export function* handleFetchAdStudy() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(FETCH_AD_STUDY[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDataById, idIri);

      if (response) {
        const parsedResponse = adStudyApiToStore(response);

        yield put(adStudiesActions.fetchAdStudy.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(adStudiesActions.fetchAdStudy.failure(error));
    }
  }
}

function* createAdStudyRequest(body, folderIdIri) {
  const response = yield call(createEntity, body);

  if (response) {
    yield put(
      adStudiesActions.createAdStudies.success({
        adIdIri: body.body.ad,
        folderIdIri: folderIdIri,
      })
    );
  }
}

export function* handleCreateAdStudies() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(CREATE_AD_STUDIES[REQUEST]);
      const { selectedAds, adGeoAddress, ad } = yield select(getAdsState);

      yield put(loaderActions.loaderDisplay.visible());

      const body = {
        endpoint: '/ad-studies',
        body: {
          folder: idIri,
        },
      };

      if (!isEmpty(ad)) {
        body.body.ad = ad.idIri;
        body.body.address = adGeoAddress;
        body.body.seller_contact = {
          full_name: ad.sellerName ?? '',
          phone_number: ad.sellerPhone ?? '',
        };

        yield createAdStudyRequest(body, idIri);
      } else {
        let index = 0;
        // create loop
        while (index < selectedAds.length) {
          body.body.ad = selectedAds[index].idIri;
          body.body.seller_contact = {
            full_name: selectedAds[index].sellerName ?? '',
            phone_number: selectedAds[index].sellerPhone ?? '',
          };

          yield createAdStudyRequest(body, idIri);

          index += 1;
        }
        yield put(adActions.selectedAdsReset.success());
      }

      yield put(adActions.adGeoAddress.reset());
      yield put(
        adActions.fetchAds.request({
          page: 1,
          itemsPerPage: 12,
          sort: 'createdAt',
        })
      );
      yield put(modalsActions.chooseFolder.close());
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.create().success()
        )
      );
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
      yield put(adStudiesActions.createAdStudies.failure(error));
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.create().failure()
        )
      );
    }
  }
}

export function* handleUpdateMultipleAdStudyForm() {
  yield takeEvery(UPDATE_AD_STUDY[REQUEST], handleUpdateAdStudy);
}

function* handleUpdateAdStudy({ payload }) {
  console.log(payload);
  const { body, type, idIri, id, history } = payload;
  try {
    yield put(loaderActions.loaderDisplay.visible());

    const parsedBody = adStudyFormToApi(body, type);

    const response = yield call(updateEntity, { idIri, body: parsedBody });

    if (response) {
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.update().success()
        )
      );
      if (type === 'folder') {
        yield put(foldersActions.fetchFolders.request());
        yield call(
          history.push,
          `/creation-annonce-dossier/${body.folder.split('/')[2]}/${id}`
        );
      }
      yield put(adStudiesActions.formTouchStates.set({ [type + 'Form']: false }));
    }
    yield put(loaderActions.loaderDisplay.hidden());
  } catch (error) {
    yield put(loaderActions.loaderDisplay.hidden());
    yield onErrorLogout(error);
    yield put(adStudiesActions.updateAdStudy.failure(error));
  }
}

export function* handleDeleteAdStudies() {
  while (true) {
    try {
      yield take(DELETE_AD_STUDIES[REQUEST]);

      const { selectedAdStudies } = yield select(getAdStudiesState);

      yield put(loaderActions.loaderDisplay.visible());

      let status = 204;
      let index = 0;

      do {
        status = yield call(deleteEntity, selectedAdStudies[index]);
        if (status === 204) {
          yield put(
            adStudiesActions.deleteAdStudies.success(selectedAdStudies[index])
          );
        }
        index += 1;
      } while (index < selectedAdStudies.length && status === 204);

      if (status === 204) {
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(adStudiesActions.resetSelectedAdStudies.success());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            adStudiesNotifications.delete().success()
          )
        );
      }
    } catch (error) {
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          adStudiesNotifications.delete().failure()
        )
      );
    }
  }
}

// ***************************************************
// *  MOVE AD-STUDY(IES)
// ***************************************************
export function* handleMoveAdStudies() {
  while (true) {
    try {
      const {
        payload: { idIri },
      } = yield take(MOVE_AD_STUDIES[REQUEST]);

      const { selectedAdStudies } = yield select(getAdStudiesState);
      const { folders } = yield select(getFoldersState);
      const selectedFolder = folders.find((e) => e.idIri === idIri);

      yield put(loaderActions.loaderDisplay.visible());

      const body = moveAdStudiesBodyParser(selectedAdStudies);

      const status = yield call(moveAdStudies, {
        idIri: `${idIri}/move-ad-studies`,
        body,
      });

      if (status === 204) {
        yield put(foldersActions.fetchFolders.request());
        yield put(adStudiesActions.moveAdStudies.success(selectedAdStudies));
        yield put(adStudiesActions.moveAdStudies.reset());
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            adStudiesNotifications.moveAdStudies.success(selectedFolder.name)
          )
        );
      }
    } catch (error) {
      console.log('error addAdsToFolder', error);
      yield onErrorLogout(error);
      yield put(foldersActions.addAdsToFolder.failure());
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          folderNotifications.changefolder.failure()
        )
      );
    }
  }
}
