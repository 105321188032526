import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import useComparativeStudies from '../../../App/hooks/useComparativeStudies';
import { getComparativeStudyComparisonsSelectState } from '../../../store/comparativeStudyComparisonsSelect/reducer';
import IndividualCircularLoader from '../../Loader/IndividualCircularLoader';
import ComparativeStudyPdf from './ComparativeStudyPdf';

const ModalContentHeader = () => {
  const { comparativeStudyContent } = useComparativeStudies(null, true);

  const adComparisonsSelected = useSelector(
    getComparativeStudyComparisonsSelectState
  );

  const displayPropertyTypologyAndRooms = () => (
    <span className="comparative-studies-details-primary-info">{`${
      comparativeStudyContent.propertyTypology.name
    } ${
      comparativeStudyContent.roomMin ? 'T' + comparativeStudyContent.roomMin : ''
    }`}</span>
  );

  const displayCityandCp = () => (
    <>
      <h2 className="comparative-studies-details-title">
        {comparativeStudyContent.city.name}
        <span className="comparative-studies-details-header-postal">{` (${comparativeStudyContent.city.postalCode})`}</span>
      </h2>
      <ComparativeStudyPdf
        adComparisonsSelected={adComparisonsSelected}
        comparativeStudyContent={comparativeStudyContent}
      />
    </>
  );

  return (
    <header>
      {isEmpty(comparativeStudyContent) ? (
        <IndividualCircularLoader size={50} />
      ) : (
        <>
          <div className="comparative-studies-details-header-elt">
            {displayCityandCp()}
          </div>
          <div className="comparative-studies-details-header-infos">
            {displayPropertyTypologyAndRooms()}
            <span className="comparative-studies-details-secondary-info">{`Surface du bien : ${comparativeStudyContent.builtArea} m²`}</span>
          </div>
          <div className="comparative-studies-details-header-infos">
            <span className="comparative-studies-details-primary-info">{`${comparativeStudyContent.price}€`}</span>
            <span className="comparative-studies-details-secondary-info">{`Surface du terrain : ${comparativeStudyContent.landArea} m²`}</span>
          </div>
        </>
      )}
    </header>
  );
};

export default ModalContentHeader;
