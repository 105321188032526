import { NotificationStatus } from '../../utils/enums';
import genericNotifications, {
  notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';

export const folderNotifications = {
  create: (name) =>
    genericNotifications(
      notificationTitles.folders,
      name,
      notificationAction.create
    ),
  update: (name) =>
    genericNotifications(
      notificationTitles.folders,
      name,
      notificationAction.update
    ),
  delete: (name) =>
    genericNotifications(
      notificationTitles.folders,
      name,
      notificationAction.delete
    ),
  // personalized notifications
  deleteButAds: () => {
    return {
      title: notificationTitles.folders.title,
      content: `Impossible de supprimer un dossier contenant des annonces`,
      status: NotificationStatus.FAILURE,
    };
  },
  addAdsToFolder: {
    success: (name) => {
      return {
        title: notificationTitles.folders.title,
        content: `Les annonces on bien été ajoutées au dossier "${name}"`,
        status: NotificationStatus.SUCCESS,
      };
    },
    failure: () => {
      return {
        title: notificationTitles.folders.title,
        content: `Les annonces n'ont pas été ajoutées au dossier`,
        status: NotificationStatus.SUCCESS,
      };
    },
  },
};
