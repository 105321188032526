import React from 'react';
import useSubscription from '../../../App/hooks/useSubscription';

const RegistrationTitle = () => {
  const { hasRequest } = useSubscription(true);

  return (
    <>
      <h2 className={hasRequest ? 'user-registration title-margin' : ''}>
        Détails de votre abonnement
      </h2>
      {hasRequest && (
        <p className="user-registration message">
          Une demande de modification d&apos;abonnement est en attente...
        </p>
      )}
    </>
  );
};

export default RegistrationTitle;
