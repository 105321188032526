import React from 'react';
import { useDispatch } from 'react-redux';
import RegistrationProfil from './RegistrationTab/RegistrationProfil';
import RegistrationSubscribedServices from './RegistrationTab/RegistrationSubscribedServices';
import RegistrationContent from './RegistrationTab/RegistrationContent';
import { modalsActions } from '../../store/modals/actions';
import Button from '../Assets/Button';
import RegistrationGeographicSectors from './RegistrationTab/RegistrationGeographicSectors';
import useSubscription from '../../App/hooks/useSubscription';

import RegistrationTitle from './RegistrationTab/RegistrationTitle';

import './RegistrationTab/styles/registrationTab.scss';

const RegistrationTab = () => {
  const { hasRequest } = useSubscription(true);
  const dispatch = useDispatch();

  return (
    <section className="my-account-content user-registration">
      <RegistrationTitle />

      <div className="user-registration-wrapper">
        <div className="user-registration-section">
          <div className="user-registration-section-sub">
            <RegistrationProfil />
            <RegistrationSubscribedServices />
          </div>
          <RegistrationContent />

          {!hasRequest && (
            <Button
              btnType="button"
              btnContent="MODIFIER MON ABONNEMENT"
              handleClick={() => dispatch(modalsActions.subscription.open())}
              primary
            />
          )}
        </div>
        <div className="user-registration-section">
          <RegistrationGeographicSectors />
        </div>
      </div>
    </section>
  );
};

export default RegistrationTab;
