import { notificationTitles } from '../../utils/genericNotifications';
import { NotificationStatus } from '../../utils/enums';

export const adsNotifications = {
  deleteAds: {
    success: () => {
      return {
        title: notificationTitles.ads.title,
        content: `Les annonces ont bien été supprimées`,
        status: NotificationStatus.SUCCESS,
      };
    },
    failure: () => {
      return {
        title: notificationTitles.ads.title,
        content: `Les annonces n'ont pas été supprimées`,
        status: NotificationStatus.FAILURE,
      };
    },
  },
};
