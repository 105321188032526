import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { foldersActions } from '../store/folders/actions';

import MyFolderFormComponent from '../components/MyFolders/MyFolderFormComponent';
import MyFolderFormHeader from '../components/MyFolders/MyFoldersFormHeader';

import './styles/MyFoldersFormPage.scss';

function MyFoldersFormPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(foldersActions.setActiveFolder.reset());
  }, []);

  return (
    <main className="my-folders-form-page">
      <MyFolderFormHeader />
      <section className="my-folders-form-page-content">
        <MyFolderFormComponent handleCancel={() => history.push('/mes-dossiers')} />
      </section>
    </main>
  );
}

export default MyFoldersFormPage;
