import { ADD, RESET, SET, SUCCESS } from '../common/actions';
import {
  DELETE_AD_STUDIES,
  FETCH_AD_STUDIES,
  FETCH_AD_STUDY,
  FORM_TOUCH_STATES,
  MOVE_AD_STUDIES,
  RESET_AD_STUDIES,
  RESET_SELECTED_AD_STUDIES,
  SELECTED_AD_STUDIES,
  SELECTED_AD_STUDY,
} from './actions';

const initialState = {
  adStudies: [],
  totalAdStudies: 0,
  activeAdStudy: {},
  selectedAdStudies: [],
  formTouchStates: {
    localisationForm: false,
    sellerContactForm: false,
    salesAgentContactForm: false,
    projectCollaboratorsForm: false,
    PLUForm: false,
    buildingPlotForm: false,
    commentForm: false,
    demarcheForm: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AD_STUDIES[SUCCESS]:
      return { ...state, adStudies: payload.response, totalAdStudies: payload.qty };
    case FETCH_AD_STUDY[SUCCESS]:
      state.activeAdStudy = { ...payload };
      delete state.activeAdStudy.demarches;
      return { ...state };

    case DELETE_AD_STUDIES[SUCCESS]:
      return {
        ...state,
        adStudies: state.adStudies.filter((elmt) => elmt.idIri !== payload),
      };

    case MOVE_AD_STUDIES[SUCCESS]: {
      return {
        ...state,
        adStudies: state.adStudies.filter((e) => !payload.includes(e.idIri)),
      };
    }
    case SELECTED_AD_STUDIES[ADD]:
      if (state.selectedAdStudies.includes(payload)) {
        return {
          ...state,
          selectedAdStudies: state.selectedAdStudies.filter(
            (elt) => elt !== payload
          ),
        };
      } else {
        return {
          ...state,
          selectedAdStudies: state.selectedAdStudies.concat(payload),
        };
      }
    case SELECTED_AD_STUDY[SET]:
      return { ...state, activeAdStudy: payload };
    case SELECTED_AD_STUDY[RESET]:
      return { ...state, activeAdStudy: null };
    case RESET_AD_STUDIES[SUCCESS]:
      return { ...state, adStudies: [], totalAdStudies: 0 };
    case RESET_SELECTED_AD_STUDIES[SUCCESS]:
      return { ...state, selectedAdStudies: [] };
    case FORM_TOUCH_STATES[SET]:
      return {
        ...state,
        formTouchStates: { ...state.formTouchStates, ...payload },
      };
    default:
      return state;
  }
};

export const getAdStudiesState = (state) => state.adStudies;
