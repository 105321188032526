import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from '../../store/account/actions';
import { getCompanyState } from '../../store/account/reducer';
import useAuth from './useAuth';

const useCompany = () => {
  const { isManager, companyIri, companyId } = useAuth();
  const company = useSelector(getCompanyState);
  const { siret, address, zipcode, city, country, name } = company;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(company) && companyIri && isManager) {
      dispatch(accountActions.fetchCompany.request(companyIri));
    }
  }, [companyIri, company, isManager]);

  return !isEmpty(company)
    ? {
        idIri: companyIri,
        id: companyId,
        name,
        siret,
        address,
        zipcode,
        city,
        country,
      }
    : {};
};

export default useCompany;
