import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

function TextInput({
  name,
  label,
  value,
  handleChange,
  onFocus,
  onBlur,
  type,
  rows,
  error,
  helperText,
  placeholder,
  required = false,
  disabled = false,
}) {
  return (
    <TextField
      disabled={disabled}
      required={required}
      name={name}
      label={label}
      placeholder={placeholder ? placeholder : label}
      variant="outlined"
      value={value ?? ''}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputLabelProps={{
        shrink: true,
      }}
      autoComplete="off"
      type={type ? type : 'text'}
      rows={rows ? rows : 1}
      multiline={rows ? true : false}
      error={error}
      helperText={helperText}
    />
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.array.isRequired,
  ]),
  handleChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default TextInput;
