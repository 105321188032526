import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveResearches } from '../../features/activeResearch/reducer';
import { adActions } from '../../store/ads/actions';
import { getAdsState } from '../../store/ads/reducer';
import { modalsActions } from '../../store/modals/actions';
import useAuth from './useAuth';

export default function useAds(adId) {
  const { marketResearchIri, totalAds } = useAuth();
  const { currentActiveResearch } = useSelector(getActiveResearches);
  const { ads, activeAd, ad, selectedAds, deleteModal, adParcels } = useSelector(
    getAdsState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isEmpty(ads) &&
      marketResearchIri &&
      !adId &&
      isEmpty(currentActiveResearch)
    ) {
      dispatch(
        adActions.fetchAds.request({
          page: 1,
          itemsPerPage: 12,
          sort: 'createdAt',
        })
      );
    }
  }, [marketResearchIri, currentActiveResearch]);

  useEffect(() => {
    if (activeAd) {
      dispatch(adActions.fetchAd.request());
    }
    if (adId) {
      dispatch(adActions.fetchAd.request(`/ads/${adId}`));
    }
  }, [activeAd, adId]);

  useEffect(() => {
    return () => {
      dispatch(adActions.setActiveAd.set(null));
    };
  }, []);

  const setActiveAd = (elmtId) => {
    dispatch(modalsActions.adDetails.open());
    dispatch(adActions.setActiveAd.success(elmtId));
  };

  return {
    ads,
    ad,
    activeAd,
    setActiveAd,
    selectedAds,
    deleteModal,
    totalAds,
    adParcels,
  };
}
