import { useLocation } from 'react-router-dom';
import SectionTitle from '../Assets/sectionTitle';

const MyFolderFormHeader = () => {
  const { pathname } = useLocation();
  return (
    <header className="my-folders-form-page-header">
      <SectionTitle
        mainTitle="Dossier de prospection"
        subTitle="Organisez et optimisez votre prospection"
      />
      <div>
        <p>
          Dossier de prospection /
          <span className="my-folders-form-page-span">
            {pathname.includes('creation')
              ? " Céation d'un dossier"
              : " Modification d'un dossier"}
          </span>
        </p>
      </div>
    </header>
  );
};

export default MyFolderFormHeader;
