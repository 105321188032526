import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { procedureEventsActions } from '../../../features/procedureEvent/actions';
import LogoButton from '../../Assets/LogoButton';
import CalendarButton from '../../calendarButton';

interface IRecentprocedureEventsRowProps {
  procedureEvent: ProcedureEvent;
}
const RecentprocedureEventsRow = ({
  procedureEvent,
}: IRecentprocedureEventsRowProps) => {
  const { idIri, type, comment, date, contact } = procedureEvent;
  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useDispatch();

  return (
    <div
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      <div className="my-folders-ad-creation-side-line-data">
        <div className="my-folders-ad-creation-side-line-name">{contact}</div>

        <div className="my-folders-ad-creation-side-line-picto">
          <LogoButton
            btnType="button"
            btnLogo={type.logo}
            tooltip={comment ?? 'Événement'}
            openTooltip={showTooltip}
            disabled
          />
        </div>

        <div className="my-folders-ad-creation-side-line-date">
          {new Date(date).toLocaleDateString()}
        </div>

        <CalendarButton procedureEvent={procedureEvent} />

        <div className="my-folders-ad-creation-side-line-delete">
          <LogoButton
            btnType="button"
            handleClick={() =>
              dispatch(procedureEventsActions.deleteProcedureEvents.request(idIri))
            }
            btnLogo="delete-red"
            tooltip={`Supprimer`}
          />
        </div>
      </div>
    </div>
  );
};

export default RecentprocedureEventsRow;
