import { NotificationStatus } from '../../utils/enums';
import genericNotifications, {
  notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';

export const adStudiesNotifications = {
  delete: () =>
    genericNotifications(
      notificationTitles.adStudies,
      null,
      notificationAction.delete
    ),
  create: () =>
    genericNotifications(
      notificationTitles.adStudies,
      null,
      notificationAction.create
    ),
  update: () =>
    genericNotifications(
      notificationTitles.adStudies,
      null,
      notificationAction.update
    ),
  moveAdStudies: {
    success: (name) => {
      return {
        title: notificationTitles.adStudies.title,
        content: `Les annonces on bien été déplacées dans le dossier "${name}"`,
        status: NotificationStatus.SUCCESS,
      };
    },
    failure: () => {
      return {
        title: notificationTitles.adStudies.title,
        content: `Les annonces n'ont pas été déplacées`,
        status: NotificationStatus.SUCCESS,
      };
    },
  },
};
