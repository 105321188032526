import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// import { generateEmailsSubscription } from '../../utils/generateEmails';

import Button from '../../Assets/Button';
import TextInput from '../../Forms/TextInput';
import RadioButtons from '../../Forms/RadioButtons';

import { subscriptionActions } from '../../../store/subscription/actions';
import { modalsActions } from '../../../store/modals/actions';
import { handleInputChange } from '../../../utils/forms';

import useAuth from '../../../App/hooks/useAuth';
import './styles/editSubscription.scss';
import {
  editSubscriptionInitForm,
  editSubscriptionRadioBtnsArray,
} from './RegistrationConstants';

function EditSubscription() {
  const { subscriptionIri } = useAuth();
  const [input, setInput] = useState(editSubscriptionInitForm);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.title) {
      dispatch(
        subscriptionActions.askForSubscriptionUpdate.request({
          ...input,
          subscription: subscriptionIri,
        })
      );
      dispatch(modalsActions.subscription.close());
    }
  };

  return (
    <div className="edit-subscription">
      <h2 className="edit-subscription-title">{`Modification de l'abonnement`}</h2>
      <form className="edit-subscription-form">
        <h3 className="edit-subscription-subtitle">Votre demande</h3>
        <RadioButtons
          name="title"
          style={{ justifyContent: 'flex-end' }}
          value={input.title}
          handleChange={(evt) => handleInputChange(evt, input, setInput)}
          radioElts={editSubscriptionRadioBtnsArray}
        />
        <div className="edit-subscription-textarea">
          <TextInput
            label="Commentaires"
            name="comment"
            value={input.comment}
            handleChange={(evt) => handleInputChange(evt, input, setInput)}
            rows={4}
          />
        </div>
        <div className="edit-subscription-btn-group">
          <Button
            btnType="button"
            btnContent="ANNULER"
            handleClick={() => dispatch(modalsActions.subscription.close())}
            textColor="violet"
            bgColor="grey"
            borderColor="grey"
          />
          <Button
            btnType="button"
            btnContent="VALIDER MA DEMANDE"
            handleClick={handleSubmit}
            primary
          />
        </div>
      </form>
    </div>
  );
}

export default EditSubscription;
