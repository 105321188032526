import { CalendarOptions, GoogleCalendar } from 'datebook';
import { ReactComponent as Calendar } from '../../images/calendar-days-solid.svg';
import styles from './calendarButton.module.scss';
interface ICalendatButtonProps {
  procedureEvent: ProcedureEvent;
}
function CalendarButton({ procedureEvent }: ICalendatButtonProps) {
  const handleCalendarClick = () => {
    const config: CalendarOptions = {
      title: `${procedureEvent.type.text} - ${procedureEvent.contact}`,
      description: procedureEvent.comment,
      start: new Date(procedureEvent.date),
      end: new Date(procedureEvent.date),
    };

    const googleCalendar = new GoogleCalendar(config);

    window.open(googleCalendar.render(), '__blank,noreferrer, noopener');
  };
  return (
    <div className={styles.calendarButton} onClick={handleCalendarClick}>
      <Calendar />
    </div>
  );
}

export default CalendarButton;
