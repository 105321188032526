import PropTypes from 'prop-types';
import Button from '../Assets/Button';

const CommonAccountFormButtons = ({ label, handleSubmit }) => {
  return (
    <div className="user-details-btns">
      <Button
        btnType="button"
        btnContent="ANNULER"
        handleClick={() => {}}
        textColor="violet"
        bgColor="white"
        borderColor="grey"
      />
      <Button
        btnType="button"
        btnContent={label}
        handleClick={handleSubmit}
        primary
      />
    </div>
  );
};

CommonAccountFormButtons.propTypes = {
  label: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
export default CommonAccountFormButtons;
