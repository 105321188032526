import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';

import './pigeParametersCitiesList.scss';
import usePigeParams from '../../../App/hooks/usePigeParams';
import useAuth from '../../../App/hooks/useAuth';
import { accountActions } from '../../../store/account/actions';
import IndividualCircularLoader from '../../Loader/IndividualCircularLoader';

import LogoButton from '../../Assets/LogoButton';

const PigeParametersCitiesList = () => {
  const dispatch = useDispatch();
  const { pigeParams } = usePigeParams();
  const { marketResearchIri } = useAuth();
  const pigeCities = {};

  const handleDelete = (id) => {
    pigeCities['cities'] = pigeParams
      .filter((elt) => elt['@id'] !== id)
      .map((elt) => elt['@id']);

    dispatch(
      accountActions.deletePigeParams.request({
        endpoint: marketResearchIri,
        body: pigeCities,
      })
    );
  };

  return (
    <div className="user-pige-elt">
      <h3 className="user-pige-title">Villes affichées</h3>
      <div className="user-pige-list">
        <h4 className="user-pige-list-title">Villes</h4>
        <ul className="user-pige-cities">
          {!pigeParams ? (
            <IndividualCircularLoader size={100} />
          ) : (
            pigeParams.map((city) => (
              <li key={uuidv4()}>
                <LogoButton
                  btnType="button"
                  handleClick={() => handleDelete(city['@id'])}
                  btnLogo="delete-red"
                  tooltip={`Supprimer`}
                />
                {city.name}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default PigeParametersCitiesList;
