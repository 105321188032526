import { useDispatch } from 'react-redux';
import urbaneaseLogo from '../../images/urbaneaseIcon.png';
import { modalsActions } from '../../store/modals/actions';
import styles from './linksToUrbanease.module.scss';
import { getUrbaneaseAuthentication } from './utils';
interface IUrbaneaseButtonProps {
  lat: number | null;
  lng: number | null;
  department: string | null;
}
function UrbaneaseButton({
  lat,
  lng,
  department,
}: IUrbaneaseButtonProps): React.ReactElement {
  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      if (lat && lng && department) {
        await getUrbaneaseAuthentication(lng, lat, department);
      }
    } catch (error: any) {
      if (error && error.status) {
        dispatch(modalsActions.noUrbaneaseAccount.open(error.status));
      }
    }
  };

  return (
    <button onClick={handleClick} className={styles.urbaneaseButton} type="button">
      <div className={styles.urbaneaseButtonContent}>
        <span>Ouvrir sur Urbanease</span>
        <img src={urbaneaseLogo} alt="urbanease" />
      </div>
    </button>
  );
}

export default UrbaneaseButton;
