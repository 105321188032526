import genericNotifications, {
  notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';
import { NotificationStatus } from '../../utils/enums';

export const activeSearchesNotifications = {
  create: (name) =>
    genericNotifications(
      notificationTitles.activeSearches,
      name,
      notificationAction.create
    ),
  update: (name) =>
    genericNotifications(
      notificationTitles.activeSearches,
      name,
      notificationAction.update
    ),
  delete: (name) =>
    genericNotifications(
      notificationTitles.activeSearches,
      name,
      notificationAction.delete
    ),
  deleteAds: {
    success: () => {
      return {
        title: notificationTitles.activeSearches.title,
        content: `Les annonces ont bien été supprimées`,
        status: NotificationStatus.SUCCESS,
      };
    },
    failure: () => {
      return {
        title: notificationTitles.activeSearches.title,
        content: `Les annonces n'ont pas été supprimées`,
        status: NotificationStatus.SUCCESS,
      };
    },
  },
};
