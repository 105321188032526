import React from 'react';
import useAuth from '../../../App/hooks/useAuth';
import useSubscription from '../../../App/hooks/useSubscription';

const RegistrationSubscribedServices = () => {
  const { isManager } = useAuth();
  const { price } = useSubscription(true);

  return (
    <>
      {isManager && (
        <div className="user-registration-elt">
          <h3 className="user-registration-title">Vos services souscrits</h3>
          <span className="user-registration-data">{`${price}€ / mois`}</span>
        </div>
      )}
    </>
  );
};

export default RegistrationSubscribedServices;
