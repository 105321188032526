import { call, put, select, take } from '@redux-saga/core/effects';
import {
  createEntity,
  deleteEntity,
  fetchDatas,
} from '../../services/axiosInstanceService';
import { getAuthState } from '../../store/auth/reducer';
import { REQUEST } from '../../store/common/actions';
import { mapObjectParser } from '../../store/common/parserCommon';
import onErrorLogout from '../../store/common/sagaCommon';
import { loaderActions } from '../../store/loader/actions';
import {
  CREATE_PROCEDURE_EVENT,
  DELETE_PROCEDURE_EVENT,
  FETCH_DASHBOARD_PROCEDURE_EVENTS,
  procedureEventsActions,
} from './actions';
import { procedureEventApiToStore, procedureEventFormToApi } from './parser';

export function* handleFetchDashboardProcedureEvents() {
  while (true) {
    try {
      yield take(FETCH_DASHBOARD_PROCEDURE_EVENTS[REQUEST]);
      const { userId } = yield select(getAuthState);

      // yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDatas, `/users/${userId}/procedure-events`);

      if (response) {
        const parsedResponse = mapObjectParser(
          response['hydra:member'],
          procedureEventApiToStore
        );
        yield put(
          procedureEventsActions.fetchDashboardProcedureEvents.success(
            parsedResponse
          )
        );
        // yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error createProcedureEvents', error);
      yield onErrorLogout(error);
      yield put(procedureEventsActions.fetchDashboardProcedureEvents.failure(error));
      // yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleCreateProcedureEvents() {
  while (true) {
    try {
      const { payload } = yield take(CREATE_PROCEDURE_EVENT[REQUEST]);
      const { user } = yield select(getAuthState);
      const parsedBody = procedureEventFormToApi(payload);
      parsedBody.owner = user.idIri;

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(createEntity, {
        endpoint: '/procedure-events',
        body: parsedBody,
      });

      if (response) {
        const parsedResponse = procedureEventApiToStore(response);
        console.log(response);
        yield put(
          procedureEventsActions.createProcedureEvents.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error createProcedureEvents', error);
      yield onErrorLogout(error);
      yield put(procedureEventsActions.createProcedureEvents.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleDeleteProcedureEvents() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_PROCEDURE_EVENT[REQUEST]);
      console.log(payload);
      yield put(loaderActions.loaderDisplay.visible());

      const status = yield call(deleteEntity, payload);

      if (status === 204) {
        yield put(procedureEventsActions.deleteProcedureEvents.success(payload));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error createProcedureEvents', error);
      yield onErrorLogout(error);
      yield put(procedureEventsActions.createProcedureEvents.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
