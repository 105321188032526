import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import useFolders from '../../App/hooks/useFolders';

import { foldersActions } from '../../store/folders/actions';
import ActionCard from '../Assets/ActionCard';
import SectionTitle from '../Assets/sectionTitle';
import IndividualCircularLoader from '../Loader/IndividualCircularLoader';

const FolderBloc = memo(() => {
  const { folders } = useFolders();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClickFoldersDetails = (id) => {
    dispatch(foldersActions.setActiveFolder.set(id));
    history.push(`/details-dossier/${id}`);
  };

  return (
    <section className="dashboard-section">
      <SectionTitle
        mainTitle="Dossiers de prospection"
        subTitle="Vos annonces en cours d’analyse..."
      />
      {!folders ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <div className="dashboard-section-content">
          {folders
            .filter((f) => f.totalNewAdStudies > 0)
            .map((elt) => (
              <ActionCard
                key={uuidv4()}
                cardType="prospection"
                cardTitle={elt.name}
                cardInfo={elt.totalAdStudies}
                cardUpdates={elt.totalNewAdStudies}
                cardState="filled"
                handleClick={() => handleClickFoldersDetails(elt.id)}
              />
            ))}
          <ActionCard
            cardTitle="Créez de nouveaux dossiers de prospection"
            cardState=""
            linkTo="/mes-dossiers-creation"
          />
        </div>
      )}
    </section>
  );
});

FolderBloc.displayName = 'FolderBloc';
export default FolderBloc;
