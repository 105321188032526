import React from 'react';
import useCollaborators from '../../App/hooks/useCollaborators';
import { v4 as uuidV4 } from 'uuid';

import TeamCard from './TeamTab/TeamCard';
import AddCollaboratorButton from './TeamTab/AddCollaboratorButton';

import './TeamTab/styles/teamTab.scss';

const TeamTab = () => {
  const { collaborators } = useCollaborators();

  return (
    <>
      <section className="my-account-content user-team">
        <header className="user-team-header">
          <h2 className="user-team-title">Equipe</h2>
          <AddCollaboratorButton />
        </header>

        <div className="user-team-content">
          {collaborators.map((user) => (
            <TeamCard key={uuidV4()} user={user} />
          ))}
        </div>
      </section>
    </>
  );
};

export default TeamTab;
