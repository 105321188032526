import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../../Modals/Modal';

import BadgeContainer from './BadgeContainer';
import ComparisonsComponent from './ComparisonsComponent';
import ModalContentHeader from './ModalContentHeader';

import { comparativeStudiesActions } from '../../../store/comparativeStudies/actions';
import { comparativeStudyComparisonsActions } from '../../../store/comparativeStudyComparisons/actions';
import { comparativeStudyComparisonsSelectActions } from '../../../store/comparativeStudyComparisonsSelect/actions';

import '../styles/comparativeStudyModalContent.scss';

function ComparativeStudyModalContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(comparativeStudiesActions.setActiveComparativeStudy.reset());
      dispatch(comparativeStudyComparisonsActions.resetComparisonsState.reset());
      dispatch(comparativeStudyComparisonsSelectActions.setAdSelected.reset());
    };
  }, []);

  return (
    <Modal>
      <div className="comparative-studies-details">
        <section className="comparative-studies-details-section">
          <ModalContentHeader />
          <BadgeContainer />
          <ComparisonsComponent />
        </section>
      </div>
    </Modal>
  );
}

export default ComparativeStudyModalContent;
