import { call, put, select, take } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import { fetchDataById } from '../../services/axiosInstanceService';
import { NotificationStatus } from '../../utils/enums';
import { appManagerActions } from '../appManager/actions';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import {
  authActions,
  FETCH_AUTH_USER,
  FIRST_PASSWORD,
  LOGIN,
  LOGOUT,
  PASSWORD_CHANGE,
  PASSWORD_RESET,
  PASSWORD_RESET_INIT,
  UPDATE_AUTH_USER,
} from './actions';
import {
  firstPassword,
  getLogin,
  passwordChange,
  passwordReset,
  passwordResetInit,
  updateAuthUser,
} from './api';
import { authUserApiToStore } from './parser';
import { getAuthState } from './reducer';

export function* handleLogin() {
  while (true) {
    try {
      const {
        payload: { values, history },
      } = yield take(LOGIN[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());

      const res = yield call(getLogin, values);
      const response = res.data;

      if (response) {
        if (res.status === 401) {
          yield put(
            appManagerActions.actionNotificationDisplay.visible({
              title: 'Connexion',
              content: `Vote compte utilisateur n'est pas activé.`,
              status: NotificationStatus.FAILURE,
            })
          );
          throw 'user account is not enabled';
        }
        if (isEmpty(response.company)) {
          yield put(
            appManagerActions.actionNotificationDisplay.visible({
              title: 'Connexion',
              content: `Vous ne faites pas partie d'une compagnie.`,
              status: NotificationStatus.FAILURE,
            })
          );
          throw 'no company found';
        }
        const parsedResponse = {
          userId: response.id,
          companyId: response.company.id,
          token: response.token,
          refresh_token: response.refresh_token,
        };

        yield put(authActions.login.success(parsedResponse));

        yield call(history.push, '/dashboard');
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield put(loaderActions.loaderDisplay.hidden());
      console.log('error login', error);
      if (
        error &&
        error.data.code === 401 &&
        error.data.message === 'Invalid credentials.'
      ) {
        yield put(authActions.login.failure());
      } else {
        yield onErrorLogout(error);
      }
    }
  }
}

export function* handleFetchAuthUser() {
  while (true) {
    try {
      yield take(FETCH_AUTH_USER[REQUEST]);
      const { userId } = yield select(getAuthState);
      const response = yield call(fetchDataById, `/users/${userId}`);
      if (response) {
        let parsedResponse = {};
        const temp = authUserApiToStore(response);

        parsedResponse.user = temp;
        parsedResponse.totalAds = temp.marketResearch.totalAds;
        parsedResponse.totalAdsToday = temp.marketResearch.totalAdsToday;

        delete temp.marketResearch.totalAds;
        delete temp.marketResearch.totalAdsToday;

        yield put(authActions.fetchAuthUser.success(parsedResponse));
      }
    } catch (error) {
      console.log('error fetchUsers', error);
      if (!error) {
        yield put(authActions.logout.success());
      }
      yield onErrorLogout(error);
      yield put(authActions.fetchAuthUser.failure(error));
    }
  }
}

export function* handleUpdateAuthUser() {
  while (true) {
    try {
      const { payload } = yield take(UPDATE_AUTH_USER[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateAuthUser, payload);
      if (response) {
        let parsedResponse = {};
        const temp = authUserApiToStore(response);
        parsedResponse.user = temp;
        parsedResponse.totalAds = temp.marketResearch.totalAds;
        parsedResponse.totalAdsToday = temp.marketResearch.totalAdsToday;

        delete temp.marketResearch.totalAds;
        delete temp.marketResearch.totalAdsToday;
        yield put(authActions.updateAuthUser.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchUsers', error);
      yield onErrorLogout(error);
      yield put(authActions.updateAuthUser.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleLogout() {
  while (true) {
    try {
      const { payload } = yield take(LOGOUT[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());

      // const response = yield call(getLogout);
      const status = 200;
      if (status === 200) {
        yield put(authActions.logout.success());
        yield put(loaderActions.loaderDisplay.hidden());
        if (payload) {
          yield call(payload.push, '/login');
        }
      }
    } catch (error) {
      console.log('error logout', error);
      yield onErrorLogout(error);
      yield put(authActions.logout.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handlePasswordChange() {
  while (true) {
    try {
      const {
        payload: { values, id },
      } = yield take(PASSWORD_CHANGE[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(passwordChange, {
        endpoint: `${id}/update-password`,
        body: values,
      });
      if (response) {
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible({
            title: 'Mot de passe',
            content: `Votre changement de mot de passe a été effectuée avec succès`,
            status: NotificationStatus.SUCCESS,
          })
        );
      }
    } catch (error) {
      console.log('error passwordChange', error);
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Mot de passe',
          content: `Votre changement de mot de passe a échoué`,
          status: NotificationStatus.FAILURE,
        })
      );
    }
  }
}

export function* handlePasswordResetInit() {
  while (true) {
    try {
      const {
        payload: { values, history },
      } = yield take(PASSWORD_RESET_INIT[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      yield call(passwordResetInit, {
        endpoint: '/reset-password-request',
        body: values,
      });
      yield put(loaderActions.loaderDisplay.hidden());
      yield call(history.push, '/');
    } catch (error) {
      console.log('error passwordResetInit', error);
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Connexion',
          content: `Votre demande de changement de mot de passe n'a pas pu être envoyée`,
          status: NotificationStatus.FAILURE,
        })
      );
    }
  }
}

export function* handlePasswordReset() {
  while (true) {
    try {
      const {
        payload: { values, history },
      } = yield take(PASSWORD_RESET[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      yield call(passwordReset, {
        endpoint: '/reset-password',
        body: values,
      });
      yield put(loaderActions.loaderDisplay.hidden());
      yield call(history.push, '/');
    } catch (error) {
      console.log('error passwordReset', error);
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Changement de mot de passe',
          content: `Votre demande de changement de mot de passe n'a pas pu être envoyée`,
          status: NotificationStatus.FAILURE,
        })
      );
    }
  }
}

export function* handleFirstPassword() {
  while (true) {
    try {
      const {
        payload: { values, history },
      } = yield take(FIRST_PASSWORD[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      yield call(firstPassword, {
        endpoint: '/set-password',
        body: values,
      });
      yield put(loaderActions.loaderDisplay.hidden());
      yield call(history.push, '/');
    } catch (error) {
      console.log('error firstPassword', error);
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Premier mot de passe',
          content: `Votre premier mot de passe n'a pas pu être envoyée`,
          status: NotificationStatus.FAILURE,
        })
      );
    }
  }
}
