import {
  CREATE_AD_STUDIES,
  DELETE_AD_STUDIES,
  MOVE_AD_STUDIES,
} from '../adStudies/actions';
import { LOGOUT } from '../auth/actions';
import { MODAL_DISPLAY, RESET, SUCCESS } from '../common/actions';
import {
  FETCH_FOLDERS,
  FOLDERS_SET_ACTIVE,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER,
} from './actions';

const initialState = {
  deleteModal: { idIri: null, name: null },
  folders: null,
  activeFolder: null,
  activeFolderAds: [],
  movingAds: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FOLDERS[SUCCESS]:
      return { ...state, folders: payload };
    case FOLDERS_SET_ACTIVE[SUCCESS]:
      return { ...state, activeFolder: payload };
    case CREATE_FOLDER[SUCCESS]:
      return { ...state, folders: state.folders.concat(payload) };
    case UPDATE_FOLDER[SUCCESS]:
      return {
        ...state,
        folders: state.folders.map((folder) =>
          folder.idIri === payload.idIri ? payload : folder
        ),
      };

    case DELETE_FOLDER[MODAL_DISPLAY]:
      return { ...state, deleteModal: { idIri: payload.idIri, name: payload.name } };
    case DELETE_FOLDER[RESET]:
      return {
        ...state,
        deleteModal: { idIri: null, name: null },
      };
    case DELETE_FOLDER[SUCCESS]:
      return {
        ...state,
        folders: state.folders.filter((folder) => folder.idIri !== payload),
        deleteModal: { idIri: null, name: null },
      };

    case MOVE_AD_STUDIES[MODAL_DISPLAY]:
      return { ...state, movingAds: true };
    case MOVE_AD_STUDIES[SUCCESS]:
      return { ...state, movingAds: false };

    case CREATE_AD_STUDIES[SUCCESS]:
      return {
        ...state,
        folders: state.folders.map((e) =>
          e.idIri !== payload.folderIdIri
            ? e
            : { ...e, totalAdStudies: e.totalAdStudies + 1 }
        ),
      };
    case DELETE_AD_STUDIES[SUCCESS]: {
      const selectedFolder = state.folders.find(
        (e) => e.idIri === state.activeFolder
      );
      selectedFolder.totalAdStudies = selectedFolder.totalAdStudies - 1;
      return {
        ...state,
        folders: state.folders.map((e) =>
          e.idIri !== state.activeFolder ? e : { ...selectedFolder }
        ),
      };
    }
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getFoldersState = (store) => store.folders;
