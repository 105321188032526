import { isEmpty } from 'lodash';
import {
  collaboratorApiToStore,
  nestedCollaboratorApiToStore,
  nestedCollaboratorsFormToApi,
} from '../collaborators/parser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  mapObjectParser,
} from '../common/parserCommon';

const folderMapping = {
  '@id': 'idIri',
  collaborators: 'collaborators',
  created_at: 'createdAt',
  is_default: 'isDefault',
  name: 'name',
  owner: 'owner',
  total_collaborators: 'totalCollaborators',
  hasUpdates: 'hasUpdates',
  total_ad_studies: 'totalAdStudies',
  total_new_ad_studies: 'totalNewAdStudies',
};

export const folderApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, folderMapping);

  parsedData.id = parsedData.idIri.split('/')[2];
  parsedData.owner = collaboratorApiToStore(parsedData.owner);

  parsedData.collaborators = mapObjectParser(
    parsedData.collaborators,
    nestedCollaboratorApiToStore
  );

  return parsedData;
};

export const folderFormToApi = (data) => {
  const parsedData = changeStoreKeyForApiKey(data, folderMapping);
  delete parsedData.owner;
  delete parsedData.total_ad_studies;
  delete parsedData.total_collaborators;

  if (!isEmpty(parsedData.collaborators)) {
    parsedData.collaborators = nestedCollaboratorsFormToApi(
      parsedData.collaborators
    );
  }

  return parsedData;
};
