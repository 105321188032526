import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import Button from '../Assets/Button';
import LogoButton from '../Assets/LogoButton';

import { ReactComponent as MapMarker } from '../../images/map-marker.svg';
import { ReactComponent as RedArrow } from '../../images/arrow-red-up.svg';
import { ReactComponent as GreenArrow } from '../../images/arrow-green-down.svg';
import { comparativeStudiesActions } from '../../store/comparativeStudies/actions';

function ComparativeStudyCard({ content }) {
  const {
    city,
    propertyTypology,
    roomMin,
    price,
    builtArea,
    landArea,
    priceComparisons: { lower, equal, higher, percentagePriceComparison },
  } = content;

  const history = useHistory();
  const dispatch = useDispatch();

  const handleConsultCard = (id) => {
    dispatch(comparativeStudiesActions.setActiveComparativeStudy.set(id));
  };

  const displayPropertyTypologyAndRooms = () => (
    <span className="comparative-studies-details-primary-info">{`${
      propertyTypology.name
    } ${roomMin ? 'T' + roomMin : ''}`}</span>
  );

  return (
    <div className="comparative-studies-card">
      <header className="comparative-studies-card-header">
        <div className="comparative-studies-card-header-elt">
          <h4 className="comparative-studies-card-title">
            {displayPropertyTypologyAndRooms()}
          </h4>
          <span className="comparative-studies-card-price">{`${
            price && price > 0 ? price : 0
          } €`}</span>
        </div>
        <div className="comparative-studies-card-header-elt">
          <div className="comparative-studies-card-state">
            <span
              className={
                percentagePriceComparison > 0
                  ? 'comparative-studies-card-percent-plus'
                  : 'comparative-studies-card-percent-less'
              }
            >{`${percentagePriceComparison}%`}</span>

            {percentagePriceComparison > 0 ? <RedArrow /> : <GreenArrow />}
          </div>
          <span className="comparative-studies-card-sub">
            du prix moyen constaté
          </span>
        </div>
      </header>

      <div className="comparative-studies-card-content">
        <div className="comparative-studies-card-content-elt">
          <div className="comparative-studies-card-city">
            <MapMarker />
            <span>{city ? city.name : ''}</span>
          </div>
          <span className="comparative-studies-card-surfaces">{`S. bien ${
            builtArea && builtArea > 0 ? builtArea : 0
          } m²`}</span>
          <span className="comparative-studies-card-surfaces">{`S. terrain ${
            landArea && landArea > 0 ? landArea : 0
          } m²`}</span>
        </div>
        <div className="comparative-studies-card-comparison">
          <div className="comparative-studies-card-comparison-elt">
            <span className="comparative-studies-card-comparison-label">
              - chers
            </span>
            <span className="comparative-studies-card-comparison-minus">
              {lower}
            </span>
          </div>
          <div className="comparative-studies-card-comparison-elt">
            <span className="comparative-studies-card-comparison-label">
              identiques
            </span>
            <span className="comparative-studies-card-comparison-minus">
              {equal}
            </span>
          </div>
          <div className="comparative-studies-card-comparison-elt">
            <span className="comparative-studies-card-comparison-label">
              + chers
            </span>
            <span className="comparative-studies-card-comparison-plus">
              {higher}
            </span>
          </div>
        </div>
      </div>

      <footer className="comparative-studies-card-footer">
        <Button
          btnType="button"
          btnContent="Consulter"
          handleClick={() => handleConsultCard(content.id)}
          primary
        />
        <LogoButton
          btnType="button"
          handleClick={() =>
            history.push(`/etude-comparative-modification/${content.id}`)
          }
          btnLogo="pen-violet"
          tooltip={`Modifier`}
        />
        <LogoButton
          btnType="button"
          handleClick={() =>
            dispatch(
              comparativeStudiesActions.deleteComparativeStudy.modal({
                idIri: content.idIri,
                display: true,
                typology: content.propertyTypology.name,
                city: content.city.name,
              })
            )
          }
          btnLogo="delete-red"
          tooltip={`Supprimer`}
        />
      </footer>
    </div>
  );
}

ComparativeStudyCard.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ComparativeStudyCard;
