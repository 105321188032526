import { useEffect, useState } from 'react';
import Map from '../../../features/adMap/Map';

import SinglePlotMapContainer from '../../../features/singlePlotMap/SinglePlotMapContainer';
import './adDetailsGeo.scss';
import SimilarAreaAdForm from './SimilarAreaAdForm';

interface IGeoContentProps {
  ad: Ad;
}

const GeoContent = ({ ad }: IGeoContentProps) => {
  const { propertyTypology, latitude, longitude, city, price } = ad;
  const [displayedLatLng, setDisplayedLatLng] = useState<DisplayedLatLngState>(null);

  useEffect(() => {
    // if no lat/lng on add, take the city lat/lng
    if (latitude && longitude) {
      setDisplayedLatLng({ lat: latitude, lng: longitude, city: false });
    } else if (city.lat && city.lng) {
      setDisplayedLatLng({ lat: city.lat, lng: city.lng, city: true });
    }
  }, [latitude, longitude, city]);

  return (
    <>
      {ad && (
        <div className="ad-details-geo">
          {propertyTypology.name !== 'Appartement' && (
            <Map
              city={city}
              displayedLatLng={displayedLatLng}
              typologyName={ad.propertyTypology.name}
              price={price}
            />
          )}

          {propertyTypology.name !== 'Appartement' && (
            <>
              <SimilarAreaAdForm ad={ad} />

              <SinglePlotMapContainer />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default GeoContent;
