import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../common/actions';

export const FETCH_PROPERTY_TYPOLOGY = createRequestTypes('FETCH_PROPERTY_TYPOLOGY');

export const propertyTypologyActions = {
  fetchPropertyTypologies: {
    request: () => action(FETCH_PROPERTY_TYPOLOGY[REQUEST]),
    success: (data) => action(FETCH_PROPERTY_TYPOLOGY[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_PROPERTY_TYPOLOGY[FAILURE], { payload: data }),
  },
};
