export enum BadgeTextColorEnum {
  WHITE = ' badge-text-white',
  DARK_GREY = ' badge-text-dark-grey',
  DARKER_GREY = ' badge-text-darker-grey',
  VIOLET = ' badge-text-violet',
  DARK_GREEN = ' badge-text-dark-green',
  DARK_BLUE = ' badge-text-dark-blue',
}

export enum BadgeBackgroundColorEnum {
  GREY = ' badge-bg-grey',
  VIOLET = ' badge-bg-violet',
  RED = ' badge-bg-red',
  BLUE = ' badge-bg-blue',
  GREEN = ' badge-bg-green',
}

export enum BadgeContentEnum {
  ABANDONNE = 'Abandonné',
  EN_COURS = 'En cours',
  VALIDE = 'Validé',
  EXCLU = 'Exclu',
  TOUS = 'Tous',
  PARTICULIER = 'Particulier',
  PRO = 'Pro',
  ENCHERES = 'Enchères',
  ETAT = 'Etat',
  AUTRE = 'Autre',
  NOUVEAU = 'Nouveau',
  MISE_A_JOUR = 'Mise à jour',
  NO_DATAS = 'Absence de données à analyser',
}
