import React from 'react';
import { Document } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import GoodStatPage from './GoodStatPage';
import Multigraph from './Multigraph';
import { isEmpty } from 'lodash';

const PdfMarketStudy = ({
  name,
  pdfData,
  sqmTypo,
  stockTypo,
  barGraphAvgSrc = [],
  barGraphSqmSrc = [],
  comment,
}) => {
  return (
    <>
      {pdfData &&
        pdfData.goodsDatas &&
        !isEmpty(barGraphAvgSrc) &&
        !isEmpty(barGraphSqmSrc) && (
          <Document>
            <>
              <GoodStatPage
                goodsDatas={pdfData.goodsDatas}
                name={name}
                barGraphAvgSrc={barGraphAvgSrc}
                barGraphSqmSrc={barGraphSqmSrc}
                comment={comment}
              />
              {/* TODO: fix graph string, pour l'instant seulement un seul graph est
              render, il faut créer un array qui contient les 2 graphs*/}
              <Multigraph sqmTypo={sqmTypo} stockTypo={stockTypo} name={name} />
            </>
          </Document>
        )}
    </>
  );
};

PdfMarketStudy.propTypes = {
  comment: PropTypes.object,
  pdfData: PropTypes.object,
  barGraphAvgSrc: PropTypes.array,
  barGraphSqmSrc: PropTypes.array,
  graph: PropTypes.string,
  name: PropTypes.string,
  sqmTypo: PropTypes.string,
  stockTypo: PropTypes.string,
};

export default PdfMarketStudy;
