import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SUCCESS,
} from '../common/actions';

// ********************************
// ** SUBSCRIPTION
// ********************************
export const FETCH_SUBSCRIPTION = createRequestTypes('FETCH_SUBSCRIPTION');
export const FETCH_SUBSCRIPTION_CITIES = createRequestTypes(
  'FETCH_SUBSCRIPTION_CITIES'
);
export const RESET_SUBSCRIPTION_CITIES = createRequestTypes(
  'RESET_SUBSCRIPTION_CITIES'
);
export const ASK_FOR_SUBSCRIPTION_UPDATE = createRequestTypes(
  'ASK_FOR_SUBSCRIPTION_UPDATE'
);

export const subscriptionActions = {
  fetchSubscription: {
    request: (data) => action(FETCH_SUBSCRIPTION[REQUEST], { payload: data }),
    success: (data) => action(FETCH_SUBSCRIPTION[SUCCESS], { payload: data }),
    failure: () => action(FETCH_SUBSCRIPTION[FAILURE]),
  },
  fetchSubscriptionCities: {
    request: (data) => action(FETCH_SUBSCRIPTION_CITIES[REQUEST], { payload: data }),
    success: (data) => action(FETCH_SUBSCRIPTION_CITIES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_SUBSCRIPTION_CITIES[FAILURE], { payload: data }),
  },
  resetSubscriptionCities: {
    reset: () => action(RESET_SUBSCRIPTION_CITIES[RESET], { payload: [] }),
  },
  askForSubscriptionUpdate: {
    request: (data) =>
      action(ASK_FOR_SUBSCRIPTION_UPDATE[REQUEST], { payload: data }),
    success: (data) =>
      action(ASK_FOR_SUBSCRIPTION_UPDATE[SUCCESS], { payload: data }),
    failure: (data) =>
      action(ASK_FOR_SUBSCRIPTION_UPDATE[FAILURE], { payload: data }),
    reset: () => action(ASK_FOR_SUBSCRIPTION_UPDATE[RESET], { payload: false }),
  },
};
