import { useDispatch } from 'react-redux';
import AlertsTable from '../../features/procedureEvent';
import { modalsActions } from '../../store/modals/actions';

import Button from '../Assets/Button';

import './styles/procedureEventModal.scss';

const ProcedureEventsModal = () => {
  const dispatch = useDispatch();
  return (
    <div className="procedure-event-modal">
      <AlertsTable />
      <div className="procedure-event-modal-btn-container">
        <Button
          btnType="button"
          btnContent="FERMER"
          primary
          handleClick={() => dispatch(modalsActions.notificationBell.close())}
        />
      </div>
    </div>
  );
};

export default ProcedureEventsModal;
