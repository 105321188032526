import { NotificationStatus } from './enums';

export const notificationTitles = {
  comparativeStudies: {
    title: 'Etudes comparatives',
    content: `L'étude comparative `,
  },
  activeSearches: {
    title: 'Recherches actives',
    content: `La recherche active `,
  },
  ads: {
    title: 'Pige classique',
    content: `L'annonce' `,
  },
  marketStudies: { title: 'Etudes de marché', content: `L'étude de marché` },
  marketStudyResult: {
    title: 'Etudes de marché : Resultat',
    content: `Le commentaire`,
  },
  folders: { title: 'Mes dossiers', content: `Le dossier ` },
  userDetails: { title: 'Coordonnées', content: `Les coordonnées ` },
  collaboratorEnable: {
    title: 'Collaborateur',
    content: 'Le collaborateur a bien été',
  },
  collaborator: {
    title: 'Collaborateur',
    content: 'Le collaborateur',
  },
  adStudies: { title: 'Prospection', content: 'Le(s) biens prospectés' },
  pigeParams: {
    title: 'Paramètres Pige',
    content: 'Le(s) paramètre(s)',
  },
};

export const notificationAction = {
  create: 'créé(e)',
  update: 'modifié(e)',
  delete: 'supprimé(e)',
};

export default function genericNotifications({ title, content }, name, action) {
  return {
    success: () => {
      return {
        title: title,
        content: name
          ? `${content} "${name}" a été ${action} avec succès.`
          : `${content} a été ${action} avec succès.`,
        status: NotificationStatus.SUCCESS,
      };
    },
    enable: () => {
      return {
        title: title,
        content: name
          ? `${content} activé avec succès.`
          : `${content} desactivé avec succès.`,
        status: NotificationStatus.SUCCESS,
      };
    },
    failure: () => {
      return {
        title: title,
        content: name
          ? `${content} "${name}" n'a pas pu être ${action}.`
          : `${content} n'a pu être ${action}.`,
        status: NotificationStatus.FAILURE,
      };
    },
  };
}
