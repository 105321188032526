import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const COMPARATIVE_STUDY_COMPARISONS_ACTIVE_TAB = createRequestTypes(
  'COMPARATIVE_STUDY_COMPARISONS_ACTIVE_TAB'
);
export const FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON = createRequestTypes(
  'FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON'
);
export const RESET_COMPARISONS_STATE = createRequestTypes('RESET_COMPARISONS_STATE');

export const comparativeStudyComparisonsActions = {
  fetchActiveComparativeStudyComparisons: {
    request: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON[REQUEST], { payload: data }),
    success: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON[FAILURE], { payload: data }),
  },
  setComparisonsActiveTab: {
    set: (data) =>
      action(COMPARATIVE_STUDY_COMPARISONS_ACTIVE_TAB[SET], { payload: data }),
  },
  resetComparisonsState: {
    reset: () => action(RESET_COMPARISONS_STATE[RESET]),
  },
};
