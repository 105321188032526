import { axiosTokenInstance } from '../../services/axiosInstanceService';

export const fetchAds = (marketId) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(`${marketId}/ads`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const fetchAd = (adId) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(`${adId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export function deleteAds({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.request.status))
      .catch((error) => reject(error));
  });
}
