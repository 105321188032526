import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as UrbaneaseLogo } from '../../images/urbaneaseIcon.svg';
import { getAuthState } from '../../store/auth/reducer';
import { modalsActions } from '../../store/modals/actions';
import { getModalState } from '../../store/modals/reducer';
import styles from './linksToUrbanease.module.scss';
import { getUrbaneaseAuthentication } from './utils';

function LinkFromMenu() {
  const { mobileNav } = useSelector(getModalState);
  const { user } = useSelector(getAuthState);
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (mobileNav) {
      dispatch(modalsActions.mobileNav.close());
    }
    try {
      await getUrbaneaseAuthentication();
    } catch (error: any) {
      if (error?.status) {
        dispatch(modalsActions.noUrbaneaseAccount.open(error.status));
      }
    }
  };

  return (
    <li className={styles.linkFromMenu} onClick={handleClick}>
      <UrbaneaseLogo className={styles.logo} />
      <span>Carte Urbanease</span>
    </li>
  );
}

export default LinkFromMenu;
