import { isEmpty } from 'lodash-es';
import { cityApiToStoreForMap } from '../../utils/cityParser';
import { changeApiKeyForStoreKey, mapObjectParser } from '../common/parserCommon';
import { propertyTypologiesApiToStore } from '../propertyTypologies/parser';

export const adMapping = {
  '@id': 'idIri',
  id: 'id',
  url: 'url',
  price: 'price',
  seller_type: 'sellerType',
  seller_name: 'sellerName',
  seller_phone: 'sellerPhone',
  built_area: 'builtArea',
  land_area: 'landArea',
  total_room: 'totalRoom',
  picture: 'picture',
  longitude: 'longitude',
  latitude: 'latitude',
  sqm_price: 'sqmPrice',
  city: 'city',
  source: 'source',
  property_typology: 'propertyTypology',
  published_at: 'publishedAt',
  status: 'status',
  is_exclusive: 'isExclusive',
  is_bargain: 'isBargain',
  ghg_rank: 'ghgRank',
  energy_rank: 'energyRank',
  is_geolocatable: 'isGeolocatable',
  customized_data: 'studied',
  description: 'description',
  old_prices: 'oldPrices',
  children: 'similaries',
};
const adParcelMapping = {
  area: 'area',
  build: 'build',
  lat: 'lat',
  lon: 'long',
  address: 'address',
};

export const adParcelApiToStore = (data) => {
  try {
    const parsedData = changeApiKeyForStoreKey(data, adParcelMapping);

    return parsedData;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const adApiToStore = (data) => {
  const parsedData = changeApiKeyForStoreKey(data, adMapping);
  parsedData.id = parseInt(parsedData.idIri.split('/')[2], 10);
  parsedData.source = parsedData.source.label;

  parsedData.city = cityApiToStoreForMap(parsedData.city);
  parsedData.propertyTypology = propertyTypologiesApiToStore(
    parsedData.propertyTypology
  );

  if (parsedData.totalRoom === 0) {
    parsedData.totalRoom = null;
  }
  // if (isEmpty(parsedData.studied.studied_by)) {
  //   parsedData.studied = {
  //     isStudied: false,
  //     adStudies: [],
  //   };
  // } else {
  //   // const { folders } = store.getState((state) => state.folders);
  //   // const folder = folders.find(e=>e.idIri = )
  //   const temp = {
  //     isStudied: true,
  //     adStudies: [],
  //   };
  //   parsedData.studied.studied_by.forEach((e) =>
  //     temp.adStudies.push({
  //       idIri: e['@id'],
  //       // folderIri: e.folder,
  //       // folderId: e.folder.split('/')[2],
  //       // ownerIri: e.owner,
  //     })
  //   );
  // }

  if (parsedData.similaries) {
    parsedData.similaries = !isEmpty(parsedData.similaries)
      ? mapObjectParser(parsedData.similaries, adApiToStore)
      : [];
  }

  return parsedData;
};
