import PropTypes from 'prop-types';

import './styles/LogoButton.scss';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { ReactComponent as CaseBlue } from '../../images/case-blue.svg';
import { ReactComponent as DeleteRed } from '../../images/delete-red.svg';
import { ReactComponent as FolderWait } from '../../images/folder-wait.svg';
import { ReactComponent as MailBlue } from '../../images/mail-blue.svg';
import { ReactComponent as MarkerBlue } from '../../images/marker-blue.svg';
import { ReactComponent as PenViolet } from '../../images/pen-violet.svg';
import { ReactComponent as PhoneBlue } from '../../images/phone-blue.svg';
import { ReactComponent as PictoEuro } from '../../images/picto-euro.svg';
import { ReactComponent as PictoPrinter } from '../../images/picto-printer.svg';
import { ReactComponent as ThreePoints } from '../../images/three-points.svg';
import { ReactComponent as TimeBlue } from '../../images/time-blue.svg';

function LogoButton({
  btnType,
  handleClick = () => {},
  btnLogo,
  disabled,
  tooltip,
  openTooltip,
}) {
  return (
    <Tooltip
      title={tooltip}
      arrow
      TransitionComponent={Zoom}
      placement="top"
      open={openTooltip}
    >
      <button
        className={disabled ? 'logo-button disabled' : 'logo-button'}
        type={btnType}
        onClick={handleClick}
      >
        {btnLogo === 'printer' && <PictoPrinter />}
        {btnLogo === 'folder-wait' && <FolderWait />}
        {btnLogo === 'delete-red' && <DeleteRed />}
        {btnLogo === 'pen-violet' && <PenViolet />}
        {btnLogo === 'mail' && <MailBlue />}
        {btnLogo === 'phone' && <PhoneBlue />}
        {btnLogo === 'time' && <TimeBlue />}
        {btnLogo === 'marker' && <MarkerBlue />}
        {btnLogo === 'euro' && <PictoEuro />}
        {btnLogo === 'case' && <CaseBlue />}
        {btnLogo === 'threePoints' && <ThreePoints />}
      </button>
    </Tooltip>
  );
}

LogoButton.propTypes = {
  btnType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  btnLogo: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string.isRequired,
  openTooltip: PropTypes.bool,
};

export default LogoButton;
