import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const FETCH_COLLABORATORS = createRequestTypes('FETCH_COLLABORATORS');
export const CREATE_COLLABORATOR = createRequestTypes('CREATE_COLLABORATOR');
export const UPDATE_COLLABORATOR = createRequestTypes('UPDATE_COLLABORATOR');
export const ENABLE_COLLABORATOR = createRequestTypes('ENABLE_COLLABORATOR');
export const SELECTED_COLLABORATOR = createRequestTypes('SELECTED_COLLABORATOR');

export const collaboratorsActions = {
  fetchCollaborators: {
    request: () => action(FETCH_COLLABORATORS[REQUEST]),
    success: (data) => action(FETCH_COLLABORATORS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_COLLABORATORS[FAILURE], { payload: data }),
  },
  createCollaborator: {
    request: (data) => action(CREATE_COLLABORATOR[REQUEST], { payload: data }),
    success: (data) => action(CREATE_COLLABORATOR[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_COLLABORATOR[FAILURE], { payload: data }),
  },
  updateCollaborator: {
    request: (data) => action(UPDATE_COLLABORATOR[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_COLLABORATOR[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_COLLABORATOR[FAILURE], { payload: data }),
  },
  enableCollaborator: {
    success: (data) => action(ENABLE_COLLABORATOR[SUCCESS], { payload: data }),
    failure: (data) => action(ENABLE_COLLABORATOR[FAILURE], { payload: data }),
    request: (data) => action(ENABLE_COLLABORATOR[REQUEST], { payload: data }),
  },
  selectedCollaborator: {
    set: (data) => action(SELECTED_COLLABORATOR[SET], { payload: data }),
    reset: () => action(SELECTED_COLLABORATOR[RESET]),
  },
};
