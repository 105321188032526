import { axiosTokenInstance } from '../../services/axiosInstanceService';

export const getPropertyTypologies = () => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get('/property-typologies')
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
