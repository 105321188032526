import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import ClassNames from 'classnames';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faqArrayProspec } from './arrayDatas';

import './styles/faqTab.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.primary.main,
  },
  heading: {
    flexBasis: '95%',
    flexShrink: 0,
  },
}));

function createMarkup(value) {
  return { __html: value };
}

const FaqTab = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={ClassNames(classes.root, 'faq')}>
      {faqArrayProspec.map((elmt, index) => (
        <Accordion
          key={uuidV4()}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <div
              className="faq-question"
              dangerouslySetInnerHTML={createMarkup(elmt.question)}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div
              className="faq-answer"
              dangerouslySetInnerHTML={createMarkup(elmt.answer)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FaqTab;
