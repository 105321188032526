import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { appManagerActions } from '../../store/appManager/actions';
import { getAppManagerState } from '../../store/appManager/reducer';

const TabItem = ({ index, type, children }) => {
  const [keyName, setKeyName] = useState('');
  const appManager = useSelector(getAppManagerState);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (type) {
      case 'assistance':
        setKeyName('selectedAssistanceTab');
        break;
      default:
        setKeyName('selectedAccountTab');
    }
  }, [type]);

  const handleClick = () => {
    dispatch(appManagerActions[keyName].set(index));
  };
  return (
    <li
      onClick={handleClick}
      className={
        appManager[keyName] === index ? 'tabs-list-elt active' : 'tabs-list-elt'
      }
    >
      {children}
    </li>
  );
};

TabItem.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabItem;
