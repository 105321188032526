import { useSelector } from 'react-redux';
import SectionTitle from '../components/Assets/sectionTitle';
import TabContainer from '../components/Assets/TabContainer';
import { assistanceTabArray } from '../components/Assistance/arrayDatas';
import FaqTab from '../components/Assistance/FaqTab';
import VideoTab from '../components/Assistance/VideoTab';
import { getAppManagerState } from '../store/appManager/reducer';

import './styles/assistancePage.scss';

function AssistancePage() {
  const { selectedAssistanceTab } = useSelector(getAppManagerState);

  return (
    <>
      <SectionTitle
        mainTitle={`Assistance`}
        subTitle="Quelques vidéos pour vous aider..."
      />

      <div className="assistance">
        <TabContainer tabArray={assistanceTabArray} type="assistance" />

        {selectedAssistanceTab === 0 && <VideoTab />}
        {selectedAssistanceTab === 1 && <FaqTab />}
      </div>
    </>
  );
}

export default AssistancePage;
