import * as yup from 'yup';
import { TITLE } from '../../utils/validation';

export const createInitialValues = {
  idIri: null,
  name: '',
  collaborators: [],
  is_default: false,
};

export const schema = yup.object({
  name: yup.string().required(TITLE.required),
});

export const sortSelectOptions = [
  { value: '', text: 'Aucun' },
  { value: 'name', text: 'Nom' },
  { value: 'totalAdStudies', text: `Nombre d'annonces` },
];
