import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { modalsActions } from '../../store/modals/actions';
import { subscriptionActions } from '../../store/subscription/actions';
import { subscriptionUpdateType } from '../Account/RegistrationTab/RegistrationConstants';
import Button from '../Assets/Button';
import useSubscription from './../../App/hooks/useSubscription';
import './styles/subscriptionSummary.scss';

const SubscriptionSummary = () => {
  const { subscriptionUpdateBody } = useSubscription();
  const dispatch = useDispatch();

  const handleClose = () => {
    // dispatch(modalsActions.subscriptionSummary.close());
    dispatch(subscriptionActions.askForSubscriptionUpdate.reset());
  };

  useEffect(() => {
    return () => dispatch(subscriptionActions.askForSubscriptionUpdate.reset());
  }, []);

  return (
    <>
      {subscriptionUpdateBody && (
        <div className="subscription-summary">
          <h2>Modification d&apos;abonnement</h2>
          <h4>Confirmation de demande</h4>
          <p className="subscription-summary-type">
            <span>Object :</span>
            {` ${
              subscriptionUpdateType.find(
                (elt) => elt.value === subscriptionUpdateBody.title
              ).summaryType
            }
        `}
          </p>
          <p className="subscription-summary-comment">
            <span>Commentaire :</span>
            {` ${subscriptionUpdateBody.comment}`}
          </p>
          <p className="subscription-summary-confirm-sentense">
            Nous avons bien reçu votre demande et nous vous contacterons dans les
            plus bref delais.
          </p>
          <Button
            btnType="button"
            btnContent="FERMER"
            handleClick={handleClose}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
        </div>
      )}
    </>
  );
};

export default SubscriptionSummary;
