import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as InfoWhite } from '../../images/info-white.svg';
import { ReactComponent as CrossWhite } from '../../images/cross-white.svg';
import { getAnnouncementsState } from '../../store/announcements/reducer';

import './announcements.scss';
import { announcementsActions } from '../../store/announcements/action';
import IndividualCircularLoader from '../Loader/IndividualCircularLoader';

const Announcements = memo(() => {
  const { announcements, isVisible } = useSelector(getAnnouncementsState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(announcementsActions.fetchAnnouncements.request());
  }, []);

  return (
    <>
      {!announcements ? (
        <IndividualCircularLoader size={100} />
      ) : (
        announcements[0] &&
        isVisible && (
          <div className="alert-modal">
            <div className="alert-modal-elt">
              <InfoWhite />
              <div className="alert-modal-text">
                <h3>{announcements[0].title}</h3>
                <p>{announcements[0].description}</p>
              </div>
            </div>
            <button
              className="alert-modal-btn"
              type="button"
              onClick={() => dispatch(announcementsActions.isVisible.hidden())}
            >
              FERMER
              <CrossWhite />
            </button>
          </div>
        )
      )}
    </>
  );
});

Announcements.displayName = 'Announcements';
export default Announcements;
