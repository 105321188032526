import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../../App/hooks/useAuth';
import useCompany from '../../App/hooks/useCompany';
import { accountActions } from '../../store/account/actions';
import { authActions } from '../../store/auth/actions';
import CommonAccountFormButtons from './CommonAccountFormButtons';
import CompanyForm from './DetailsTab/CompanyForm';
import { detailsInitialValues, detailsSchema } from './DetailsTab/detailsFormValues';
import './DetailsTab/UserDetails.scss';
import UserForm from './DetailsTab/UserForm';

const DetailsTab = () => {
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isCompanyUpdated, setIsCompanyUpdated] = useState(false);
  const { user, isManager, companyIri, userIri } = useAuth();
  const company = useCompany();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: detailsInitialValues(user, company),
    validationSchema: detailsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isUserUpdated) {
        dispatch(
          authActions.updateAuthUser.request({ endpoint: userIri, body: values })
        );
      }

      if (isManager && isCompanyUpdated) {
        dispatch(
          accountActions.updateCompany.request({
            endpoint: companyIri,
            body: values,
          })
        );
      }
    },
  });

  return (
    <section className="my-account-content user-details">
      <form className="user-details-form">
        <div className="user-details-elt">
          <UserForm formik={formik} setIsUserUpdated={setIsUserUpdated} />
          {isManager && (
            <CompanyForm formik={formik} setIsCompanyUpdated={setIsCompanyUpdated} />
          )}
        </div>
        <CommonAccountFormButtons
          label="METTRE A JOUR MES COORDONNEES"
          handleSubmit={formik.handleSubmit}
        />
      </form>
    </section>
  );
};

export default DetailsTab;
