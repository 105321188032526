import * as yup from 'yup';

import { CITY } from '../../../utils/validation';

export const initialValues = {
  cities: [],
};

export const schema = yup.object({
  cities: yup.array().min(1, CITY.required).required(),
});
