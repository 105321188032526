import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { passwordResetInitForm, passwordResetInitSchema } from './constants';

import Button from '../Assets/Button';
import TextInput from '../Forms/TextInput';
import { authActions } from '../../store/auth/actions';

function PasswordResetInit() {
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: passwordResetInitForm,
    validationSchema: passwordResetInitSchema,
    onSubmit: (values) => {
      dispatch(authActions.passwordResetInit.request({ values, history }));
    },
  });

  const { values, touched, errors } = formik;

  return (
    <form className="sign-form" noValidate>
      <TextInput
        name="username"
        label="Email"
        type="email"
        value={values.username}
        handleChange={formik.handleChange}
        error={touched.username && Boolean(errors.username)}
        helperText={touched.username && errors.username}
      />
      <Button
        btnType="submit"
        btnContent="ENVOYER"
        primary
        handleClick={formik.handleSubmit}
      />
    </form>
  );
}

export default PasswordResetInit;
