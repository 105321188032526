import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

import useProcedureEvents from './useProcedureEvents';

import IndividualCircularLoader from '../../components/Loader/IndividualCircularLoader';
import './AlertsTable.scss';
import AlertTableRow from './AlertTableRow';

function AlertsTable() {
  const { dashboardProcedureEvents } = useProcedureEvents('dashboard');

  return (
    <div className="dashboard-section-content-events">
      {!dashboardProcedureEvents ? (
        <IndividualCircularLoader size={100} />
      ) : isEmpty(dashboardProcedureEvents) ? (
        <p className="no-content">Aucun événement à venir</p>
      ) : (
        <table className="alerts-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th className="alerts-table-desktop">Date</th>
              <th>RDV avec</th>
              <th className="alerts-table-desktop">Commentaire</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(dashboardProcedureEvents as ProcedureEvent[]).map((elt) => (
              <AlertTableRow key={uuidV4()} procedureEvent={elt} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AlertsTable;
