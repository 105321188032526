import { call, put, select, take } from '@redux-saga/core/effects';

import {
  createEntity,
  deleteEntity,
  fetchDataById,
  fetchDatas,
  updateEntity,
} from '../../services/axiosInstanceService';
import { NotificationStatus } from '../../utils/enums';
import { appManagerActions } from '../appManager/actions';
import { getAuthState } from '../auth/reducer';
import { REQUEST } from '../common/actions';
import onErrorLogout, { historyRedirect } from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import {
  CREATE_COMPARATIVE_STUDY,
  DELETE_COMPARATIVE_STUDY,
  FETCH_ACTIVE_COMPARATIVE_STUDY,
  FETCH_COMPARATIVE_STUDIES,
  UPDATE_COMPARATIVE_STUDY,
  comparativeStudiesActions,
} from './actions';
import { comparativeStudiesNotifications } from './notifications';
import { comparativeStudyApiToStore, comparativeStudyFormToApi } from './parser';
import { getComparativeStudiesState } from './reducer';

export function* handleFetchComparativeStudies() {
  while (true) {
    try {
      yield take(FETCH_COMPARATIVE_STUDIES[REQUEST]);
      // yield put(loaderActions.loaderDisplay.visible());
      const { user } = yield select(getAuthState);
      const response = yield call(fetchDatas, `${user.idIri}/comparative-studies`);
      if (response) {
        const parsedResponse = response['hydra:member'].map((r) =>
          comparativeStudyApiToStore(r)
        );

        yield put(
          comparativeStudiesActions.fetchComparativeStudies.success(parsedResponse)
        );

        // yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchComparativesStudies', error);
      yield onErrorLogout(error);
      yield put(comparativeStudiesActions.fetchComparativeStudies.failure(error));
      // yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}

export function* handleFetchComparativeStudy() {
  while (true) {
    try {
      yield take(FETCH_ACTIVE_COMPARATIVE_STUDY[REQUEST]);

      const { comparativeStudyActive } = yield select(getComparativeStudiesState);
      // yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(
        fetchDataById,
        `/comparative-studies/${comparativeStudyActive}`
      );

      if (response) {
        const parsedResponse = comparativeStudyApiToStore(response);
        yield put(
          comparativeStudiesActions.fetchActiveComparativeStudy.success(
            parsedResponse
          )
        );

        // yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchComparativesStudiesDetails', error);
      yield onErrorLogout(error);
      // yield put(loaderActions.loaderDisplay.hidden());
      yield put(
        appManagerActions.actionNotificationDisplay.visible({
          title: 'Etude comparative',
          content: `Le résultat de l'étude comparative n'a pas pu être chargée`,
          status: NotificationStatus.FAILURE,
        })
      );
      yield put(
        comparativeStudiesActions.fetchActiveComparativeStudy.failure(error)
      );
    }
  }
}

export function* handleCreateComparativeStudies() {
  while (true) {
    try {
      const {
        payload: { history, body },
      } = yield take(CREATE_COMPARATIVE_STUDY[REQUEST]);
      const parsedBody = comparativeStudyFormToApi(body);

      yield put(loaderActions.loaderDisplay.visible());

      const response = yield call(createEntity, {
        endpoint: '/comparative-studies',
        body: parsedBody,
      });
      if (response) {
        const parsedResponse = comparativeStudyApiToStore(response);
        yield put(
          comparativeStudiesActions.createComparativeStudy.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
        yield call(historyRedirect, history, '/etudes-comparatives');

        // create notification
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            comparativeStudiesNotifications.create().success()
          )
        );
      }
    } catch (error) {
      console.log('error createComparativesStudies', error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          comparativeStudiesNotifications.create().failure()
        )
      );
      yield put(comparativeStudiesActions.createComparativeStudy.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
      yield onErrorLogout(error);
    }
  }
}

export function* handleUpdateComparativeStudies() {
  while (true) {
    try {
      const {
        payload: { history, idIri, body },
      } = yield take(UPDATE_COMPARATIVE_STUDY[REQUEST]);

      const parsedBody = comparativeStudyFormToApi(body);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateEntity, { idIri, body: parsedBody });

      if (response) {
        yield call(historyRedirect, history, '/etudes-comparatives');
        const parsedResponse = comparativeStudyApiToStore(response);

        yield put(
          comparativeStudiesActions.updateComparativeStudy.success(parsedResponse)
        );
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            comparativeStudiesNotifications.update().success()
          )
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error updateComparativesStudies', error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          comparativeStudiesNotifications.update().failure()
        )
      );
      yield onErrorLogout(error);
      yield put(loaderActions.loaderDisplay.hidden());
      yield put(comparativeStudiesActions.updateComparativeStudy.failure(error));
    }
  }
}

export function* handleDeleteComparativeStudies() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_COMPARATIVE_STUDY[REQUEST]);

      yield put(loaderActions.loaderDisplay.visible());
      const status = yield call(deleteEntity, payload);

      if (status === 204) {
        yield put(comparativeStudiesActions.deleteComparativeStudy.success(payload));
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            comparativeStudiesNotifications.delete().success()
          )
        );
      }
    } catch (error) {
      console.log('error deleteComparativesStudies', error);
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          comparativeStudiesNotifications.delete().failure()
        )
      );
      yield put(comparativeStudiesActions.deleteComparativeStudy.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
