import React from 'react';

import { ReactComponent as SignLogo } from '../../images/sign-logo.svg';

interface IPasswordResetHeaderProps {
  path: string;
}

function PasswordResetHeader({ path }: IPasswordResetHeaderProps) {
  return (
    <header className="password-header">
      <SignLogo className="password-header-logo" />
      <div className="password-header-titles">
        <h2 className="password-header-title">
          {path === '/premier-mot-de-passe'
            ? 'Création de mot de passe'
            : 'Changement de mot de passe'}
        </h2>
        <span className="password-header-subtitle">
          {path === '/premier-mot-de-passe'
            ? 'Je souhaite créer mon mot de passe'
            : 'Je souhaite changer mon mot de passe'}
        </span>
      </div>
    </header>
  );
}

export default PasswordResetHeader;
