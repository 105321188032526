import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useMarketStudies from './../App/hooks/useMarketStudies';

import { appManagerActions } from '../store/appManager/actions';
import { NotificationStatus } from '../utils/enums';
import { notificationTitles } from '../utils/genericNotifications';

import ActionCard from '../components/Assets/ActionCard';
import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import PaginationCustom from '../components/Assets/PaginationCustom';
import SectionTitle from '../components/Assets/sectionTitle';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import MarketStudiesCard from '../components/MarketStudies/MarketStudiesCard';
import MarketStudyDeleteModal from '../components/MarketStudies/MarketStudyDeleteModal';

import { getModalState } from '../store/modals/reducer';

import './styles/MarketStudies.scss';

const displayedItemsPerPage = 100;

function MarketStudiesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { marketStudies } = useMarketStudies();
  const { deleteMarketStudy } = useSelector(getModalState);
  const [minIndexTab, setMinIndexTab] = useState(0);

  useEffect(() => {
    if (marketStudies) {
      const marketStudiesMissingValue = marketStudies.filter(
        (elt) => isEmpty(elt.city) || isEmpty(elt.propertyTypologies)
      );
      if (!isEmpty(marketStudiesMissingValue)) {
        dispatch(
          appManagerActions.actionNotificationDisplay.visible({
            title: notificationTitles.marketStudies.title,
            content:
              marketStudiesMissingValue.length === 1
                ? `Etude de marché "${marketStudiesMissingValue[0].name}" est incomplète, veuillez modifier l'étude de marché.`
                : `Etudes de marché ${marketStudiesMissingValue
                    .map((e) => `"${e.name}"`)
                    .join(
                      ', '
                    )} sont incomplètes, veuillez modifier les études de marché.`,
            status: NotificationStatus.FAILURE,
          })
        );
      }
    }
  }, [marketStudies]);

  return (
    <main className="market-studies">
      <ActionNotification />
      {deleteMarketStudy && <MarketStudyDeleteModal />}
      <header className="market-studies-header">
        <SectionTitle
          mainTitle="Étude de marché"
          subTitle="Visualisez et selectionnez vos études"
        />
        <div>
          <Button
            btnType="button"
            btnContent="CREER UNE ETUDE"
            handleClick={() => history.push('/etudes-de-marche-creation')}
            primary
          />
        </div>
      </header>
      <section className="market-studies-cards">
        {!marketStudies ? (
          <IndividualCircularLoader size={200} />
        ) : (
          <>
            {marketStudies
              .filter(
                (elt, index) =>
                  index >= minIndexTab && index < minIndexTab + displayedItemsPerPage
              )
              .map((elt) => (
                <MarketStudiesCard key={uuidv4()} content={elt} />
              ))}
            <ActionCard
              cardTitle="Créez une nouvelle étude"
              cardState=""
              linkTo="/etudes-de-marche-creation"
            />
          </>
        )}
      </section>

      <PaginationCustom
        elementsQty={marketStudies ? marketStudies.length : 0}
        itemsPerPage={displayedItemsPerPage}
        handleMinIndexTab={setMinIndexTab}
      />
    </main>
  );
}

export default MarketStudiesPage;
