import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import useCollaborators from '../../../App/hooks/useCollaborators';

import { modalsActions } from '../../../store/modals/actions';

import Button from '../../Assets/Button';
import TextInput from '../../Forms/TextInput';
import { collaboratorsActions } from '../../../store/collaborators/actions';

import { createSchema, initForm, updateSchema } from './initForm';

import './styles/formCollaborator.scss';

function FormCollaborator() {
  const { selectedCollaborator = {}, companyIri } = useCollaborators();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(modalsActions.newCollaborator.close());
    dispatch(collaboratorsActions.selectedCollaborator.reset());
  };

  const formik = useFormik({
    initialValues: initForm(selectedCollaborator),
    validationSchema: isEmpty(selectedCollaborator) ? createSchema : updateSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isEmpty(selectedCollaborator)) {
        values.company = companyIri;
        values.enabled = false;
        dispatch(collaboratorsActions.createCollaborator.request(values));
      } else {
        dispatch(
          collaboratorsActions.updateCollaborator.request({
            idIri: selectedCollaborator.idIri,
            body: values,
          })
        );
      }
      closeModal();
    },
  });

  return (
    <div className="add-team-member">
      <h2 className="add-team-member-title">+ Ajouter un nouveau collaborateur</h2>
      <form className="add-team-member-form">
        <h3 className="add-team-member-subtitle">Profil général</h3>
        <TextInput
          name="lastName"
          label="Nom du collaborateur"
          required
          value={formik.values.lastName}
          handleChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextInput
          name="firstName"
          label="Prénom du collaborateur"
          required
          value={formik.values.firstName}
          handleChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextInput
          name="email"
          label="Email"
          required
          value={formik.values.email}
          handleChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {/* <TextInput
          type="password"
          name="password"
          required={isEmpty(selectedCollaborator) ? true : false}
          label="Mot de passe"
          value={formik.values.password}
          handleChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextInput
          type="password"
          name="passwordCheck"
          label="Retappez le Mot de passe"
          required={isEmpty(selectedCollaborator) ? true : false}
          value={formik.values.passwordCheck}
          handleChange={formik.handleChange}
          error={
            formik.touched.passwordCheck && Boolean(formik.errors.passwordCheck)
          }
          helperText={formik.touched.passwordCheck && formik.errors.passwordCheck}
        /> */}
        <TextInput
          name="phoneNumber"
          label="Téléphone"
          value={formik.values.phoneNumber}
          handleChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />

        <div className="add-team-member-btn-group">
          <Button
            btnType="button"
            btnContent="ANNULER"
            handleClick={closeModal}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
          <Button
            btnType="submit"
            btnContent="VALIDER"
            handleClick={formik.handleSubmit}
            primary
          />
        </div>
      </form>
    </div>
  );
}

export default FormCollaborator;
