import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from '../../store/account/actions';
import { subscriptionActions } from '../../store/subscription/actions';
import { getFullSubscriptionState } from '../../store/subscription/reducer';
import useAuth from './useAuth';
import useCompany from './useCompany';

export default function useSubscription(full) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isManager } = useAuth();
  const company = useCompany();
  const {
    subscriptionCities,
    departments,
    price,
    hasMarketResearch,
    hasActiveResearch,
    hasComparativeStudy,
    hasGeolocation,
    totalAuthorizedUsers,
    hasRequest,
    canMakeNewRequest,
    subscriptionUpdateBody,
  } = useSelector(getFullSubscriptionState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isManager && isEmpty(company)) {
      dispatch(accountActions.fetchCompany.request());
    }
  }, [isManager]);

  useEffect(() => {
    if (full && !isEmpty(company) && !isLoaded) {
      setIsLoaded(true);
      dispatch(subscriptionActions.fetchSubscription.request());
    }
  }, [full, company, isLoaded]);

  return {
    subscriptionCities,
    departments,
    subscriptionUpdateBody,
    canMakeNewRequest,
    price,
    hasMarketResearch,
    hasActiveResearch,
    hasComparativeStudy,
    hasGeolocation,
    totalAuthorizedUsers,
    hasRequest,
  };
}
