import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash-es';

import useComparativeStudyComparisons from '../../../App/hooks/useComparativeStudyComparisons';

import IndividualCircularLoader from '../../Loader/IndividualCircularLoader';
import { priceComparisonsTableHeader } from '../init';
import AdTableCustom from './AdTableCustom';

const ComparisonsContent = ({ count }) => {
  const { comparisonsActiveTab, lists } = useComparativeStudyComparisons();

  return (
    <>
      {!lists[comparisonsActiveTab] && count !== 0 ? (
        <IndividualCircularLoader size={100} />
      ) : !isEmpty(lists[comparisonsActiveTab]) ? (
        <AdTableCustom
          columnTitles={priceComparisonsTableHeader}
          adsArray={lists[comparisonsActiveTab]}
          needCheck={true}
        />
      ) : (
        <p className="no-content">Aucune annonce Pour cette catégorie</p>
      )}
    </>
  );
};

ComparisonsContent.propTypes = {
  count: PropTypes.number,
};

export default ComparisonsContent;
