import { call } from '@redux-saga/core/effects';
import axios from 'axios';

import store from '../App/redux';
import { authActions } from '../store/auth/actions';
import { createRequestQuery } from '../store/common/sagaCommon';
import { loaderActions } from '../store/loader/actions';

const API_URL = process.env.REACT_APP_API_URL;

async function refreshTokenRequest() {
  const { refresh_token } = store.getState().auth;
  const newToken = await axiosInstance().post('/refresh-token', {
    refresh_token,
  });
  if (newToken) {
    store.dispatch(authActions.refreshToken.success(newToken.data));

    return newToken.data.token;
  }
}

const requestInterseptor = (config) => {
  const { token } = store.getState().auth;
  try {
    config.headers.common.Authorization = 'Bearer ' + token;
    return config;
  } catch (error) {
    console.log(error);
  }
};

const responseInterceptorError = async (error) => {
  // if timeout create custom error message
  if (error?.code === 'ECONNABORTED') {
    // remove loader
    store.dispatch(loaderActions.loaderDisplay.hidden());
    return Promise.reject({ status: 504, message: 'timeout' });
  }

  const { refresh_token } = store.getState().auth;
  const originalRequest = error.config;

  if (
    error &&
    error.response &&
    error.response.status === 401 &&
    JSON.parse(error.request.response).message === 'Expired JWT Token' &&
    !originalRequest._retry
  ) {
    // if no refresh_token in LS, error and logout
    if (
      refresh_token &&
      (refresh_token === 'null' || refresh_token === 'undefined')
    ) {
      return Promise.reject(error);
    }

    // refresh_token request
    originalRequest._retry = true;
    const newToken = await refreshTokenRequest();

    if (newToken) {
      // retry last request with error 401
      return await new Promise((resolve, reject) => {
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        axios(originalRequest)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }
  }

  // for all others errors => axios.catch
  return Promise.reject(error.response);
};

export function axiosInstance() {
  const request = axios.create({
    baseURL: API_URL,
    timeout: 30000,
  });
  request.interceptors.response.use((response) => {
    return response;
  }, responseInterceptorError);
  return request;
}

export function axiosTokenInstance() {
  const request = axiosInstance();
  request.interceptors.request.use(requestInterseptor);
  return request;
}

// ****************************
// Common CRUD
// ****************************

// get all
export const fetchDatas = (endpoint, params) => {
  return new Promise((resolve, reject) => {
    const query = params ? createRequestQuery(params) : '';

    axiosTokenInstance()
      .get(`${endpoint}${query}`)
      .then((response) => resolve(response.data))
      .catch(({ response, request, message, config }) => {
        return reject({
          response,
          request,
          message,
          config,
          status: request ? request.status : null,
          messageHydra: JSON.parse(request.responseText)['hydra:description'],
        });
      });
  });
};

// get one
export const fetchDataById = (idIri, params) => {
  return new Promise((resolve, reject) => {
    const query = params ? createRequestQuery(params) : '';
    axiosTokenInstance()
      .get(`${idIri}${query}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error) {
          return reject({ ...error });
        } else {
          return reject(error);
        }
      });
  });
};

// create entity
export const createEntity = ({ endpoint, body }) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.data))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};

// update entity
export const updateEntity = ({ idIri, body }) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(idIri, body)
      .then((response) => resolve(response.data))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};

// delete entity
export const deleteEntity = (idIri) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .delete(idIri)
      .then((response) => resolve(response.status))
      .catch(({ response, request, message, config }) => {
        return reject({ response, request, message, config });
      });
  });
};

// use pagination of jsonId
export function* genericPagingTraitment(request, endpoint, params = {}) {
  const memberTabArray = [];
  let isLoop = false;
  let page = 1;

  do {
    params.page = page;
    const response = yield call(request, endpoint, params);
    memberTabArray.push(response['hydra:member']);
    if (response['hydra:view'] && response['hydra:view']['hydra:next']) {
      isLoop = true;
      page += 1;
    } else {
      isLoop = false;
    }
  } while (isLoop);

  return memberTabArray.flat(1);
}
