import { FormControlLabel, Radio } from '@material-ui/core';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import UrbaneaseButton from '../../components/linksToUrbanease/UrbaneaseButton';
// import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as PictoPlace } from '../../images/picto-place.svg';
import { ReactComponent as PictoSurface } from '../../images/picto-surface.svg';
import StreetViewLink from './StreetViewLink';

interface ISinglePlotMapProps {
  marker: AdPlot;
  radioKey: string;
  department: string | null;
}

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const SinglePlotMap = ({ marker, radioKey, department }: ISinglePlotMapProps) => {
  return (
    <div className="ad-details-geo-single-parcel-container-elt">
      <MapContainer
        center={[marker.lat, marker.long]}
        zoom={17}
        scrollWheelZoom={false}
        className="ad-details-geo-single-parcel-container-elt-map"
      >
        <TileLayer
          url={
            'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&key=' +
            GOOGLE_KEY
          }
          key="satellite"
          opacity={1}
          maxZoom={22}
        />

        <Marker position={[marker.lat, marker.long]}></Marker>
      </MapContainer>

      <div className="ad-details-geo-single-parcel-container-elt-infos">
        <p>
          <PictoPlace width="16" height="20" />
          <span>Lieu</span>
          {':'}
          {marker.address ? (
            <StreetViewLink
              lat={marker.lat}
              lng={marker.long}
              address={marker.address}
            />
          ) : (
            'indéfinie'
          )}
        </p>
        <p>
          <PictoSurface width="16" height="20" />
          <span>Surface du bien</span> {` : ${marker.build} m²`}
        </p>
        <p>
          <PictoSurface width="16" height="20" />
          <span>Surface du terrain</span> {` : ${marker.area} m²`}
        </p>
        <FormControlLabel
          value={marker.address ?? radioKey}
          control={<Radio />}
          label="Sélectionnez cette adresse pour enregistrer l’annonce"
        />
        <UrbaneaseButton
          department={department}
          lat={marker.lat}
          lng={marker.long}
        />
      </div>
    </div>
  );
};

export default SinglePlotMap;
