import { CREATE_AD_STUDIES } from '../adStudies/actions';
import { LOGOUT } from '../auth/actions';
import { ADD, FAILURE, MODAL_DISPLAY, RESET, SET, SUCCESS } from '../common/actions';
import {
  ADS_PER_PAGE,
  AD_GEO_ADDRESS,
  DELETE_ADS,
  FETCH_AD,
  FETCH_ADS,
  FETCH_PARCELS,
  PAGE_QUANTITY,
  RESET_PARCELS,
  SELECTED_ADS,
  SELECTED_ADS_RESET,
  SELECTED_SORT,
  SET_ACTIVE_AD,
} from './actions';
// import { CREATE_AD_STUDIES } from '../adStudies/actions';

const initialState = {
  adsPerPage: 12,
  selectedSort: 'createdAt',
  pageQuantity: 1,
  deleteModal: { quantity: 0 },
  ads: null,
  activeAd: null,
  selectedAds: [],
  ad: null,
  adParcels: [],
  adGeoAddress: '',
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_ADS[FAILURE]:
    case FETCH_ADS[SUCCESS]: {
      return { ...state, ads: payload.ads };
    }
    case AD_GEO_ADDRESS[SET]:
    case AD_GEO_ADDRESS[RESET]:
      return { ...state, adGeoAddress: payload };
    case PAGE_QUANTITY[SET]:
      return { ...state, pageQuantity: payload };
    case ADS_PER_PAGE[SET]:
      return { ...state, adsPerPage: payload };
    case SELECTED_SORT[SET]:
      return { ...state, selectedSort: payload };

    case SET_ACTIVE_AD[SUCCESS]: {
      return { ...state, activeAd: payload };
    }
    case FETCH_AD[SUCCESS]: {
      return { ...state, ad: payload };
    }
    case FETCH_PARCELS[SUCCESS]: {
      return { ...state, adParcels: payload };
    }
    case RESET_PARCELS[RESET]: {
      return { ...state, adParcels: [], adGeoAddress: '' };
    }
    case SELECTED_ADS[ADD]:
      if (state.selectedAds.includes(payload)) {
        return {
          ...state,
          selectedAds: state.selectedAds.filter((elt) => elt !== payload),
        };
      } else {
        return {
          ...state,
          selectedAds: state.selectedAds.concat(payload),
        };
      }
    case DELETE_ADS[MODAL_DISPLAY]:
      return { ...state, deleteModal: { quantity: payload.quantity } };
    case SELECTED_ADS_RESET[SUCCESS]:
    case DELETE_ADS[RESET]:
      return { ...state, selectedAds: [], deleteModal: { quantity: 0 } };
    case DELETE_ADS[SUCCESS]:
      return {
        ...state,
        ads: state.ads ? state.ads.filter((f) => f.idIri !== payload) : state.ads,
        deleteModal: { quantity: 0 },
        selectedAds: [],
        activeAd: null,
      };

    case CREATE_AD_STUDIES[SUCCESS]:
      return state.ads
        ? {
            ...state,
            ads: state.ads.filter((elt) => elt.idIri !== payload.adIdIri),
            selectedAds: [],
          }
        : state;
    case CREATE_AD_STUDIES[RESET]:
      return { ...state, selectedAds: [] };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getAdsState = (state) => state.ads;
export const getAds = (state) => state.ads.ads;
export const getActiveAd = (state) => state.ads.activeAd;
export const getAd = (state) => state.ads.ad;
