import { combineReducers } from 'redux';
import activeResearches from '../../features/activeResearch/reducer';
import procedureEvents from '../../features/procedureEvent/reducer';
import account from '../../store/account/reducer';
import adSourcesReducer from '../../store/adSources/reducer';
import adStudies from '../../store/adStudies/reducer';
import ads from '../../store/ads/reducer';
import announcementsReducer from '../../store/announcements/reducer';
import appManager from '../../store/appManager/reducer';
import auth from '../../store/auth/reducer';
import collaborators from '../../store/collaborators/reducer';
import comparativeStudies from '../../store/comparativeStudies/reducer';
import comparativeStudyComparisons from '../../store/comparativeStudyComparisons/reducer';
import comparativeStudyComparisonsSelect from '../../store/comparativeStudyComparisonsSelect/reducer';
import folders from '../../store/folders/reducer';
import loader from '../../store/loader/reducer';
import marketStudies from '../../store/marketStudies/reducer';
import marketStudyCommentReducer from '../../store/marketStudyComment/reducer';
import modals from '../../store/modals/reducer';
import propertyTypologies from '../../store/propertyTypologies/reducer';
import subscription from '../../store/subscription/reducer';

const rootReducer = combineReducers({
  modals,
  activeResearches,
  account,
  comparativeStudies,
  comparativeStudyComparisons,
  comparativeStudyComparisonsSelect,
  folders,
  marketStudies,
  auth,
  ads,
  appManager,
  loader,
  collaborators,
  propertyTypologies,
  adStudies,
  subscription,
  procedureEvents,
  adSourcesReducer,
  announcementsReducer,
  marketStudyCommentReducer,
});

export default rootReducer;
