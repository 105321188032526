import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Nav from './Nav';

import './styles/main.scss';
import ModalContainer from './ModalContainer';
import AdBlankPage from '../pages/AdBlankPage';
import useAuth from '../App/hooks/useAuth';
import { isEmpty } from 'lodash-es';
import UserLoader from '../components/Loader/UserLoader';

const Layout = ({ children }) => {
  const { user } = useAuth();

  const { pathname } = useLocation();

  switch (pathname) {
    case '/inscription':
    case '/recuperation-mot-de-passe':
    case '/premier-mot-de-passe':
    case '/erreur':
      return <main className="main">{children}</main>;

    default:
      return (
        <div className="body">
          {isEmpty(user) && <UserLoader />}
          <ModalContainer />
          {pathname.split('/')[1] === 'ad' ? (
            <AdBlankPage />
          ) : (
            <>
              <Header />
              <div className="body-content">
                <Nav activeNav={pathname} />
                <main className="main-content">{children}</main>
              </div>
            </>
          )}
        </div>
      );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
