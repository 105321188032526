import * as L from 'leaflet';

export const geoRadioBtnsFormValues = [
  { value: '0', label: 'Surface exacte du terrain' },
  {
    value: '5',
    label: 'Plus ou moins 5 m² pour la surface du terrain',
  },
  {
    value: '10',
    label: 'Plus ou moins 10 m² pour la surface du terrain',
  },
];

export const adIcon = L.icon({ iconUrl: '/images/marker-icon-red.png' });

export const similariesTableHeader = [
  '',
  'Typologie',
  'S.bien',
  'S.terrain',
  'Ville',
  'Origine',
  'Prix',
  'Activité',
  'Dossier',
];
