import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useAuth from '../../App/hooks/useAuth';
import { isEmpty } from 'lodash';

import Button from '../Assets/Button';
import LogoButton from '../Assets/LogoButton';

import { marketStudiesActions } from '../../store/marketStudies/actions';
import { ReactComponent as MapMarker } from '../../images/map-marker.svg';
import { dateFormat } from '../../utils/jsFunctions';
import { marketStudyCommentActions } from '../../store/marketStudyComment/actions';

function MarketStudiesCard({ content }) {
  const { city, createdAt, owner, propertyTypologies, id, name } = content;
  const { userIri } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleConsult = () => {
    dispatch(marketStudyCommentActions.marketStudyComment.reset());
    dispatch(marketStudiesActions.resetActiveMarketStudy.success());
    history.push(`/etude-de-marche-resultats/${id}`);
  };
  const getPropertyTypologiesStrings = () => {
    return propertyTypologies.name;
  };

  const isOwner = owner.idIri === userIri;

  return (
    <div className="market-studies-card">
      <header className="market-studies-card-header">
        <h4 className="market-studies-card-title">{name}</h4>
        <span className="market-studies-card-subtitle">{`Créé par ${owner.fullName}`}</span>
      </header>

      <section className="market-studies-card-content">
        <div className="market-studies-card-city">
          <MapMarker />
          <span>{city.name}</span>
        </div>
        <span className="market-studies-card-types">
          {getPropertyTypologiesStrings()}
        </span>
      </section>

      <footer className="market-studies-card-footer">
        <div className="market-studies-card-elt">
          <span className="market-studies-card-date">{dateFormat(createdAt)}</span>
        </div>
        <div className="market-studies-card-footer-btns">
          <Button
            btnType="button"
            btnContent="Consulter"
            handleClick={handleConsult}
            primary
            disabled={isEmpty(city) || isEmpty(propertyTypologies) ? true : false}
          />
          {isOwner && (
            <>
              <LogoButton
                btnType="button"
                handleClick={() =>
                  history.push(`/etudes-de-marche-modification/${id}`)
                }
                btnLogo="pen-violet"
                tooltip={`Modifier`}
              />
              <LogoButton
                btnType="button"
                handleClick={() =>
                  dispatch(
                    marketStudiesActions.deleteMarketStudy.modal({
                      display: true,
                      idIri: content.idIri,
                      name: content.name,
                    })
                  )
                }
                btnLogo="delete-red"
                tooltip={`Supprimer`}
              />
            </>
          )}
        </div>
      </footer>
    </div>
  );
}

MarketStudiesCard.propTypes = {
  content: PropTypes.object.isRequired,
};

export default MarketStudiesCard;
