import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { getCollaboratorsState } from '../../store/collaborators/reducer';
import { collaboratorsActions } from '../../store/collaborators/actions';
import useAuth from './useAuth';

const useCollaborators = () => {
  const [selectedCollaborator, setSelectedCollaborator] = useState({});
  const { user, companyIri } = useAuth();
  const { collaborators, selectedCollaboratorId } = useSelector(
    getCollaboratorsState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(collaborators) && !isEmpty(user)) {
      dispatch(collaboratorsActions.fetchCollaborators.request());
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(collaborators) && selectedCollaboratorId) {
      setSelectedCollaborator(
        collaborators.find((elmt) => elmt.idIri === selectedCollaboratorId)
      );
    }
  }, [collaborators]);

  return !isEmpty(collaborators)
    ? {
        collaborators,
        collaboratorsCount: collaborators.length,
        selectedCollaborator,
        companyIri,
      }
    : { collaborators: [], collaboratorsCount: 0, selectedCollaborator, companyIri };
};

export default useCollaborators;
