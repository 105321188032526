import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useAuth from '../../../App/hooks/useAuth';

import SwitchButton from '../../Forms/SwitchButton';

import { collaboratorsActions } from '../../../store/collaborators/actions';
import Button from '../../Assets/Button';
import { modalsActions } from '../../../store/modals/actions';
import './styles/teamCard.scss';
import { Tooltip } from '@material-ui/core';

function TeamCard({ user }) {
  const { isManager } = useAuth();
  const dispatch = useDispatch();

  const handleEnableCollaborator = () => {
    dispatch(
      collaboratorsActions.updateCollaborator.request({
        idIri: user.idIri,
        body: { enabled: !user.enabled },
      })
    );
  };

  const handleUpdateCollaborator = (endpoint) => {
    dispatch(collaboratorsActions.selectedCollaborator.set(endpoint));
    dispatch(modalsActions.newCollaborator.open());
  };

  const handleUserRole = () => {
    return user.roles.includes('ROLE_ADMIN')
      ? 'Admin'
      : user.roles.includes('ROLE_MANAGER')
      ? 'Manager'
      : 'Prospecteur';
  };

  return (
    <div className="team-card">
      <div className="team-card-people">
        {isManager && (
          <div className="team-card-admin">
            <div>
              <SwitchButton
                label={user.enabled ? 'Activé' : 'desactivé'}
                checked={user.enabled}
                handleChange={handleEnableCollaborator}
                name="enabled"
                labelPlacement="top"
              />
            </div>
            <Button
              btnType="button"
              btnContent="Modifier"
              handleClick={() => handleUpdateCollaborator(user.idIri)}
              primary
            />
          </div>
        )}
        <h3 className="team-card-name">{`${user.firstName} ${user.lastName}`}</h3>
        {user.email.length <= 28 ? (
          <span className="team-card-mail">{user.email}</span>
        ) : (
          <Tooltip title={user.email} placement="top" interactive>
            <span className="team-card-mail">{user.email.substring(0, 25)}...</span>
          </Tooltip>
        )}
      </div>

      <div className="team-card-footer">
        {user.roles && <span>{handleUserRole()}</span>}
      </div>
    </div>
  );
}

TeamCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default TeamCard;
