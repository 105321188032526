import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adActions } from '../../store/ads/actions';
import { adStudiesActions } from '../../store/adStudies/actions';
import { modalsActions } from '../../store/modals/actions';
import { formatString } from '../../utils/tools';
import './adThumb.scss';
import ThumbBadge from './ThumbBadge';
import ThumbFooter from './ThumbFooter';
import ThumbHeader from './ThumbHeader';

interface IAdThumbProps {
  data: Ad;
  type: string;
  dataRealId: string;
  folderId?: any;
  status?: any;
  isNew?: boolean;
}

function AdThumb({
  data,
  type,
  dataRealId,
  folderId,
  status,
  isNew,
}: IAdThumbProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { propertyTypology, totalRoom } = data;

  const handleClick = () => {
    switch (type) {
      case 'ads':
      case 'activeResearches':
        dispatch(adActions.setActiveAd.set(data.idIri));
        dispatch(modalsActions.adDetails.open());
        break;
      case 'adStudies':
        dispatch(adStudiesActions.activeAdStudy.set(dataRealId));
        history.push(
          `/creation-annonce-dossier/${folderId}/${dataRealId.split('/')[2]}`
        );
        break;
      default:
        break;
    }
  };
  // AD_STATUS = [‘offline’ => 0, ‘online’ => 1];
  return (
    <div className="ad-thumb">
      <div>
        <ThumbHeader
          dataRealId={dataRealId}
          sellerType={data.sellerType}
          status={type === 'adStudies' ? data.status : null}
          type={type}
          data={data}
          isNew={isNew}
        />

        <section className="ad-thumb-main-section" onClick={handleClick}>
          <ThumbBadge
            picture={
              data.picture ?? process.env.PUBLIC_URL + '/images/logo-prospecimmo.svg'
            }
            status={status ?? 0}
            hasBadge={type === 'adStudies'}
          />

          <div className="ad-thumb-header">
            <div className="ad-thumb-header-elt">
              <h4 className="ad-thumb-header-title">
                {`${propertyTypology.name} ${totalRoom > 0 ? 'F' + totalRoom : ''}`}
              </h4>
              <span className="ad-thumb-header-price">{`${data.price} €`}</span>
            </div>
            <div className="ad-thumb-header-elt">
              <span className="ad-thumb-header-city">
                {formatString(data.city.name)}
              </span>
              <span className="ad-thumb-header-meter-price">{`${data.sqmPrice} €/m²`}</span>
            </div>
          </div>

          <ul className="ad-thumb-infos">
            <li className="ad-thumb-infos-item">{`Nbr de pièces : ${
              data.totalRoom > 0 ? data.totalRoom + ' Pièces' : '-'
            }`}</li>
            <li className="ad-thumb-infos-item">{`Superficie : ${data.builtArea} m²`}</li>
            <li className="ad-thumb-infos-item">{`Superficie terrain : ${data.landArea} m²`}</li>
          </ul>

          <ThumbFooter
            isBargain={data.isBargain}
            isExclusive={data.isExclusive}
            isGeolocatable={data.isGeolocatable}
            url={data.url}
            studied={data.studied}
            isAdStudy={type === 'adStudies'}
            source={data.source}
          />
        </section>
      </div>
    </div>
  );
}

export default AdThumb;
