import CardBody from './ActiveResearchCard/CardBody';
import CardHeader from './ActiveResearchCard/CardHeader';
import CardFooter from './ActiveResearchCard/cardFooter';

interface IActiveResearchCardProps {
  activeResearch: IActiveResearch;
}
function ActiveResearchCard({ activeResearch }: IActiveResearchCardProps) {
  return (
    <div className="active-search-card">
      <CardHeader {...activeResearch} />
      <CardBody {...activeResearch} />
      <CardFooter {...activeResearch} />
    </div>
  );
}

export default ActiveResearchCard;
