import styles from './signIn.module.scss';

interface IPasswordRecoveryLinkProps {
  isSignInForm?: boolean;
  onClick: () => void;
}

function PasswordRecoveryLink({
  isSignInForm,
  onClick,
}: IPasswordRecoveryLinkProps) {
  const handleClick = () => {
    onClick();
  };

  return (
    <span className={styles.infoText}>
      {isSignInForm && `Mot de passe oublié ? `}
      <a
        className={styles.link}
        href="#"
        title="Récupération de mot de passe"
        onClick={handleClick}
      >
        {isSignInForm ? 'Cliquez ici' : 'Annuler'}
      </a>
    </span>
  );
}

export default PasswordRecoveryLink;
