import React from 'react';

import DateForm from './StatisticTab/DateForm';
import TableHeader from './StatisticTab/TableHeader';
import TableBody from './StatisticTab/TableBody';

import './StatisticTab/statisticTab.scss';

const StatisitcTab = () => {
  return (
    <section className="my-account-content user-statistics">
      <header className="user-statistics-header">
        <h2 className="user-statistics-title">Statistiques</h2>
        <DateForm />
      </header>

      <div className="user-statistics-table-wrapper">
        <table className="user-statistics-table">
          <TableHeader />
          <TableBody />
        </table>
      </div>
    </section>
  );
};

export default StatisitcTab;
