import { Marker, Popup } from 'react-leaflet';

interface IPlotMarker {
  lat: number;
  lng: number;
  address: string;
}
const PlotMarker = ({ lat, lng, address }: IPlotMarker) => {
  return (
    <Marker position={[lat, lng]}>
      <Popup>
        <div>
          <p>{address}</p>
        </div>
      </Popup>
    </Marker>
  );
};

export default PlotMarker;
