import {
  action,
  createRequestTypes,
  MODAL_DISPLAY,
  FAILURE,
  REQUEST,
  RESET,
  SUCCESS,
  SET,
} from '../common/actions';
export const FETCH_COMPARATIVE_STUDIES = createRequestTypes(
  'FETCH_COMPARATIVE_STUDIES'
);
export const FETCH_ACTIVE_COMPARATIVE_STUDY = createRequestTypes(
  'FETCH_ACTIVE_COMPARATIVE_STUDY'
);

export const CREATE_COMPARATIVE_STUDY = createRequestTypes(
  'CREATE_COMPARATIVE_STUDY'
);
export const UPDATE_COMPARATIVE_STUDY = createRequestTypes(
  'UPDATE_COMPARATIVE_STUDY'
);
export const DELETE_COMPARATIVE_STUDY = createRequestTypes(
  'DELETE_COMPARATIVE_STUDY'
);
export const SET_ACTIVE_COMPARATIVE_STUDY = createRequestTypes(
  'SET_ACTIVE_COMPARATIVE_STUDY'
);
export const SET_COMPARATIVE_STUDY_TO_EDIT = createRequestTypes(
  'SET_COMPARATIVE_STUDY_TO_EDIT'
);

export const comparativeStudiesActions = {
  fetchComparativeStudies: {
    request: (data) => action(FETCH_COMPARATIVE_STUDIES[REQUEST], { payload: data }),
    success: (data) => action(FETCH_COMPARATIVE_STUDIES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_COMPARATIVE_STUDIES[FAILURE], { payload: data }),
  },
  fetchActiveComparativeStudy: {
    request: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY[REQUEST], { payload: data }),
    success: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_ACTIVE_COMPARATIVE_STUDY[FAILURE], { payload: data }),
  },

  createComparativeStudy: {
    request: (data) => action(CREATE_COMPARATIVE_STUDY[REQUEST], { payload: data }),
    success: (data) => action(CREATE_COMPARATIVE_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_COMPARATIVE_STUDY[FAILURE], { payload: data }),
  },
  updateComparativeStudy: {
    request: (data) => action(UPDATE_COMPARATIVE_STUDY[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_COMPARATIVE_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_COMPARATIVE_STUDY[FAILURE], { payload: data }),
  },
  deleteComparativeStudy: {
    modal: (data) =>
      action(DELETE_COMPARATIVE_STUDY[MODAL_DISPLAY], { payload: data }),
    request: (data) => action(DELETE_COMPARATIVE_STUDY[REQUEST], { payload: data }),
    success: (data) => action(DELETE_COMPARATIVE_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_COMPARATIVE_STUDY[FAILURE], { payload: data }),
    reset: () =>
      action(DELETE_COMPARATIVE_STUDY[RESET], {
        payload: { display: false, idIri: null, typology: null, city: null },
      }),
  },
  setActiveComparativeStudy: {
    set: (data) => action(SET_ACTIVE_COMPARATIVE_STUDY[SET], { payload: data }),
    reset: (data) => action(SET_ACTIVE_COMPARATIVE_STUDY[RESET], { payload: data }),
  },
};
