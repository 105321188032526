import SignLogo from '../../../images/sign-logo.svg';
import styles from './signActionTitle.module.scss';
interface ISignActionTitleProps {
  hasAccount: boolean;
  className: string;
}

function SignActionTitle({ hasAccount, className }: ISignActionTitleProps) {
  return (
    <div className={className}>
      <div className={styles.signActionTitle}>
        <img src={SignLogo} className={styles.logo} />
        <div className={styles.titles}>
          <h2 className={styles.title}>
            {hasAccount ? 'Connexion' : 'Créer un compte'}
          </h2>
          <p className={styles.subtitle}>
            {hasAccount
              ? 'Votre compte est déjà activé, vous êtes au bon endroit...'
              : 'Je souhaite être contacté pour créer un compte'}
          </p>
        </div>
      </div>{' '}
    </div>
  );
}

export default SignActionTitle;
