import PropTypes from 'prop-types';

import { countries } from '../../../lib/constants';

import SelectInput from '../../Forms/SelectInput';
import TextInput from '../../Forms/TextInput';

function CompanyForm({ formik, setIsCompanyUpdated }) {
  const { values, touched, errors } = formik;

  const handleChange = (e) => {
    setIsCompanyUpdated(true);
    formik.handleChange(e);
  };

  return (
    <div className="user-details-form-group">
      <h2>Information entreprise</h2>
      <TextInput
        label="Nom de l'entreprise"
        name="name"
        value={values.name}
        handleChange={(e) => handleChange(e)}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />
      <TextInput
        label="Siret"
        name="siret"
        value={values.siret}
        handleChange={(e) => handleChange(e)}
        error={touched.siret && Boolean(errors.siret)}
        helperText={touched.siret && errors.siret}
      />
      <TextInput
        label="Adresse postale"
        name="address"
        value={values.address}
        handleChange={(e) => handleChange(e)}
        error={touched.address && Boolean(errors.address)}
        helperText={touched.address && errors.address}
      />
      <div className="user-details-form-group-sub">
        <TextInput
          label="Ville"
          name="city"
          value={values.city}
          handleChange={(e) => handleChange(e)}
          error={touched.city && Boolean(errors.city)}
          helperText={touched.city && errors.city}
        />
        <TextInput
          label="Code postal"
          name="zipcode"
          value={values.zipcode}
          handleChange={(e) => handleChange(e)}
          error={touched.zipcode && Boolean(errors.zipcode)}
          helperText={touched.zipcode && errors.zipcode}
        />
      </div>
      <SelectInput
        name="country"
        value={values.country}
        handleChange={formik.handleChange}
        label="Pays"
        items={countries}
      />
    </div>
  );
}

CompanyForm.propTypes = {
  formik: PropTypes.object.isRequired,
  setIsCompanyUpdated: PropTypes.func.isRequired,
};

export default CompanyForm;
