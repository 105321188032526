import { DELETE_ADS } from '../../store/ads/actions';
import { CREATE_AD_STUDIES } from '../../store/adStudies/actions';
import { LOGOUT } from '../../store/auth/actions';
import { ADD, RESET, SET, SUCCESS } from '../../store/common/actions';

import {
  ACTIVE_RESEARCH_SWITCH_ALERT_UPDATE,
  ACTIVE_SEARCHES_SET_CURRENT,
  CREATE_ACTIVE_RESEARCH,
  DECREMENT_TOTAL_ADS_COUNT,
  DELETE_ACTIVE_RESEARCH,
  FETCH_ACTIVE_RESEARCHES,
  FETCH_CURRENT_ACTIVE_RESEARCH,
  FETCH_CURRENT_ACTIVE_RESEARCH_ADS,
  FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS,
  RESET_CURRENT_ACTIVE_RESEARCH,
  SELECTED_ACTIVE_RESEARCHES_ADS,
  SELECTED_ACTIVE_RESEARCHES_ADS_RESET,
  UPDATE_ACTIVE_RESEARCH,
} from './actions';

const initialState = {
  activeResearches: null,
  currentActiveResearchAds: null,
  currentActiveResearch: null,
  selectedActiveResearchesAds: [],
  adsQuantity: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DECREMENT_TOTAL_ADS_COUNT[SET]:
      return { ...state, adsQuantity: state.adsQuantity - payload };
    case FETCH_ACTIVE_RESEARCHES[SUCCESS]:
      return {
        ...state,
        activeResearches: payload,
        currentActiveResearch: null,
        currentActiveResearchAds: null,
      };
    case FETCH_SINGLE_ADS_AND_NEW_ADS_INFOS[SUCCESS]: {
      return {
        ...state,
        activeResearches: state.activeResearches.map((ar) =>
          ar.idIri !== payload.idIri
            ? ar
            : { ...ar, totalAds: payload.totalAds, totalNewAds: payload.totalNewAds }
        ),
      };
    }
    case FETCH_CURRENT_ACTIVE_RESEARCH[SUCCESS]:
      return {
        ...state,
        currentActiveResearch: payload,
        activeResearches:
          state.activeResearches?.map((elt) => {
            if (elt.idIri === payload.idIri) {
              return {
                ...elt,
                totalAds: payload.totalAds,
                totalNewAds: payload.totalNewAds,
              };
            } else {
              return elt;
            }
          }) ?? null,
      };
    case RESET_CURRENT_ACTIVE_RESEARCH[RESET]:
      return { ...state, currentActiveResearch: null };

    case CREATE_ACTIVE_RESEARCH[SUCCESS]:
      return {
        ...state,
        currentActiveResearch: payload,
        activeResearches: state.activeResearches
          ? state.activeResearches.concat(payload)
          : [payload],
      };
    case UPDATE_ACTIVE_RESEARCH[SUCCESS]:
      return {
        ...state,
        currentActiveResearch: state.currentActiveResearch
          ? { ...state.currentActiveResearch, ...payload }
          : null,
        activeResearches: state.activeResearches?.map((m) =>
          m.idIri === payload.idIri ? payload : m
        ),
      };
    case DELETE_ACTIVE_RESEARCH[SUCCESS]:
      return {
        ...state,
        activeResearches: state.activeResearches.filter(
          (elt) => elt.idIri !== payload
        ),
      };

    case FETCH_CURRENT_ACTIVE_RESEARCH_ADS[SUCCESS]:
    case FETCH_CURRENT_ACTIVE_RESEARCH_ADS[RESET]:
      return {
        ...state,
        currentActiveResearchAds: payload.ads,
        adsQuantity: payload.qty,
      };
    case SELECTED_ACTIVE_RESEARCHES_ADS[ADD]:
      if (state.selectedActiveResearchesAds.includes(payload)) {
        return {
          ...state,
          selectedActiveResearchesAds: state.selectedActiveResearchesAds.filter(
            (elt) => elt !== payload
          ),
        };
      } else {
        return {
          ...state,
          selectedActiveResearchesAds: state.selectedActiveResearchesAds.concat(
            payload
          ),
        };
      }

    case DELETE_ADS[SUCCESS]:
      return state.currentActiveResearchAds
        ? {
            ...state,
            currentActiveResearchAds: state.currentActiveResearchAds.filter(
              (elt) => !payload.includes(elt.idIri)
            ),
            selectedActiveResearchesAds: [],
          }
        : state;
    case SELECTED_ACTIVE_RESEARCHES_ADS_RESET[SUCCESS]:
      return { ...state, selectedActiveResearchesAds: [] };
    case ACTIVE_RESEARCH_SWITCH_ALERT_UPDATE[SUCCESS]:
      return {
        ...state,
        activeResearches: state.activeResearches.map((elt) => {
          if (elt.idIri === payload) {
            return { ...elt, alertEnabled: !elt.alertEnabled };
          } else {
            return { ...elt };
          }
        }),
      };
    case ACTIVE_SEARCHES_SET_CURRENT:
      return {
        ...state,
        currentActiveResearch: payload,
      };

    case CREATE_AD_STUDIES[SUCCESS]:
      return state.currentActiveResearchAds
        ? {
            ...state,
            currentActiveResearchAds: state.currentActiveResearchAds.filter(
              (elt) => elt.idIri !== payload.adIdIri
            ),
          }
        : state;

    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getActiveResearches = (store) => store.activeResearches;
