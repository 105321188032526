import { HIDDEN, SUCCESS, VISIBLE } from '../common/actions';
import { ANNOUNCEMENTS_IS_VISIBLE, FETCH_ANNOUNCEMENTS } from './action';

const initialState = {
  announcements: null,
  isVisible: true,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ANNOUNCEMENTS[SUCCESS]:
      return { ...state, announcements: payload };
    case ANNOUNCEMENTS_IS_VISIBLE[VISIBLE]:
      return { ...state, isVisible: true };
    case ANNOUNCEMENTS_IS_VISIBLE[HIDDEN]:
      return { ...state, isVisible: false };
    default:
      return state;
  }
}

export const getAnnouncementsState = (state) => state.announcementsReducer;
