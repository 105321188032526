const handleInputChange = (evt, formValues, setFormValues) => {
  setFormValues({
    ...formValues,
    [evt.target.name]: evt.target.value,
  });
};

const handleCheckChange = (evt, checked, setChecked) => {
  setChecked({
    ...checked,
    [evt.target.name]: evt.target.checked,
  });
};

export { handleInputChange, handleCheckChange };
