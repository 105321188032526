import { LOGOUT } from '../auth/actions';
import { MODAL_DISPLAY, RESET, SET, SUCCESS } from '../common/actions';
import {
  CREATE_COMPARATIVE_STUDY,
  DELETE_COMPARATIVE_STUDY,
  FETCH_COMPARATIVE_STUDIES,
  FETCH_ACTIVE_COMPARATIVE_STUDY,
  SET_ACTIVE_COMPARATIVE_STUDY,
  UPDATE_COMPARATIVE_STUDY,
} from './actions';

const initialState = {
  deleteModal: { idIri: null, typology: null, city: null },
  comparativeStudies: null,
  comparativeStudyActive: null,
  comparativeStudyContent: {},
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_COMPARATIVE_STUDIES[SUCCESS]:
      return { ...state, comparativeStudies: payload };
    case CREATE_COMPARATIVE_STUDY[SUCCESS]:
      return {
        ...state,
        comparativeStudies: state.comparativeStudies.concat(payload),
      };
    case UPDATE_COMPARATIVE_STUDY[SUCCESS]:
      return {
        ...state,
        comparativeStudies: state.comparativeStudies.map((elmt) => {
          return elmt.idIri === payload.idIri ? payload : elmt;
        }),
        comparativeStudyToEdit: null,
      };
    case DELETE_COMPARATIVE_STUDY[MODAL_DISPLAY]:
      return {
        ...state,
        deleteModal: {
          idIri: payload.idIri,
          typology: payload.typology,
          city: payload.city,
        },
      };
    case DELETE_COMPARATIVE_STUDY[RESET]:
      return {
        ...state,
        deleteModal: { idIri: null, typology: null, city: null },
      };
    case DELETE_COMPARATIVE_STUDY[SUCCESS]:
      console.log('reducer', payload);
      return {
        ...state,
        comparativeStudies: state.comparativeStudies.filter(
          (elmt) => elmt.idIri !== payload
        ),
        deleteModal: { idIri: null, typology: null, city: null },
      };
    case SET_ACTIVE_COMPARATIVE_STUDY[SET]:
      return { ...state, comparativeStudyActive: payload };
    case SET_ACTIVE_COMPARATIVE_STUDY[RESET]:
      return {
        ...state,
        comparativeStudyActive: null,
        comparativeStudyContent: {},
      };
    case FETCH_ACTIVE_COMPARATIVE_STUDY[SUCCESS]: {
      let temp = { ...payload };
      delete temp.priceComparisons;
      return { ...state, comparativeStudyContent: temp };
    }

    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getComparativeStudiesState = (state) => state.comparativeStudies;
