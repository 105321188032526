import { memo } from 'react';
import { TileLayer } from 'react-leaflet';
import OSMTileLayer from './OSMTileLayer';

interface SatelliteLayerProps {
  satelliteView: boolean;
}
const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const SatelliteLayer = memo(({ satelliteView }: SatelliteLayerProps) => {
  return (
    <>
      {satelliteView ? (
        <TileLayer
          url={
            'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&key=' +
            GOOGLE_KEY
          }
          key="satellite"
          opacity={1}
          maxZoom={22}
        />
      ) : (
        <OSMTileLayer />
      )}
    </>
  );
});

SatelliteLayer.displayName = 'SatelliteLayer';

export default SatelliteLayer;
