import { call, take, put } from 'redux-saga/effects';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import { FETCH_PROPERTY_TYPOLOGY, propertyTypologyActions } from './actions';
import { getPropertyTypologies } from './api';
import { propertyTypologiesApiToStore } from './parser';

export function* handleFetchPropertyTypologies() {
  while (true) {
    try {
      yield take(FETCH_PROPERTY_TYPOLOGY[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());

      const response = yield call(getPropertyTypologies);
      if (response) {
        const parsedResponse = response['hydra:member'].map((e) =>
          propertyTypologiesApiToStore(e)
        );
        yield put(
          propertyTypologyActions.fetchPropertyTypologies.success(parsedResponse)
        );
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      console.log('error fetchPropertyTypologies', error);

      yield onErrorLogout(error);
      propertyTypologyActions.fetchPropertyTypologies.failure(error);
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
