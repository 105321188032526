import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

const GoodStat = ({
  name,
  title,
  totalStock,
  exploitableStock,
  avgPrice,
  minPrice,
  maxPrice,
  sqmAvgPrice,
  avgArea,
}) => {
  return (
    <View>
      <Text style={styles.statsTitle}>{name}</Text>
      <View>
        <Text style={styles.statsSubTitle}>{title}</Text>

        {totalStock === 0 ? (
          <View style={styles.row}>
            <Text style={styles.statsItem}>Resultat</Text>
            <Text style={styles.statsBadge}>Absence de données à analyser</Text>
          </View>
        ) : (
          <>
            <View style={styles.row}>
              <Text style={styles.statsItem}>Stock</Text>
              <Text
                style={styles.statsBadge}
              >{`${totalStock} bien(s), dont ${exploitableStock} bien(s) exploitable(s) pour la statistique`}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.statsItem}>Prix moyen</Text>
              <Text style={styles.statsBadge}>{`${avgPrice} €`}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.statsItem}>Prix minimum</Text>
              <Text style={styles.statsBadge}>{`${minPrice} €`}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.statsItem}>Prix maximum</Text>
              <Text style={styles.statsBadge}>{`${maxPrice} €`}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.statsItem}>Prix moyen m²</Text>
              <Text style={styles.statsBadge}>{`${sqmAvgPrice} €`}</Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.statsItem}>Surface moyenne</Text>
              <Text style={styles.statsBadge}>{`${avgArea} m²`}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

GoodStat.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  totalStock: PropTypes.number,
  exploitableStock: PropTypes.number,
  avgPrice: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  sqmAvgPrice: PropTypes.number,
  avgArea: PropTypes.number,
};

export default GoodStat;
