import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NavIcons from './NavIcons';
import { useDispatch, useSelector } from 'react-redux';
import { modalsActions } from '../store/modals/actions';
import { getModalState } from '../store/modals/reducer';

const NavLine = ({ activeNav, navName, path }) => {
  const { mobileNav } = useSelector(getModalState);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (mobileNav) {
      dispatch(modalsActions.mobileNav.close());
    }
  };

  return (
    <li
      key={navName}
      className={activeNav === path ? 'active-nav' : ''}
      onClick={handleClick}
    >
      <Link to={path}>
        <NavIcons title={navName} />
        {navName}
      </Link>
    </li>
  );
};

NavLine.propTypes = {
  activeNav: PropTypes.string.isRequired,
  navName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default NavLine;
