import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import { v4 as uuidV4 } from 'uuid';

import useFolders from '../../App/hooks/useFolders';

import Button from '../Assets/Button';
import ChoseFolderElt from './ChoseFolderElt';

import { adStudiesActions } from '../../store/adStudies/actions';

import { ReactComponent as FolderWaitBig } from '../../images/folder-wait-big.svg';

import './styles/chooseFolder.scss';
import { modalsActions } from '../../store/modals/actions';

function ChooseFolder() {
  const [radioValue, setRadioValue] = useState('');
  const { folders, movingAds, activeFolder } = useFolders();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (movingAds) {
      dispatch(adStudiesActions.moveAdStudies.reset());
    } else {
      dispatch(adStudiesActions.createAdStudies.reset());
    }
  };

  const handleFolderChooseSubmit = () => {
    if (radioValue !== '') {
      if (movingAds) {
        dispatch(
          adStudiesActions.moveAdStudies.request({
            idIri: radioValue,
          })
        );
      } else {
        dispatch(
          adStudiesActions.createAdStudies.request({
            idIri: radioValue,
            history,
          })
        );
      }
      dispatch(modalsActions.chooseFolder.close());
    }
  };

  useEffect(() => {
    if (!isEmpty(folders)) {
      const defaultFolder = folders.find((f) => f.isDefault === true);
      if (defaultFolder) {
        setRadioValue(defaultFolder.idIri);
      }
    }
  }, [folders]);

  return (
    <div className="chose-folder">
      <header className="chose-folder-header">
        <div className="chose-folder-title">
          <FolderWaitBig />
          <span>Choisissez votre dossier</span>
        </div>
        <Button
          btnType="button"
          btnContent="+ NOUVEAU"
          primary
          handleClick={() => history.push('/mes-dossiers-creation')}
        />
      </header>

      <div className="chose-folder-content">
        <RadioGroup
          name="chose-folder"
          value={radioValue}
          onChange={(e) => setRadioValue(e.target.value)}
        >
          {folders &&
            folders
              .filter((f) => f.idIri !== activeFolder)
              .map((elt) => {
                return <ChoseFolderElt key={uuidV4()} elt={elt} />;
              })}
        </RadioGroup>
      </div>

      <footer className="chose-folder-footer">
        <Button
          btnType="button"
          btnContent="ANNULER"
          handleClick={handleCancel}
          textColor="violet"
          bgColor="white"
          borderColor="grey"
        />
        <Button
          btnType="button"
          btnContent="CHOISIR CE DOSSIER"
          primary
          handleClick={handleFolderChooseSubmit}
        />
      </footer>
    </div>
  );
}

export default ChooseFolder;
