import PropTypes from 'prop-types';
import React from 'react';
import CheckBox from '../../Forms/CheckBox';
import TextInput from '../../Forms/TextInput';

function UserForm({ formik, setIsUserUpdated }) {
  const { values, touched, errors } = formik;

  const handleChange = (e) => {
    setIsUserUpdated(true);
    formik.handleChange(e);
  };

  const handleChecked = (e) => {
    setIsUserUpdated(true);
    formik.setFieldValue('enableNewsletter', !e.target.checked);
  };

  return (
    <div className="user-details-form-group">
      <h2>Profil Général</h2>
      <TextInput
        label="Nom"
        name="lastName"
        value={values.lastName}
        handleChange={(e) => handleChange(e)}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName && errors.lastName}
      />
      <TextInput
        label="Prénom"
        name="firstName"
        value={values.firstName}
        handleChange={(e) => handleChange(e)}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
      />
      <TextInput
        type="email"
        label="Email"
        name="email"
        value={values.email}
        handleChange={(e) => handleChange(e)}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <TextInput
        label="Téléphone"
        name="phoneNumber"
        value={values.phoneNumber}
        handleChange={(e) => handleChange(e)}
        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
        helperText={touched.phoneNumber && errors.phoneNumber}
      />
      <CheckBox
        checked={!values.enableNewsletter}
        name="enableNewsletter"
        label="Je ne désire pas recevoir la newsletter."
        handleChange={handleChecked}
      />
    </div>
  );
}

UserForm.propTypes = {
  formik: PropTypes.object.isRequired,
  setIsUserUpdated: PropTypes.func.isRequired,
};

export default UserForm;
