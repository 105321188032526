import { Tooltip } from '@material-ui/core';
import { isEmpty } from 'lodash';
import Badge from '../../components/badge';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../../components/badge/enums';
import { ReactComponent as Like } from '../../images/like.svg';
import { ReactComponent as SharedAdStudy } from '../../images/note-violet.svg';
import { ReactComponent as GelolocIcon } from '../../images/prospection-violet.svg';
import './adThumb.scss';

interface IThumbFooterProps {
  url?: string;
  source?: string;
  isBargain: boolean;
  isExclusive: boolean;
  isGeolocatable: boolean;
  studied: any;
  isAdStudy: boolean;
}

function ThumbFooter({
  url,
  source,
  isBargain,
  isExclusive,
  isGeolocatable,
  studied,
  isAdStudy,
}: IThumbFooterProps) {
  return (
    <footer className="ad-thumb-footer">
      <span className="ad-thumb-footer-author">
        Par{' '}
        <a href={url} title={source} target="_blank" rel="noreferrer">
          {source}
        </a>
      </span>
      <div className="ad-thumb-footer-elt">
        <div className="ad-thumb-footer-icons">
          {isBargain && (
            <Tooltip title="Bon coup" placement="top">
              <div className="ad-thumb-like">
                <Like />
              </div>
            </Tooltip>
          )}
          {isExclusive && (
            <Badge
              // className="ad-thumb-exclu"
              badgeContent={BadgeContentEnum.EXCLU}
              bgColor={BadgeBackgroundColorEnum.RED}
              textColor={BadgeTextColorEnum.WHITE}
            />
          )}
        </div>
        <div className="ad-thumb-footer-pictos">
          {isGeolocatable && (
            <div className="ad-thumb-footer-picto ad-thumb-prospec-icon">
              <Tooltip title="Géolocalisation" placement="top">
                <GelolocIcon />
              </Tooltip>
            </div>
          )}

          {studied && !isAdStudy && !isEmpty(studied.studied_by) && (
            <div className="ad-thumb-footer-picto ad-thumb-note-icon">
              <Tooltip title="A l'étude par un collaborateur" placement="top">
                <SharedAdStudy />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

export default ThumbFooter;
