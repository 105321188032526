import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAds from '../../App/hooks/useAds';

import { adActions } from '../../store/ads/actions';
import { adStudiesActions } from '../../store/adStudies/actions';

import GeoContent from '../AdDetails/geo/GeoContent';
import AdBasicDetails from '../Assets/AdBasicDetails';
import Button from '../Assets/Button';
import LogoButton from '../Assets/LogoButton';
import PaginationCustom from '../Assets/PaginationCustom';
import Badge from '../badge';
import IndividualCircularLoader from '../Loader/IndividualCircularLoader';

import { euroFormat } from '../../utils/jsFunctions';
import { similariesTableHeader } from '../AdDetails/constant';
import { BadgeBackgroundColorEnum, BadgeTextColorEnum } from '../badge/enums';
import AdTableCustom from '../ComparativeStudies/Details/AdTableCustom';
import './styles/AdDetails.scss';

function AdDetails({ adId }) {
  const { ad = {}, activeAd } = useAds(adId);
  const [minIndexTab, setMinIndexTab] = useState(0);
  const display = 20;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adActions.resetParcels.reset());
  }, [activeAd]);

  const handleFolder = () => {
    dispatch(adStudiesActions.createAdStudies.modal());
  };

  const handleDelete = () => {
    dispatch(
      adActions.deleteAds.modal({
        display: true,
      })
    );
  };

  return (
    <>
      {!ad ? (
        <IndividualCircularLoader size={200} />
      ) : (
        <div className="ad-details">
          <header className="ad-details-header">
            <div className="ad-details-header-info">
              <h2 className="ad-details-header-title">
                {ad.totalRoom > 0
                  ? `${ad.propertyTypology.name} F${ad.totalRoom}`
                  : `${ad.propertyTypology.name}`}
                <Badge
                  badgeDynamicContent={`${euroFormat(ad.price, true)} €`}
                  bgColor={BadgeBackgroundColorEnum.VIOLET}
                  textColor={BadgeTextColorEnum.WHITE}
                  isMini
                />
              </h2>
              <span className="ad-details-header-date">{`Publiée le ${new Date(
                ad.publishedAt
              ).toLocaleDateString()}`}</span>
            </div>
            <div className="ad-details-header-btns">
              <LogoButton
                btnType="button"
                handleClick={handleFolder}
                btnLogo="folder-wait"
                tooltip={`A étudier`}
              />
              <LogoButton
                btnType="button"
                handleClick={handleDelete}
                btnLogo="delete-red"
                tooltip={`Supprimer`}
              />
            </div>
          </header>

          <AdBasicDetails data={ad} />

          <section className="ad-details-ad-price">
            {/* <div className="ad-details-graph">
              <h3 className="ad-details-section-title">
                {"Évolution du prix de l'annonce"}
              </h3>
              <div className="ad-details-graph-elt">
                <DetailsGraph
                  labels={ad.adPriceEvolution.labels}
                  datasets={ad.adPriceEvolution.datasets}
                /> 
              </div>
            </div>*/}
            <Button
              btnType="button"
              btnContent="VOIR L'ANNONCE EN DETAILS"
              primary
              handleClick={() => window.open(ad.url, '_blank,noopener,noreferrer')}
            />
          </section>

          <section className="ad-details-search">
            <GeoContent ad={ad} />

            <div className="ad-details-similar">
              <h3 className="ad-details-section-title">{`Bien(s) similaire(s) à la vente récemment`}</h3>
              {isEmpty(ad.similaries) ? (
                <p className="no-content">Aucune annonce similaire trouvée</p>
              ) : isEmpty(ad) ? (
                <IndividualCircularLoader size={200} />
              ) : (
                <>
                  <AdTableCustom
                    columnTitles={similariesTableHeader}
                    adsArray={ad.similaries}
                    needCheck={false}
                    display={display}
                    minIndexTab={minIndexTab}
                  />
                  <PaginationCustom
                    elementsQty={ad.similaries.length}
                    itemsPerPage={parseInt(display)}
                    handleMinIndexTab={setMinIndexTab}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

AdDetails.propTypes = {
  adId: PropTypes.string,
};

export default AdDetails;
