import genericNotifications, {
  notificationAction,
  // notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';

export const collaboratorNotifications = {
  create: (name) =>
    genericNotifications(
      notificationTitles.collaborator,
      name,
      notificationAction.create
    ),
  update: (name) =>
    genericNotifications(
      notificationTitles.collaborator,
      name,
      notificationAction.update
    ),
  enable: (enabled) =>
    genericNotifications(notificationTitles.collaboratorEnable, enabled, null),
};
