import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../App/hooks/useAuth';
import { procedureEventsActions } from './actions';
import { getProcedureEventsState } from './reducer';

const useProcedureEvents = (pageName?: string) => {
  const { userIri } = useAuth();
  const { adStudyProcedureEvents, dashboardProcedureEvents } = useSelector(
    getProcedureEventsState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (userIri && pageName === 'dashboard') {
      dispatch(procedureEventsActions.fetchDashboardProcedureEvents.request());
    }
  }, [userIri]);

  return { adStudyProcedureEvents, dashboardProcedureEvents };
};

export default useProcedureEvents;
