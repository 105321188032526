import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

import logo from '../../../images/logo_pdf.png';

const Header = ({ name }) => {
  return (
    <View style={styles.header}>
      <View>
        <Text>{"Résultats de l'étude"}</Text>
        <Text>{name}</Text>
      </View>
      <View style={styles.headerLogo}>
        <Image src={logo} />
      </View>
    </View>
  );
};

Header.propTypes = { name: PropTypes.string };

export default Header;
