import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Button from '../../../../components/Assets/Button';
import TextInput from '../../../../components/Forms/TextInput';
import {
  signInInitForm,
  signInSchema,
} from '../../../../components/SignPage/constants';
import { authActions } from '../../../../store/auth/actions';
import { getAuthState } from '../../../../store/auth/reducer';
import { ReactComponent as CloseRed } from '.././../../../images/close-red.svg';
import styles from './signInForm.module.scss';
function SignInForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isBadCredentials } = useSelector(getAuthState);

  const formik = useFormik({
    initialValues: signInInitForm,
    validationSchema: signInSchema,
    onSubmit: (values) => {
      dispatch(authActions.login.request({ values, history }));
    },
  });

  const { values, touched, errors } = formik;

  return (
    <div className={styles.signInForm}>
      {isBadCredentials && (
        <div className={styles.badCredentials}>
          <CloseRed />
          <p>Votre identifiant ou votre mot de passe est incorrect.</p>
        </div>
      )}
      <form className={styles.form} noValidate>
        <TextInput
          name="username"
          label="Nom complet ou email"
          type="email"
          value={values.username}
          handleChange={formik.handleChange}
          error={touched.username && Boolean(errors.username)}
          helperText={touched.username && errors.username}
        />
        <TextInput
          name="password"
          label="Mot de passe"
          type="password"
          value={values.password}
          handleChange={formik.handleChange}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
        />
        <Button
          btnType="submit"
          btnContent="SE CONNECTER"
          primary
          handleClick={formik.handleSubmit}
        />
      </form>
    </div>
  );
}

export default SignInForm;
