import { makeStyles } from '@material-ui/styles';

const colorGrey = '#879EBE';

const SelectInputUseStyles = makeStyles((theme) => ({
  // background and label = purple, border and text = white
  negative: {
    '& .MuiOutlinedInput': {
      '&-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 4,
            borderColor: '#fff',
          },
        },
      },
      '&-notchedOutline': {
        border: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: '#fff',
      },
    },

    '&:hover': {
      '& .MuiOutlinedInput': {
        '&-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
        },
      },
    },

    '& label': {
      color: '#fff',
      '&.Mui-focused': {
        color: '#fff',
      },
    },

    '& svg': {
      color: '#fff',
    },

    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },
  // text purple, label grey
  reverse: {
    '& label': {
      color: colorGrey,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
    },
  },
}));

export default SelectInputUseStyles;
