interface IStreetViewLinkProps {
  lat: number;
  lng: number;
  address: string;
}

const StreetViewLink = ({ lat, lng, address }: IStreetViewLinkProps) => {
  if (!(lat && lng)) {
    return <span>{address}</span>;
  }

  return (
    <a
      href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`}
      title="streetview"
      target="_blank"
      rel="noopener, noreferrer"
    >
      {' ' + address}
    </a>
  );
};

export default StreetViewLink;
