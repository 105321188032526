import { usePDF } from '@react-pdf/renderer';
import { memo, useContext, useEffect, useRef } from 'react';
import { MarketStudyResultContext } from '../../pages/MarketStudiesResultPage';
import LogoButton from '../Assets/LogoButton';
import IndividualCircularLoader from '../Loader/IndividualCircularLoader';
import PdfMarketStudy from './pdf/pdfMarketStudy';

interface IPrintButtonProps {
  selectedMarketStudy?: any;
  pdfDatas?: any;
}

function PrintButton({ selectedMarketStudy, pdfDatas }: IPrintButtonProps) {
  const { sqmTypo, stockTypo, barGraphAvgSrc, barGraphSqmSrc } = useContext(
    MarketStudyResultContext
  );

  const linkPdf = useRef<HTMLAnchorElement>(null);
  const [instance, updateInstance] = usePDF({
    document: (
      <PdfMarketStudy
        name={selectedMarketStudy?.name}
        pdfData={pdfDatas}
        sqmTypo={sqmTypo}
        stockTypo={stockTypo}
        barGraphAvgSrc={barGraphAvgSrc}
        barGraphSqmSrc={barGraphSqmSrc}
        comment={selectedMarketStudy?.comment ?? {}}
      />
    ),
  });

  useEffect(() => {
    updateInstance();
  }, [
    pdfDatas,
    barGraphAvgSrc,
    barGraphSqmSrc,
    stockTypo,
    sqmTypo,
    selectedMarketStudy,
  ]);

  return (
    <>
      {instance.loading ? (
        <IndividualCircularLoader size={30} />
      ) : (
        <LogoButton
          btnType="button"
          handleClick={() => linkPdf.current?.click()}
          btnLogo="printer"
          tooltip={`Imprimer`}
        />
      )}
      <a
        href={instance.url || ''}
        ref={linkPdf}
        download="etude-de-marche-resultats.pdf"
        hidden
      />
    </>
  );
}

export default memo(PrintButton);
