import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { comparativeStudyComparisonsActions } from '../../../store/comparativeStudyComparisons/actions';

const ComparisonsTab = ({
  property,
  label,
  comparisonsResume,
  comparisonsActiveTab,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={`comparative-studies-details-tab ${
        comparisonsActiveTab === property ? 'active' : ''
      }`}
      onClick={() =>
        dispatch(
          comparativeStudyComparisonsActions.setComparisonsActiveTab.set(property)
        )
      }
    >
      <span className="comparative-studies-details-tab-amount">
        {comparisonsResume[property]}
      </span>
      <span className="comparative-studies-details-tab-type">{label}</span>
    </div>
  );
};

ComparisonsTab.propTypes = {
  property: PropTypes.string,
  label: PropTypes.string,
  comparisonsResume: PropTypes.object,
  comparisonsActiveTab: PropTypes.string,
};

export default ComparisonsTab;
