import { changeApiKeyForStoreKey } from '../common/parserCommon';

export const companyMapping = {
  '@id': 'idIri',
  address: 'address',
  city: 'city',
  country: 'country',
  name: 'name',
  siret: 'siret',
  subscription: 'subscrption',
  users: 'users',
};

export const companyApiToStore = (data) => {
  // const { base } = objectKeyGenericMaping(companyMapping);
  const parsedData = changeApiKeyForStoreKey(data, companyMapping);

  return parsedData;
};
