import React from 'react';
import useCollaborators from '../../../App/hooks/useCollaborators';
import useSubscription from '../../../App/hooks/useSubscription';

import { ReactComponent as NavClassic } from '../../../images/nav-classic.svg';
import { ReactComponent as NavCompare } from '../../../images/nav-compare.svg';
import { ReactComponent as NavMarket } from '../../../images/nav-market.svg';
import { ReactComponent as NavSearches } from '../../../images/nav-searches.svg';
import { ReactComponent as MapMarker } from '../../../images/map-marker.svg';

const RegistrationContent = () => {
  const { collaboratorsCount } = useCollaborators();
  const {
    hasMarketResearch,
    hasActiveResearch,
    hasComparativeStudy,
    hasGeolocation,
    totalAuthorizedUsers,
  } = useSubscription(true);

  return (
    <div className="user-registration-elt">
      <h3 className="user-registration-title">{`Nombre d'utilisateurs autorisés`}</h3>
      {/* +1 for collaborators + connectedUser */}
      <span className="user-registration-data">{`${
        collaboratorsCount + 1
      }/${totalAuthorizedUsers}`}</span>
      <ul>
        <li
          className={
            'my-account-domain active'
            // company.subscription.company_study
            //   ? 'my-account-domain active'
            //   : 'my-account-domain'
          }
        >
          <NavClassic />
          <span className="user-registration-text">Pige classique</span>
        </li>
        <li
          className={
            hasComparativeStudy ? 'my-account-domain active' : 'my-account-domain'
          }
        >
          <NavCompare />
          <span className="user-registration-text">Etudes comparatives</span>
        </li>
        <li
          className={
            hasMarketResearch ? 'my-account-domain active' : 'my-account-domain'
          }
        >
          <NavMarket />
          <span className="user-registration-text">Etudes de marché</span>
        </li>
        <li
          className={
            hasActiveResearch ? 'my-account-domain active' : 'my-account-domain'
          }
        >
          <NavSearches />
          <span className="user-registration-text">Recherches actives</span>
        </li>
        <li
          className={
            hasGeolocation ? 'my-account-domain active' : 'my-account-domain'
          }
        >
          <MapMarker />
          <span className="user-registration-text">
            {`Géolocalisation d'annonce`}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default RegistrationContent;
