import * as yup from 'yup';

import { EMAIL, PASSWORD } from '../../utils/validation';

// PASSWORD CHANGE
export const passwordResetSchema = yup.object({
  username: yup.string().email(EMAIL.type).required(EMAIL.required),
  password: yup.string().min(4, PASSWORD.min).required(PASSWORD.required),
  token: yup.string().required(),
});
