import { RESET, SET } from '../common/actions';
import { MARKET_STUDY_COMMENT, MARKET_STUDY_COMMENT_INIT } from './actions';

const initialState = {
  idIri: null,
  comment: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MARKET_STUDY_COMMENT_INIT[SET]:
      return {
        ...state,
        idIri: payload.idIri,
        comment: payload.comment,
      };
    case MARKET_STUDY_COMMENT[SET]:
      return {
        ...state,
        comment: { ...state.comment, [payload.name]: payload.comment },
      };

    case MARKET_STUDY_COMMENT[RESET]:
      return { ...state, comment: {} };

    default:
      return state;
  }
};

export const getMarketStudyCommentState = (state) => state.marketStudyCommentReducer;
