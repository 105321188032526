import { useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import Button from '../../components/Assets/Button';
import IndividualCircularLoader from '../../components/Loader/IndividualCircularLoader';
import { getAdsState } from '../../store/ads/reducer';
import AdMarker from './AdMarker';
import ChangeView from './ChangeView';
import PlotMarkerContainer from './PlotMarkerContainer';
import SatelliteLayer from './SatelliteLayer';

const ZOOM = 14;

interface IMapProps {
  city: CityForMap;
  typologyName: string;
  displayedLatLng: DisplayedLatLngState;
  totalRoom?: number;
  price: number;
}

const Map = ({
  city,
  displayedLatLng,
  typologyName,
  totalRoom,
  price,
}: IMapProps) => {
  const { adParcels } = useSelector(getAdsState);
  const [isSatellite, setIsSatellite] = useState(true);

  return (
    <>
      {!(displayedLatLng?.lat && displayedLatLng?.lng) ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <>
          <MapContainer
            center={[city.lat as number, city.lng as number]}
            zoom={ZOOM}
            scrollWheelZoom={false}
            className="ad-details-geo-map"
          >
            <ChangeView
              center={[displayedLatLng.lat, displayedLatLng.lng]}
              zoom={ZOOM}
            />

            <SatelliteLayer satelliteView={isSatellite} />

            {!displayedLatLng.city && (
              <AdMarker
                lat={displayedLatLng.lat}
                lng={displayedLatLng.lng}
                typologyName={typologyName}
                totalRoom={totalRoom}
                price={price}
              />
            )}

            <PlotMarkerContainer adPlots={adParcels} />
          </MapContainer>
          <Button
            btnType="button"
            borderColor="violet"
            bgColor="grey"
            textColor="violet"
            btnContent={!isSatellite ? 'VUE SATELLITE' : 'VUE PLAN'}
            handleClick={() => setIsSatellite(!isSatellite)}
          />
        </>
      )}
    </>
  );
};

export default Map;
