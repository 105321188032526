import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from '../../store/account/actions';
import { getAccountState } from '../../store/account/reducer';
import useAuth from './useAuth';

export default function usePigeParams() {
  const { user } = useAuth();
  const { pigeParams } = useSelector(getAccountState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pigeParams && !isEmpty(user)) {
      dispatch(accountActions.fetchPigeParams.request());
    }
  }, [user, pigeParams]);

  return { pigeParams };
}
