import { action, createRequestTypes, HIDDEN, VISIBLE } from '../common/actions';

export const LOADER_SPINNER = createRequestTypes('LOADER_SPINNER');

export const loaderActions = {
  loaderDisplay: {
    visible: () => action(LOADER_SPINNER[VISIBLE]),
    hidden: () => action(LOADER_SPINNER[HIDDEN]),
  },
};
