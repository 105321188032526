import { createMuiTheme } from '@material-ui/core/styles';

const colorPrimary = '#5c2670';
const colorSecondary = '#1cd4fd';
const colorError = '#f00';
const colorNeutralLight = '#f0f4f8';
const colorNeutralDark = '#879ebe';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
    },
    error: {
      main: '#f00',
    },
  },
  overrides: {
    // inputs && selects
    MuiInputBase: {
      input: {
        color: '#000',
      },
    },
    WAMuiChipInput: {
      root: {
        color: colorPrimary,
        borderRadius: 4,
        '&$error': {
          '& $notchedOutline': {
            borderColor: colorError,
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: colorPrimary,
        borderRadius: 4,
        '& $notchedOutline': {
          borderColor: colorPrimary,
          borderWidth: 1,
          borderStyle: 'solid',
        },
        '&$error': {
          '& $notchedOutline': {
            borderColor: colorError,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colorPrimary,
        '&$error': {
          color: colorError,
        },
        fontSize: '0.75rem',
      },
    },
    MuiSelect: {
      icon: {
        color: colorPrimary,
      },
    },
    // checkboxs
    MuiFormControlLabel: {
      label: {
        color: colorPrimary,
      },
    },
    MuiCheckbox: {
      root: {
        color: colorPrimary,
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: colorPrimary,
        '&$checked': {
          color: colorPrimary,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: colorError,
        },
      },
    },
    // Tooltip
    MuiTooltip: {
      tooltip: {
        fontSize: '0.625em',
        color: '#fff',
        backgroundColor: colorPrimary,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
      },
      arrow: {
        '&::before': {
          backgroundColor: colorPrimary,
        },
      },
    },
    // Pagination
    MuiPagination: {
      root: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MuiPaginationItem: {
      root: {
        backgroundColor: colorNeutralLight,
      },
      page: {
        '&.Mui-selected': {
          backgroundColor: colorNeutralDark,
        },
      },
    },
    MuiTabs: {
      root: { height: 60 },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '1rem',
      },
      textColorPrimary: {
        color: colorNeutralDark,
        '&.Mui-selected': {
          fontWeight: 700,
          color: colorPrimary,
        },
      },
    },
  },
});

export default mainTheme;
