import { TileLayer } from 'react-leaflet';

function OSMTileLayer() {
  return (
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      opacity={1}
      key="plain_map"
      maxNativeZoom={22}
      maxZoom={22}
    />
  );
}

export default OSMTileLayer;
