import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Button from '../../Assets/Button';

const ValidationButton = forwardRef((props, ref) => {
  const { onClick } = props;
  return (
    <div className="my-folders-ad-creation-content-main-group-form-btn">
      <Button
        ref={ref}
        btnType="button"
        btnContent="ENREGISTRER"
        handleClick={onClick}
        textColor="blue"
        bgColor="white"
        borderColor="violet"
      />
    </div>
  );
});

ValidationButton.propTypes = {
  onClick: PropTypes.func,
};

ValidationButton.displayName = 'Button';

export default ValidationButton;
