import {
  action,
  createRequestTypes,
  MODAL_DISPLAY,
  FAILURE,
  REQUEST,
  RESET,
  SUCCESS,
} from '../common/actions';

export const FETCH_FOLDERS = createRequestTypes('FETCH_FOLDERS');
export const FOLDERS_SET_ACTIVE = createRequestTypes('FOLDERS_SET_ACTIVE');
export const FOLDERS_SET_EDIT = createRequestTypes('FOLDERS_SET_EDIT');
export const CREATE_FOLDER = createRequestTypes('CREATE_FOLDER');
export const UPDATE_FOLDER = createRequestTypes('UPDATE_FOLDER');
export const DELETE_FOLDER = createRequestTypes('DELETE_FOLDER');
// export const UPDATE_AD_STUDIES_FOLDER_COUNT = createRequestTypes(
//   'UPDATE_AD_STUDIES_FOLDER_COUNT'
// );

export const foldersActions = {
  fetchFolders: {
    request: () => action(FETCH_FOLDERS[REQUEST]),
    success: (data) => action(FETCH_FOLDERS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_FOLDERS[FAILURE], { payload: data }),
  },
  setActiveFolder: {
    set: (data) => action(FOLDERS_SET_ACTIVE[SUCCESS], { payload: data }),
    reset: () => action(FOLDERS_SET_ACTIVE[SUCCESS], { payload: null }),
  },
  createFolder: {
    request: (data) => action(CREATE_FOLDER[REQUEST], { payload: data }),
    success: (data) => action(CREATE_FOLDER[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_FOLDER[FAILURE], { payload: data }),
  },
  updateFolder: {
    success: (data) => action(UPDATE_FOLDER[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_FOLDER[FAILURE], { payload: data }),
    request: (data) => action(UPDATE_FOLDER[REQUEST], { payload: data }),
  },
  deleteFolder: {
    modal: (data) => action(DELETE_FOLDER[MODAL_DISPLAY], { payload: data }),
    request: (data) => action(DELETE_FOLDER[REQUEST], { payload: data }),
    reset: () => action(DELETE_FOLDER[RESET]),
    success: (data) => action(DELETE_FOLDER[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_FOLDER[FAILURE], { payload: data }),
  },
};
