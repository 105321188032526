import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getActionNotificationState } from '../../store/appManager/reducer';
import { appManagerActions } from '../../store/appManager/actions';

import { ReactComponent as CrossWhite } from '../../images/cross-white.svg';
import { ReactComponent as CrossBlack } from '../../images/cross-black.svg';

import { NotificationStatus } from '../../utils/enums';
import './styles/ActionNotification.scss';

const getColor = (status) => {
  switch (status) {
    case NotificationStatus.SUCCESS:
      return 'action-notification-success';
    case NotificationStatus.FAILURE:
      return 'action-notification-failure';
    default:
      return '';
  }
};

const ActionNotification = () => {
  const { title, content, link, contentLink, status, display } = useSelector(
    getActionNotificationState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return dispatch(appManagerActions.actionNotificationDisplay.hidden());
  }, []);

  useEffect(() => {
    if (display) {
      window.scrollTo(0, 0);
    }
  }, [display]);

  return (
    <>
      {display && (
        <div className={`action-notification-container ${getColor(status)}`}>
          {title && <h4 className="action-notification-title">{title}</h4>}
          {content && <p className="action-notification-content">{content}</p>}
          {link && (
            <Link to={link} className="action-notification-link">
              {contentLink}
            </Link>
          )}
          <button
            className="alert-modal-btn"
            type="button"
            onClick={() =>
              dispatch(appManagerActions.actionNotificationDisplay.hidden())
            }
          >
            FERMER
            {status === NotificationStatus.SUCCESS ? <CrossBlack /> : <CrossWhite />}
          </button>
        </div>
      )}
    </>
  );
};

export default ActionNotification;
