import React, { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAdStudies from '../../App/hooks/useAdStudies';

import { adStudiesActions } from '../../store/adStudies/actions';

// import Button from '../Assets/Button';
import TextInput from '../Forms/TextInput';
import ValidationButton from './customComponants/ValidationButton';

function CommentariesForm(props, ref) {
  const dispatch = useDispatch();
  const {
    activeAdStudy: { comment, idIri },
  } = useAdStudies();

  const formik = useFormik({
    initialValues: { comment: comment ?? '' },
    validationSchema: yup.object({ comment: yup.string() }),
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'comment',
          idIri,
        })
      );
    },
  });

  const { dirty } = formik;

  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ commentForm: dirty }));
  }, [dirty]);

  return (
    <div className="my-folders-ad-creation-content-main-group-form">
      <h4>Commentaire divers</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Commentaire"
          rows={4}
          name="comment"
          value={formik.values.comment}
          handleChange={formik.handleChange}
        />
      </div>

      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </div>
  );
}

export default forwardRef(CommentariesForm);
