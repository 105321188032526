import * as yup from 'yup';

import { PASSWORD } from '../../../utils/validation';

export const passwordInitialValues = {
  lastPassword: '',
  newPassword: '',
  newPasswordCheck: '',
};

export const passwordSchema = yup.object({
  lastPassword: yup.string().required(PASSWORD.required),
  newPassword: yup.string().required(PASSWORD.required),
  newPasswordCheck: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      'Le mot de passe doit correspondre au nouveau mot de passe'
    )
    .required(PASSWORD.required),
});
