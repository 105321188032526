import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, Image } from '@react-pdf/renderer';

import { styles } from './styles';

const BarGraph = ({ title, graphSrc }) => {
  return (
    <View>
      <View style={styles.sectionBarGraph}>
        <Text style={styles.barGraphTitle}>{title}</Text>
        <Image src={graphSrc} style={styles.barGraphImg} />
      </View>
    </View>
  );
};

BarGraph.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  graphSrc: PropTypes.string,
};

export default BarGraph;
