import { fetchUrbaAuthenticationUrl } from './requests';

export async function getUrbaneaseAuthentication(
  lng?: number,
  lat?: number,
  department?: string
) {
  try {
    const result: any = await fetchUrbaAuthenticationUrl(lng, lat, department);

    if (result.url) {
      window.open(result.url, '_blank');
    }
    return 200;
  } catch (error: any) {
    return Promise.reject(error);
  }
}
