import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import useComparativeStudies from '../App/hooks/useComparativeStudies';

import ComparativeStudyCard from '../components/ComparativeStudies/ComparativeStudyCard';
import { distinctStringValues } from '../utils/jsFunctions';

import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';

import { isEmpty } from 'lodash-es';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import './styles/comparativeStudiesPage.scss';

function ComparativeStudiesPage() {
  const [cities, setCities] = useState(null);
  const { comparativeStudies } = useComparativeStudies();
  const history = useHistory();

  useEffect(() => {
    if (comparativeStudies) {
      const distinctCities = distinctStringValues(
        comparativeStudies
          .map((cs) => {
            return {
              name: cs.city.name,
              postalCode: cs.city.postalCode,
            };
          })
          .sort()
      );
      const filteredArr = Array.from(new Set(distinctCities.map((a) => a.name))).map(
        (name) => {
          return distinctCities.find((a) => a.name === name);
        }
      );
      setCities(filteredArr);
    }
  }, [comparativeStudies]);

  return (
    <>
      <main className="comparative-studies">
        <ActionNotification />
        <header className="comparative-studies-main-header">
          <SectionTitle
            mainTitle="Étude comparative"
            subTitle="Visualisez et selectionnez vos études"
          />
          <div>
            <Button
              btnType="button"
              btnContent="CREER UNE ETUDE"
              handleClick={() => history.push('/etude-comparative-creation')}
              primary
            />
          </div>
        </header>

        <section className="comparative-studies-locations">
          {!cities ? (
            <IndividualCircularLoader size={200} />
          ) : isEmpty(cities) ? (
            <p className="no-content">Aucune étude enregistrée</p>
          ) : (
            cities.map((city) => (
              <div key={city.name}>
                <header className="comparative-studies-header">
                  <h4>{`${city.name} (${city.postalCode})`}</h4>
                </header>
                <div className="comparative-studies-cards">
                  {comparativeStudies
                    .filter((elt) => elt.city.name === city.name)
                    .map((elt) => (
                      <ComparativeStudyCard key={uuidV4()} content={elt} />
                    ))}
                </div>
              </div>
            ))
          )}
        </section>
      </main>
    </>
  );
}

export default ComparativeStudiesPage;
