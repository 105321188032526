export const pieceworkInitValues = {
  actions: '',
  sort: '',
  display: '12',
};

export const sortValuesb = [
  { value: '', text: 'Aucun' },
  { value: 'price', text: 'Prix' },
  { value: 'publication', text: 'Date' },
  { value: 'sqmPrice', text: 'Prix au m²' },
];

export const pieceworksActions = (empty) => {
  return [
    {
      value: '',
      text: empty ? 'Selectionner une annonce' : 'ACTION',
    },
    { value: 'study', text: `A étudier` },
    { value: 'delete', text: `Supprimer` },
  ];
};

export const sortValues = [
  { value: 'createdAt', text: 'Date' },
  { value: 'price', text: 'Prix' },
  { value: 'sqmPrice', text: 'Prix au m²' },
  { value: 'sellerTypes', text: 'Profil vendeur' },
  { value: 'builtArea', text: 'Surface bâtie' },
  { value: 'landArea', text: 'Surface terrain' },
];
