import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from '../Assets/Button';
import TextInput from '../Forms/TextInput';
import { useSelector } from 'react-redux';
import { getMarketStudyCommentState } from '../../store/marketStudyComment/reducer';
import { marketStudyCommentActions } from '../../store/marketStudyComment/actions';

function MarketStudyResultComment({ name }) {
  const [commentState, setCommentState] = useState('');
  const { comment } = useSelector(getMarketStudyCommentState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (comment && comment[name] && commentState === '') {
      setCommentState(comment[name]);
    }
  }, [comment]);

  const handleSubmit = () => {
    dispatch(marketStudyCommentActions.updateMarketStudyComment.request());
  };

  const handleBlur = () => {
    dispatch(
      marketStudyCommentActions.marketStudyComment.set({
        name,
        comment: commentState,
      })
    );
  };

  const handleChange = (e) => {
    setCommentState(e.target.value);
  };

  return (
    <>
      <h4 className="market-studies-result-section-title">Commentaires</h4>
      <form>
        <TextInput
          label="Remarques diverses"
          rows={12}
          name={name}
          value={commentState}
          onBlur={handleBlur}
          handleChange={handleChange}
        />
        <Button
          btnType="button"
          btnContent="ENREGISTRER"
          handleClick={handleSubmit}
          bgColor="none"
          borderColor="darker-grey"
          textColor="darker-grey"
        />
      </form>
    </>
  );
}

MarketStudyResultComment.propTypes = {
  name: PropTypes.string,
};

export default MarketStudyResultComment;
