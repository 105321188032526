import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAuth from '../App/hooks/useAuth';

import routes from '../router/routes';
import { authActions } from '../store/auth/actions';
import { modalsActions } from '../store/modals/actions';
import { getModalState } from '../store/modals/reducer';

import Button from '../components/Assets/Button';
import Badge from '../components/badge';

import { ReactComponent as CloseNavBar } from '../images/close-navbar.svg';
import { ReactComponent as Deconnection } from '../images/deconnection.svg';
import { ReactComponent as MapMarker } from '../images/map-marker.svg';

import genericSort from '../utils/genericSort';
import NavLine from './NavLine';

import {
  BadgeBackgroundColorEnum,
  BadgeTextColorEnum,
} from '../components/badge/enums';
import LinkFromMenu from '../components/linksToUrbanease/LinkFromMenu';
import './styles/Nav.scss';

function Nav({ activeNav }) {
  const { mobileNav } = useSelector(getModalState);
  const { role, userFullNameWithInitial, companyName } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (mobileNav) {
      dispatch(modalsActions.mobileNav.close());
      dispatch(authActions.logout.request(history));
    }
  };

  return (
    <nav className="nav-wrapper">
      <nav className={mobileNav ? 'nav nav-mobile-active' : 'nav'}>
        <div className="nav-mobile-elt">
          <h2 className="nav-mobile-map">
            <MapMarker />
            <p>{companyName ?? 'Société'}</p>
          </h2>
          <div className="nav-mobile-user">
            {userFullNameWithInitial}
            <Badge
              badgeContent={role}
              bgColor={BadgeBackgroundColorEnum.GREY}
              textColor={BadgeTextColorEnum.DARK_GREY}
              isMini
            />
          </div>
        </div>
        <ul>
          {genericSort(
            routes.filter((elmt) => elmt.nav),
            'asc',
            'nav'
          ).map((elmt) => (
            <NavLine key={elmt.navName} activeNav={activeNav} {...elmt} />
          ))}
          <LinkFromMenu />

          <li className="nav-mobile-deconnection" onClick={handleClick}>
            <button type="button">
              <Deconnection className="nav-logo" />
              Déconnexion
            </button>
          </li>
        </ul>

        <div className="nav-elt">
          <img src="../images/dashnav-picture.png" className="nav-picture" />
          <Button
            btnType="button"
            btnContent="DECONNEXION"
            btnImg="deconnection"
            handleClick={() => dispatch(authActions.logout.request(history))}
            primary
          />
        </div>
        <button type="button" className="nav-mobile-close" onClick={handleClick}>
          <CloseNavBar />
        </button>
      </nav>

      <span className="nav-coryright">
        Copyright 2021 -{' '}
        <a href="https://prospec-immo.com/cgu.html" title="Mentions légales">
          Mentions légales
        </a>{' '}
        -{' '}
        <a href="https://prospec-immo.com/cgu.html" title="CGV">
          CGV
        </a>
      </span>
    </nav>
  );
}

Nav.propTypes = {
  activeNav: PropTypes.string,
};

export default Nav;
