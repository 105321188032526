import * as yup from 'yup';
import {
  last_name,
  first_name,
  EMAIL,
  // PASSWORD,
  // PASSWORD_CHECK,
  PHONE_NUMBER,
} from '../../../utils/validation';

export const createSchema = yup.object({
  lastName: yup.string().required(last_name.required),
  firstName: yup.string().required(first_name.required),
  email: yup.string().email(EMAIL.type).required(EMAIL.required),
  phoneNumber: yup.number().typeError(PHONE_NUMBER.type),
  // password: yup.string().min(4, PASSWORD.min).required(PASSWORD.required),
  // passwordCheck: yup
  //   .string()
  //   .oneOf([yup.ref('password')], PASSWORD_CHECK.check)
  //   .required(PASSWORD_CHECK.required),
});

export const updateSchema = yup.object({
  lastName: yup.string().required(last_name.required),
  firstName: yup.string().required(first_name.required),
  email: yup.string().email(EMAIL.type).required(EMAIL.required),
  phoneNumber: yup.number().typeError(PHONE_NUMBER.type),
  // password: yup.string().min(4, PASSWORD.min),
  // passwordCheck: yup.string().oneOf([yup.ref('password')], PASSWORD_CHECK.check),
});

export const initForm = (selectedCollaborator) => {
  return {
    lastName: selectedCollaborator.lastName ?? '',
    firstName: selectedCollaborator.firstName ?? '',
    email: selectedCollaborator.email ?? '',
    phoneNumber: selectedCollaborator.phone_number ?? '',
    // password: '',
    // passwordCheck: '',
  };
};
