import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from '../../App/hooks/useAuth';

import TabItem from './TabItem';
import UserAccountTab from '../Modals/UserAccountTabs';

import { modalsActions } from '../../store/modals/actions';
import { getModalState } from '../../store/modals/reducer';

import { ReactComponent as DownArrowGrey } from '../../images/down-arrow-grey.svg';
import './styles/tabContainer.scss';

const TabContainer = ({ tabArray, type }) => {
  const { userMobileTabs } = useSelector(getModalState);
  const { isManager } = useAuth();
  const dispatch = useDispatch();

  const handleMenuClick = () => {
    userMobileTabs
      ? dispatch(modalsActions.userMobileTabs.close())
      : dispatch(modalsActions.userMobileTabs.open());
  };
  return (
    <div className="tabs">
      <ul className="tabs-list">
        {tabArray
          .filter((elmt) => (isManager ? true : !elmt.onlyAdmin))
          .map((elmt) => (
            <TabItem key={elmt.index} index={elmt.index} type={type}>
              {elmt.name}
            </TabItem>
          ))}
      </ul>
      <div onClick={handleMenuClick} className="tabs-arrow">
        <DownArrowGrey />
      </div>
      {userMobileTabs && (
        <div className="tabs-menu">
          <UserAccountTab />
        </div>
      )}
    </div>
  );
};

TabContainer.propTypes = {
  tabArray: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};
export default TabContainer;
