export const NEW_GOODS_SALE =
  'Biens neufs constatés à la vente sur les 12 derniers mois';
export const NEW_GOODS_SOLD = 'Biens neufs vendus base fiscale sur la période ';

export const OLD_GOODS_SALE =
  'Biens anciens constatés à la vente sur les 12 derniers mois';
export const OLD_GOODS_SOLD = 'Biens anciens vendus base fiscale sur la période ';

export const FIELD_GOODS_SALE =
  'Biens constatés à la vente sur les 12 derniers mois';
export const FIELD_GOODS_SOLD = 'Biens vendus base fiscale sur la période ';

export const averagePriceEvolutionRoomLabels = [
  'à la vente ancien',
  'à la vente neuf',
  'vendu ancien',
  'vendu neuf',
];

export const averagePriceEvolutionAreaLabels = ['A la vente', 'Vendus'];
export const statTypeFieldValues = ['ground600', 'ground1000', 'ground_more'];

export const fieldAreaDisplay = (ground: string) => {
  switch (ground) {
    case 'ground_more':
      return `supérieur à 1000 m²`;
    case 'ground1000':
      return `de 600 à 1000 m²`;
    case 'ground600':
    default:
      return `de 0 à 600 m²`;
  }
};
