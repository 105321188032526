import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from 'date-fns/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';

function DateInput({ value, handleChange, label, name, helperText }) {
  // const dateInitial = value
  //   ? new Date(+value.split('/')[2], value.split('/')[1] - 1, +value.split('/')[0])
  //   : value;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <DatePicker
        inputVariant="outlined"
        cancelLabel="ANNULER"
        name={name}
        label={label}
        value={value ? new Date(value).toISOString() : null}
        format="dd MMM yyyy"
        onChange={(value) => {
          handleChange(name, value ? new Date(value).toISOString() : null);
        }}
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        clearable
        clearLabel="Effacer"
      />
    </MuiPickersUtilsProvider>
  );
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date).isRequired,
    PropTypes.string.isRequired,
  ]),
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
};
export default DateInput;
