import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import useAuth from '../../App/hooks/useAuth';

import TextInput from '../Forms/TextInput';
import { passwordSchema } from './PasswordTab/passwordFormValues';
import { passwordInitialValues } from './PasswordTab/passwordFormValues';
import CommonAccountFormButtons from './CommonAccountFormButtons';

import './PasswordTab/passwordTab.scss';
import { authActions } from '../../store/auth/actions';

const PasswordTab = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: passwordInitialValues,
    validationSchema: passwordSchema,
    onSubmit: () => {
      dispatch(
        authActions.passwordChange.request({
          values: {
            last_password: values.lastPassword,
            new_password: values.newPassword,
          },
          id: user.idIri,
        })
      );
    },
  });

  const { values, touched, errors } = formik;

  return (
    <section className="my-account-content user-password">
      <h2>Mot de passe</h2>
      <form>
        <div className="user-password-form-elt">
          <TextInput
            label="Mot de passe actuel"
            type="password"
            name="lastPassword"
            value={values.lastPassword}
            handleChange={formik.handleChange}
            error={touched.lastPassword && Boolean(errors.lastPassword)}
            helperText={touched.lastPassword && errors.lastPassword}
          />
          <TextInput
            label="Nouveau mot de passe"
            type="password"
            name="newPassword"
            value={values.newPassword}
            handleChange={formik.handleChange}
            error={touched.newPassword && Boolean(errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
        </div>
        <div className="user-password-form-elt">
          <TextInput
            label="Confirmer le nouveau mot de passe"
            type="password"
            name="newPasswordCheck"
            value={values.newPasswordCheck}
            handleChange={formik.handleChange}
            error={touched.newPasswordCheck && Boolean(errors.newPasswordCheck)}
            helperText={touched.newPasswordCheck && errors.newPasswordCheck}
          />
        </div>
        <CommonAccountFormButtons
          label="METTRE A JOUR LE NOUVEAU MOT DE PASSE"
          handleSubmit={formik.handleSubmit}
        />
      </form>
    </section>
  );
};

export default PasswordTab;
