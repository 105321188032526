import genericNotifications, {
  notificationAction,
  notificationTitles,
} from '../../utils/genericNotifications';

export const comparativeStudiesNotifications = {
  create: (name) =>
    genericNotifications(
      notificationTitles.comparativeStudies,
      name,
      notificationAction.create
    ),
  update: (name) =>
    genericNotifications(
      notificationTitles.comparativeStudies,
      name,
      notificationAction.update
    ),
  delete: (name) =>
    genericNotifications(
      notificationTitles.comparativeStudies,
      name,
      notificationAction.delete
    ),
};
