import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../Assets/Button';

import DeleteRed from '../../images/delete-red.svg';
import './styles/actionModal.scss';

const ActionModal = ({
  children,
  closeAction,
  validAction,
  validText = 'VALIDER',
  modalType = 'suppression',
  monoButton,
}) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (modalType) {
      case 'suppression':
        setTitle('SUPPRESSION');
        setImage(DeleteRed);
        break;
      case 'timeout':
        setTitle('Temps Dépassé');
        break;
      default:
        return 'SANS TITRE';
    }
  }, []);

  return (
    <div className="overlay">
      <div className="window-modal">
        <h3>{title}</h3>

        <div className="window-modal-container">
          {image && (
            <div className="window-modal-container-icon">
              <img src={image} />
            </div>
          )}
          <div className="window-modal-container-content">{children}</div>
        </div>

        <footer className="window-modal-footer">
          {!monoButton && (
            <Button
              btnType="button"
              btnContent="ANNULER"
              handleClick={() => dispatch(closeAction)}
              textColor="violet"
              bgColor="white"
              borderColor="grey"
            />
          )}
          <Button
            btnType="button"
            btnContent={validText}
            primary
            handleClick={() => dispatch(validAction)}
          />
        </footer>
      </div>
    </div>
  );
};

ActionModal.propTypes = {
  children: PropTypes.node.isRequired,
  validAction: PropTypes.object.isRequired,
  closeAction: PropTypes.object.isRequired,
  validText: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  monoButton: PropTypes.bool,
  hasIcon: PropTypes.bool,
};
export default ActionModal;
