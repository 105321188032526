import PropTypes from 'prop-types';
import React from 'react';
import ActiveResearchWordFilter from './ActiveResearchWordFilter';

const KeywordsBloc = ({ formik }) => {
  return (
    <div className="keywords-filter-group">
      <ActiveResearchWordFilter
        formik={formik}
        name="includedKeywords"
        label="Mots-clés à inclure"
        placeholder="Mots-clés à inclure"
      />
      <ActiveResearchWordFilter
        formik={formik}
        name="excludedKeywords"
        label="Mots-clés à exclure"
        placeholder="Mots-clés à exclure"
      />
    </div>
  );
};

KeywordsBloc.propTypes = { formik: PropTypes.object };

export default KeywordsBloc;
