import React from 'react';
import { CircularProgress } from '@material-ui/core';

const UserLoader = () => {
  return (
    <div className="loader-circular-user">
      <CircularProgress size={200} />
      <p>{`Chargement de l'utilisateur ...`}</p>
    </div>
  );
};

export default UserLoader;
