import {
  axiosInstance,
  axiosTokenInstance,
} from '../../services/axiosInstanceService';

export const getProspec = ({ endpoint, body }) => {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
export const fetchCompany = (companyId) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(`${companyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const updateCompany = ({ endpoint, body }) => {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export function fetchPigeParams(marketResearchIri) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .get(marketResearchIri)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function createPigeParams({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function deletePigeParams({ endpoint, body }) {
  return new Promise((resolve, reject) => {
    axiosTokenInstance()
      .put(endpoint, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
