import { isEmpty } from 'lodash';
import { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useMarketStudies from '../App/hooks/useMarketStudies';

import ActionNotification from '../components/Assets/ActionNotification';
import Footer from '../components/Assets/Footer';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import Header from '../components/MarketStudyResult/Header';
import MultiGraphBloc from '../components/MarketStudyResult/MultiGraphBloc';
import ResultSummary from '../components/MarketStudyResult/ResultSummary';

import { marketStudiesActions } from '../store/marketStudies/actions';
import { marketStudyCommentActions } from '../store/marketStudyComment/actions';

import CreateMarketStudyResultCtx from '../App/contexts/marketStudyContext';
import GoodContainer from '../components/MarketStudyResult/GoodContainer';
import './styles/marketStudiesResultPage.scss';

export enum GoodTypeEnum {
  TERRAIN,
  CONSTRUCTION,
  NONE,
}
const [ctx, MarketStydyResultProvider] = CreateMarketStudyResultCtx();

export const MarketStudyResultContext = ctx;

function MarketStudiesResultPage() {
  const { id } = useParams<{ id: string }>();

  const { setSqmTypo, setStockTypo } = useContext(MarketStudyResultContext);

  const { marketStudyResult, selectedMarketStudy } = useMarketStudies(id, id);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    dispatch(marketStudyCommentActions.updateMarketStudyComment.request());
    dispatch(marketStudiesActions.resetActiveMarketStudy.success());
    history.push('/etudes-de-marche');
  };

  const handleCancel = () => {
    dispatch(marketStudiesActions.resetActiveMarketStudy.success());
    history.push('/etudes-de-marche');
  };

  return (
    <>
      <main className="market-studies-result-wrapper">
        <ActionNotification />

        <Header handleSubmit={handleSubmit} handleCancel={handleCancel} />

        <div>
          <>
            <ResultSummary result={marketStudyResult} />

            {!marketStudyResult ? (
              <IndividualCircularLoader size={200} />
            ) : !isEmpty(marketStudyResult) ? (
              <section className="market-studies-result-prices">
                <GoodContainer
                  type={
                    selectedMarketStudy?.propertyTypologies
                      ? selectedMarketStudy?.propertyTypologies.name === 'Terrain'
                        ? GoodTypeEnum.TERRAIN
                        : GoodTypeEnum.CONSTRUCTION
                      : GoodTypeEnum.NONE
                  }
                />
              </section>
            ) : (
              <p className="no-result">Aucun Resultat pour cette étude de marché</p>
            )}
            <MultiGraphBloc type="prices" id={id} handleGraph={setSqmTypo}>
              Évolution des prix m² par typologies sur 1 an
            </MultiGraphBloc>
            <MultiGraphBloc type="stocks" id={id} handleGraph={setStockTypo}>
              Évolution des stocks par typologies sur 1 an
            </MultiGraphBloc>
          </>
        </div>
      </main>

      <Footer
        marketStudies
        handlePrint={() => {}}
        handleClick={handleSubmit}
        handleEdit={handleSubmit}
      />
    </>
  );
}

function ContextProviderPage() {
  return (
    <MarketStydyResultProvider>
      <MarketStudiesResultPage />
    </MarketStydyResultProvider>
  );
}
export default memo(ContextProviderPage);
