import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import CheckBox from '../../Forms/CheckBox';
import { accountActions } from '../../../store/account/actions';
import { useDispatch } from 'react-redux';

const DateForm = () => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [onlyYear, setOnlyYear] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = { year: new Date(selectedDate).getFullYear() };
    if (!onlyYear) {
      data.month = new Date(selectedDate).getMonth() + 1;
    }

    dispatch(accountActions.fetchCompanyStats.request(data));
  }, [selectedDate, onlyYear]);

  const handleDisplay = () => {
    return onlyYear ? ['year'] : ['month', 'year'];
  };

  return (
    <form className="user-statistics-form">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <DatePicker
          value={selectedDate}
          views={handleDisplay()}
          inputVariant="outlined"
          minDate={new Date('2015-01-01')}
          maxDate={new Date()}
          onChange={(date) => setSelectedDate(new Date(date))}
        />
      </MuiPickersUtilsProvider>

      <CheckBox
        name="onlyYear"
        checked={onlyYear}
        handleChange={(e) => setOnlyYear(e.target.checked)}
        label="Année uniquement"
      />
    </form>
  );
};

export default DateForm;
