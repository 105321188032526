import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

function RadioButtons({
  label,
  value,
  name,
  handleChange,
  radioElts,
  isRow,
  style,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={handleChange}
        row={isRow}
        style={style}
      >
        {radioElts.map((elt) => (
          <FormControlLabel
            key={elt.value}
            value={elt.value}
            control={<Radio />}
            label={elt.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtons.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  radioElts: PropTypes.array.isRequired,
  isRow: PropTypes.bool,
  style: PropTypes.object,
};

export default RadioButtons;
