import {
  action,
  ADD,
  createRequestTypes,
  FAILURE,
  MODAL_DISPLAY,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

export const FETCH_ADS = createRequestTypes('FETCH_ADS');
export const SET_ACTIVE_AD = createRequestTypes('SET_ACTIVE_AD');
export const AD_GEO_ADDRESS = createRequestTypes('AD_GEO_ADDRESS');
export const FETCH_AD = createRequestTypes('FETCH_AD');
export const FETCH_PARCELS = createRequestTypes('FETCH_PARCELS');
export const SELECTED_ADS = createRequestTypes('SELECTED_ADS');
export const SELECTED_ADS_RESET = createRequestTypes('SELECTED_ADS_RESET');
export const DELETE_ADS = createRequestTypes('DELETE_ADS');
export const PAGE_QUANTITY = createRequestTypes('PAGE_QUANTITY');
export const ADS_PER_PAGE = createRequestTypes('ADS_PER_PAGE');
export const SELECTED_SORT = createRequestTypes('SELECTED_SORT');
export const RESET_PARCELS = createRequestTypes('RESET_PARCELS');
export const RESET_NEW_ADS_COUNT = createRequestTypes('RESET_NEW_ADS_COUNT');

export const adActions = {
  fetchAds: {
    request: (data) => action(FETCH_ADS[REQUEST], { payload: data }),
    success: (data) => action(FETCH_ADS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_ADS[FAILURE], { payload: data }),
  },
  fetchAd: {
    request: (data) => action(FETCH_AD[REQUEST], { payload: data }),
    success: (data) => action(FETCH_AD[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_AD[FAILURE], { payload: data }),
  },
  fetchParcels: {
    request: (data) => action(FETCH_PARCELS[REQUEST], { payload: data }),
    success: (data) => action(FETCH_PARCELS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_PARCELS[FAILURE], { payload: data }),
  },
  resetParcels: {
    reset: () => action(RESET_PARCELS[RESET]),
  },
  newAdsCount: {
    reset: () => action(RESET_NEW_ADS_COUNT[RESET]),
  },
  setActiveAd: {
    set: (data) => action(SET_ACTIVE_AD[SUCCESS], { payload: data }),
    failure: (data) => action(SET_ACTIVE_AD[FAILURE], { payload: data }),
  },
  adGeoAddress: {
    set: (data) => action(AD_GEO_ADDRESS[SET], { payload: data }),
    reset: () => action(AD_GEO_ADDRESS[RESET], { payload: '' }),
  },
  pageQuantity: {
    set: (data) => action(PAGE_QUANTITY[SET], { payload: data }),
  },
  adsPerPage: {
    set: (data) => action(ADS_PER_PAGE[SET], { payload: data }),
  },
  selectedSort: {
    set: (data) => action(SELECTED_SORT[SET], { payload: data }),
  },
  selectedAds: {
    add: (data) => action(SELECTED_ADS[ADD], { payload: data }),
    failure: (data) => action(SELECTED_ADS[FAILURE], { payload: data }),
  },
  selectedAdsReset: {
    success: () => action(SELECTED_ADS_RESET[SUCCESS]),
    failure: () => action(SELECTED_ADS_RESET[FAILURE]),
  },
  deleteAds: {
    request: (data) => action(DELETE_ADS[REQUEST], { payload: data }),
    modal: (data) => action(DELETE_ADS[MODAL_DISPLAY], { payload: data }),
    reset: () =>
      action(DELETE_ADS[RESET], {
        payload: { display: false },
      }),
    success: (data) => action(DELETE_ADS[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_ADS[FAILURE], { payload: data }),
  },
};
