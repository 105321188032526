import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import routes from '../../router/routes';

const HelmetCustom = () => {
  const { pathname } = useLocation();

  const displayTitle = () => {
    const exists = routes.find((elmt) => elmt.path === pathname);

    return exists ? exists.title : 'Prospec-immo';
  };
  return (
    <Helmet>
      <title>{displayTitle()}</title>
    </Helmet>
  );
};

export default HelmetCustom;
