import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import useSubscription from '../../../App/hooks/useSubscription';

import './styles/registrationGeographicSectors.scss';

const RegistrationGeographicSectors = () => {
  const { subscriptionCities = [], departments = [] } = useSubscription(true);
  return (
    <div className="user-registration-elt">
      <h3 className="user-registration-title">Secteurs géographiques</h3>
      <div className="user-registration-lists">
        <div className="user-registration-list">
          <h4 className="user-registration-list-title">Villes</h4>
          <ul className="geographic-sectors-list">
            {subscriptionCities.map((city) => (
              <li className="user-registration-list-item" key={uuidv4()}>
                {city}
              </li>
            ))}
          </ul>
        </div>
        <div className="user-registration-list">
          <h4 className="user-registration-list-title">Départements</h4>
          <ul className="geographic-sectors-list">
            {departments.map((d) => (
              <li className="user-registration-list-item" key={uuidv4()}>
                {d}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegistrationGeographicSectors;
