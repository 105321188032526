import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useFolders from '../App/hooks/useFolders';
import ActionNotification from '../components/Assets/ActionNotification';
import Footer from '../components/Assets/Footer';
import SelectInput from '../components/Forms/SelectInput';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';
import FolderLine from '../components/MyFolders/FolderLine';
import { sortSelectOptions } from '../components/MyFolders/form';
import MyFoldersHeader from '../components/MyFolders/MyFoldersHeader';
import { foldersActions } from '../store/folders/actions';
import genericSort from '../utils/genericSort';
import './styles/MyFolders.scss';

function MyFoldersPage() {
  const [sort, setSort] = useState('');
  const { activeFolder, folders } = useFolders();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <main className="my-folders">
        <MyFoldersHeader />

        <section className="my-folders-content">
          <ActionNotification />
          <div className="my-folders-content-actions">
            <p>{"Affiner l'affichage"}</p>
            <SelectInput
              name="sort"
              colorDisplay="reverse"
              nameKey="text"
              value={sort}
              handleChange={(e) => setSort(e.target.value)}
              label="Critère de tri"
              items={sortSelectOptions}
            />
          </div>
          {isEmpty(folders) ? (
            <IndividualCircularLoader size={200} />
          ) : (
            <table>
              <tbody>
                {folders
                  .filter((elmt) => elmt.isDefault)
                  .map((elt) => (
                    <FolderLine
                      key={uuidv4()}
                      idIri={elt.idIri}
                      id={elt.id}
                      name={elt.name}
                      ownerId={elt.owner ? elt.owner.idIri : ''}
                      collaborators={elt.totalCollaborators}
                      ads={elt.totalAdStudies}
                      hasUpdates={elt.hasUpdates}
                      isDefault={true}
                    />
                  ))}
                {folders &&
                  genericSort(folders, 'asc', sort)
                    .filter((elmt) => !elmt.isDefault)
                    .map((elt) => (
                      <FolderLine
                        key={uuidv4()}
                        idIri={elt.idIri}
                        id={elt.id}
                        name={elt.name}
                        ownerId={elt.owner ? elt.owner.idIri : ''}
                        collaborators={elt.totalCollaborators}
                        ads={elt.totalAdStudies}
                        hasUpdates={elt.hasUpdates}
                      />
                    ))}
              </tbody>
            </table>
          )}
        </section>
      </main>
      {activeFolder && (
        <Footer
          myFolders
          handleEdit={() => {
            history.push(`/mes-dossiers-modification/${activeFolder.split('/')[2]}`);
          }}
          handleDelete={() =>
            dispatch(foldersActions.deleteFolder.request(activeFolder))
          }
        />
      )}
    </>
  );
}

export default MyFoldersPage;
