import { RESET, SET, SUCCESS } from '../common/actions';
import { FETCH_ACTIVE_COMPARATIVE_STUDY } from '../comparativeStudies/actions';
import {
  COMPARATIVE_STUDY_COMPARISONS_ACTIVE_TAB,
  FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON,
  RESET_COMPARISONS_STATE,
} from './actions';

const initialState = {
  comparisonsActiveTab: 'lower',
  comparisonsResume: {},
  lists: { lower: null, equal: null, higher: null },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPARATIVE_STUDY_COMPARISONS_ACTIVE_TAB[SET]:
      return { ...state, comparisonsActiveTab: payload };
    case FETCH_ACTIVE_COMPARATIVE_STUDY[SUCCESS]:
      return { ...state, comparisonsResume: { ...payload.priceComparisons } };
    case FETCH_ACTIVE_COMPARATIVE_STUDY_COMPARISON[SUCCESS]:
      return { ...state, lists: { ...state.lists, [payload.key]: payload.list } };
    case RESET_COMPARISONS_STATE[RESET]:
      return { ...initialState };
    default:
      return state;
  }
};

export const getComparativeStudyComparisonsState = (state) =>
  state.comparativeStudyComparisons;
