import React, { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import useAdStudies from '../../App/hooks/useAdStudies';

import TextInput from '../Forms/TextInput';
import ValidationButton from './customComponants/ValidationButton';

import { adStudiesActions } from '../../store/adStudies/actions';
import {
  projectCollaboratorsInitialValues,
  projectCollaboratorsSchema,
} from './form';

function ProjectCollaboratorForm(props, ref) {
  const {
    activeAdStudy: { projectCollaborators, idIri },
  } = useAdStudies();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: !isEmpty(projectCollaborators)
      ? projectCollaborators
      : projectCollaboratorsInitialValues,
    projectCollaborators,
    validationSchema: projectCollaboratorsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'projectCollaborators',
          idIri,
        })
      );
    },
  });

  const { values, touched, errors, dirty } = formik;

  useEffect(() => {
    dispatch(
      adStudiesActions.formTouchStates.set({ projectCollaboratorsForm: dirty })
    );
  }, [dirty]);

  return (
    <form className="my-folders-ad-creation-content-main-group-form" noValidate>
      <h4>Intervenants du projet</h4>
      {
        <div className="my-folders-ad-creation-content-main-group-form-row">
          <TextInput
            label="Architecte du projet"
            placeholder="Nom"
            name="projectArchitect"
            value={values.projectArchitect}
            handleChange={formik.handleChange}
            error={touched.projectArchitect && Boolean(errors.projectArchitect)}
            helperText={touched.projectArchitect && errors.projectArchitect}
          />
          <TextInput
            label="Téléphone de l'architecte"
            placeholder="Tel"
            name="architectPhone"
            value={values.architectPhone}
            type="tel"
            handleChange={formik.handleChange}
            error={touched.architectPhone && Boolean(errors.architectPhone)}
            helperText={touched.architectPhone && errors.architectPhone}
          />
          <TextInput
            label="Email de l'architecte"
            placeholder="Email"
            name="architectEmail"
            value={values.architectEmail}
            type="email"
            handleChange={formik.handleChange}
            error={touched.architectEmail && Boolean(errors.architectEmail)}
            helperText={touched.architectEmail && errors.architectEmail}
          />
        </div>
      }

      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Notaire du projet"
          placeholder="Nom"
          name="projectNotary"
          value={values.projectNotary}
          handleChange={formik.handleChange}
          error={touched.projectNotary && Boolean(errors.projectNotary)}
          helperText={touched.projectNotary && errors.projectNotary}
        />
        <TextInput
          label="Téléphone du notaire"
          placeholder="Tel"
          name="notaryPhone"
          value={values.notaryPhone}
          type="tel"
          handleChange={formik.handleChange}
          error={touched.notaryPhone && Boolean(errors.notaryPhone)}
          helperText={touched.notaryPhone && errors.notaryPhone}
        />
        <TextInput
          label="Email du notaire"
          placeholder="Email"
          name="notaryEmail"
          value={values.notaryEmail}
          type="email"
          handleChange={formik.handleChange}
          error={touched.notaryEmail && Boolean(errors.notaryEmail)}
          helperText={touched.notaryEmail && errors.notaryEmail}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Géomètre du projet"
          placeholder="Nom"
          name="projectSurveyor"
          value={values.projectSurveyor}
          handleChange={formik.handleChange}
          error={touched.projectSurveyor && Boolean(errors.projectSurveyor)}
          helperText={touched.projectSurveyor && errors.projectSurveyor}
        />
        <TextInput
          label="Téléphone du géomètre"
          placeholder="Tel"
          name="surveyorPhone"
          value={values.surveyorPhone}
          type="tel"
          handleChange={formik.handleChange}
          error={touched.surveyorPhone && Boolean(errors.surveyorPhone)}
          helperText={touched.surveyorPhone && errors.surveyorPhone}
        />
        <TextInput
          label="Email du géomètre"
          placeholder="Email"
          name="surveyorEmail"
          value={values.surveyorEmail}
          type="email"
          handleChange={formik.handleChange}
          error={touched.surveyorEmail && Boolean(errors.surveyorEmail)}
          helperText={touched.surveyorEmail && errors.surveyorEmail}
        />
      </div>
      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </form>
  );
}

export default forwardRef(ProjectCollaboratorForm);
