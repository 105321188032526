import _ from 'lodash';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const ADD = 'ADD';
export const FAILURE = 'FAILURE';
export const MODAL_DISPLAY = 'MODAL_DISPLAY';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const TRUE = 'TRUE';
export const SET = 'SET';
export const RESET = 'RESET';
export const FALSE = 'FALSE';
export const VISIBLE = 'VISIBLE';
export const HIDDEN = 'HIDDEN';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SHOW = 'SHOW';
export const HIDE = 'HIDE';

export const createRequestTypes = (base: string) => {
  const CONST = _.reduce(
    [
      MODAL_DISPLAY,
      REQUEST,
      SUCCESS,
      FAILURE,
      OPEN,
      CLOSE,
      VISIBLE,
      HIDDEN,
      TRUE,
      FALSE,
      ADD,
      SET,
      RESET,
    ],
    (acc: Record<string, string>, type: string) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    {} as Record<string, string>
  );
  CONST.toString = () => base;
  return CONST;
};

export function action<T extends string, P>(type: T, payload?: Partial<P>) {
  return { type, ...payload };
}
