import { LOGOUT } from '../auth/actions';
import { RESET, SUCCESS } from '../common/actions';
import {
  ASK_FOR_SUBSCRIPTION_UPDATE,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_CITIES,
  RESET_SUBSCRIPTION_CITIES,
} from './actions';

const initialState = {
  subscriptionUpdateBody: {},
  cities: [],
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SUBSCRIPTION[SUCCESS]:
      return { ...state, ...payload };
    case RESET_SUBSCRIPTION_CITIES[RESET]:
    case FETCH_SUBSCRIPTION_CITIES[SUCCESS]:
      return { ...state, cities: payload };
    case ASK_FOR_SUBSCRIPTION_UPDATE[SUCCESS]:
      return {
        ...state,
        has_request: true,
        subscriptionUpdateBody: payload,
      };
    case LOGOUT[SUCCESS]:
      return { ...initialState };
    default:
      return state;
  }
}

export const getFullSubscriptionState = (state) => state.subscription;
export const getSubscriptionState = (state) => state.subscription.subscription;
export const getCitiesState = (state) => state.subscription.cities;
export const getsubscriptionUpdateBodyState = (state) =>
  state.subscription.subscriptionUpdateBody;
