import {
  createRequestTypes,
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
  VISIBLE,
  HIDDEN,
} from '../common/actions';

export const FETCH_ANNOUNCEMENTS = createRequestTypes('FETCH_ANNOUNCEMENTS');
export const ANNOUNCEMENTS_IS_VISIBLE = createRequestTypes(
  'ANNOUNCEMENTS_IS_VISIBLE'
);

export const announcementsActions = {
  fetchAnnouncements: {
    request: () => action(FETCH_ANNOUNCEMENTS[REQUEST]),
    success: (data) => action(FETCH_ANNOUNCEMENTS[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_ANNOUNCEMENTS[FAILURE], { payload: data }),
  },
  isVisible: {
    visible: () => action(ANNOUNCEMENTS_IS_VISIBLE[VISIBLE]),
    hidden: () => action(ANNOUNCEMENTS_IS_VISIBLE[HIDDEN]),
  },
};
