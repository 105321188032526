import { useHistory } from 'react-router-dom';

import Button from '../Assets/Button';
import SectionTitle from '../Assets/sectionTitle';

const MyFoldersHeader = () => {
  const history = useHistory();

  return (
    <header className="my-folders-header">
      <SectionTitle
        mainTitle="Dossier de prospection"
        subTitle="Organisez et optimisez votre prospection"
      />
      <div>
        <Button
          btnType="button"
          btnContent="CREER UN DOSSIER"
          handleClick={() => history.push('/mes-dossiers-creation')}
          primary
        />
      </div>
    </header>
  );
};

export default MyFoldersHeader;
