import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';

const PaginationCustom = ({
  elementsQty,
  itemsPerPage,
  handleMinIndexTab,
  variant = 'outlined',
}) => {
  const [activePage, setActivePage] = useState(1);
  const [pageQty, setPageQty] = useState();

  useEffect(() => {
    const newPageQty = Math.ceil(elementsQty / itemsPerPage);
    setPageQty(newPageQty);

    if (newPageQty === 0) {
      setActivePage(1);
    } else if (activePage > newPageQty) {
      setActivePage(newPageQty);
    }
  }, [elementsQty, itemsPerPage]);

  useEffect(() => {
    handleMinIndexTab(itemsPerPage * (activePage - 1));
  }, [activePage]);

  const handleChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {pageQty > 1 && (
        <Pagination
          page={activePage}
          count={pageQty}
          onChange={(evt, page) => handleChange(page)}
          variant={variant}
        />
      )}
    </>
  );
};

PaginationCustom.propTypes = {
  elementsQty: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  handleMinIndexTab: PropTypes.func.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default PaginationCustom;
