import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useFolders from '../../App/hooks/useFolders';
import { foldersActions } from '../../store/folders/actions';
import Button from '../Assets/Button';
import AutocompleteInputCollaborators from '../Forms/AutocompleteInputCollaborators';
import TextInput from '../Forms/TextInput';
import { createInitialValues, schema } from './form';
import './styles/MyFoldersForm.scss';

interface IMyFolderFormContentProps {
  isModal: boolean;
  handleCancel: () => void;
}

function MyFolderFormComponent({
  isModal,
  handleCancel,
}: IMyFolderFormContentProps) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { selectedFolder } = useFolders(id);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: selectedFolder ?? createInitialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.idIri) {
        dispatch(foldersActions.updateFolder.request({ body: values, history }));
      } else {
        dispatch(foldersActions.createFolder.request({ body: values, history }));
      }
    },
  });

  const { values, touched, errors } = formik;

  return (
    <div className="my-folders-form">
      <div className={isModal ? 'my-folders-form-modal' : 'my-folders-form-sub'}>
        {values && (
          <>
            <TextInput
              label="Titre du dossier"
              name="name"
              value={values.name}
              handleChange={formik.handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={errors.name}
            />

            <AutocompleteInputCollaborators
              value={values.collaborators}
              handleChange={formik.setFieldValue}
              multiple
            />
          </>
        )}
      </div>
      <div className="my-folders-form-btn-group">
        <Button
          btnType="button"
          btnContent="ENREGISTRER"
          handleClick={formik.handleSubmit}
          primary
        />
        <Button
          btnType="button"
          btnContent="ANNULER"
          handleClick={handleCancel}
          textColor="violet"
          bgColor="white"
          borderColor="grey"
        />
      </div>
    </div>
  );
}

export default MyFolderFormComponent;
