import {
  action,
  createRequestTypes,
  MODAL_DISPLAY,
  FAILURE,
  REQUEST,
  RESET,
  SUCCESS,
} from '../common/actions';
export const FETCH_MARKET_STUDIES = createRequestTypes('FETCH_MARKET_STUDIES');
export const CREATE_MARKET_STUDY = createRequestTypes('CREATE_MARKET_STUDY');
export const UPDATE_MARKET_STUDY = createRequestTypes('UPDATE_MARKET_STUDY');
export const UPDATE_MARKET_STUDY_RESULT_COMMENT = createRequestTypes(
  'UPDATE_MARKET_STUDY_RESULT_COMMENT'
);
export const DELETE_MARKET_STUDY = createRequestTypes('DELETE_MARKET_STUDY');
export const SET_ACTIVE_MARKET_STUDIES = createRequestTypes(
  'SET_ACTIVE_MARKET_STUDIES'
);
export const RESET_ACTIVE_MARKET_STUDY = createRequestTypes(
  'RESET_ACTIVE_MARKET_STUDY'
);
export const FETCH_MARKET_STUDY_RESULT = createRequestTypes(
  'FETCH_MARKET_STUDY_RESULT'
);

export const marketStudiesActions = {
  fetchMarketStudies: {
    request: (data) => action(FETCH_MARKET_STUDIES[REQUEST], { payload: data }),
    success: (data) => action(FETCH_MARKET_STUDIES[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_MARKET_STUDIES[FAILURE], { payload: data }),
  },
  createMarketStudy: {
    request: (data) => action(CREATE_MARKET_STUDY[REQUEST], { payload: data }),
    success: (data) => action(CREATE_MARKET_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_MARKET_STUDY[FAILURE], { payload: data }),
  },
  updateMarketStudy: {
    request: (data) => action(UPDATE_MARKET_STUDY[REQUEST], { payload: data }),
    success: (data) => action(UPDATE_MARKET_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(UPDATE_MARKET_STUDY[FAILURE], { payload: data }),
  },
  // updateMarketStudyResultComment: {
  //   request: (data) =>
  //     action(UPDATE_MARKET_STUDY_RESULT_COMMENT[REQUEST], { payload: data }),
  //   success: (data) =>
  //     action(UPDATE_MARKET_STUDY_RESULT_COMMENT[SUCCESS], { payload: data }),
  //   failure: (data) =>
  //     action(UPDATE_MARKET_STUDY_RESULT_COMMENT[FAILURE], { payload: data }),
  // },
  deleteMarketStudy: {
    modal: (data) => action(DELETE_MARKET_STUDY[MODAL_DISPLAY], { payload: data }),
    request: (data) => action(DELETE_MARKET_STUDY[REQUEST], { payload: data }),
    success: (data) => action(DELETE_MARKET_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_MARKET_STUDY[FAILURE], { payload: data }),
    reset: () =>
      action(DELETE_MARKET_STUDY[RESET], {
        payload: { display: false, idIri: null, name: null },
      }),
  },
  setActiveMarketStudies: {
    success: (data) => action(SET_ACTIVE_MARKET_STUDIES[SUCCESS], { payload: data }),
    failure: (data) => action(SET_ACTIVE_MARKET_STUDIES[FAILURE], { payload: data }),
  },
  resetActiveMarketStudy: {
    success: (data) => action(RESET_ACTIVE_MARKET_STUDY[SUCCESS], { payload: data }),
    failure: (data) => action(RESET_ACTIVE_MARKET_STUDY[FAILURE], { payload: data }),
  },
  fetchMarketStudyResult: {
    request: (data) => action(FETCH_MARKET_STUDY_RESULT[REQUEST], { payload: data }),
    success: (data) => action(FETCH_MARKET_STUDY_RESULT[SUCCESS], { payload: data }),
    failure: (data) => action(FETCH_MARKET_STUDY_RESULT[FAILURE], { payload: data }),
  },
};
