import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../store/common/actions';

export const FETCH_DASHBOARD_PROCEDURE_EVENTS = createRequestTypes(
  'FETCH_DASHBOARD_PROCEDURE_EVENTS'
);
export const CREATE_PROCEDURE_EVENT = createRequestTypes('CREATE_PROCEDURE_EVENT');
export const DELETE_PROCEDURE_EVENT = createRequestTypes('DELETE_PROCEDURE_EVENT');

export const procedureEventsActions = {
  fetchDashboardProcedureEvents: {
    request: () => action(FETCH_DASHBOARD_PROCEDURE_EVENTS[REQUEST]),
    success: (data) =>
      action(FETCH_DASHBOARD_PROCEDURE_EVENTS[SUCCESS], { payload: data }),
    failure: (data) =>
      action(FETCH_DASHBOARD_PROCEDURE_EVENTS[FAILURE], { payload: data }),
  },
  createProcedureEvents: {
    request: (data) => action(CREATE_PROCEDURE_EVENT[REQUEST], { payload: data }),
    success: (data) => action(CREATE_PROCEDURE_EVENT[SUCCESS], { payload: data }),
    failure: (data) => action(CREATE_PROCEDURE_EVENT[FAILURE], { payload: data }),
  },
  deleteProcedureEvents: {
    request: (data) => action(DELETE_PROCEDURE_EVENT[REQUEST], { payload: data }),
    success: (data) => action(DELETE_PROCEDURE_EVENT[SUCCESS], { payload: data }),
    failure: (data) => action(DELETE_PROCEDURE_EVENT[FAILURE], { payload: data }),
  },
};
