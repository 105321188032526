import React, { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import useAdStudies from '../../App/hooks/useAdStudies';

import ValidationButton from './customComponants/ValidationButton';
import TextInput from '../Forms/TextInput';

import { salesAgentContactInitialValues, salesAgentContactSchema } from './form';
import { adStudiesActions } from '../../store/adStudies/actions';

function SalesAgentContactForm(props, ref) {
  const {
    activeAdStudy: { salesAgentContact, idIri },
  } = useAdStudies();
  const formik = useFormik({
    initialValues: salesAgentContact ?? salesAgentContactInitialValues,
    validationSchema: salesAgentContactSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'salesAgentContact',
          idIri,
        })
      );
    },
  });
  const dispatch = useDispatch();
  const { values, touched, errors, dirty } = formik;

  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ salesAgentContactForm: dirty }));
  }, [dirty]);

  return (
    <form className="my-folders-ad-creation-content-main-group-form" noValidate>
      <h4>Contact agent commercial</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Nom"
          name="salesAgentName"
          value={values.salesAgentName}
          handleChange={formik.handleChange}
          error={touched.salesAgentName && Boolean(errors.salesAgentName)}
          helperText={touched.salesAgentName && errors.salesAgentName}
        />
        <TextInput
          label="Téléphone"
          name="salesAgentPhone"
          value={values.salesAgentPhone}
          type="tel"
          handleChange={formik.handleChange}
          error={touched.salesAgentPhone && Boolean(errors.salesAgentPhone)}
          helperText={touched.salesAgentPhone && errors.salesAgentPhone}
        />
        <TextInput
          label="Email"
          name="salesAgentMail"
          value={values.salesAgentMail}
          type="email"
          handleChange={formik.handleChange}
          error={touched.salesAgentMail && Boolean(errors.salesAgentMail)}
          helperText={touched.salesAgentMail && errors.salesAgentMail}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Infos"
          name="salesAgentInfo"
          value={values.salesAgentInfo}
          handleChange={formik.handleChange}
          error={touched.salesAgentInfo && Boolean(errors.salesAgentInfo)}
          helperText={touched.salesAgentInfo && errors.salesAgentInfo}
        />
      </div>
      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </form>
  );
}

export default forwardRef(SalesAgentContactForm);
