import { useLocation } from 'react-router-dom';

import LeftBand from '../../features/sign/leftBand';
import ActionNotification from '../Assets/ActionNotification';
import PasswordResetForm from './PasswordResetForm';
import PasswordResetHeader from './PasswordResetHeader';

import './styles/passwordReset.scss';

function PasswordReset() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="password">
      <LeftBand />
      <main className="password-content">
        <div className="password-content-elt">
          <PasswordResetHeader path={path} />
          <div className="password-form-wrapper">
            <ActionNotification />
            <PasswordResetForm path={path} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default PasswordReset;
