import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import RadioButtons from '../../Forms/RadioButtons';
import { radioButtonElts } from '../Form/constant';
import './activeResearchWordFilter.scss';

const ActiveResearchWordFilter = ({ formik, name, label, placeholder }) => {
  const { values } = formik;
  return (
    <div className="word-filter">
      <ChipInput
        name={name}
        placeholder={placeholder}
        label={label}
        defaultValue={values[name] ?? []}
        onChange={(chips) => formik.setFieldValue(name, chips)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <RadioButtons
        name={`${name}Operator`}
        value={values[`${name}Operator`] ?? 0}
        handleChange={(e) =>
          formik.setFieldValue(e.target.name, Number(e.target.value))
        }
        radioElts={radioButtonElts}
        isRow
        style={{ justifyContent: 'flex-end' }}
      />
    </div>
  );
};

ActiveResearchWordFilter.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ActiveResearchWordFilter;
