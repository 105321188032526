import { Tooltip } from '@material-ui/core';
import './badge.scss';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from './enums';

interface IBadgeProps {
  badgeDynamicContent?: string;
  badgeContent?: BadgeContentEnum;
  bgColor: BadgeBackgroundColorEnum;
  textColor: BadgeTextColorEnum;
  isMini?: boolean;
}

function Badge({
  badgeDynamicContent,
  badgeContent,
  bgColor,
  textColor,
  isMini,
}: IBadgeProps) {
  const badgeWeight = isMini ? 'badge-mini' : '';
  const badgeClass = badgeWeight + bgColor + textColor;

  return badgeContent === BadgeContentEnum.EXCLU ? (
    <Tooltip title="Exclusivité" placement="top">
      <span className={'badge ' + badgeClass}>
        {badgeDynamicContent ?? badgeContent}
      </span>
    </Tooltip>
  ) : (
    <span className={'badge ' + badgeClass}>
      {badgeDynamicContent ?? badgeContent}
    </span>
  );
}

export default Badge;
