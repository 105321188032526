import React from 'react';
import useComparativeStudies from '../../App/hooks/useComparativeStudies';
import { comparativeStudiesActions } from '../../store/comparativeStudies/actions';
import ActionModal from '../Modals/ActionModal';

import './comparativeStudyDeleteModal.scss';

const ComparativeStudyDeleteModal = () => {
  const { deleteModal } = useComparativeStudies();

  return (
    <ActionModal
      closeAction={comparativeStudiesActions.deleteComparativeStudy.reset()}
      validAction={comparativeStudiesActions.deleteComparativeStudy.request(
        deleteModal.idIri
      )}
      validText="SUPPRIMER"
      modalType="suppression"
    >
      <p>
        Confirmez-vous la suppression de l&apos;étude comparative pour la typologie
        <span>{` ${deleteModal.typology} `}</span> sur la ville de
        <span>{` ${deleteModal.city} `}</span>?
      </p>
      <p>La suppression sera définitive.</p>
    </ActionModal>
  );
};

export default ComparativeStudyDeleteModal;
