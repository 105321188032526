import * as yup from 'yup';
import { AREA, CITY, DPE_GES, PRICE, ROOM, TITLE } from '../../../utils/validation';

export const radioButtonElts = [
  { value: 0, label: 'Et' },
  { value: 1, label: 'Ou' },
];

export const sellerTypes = [
  { value: 0, name: 'Tous' },
  { value: 1, name: 'Particuliers' },
  { value: 2, name: 'Professionnels' },
  { value: 3, name: 'Enchères' },
  { value: 4, name: 'Etat / Collectivités' },
  { value: 5, name: 'Autre' },
];

export const createInitialValues: IActiveResearchFormValues = {
  builtAreaMax: null,
  builtAreaMin: null,
  cities: [],
  cityIdRadiusOrigin: null,
  name: '',
  propertyTypologies: [{ idIri: '', name: 'Tous' }],
  roomMin: null,
  roomMax: null,
  priceMin: null,
  priceMax: null,
  sqmPriceMin: null,
  sqmPriceMax: null,
  landAreaMin: null,
  landAreaMax: null,
  collaborators: [],
  sellerTypes: [0],
  limitFromDate: null,
  includedKeywordsOperator: 0,
  includedKeywords: [],
  excludedKeywordsOperator: 0,
  excludedKeywords: [],
  includedSellerNameOperator: 0,
  includedSellerName: [],
  excludedSellerNameOperator: 0,
  excludedSellerName: [],
  isGoodDealsOnly: false,
  isAlertEnabled: false,
  isExclusiveOnly: false,
  dpeMax: null,
  dpeMin: null,
  gesMax: null,
  gesMin: null,
  radius: null,
  hasCityRadius: false,
  owner: '',
};

export const ActiveResearchesSelectDefaultValues = {
  sort: 'createdAt',
  display: 12,
  action: '',
};

export const activeResearchesActionsValues = [
  { value: '', text: 'Choisir...' },
  { value: 'study', text: 'Etudier' },
  { value: 'delete', text: 'Supprimer' },
];

export const sortValues = [
  { value: 'createdAt', text: 'Date' },
  { value: 'price', text: 'Prix' },
  { value: 'sqmPrice', text: 'Prix au m²' },
  { value: 'sellerTypes', text: 'Profil vendeur' },
  { value: 'builtArea', text: 'Surface bâtie' },
  { value: 'landArea', text: 'Surface terrain' },
];

export const radiusValues = [
  { value: '', text: 'Pas de périmètre alentours' },
  { value: 5, text: '5 km' },
  { value: 10, text: '10 km' },
  { value: 30, text: '30 km' },
];

export const dpeGes = (type: 'dpe' | 'ges') => [
  { value: '', text: type === 'dpe' ? 'Pas de DPE' : 'Pas de GES' },
  { value: 'A', text: 'A' },
  { value: 'B', text: 'B' },
  { value: 'C', text: 'C' },
  { value: 'D', text: 'D' },
  { value: 'E', text: 'E' },
  { value: 'F', text: 'F' },
  { value: 'G', text: 'G' },
];

export const schema = yup.object({
  name: yup.string().required(TITLE.required),
  cities: yup.array().min(1, CITY.required).required(),
  propertyTypologies: yup.array(),
  collaborators: yup.array(),
  priceMin: yup.number().typeError(PRICE.type).min(0, PRICE.min).nullable(),
  priceMax: yup
    .number()
    .typeError(PRICE.type)
    .moreThan(yup.ref('priceMin'), PRICE.moreThan)
    .nullable(),
  sqmPriceMin: yup.number().typeError(PRICE.type).min(0, PRICE.min).nullable(),
  sqmPriceMax: yup
    .number()
    .typeError(PRICE.type)
    .moreThan(yup.ref('sqmPriceMin'), PRICE.moreThan)
    .nullable(),
  roomMin: yup.number().typeError(ROOM.type).min(1, ROOM.min).nullable(),
  roomMax: yup
    .number()
    .typeError(ROOM.type)
    .min(yup.ref('roomMin'), ROOM.moreThanOrEqual)
    .nullable(),
  landAreaMin: yup.number().typeError(AREA.type).min(0, AREA.min).nullable(),
  landAreaMax: yup
    .number()
    .typeError(AREA.type)
    .moreThan(yup.ref('landAreaMin'), AREA.moreThan)
    .nullable(),
  builtAreaMin: yup.number().typeError(AREA.type).min(0, AREA.min).nullable(),
  builtAreaMax: yup
    .number()
    .typeError(AREA.type)
    .moreThan(yup.ref('builtAreaMin'), AREA.moreThan)
    .nullable(),
  dpeMin: yup
    .string()
    .nullable()
    .test('dpeMinGreaterThandpeMax', DPE_GES('DPE').lessThan, (value, context) => {
      const { dpeMax } = context.parent;

      if (value && dpeMax) {
        return value >= dpeMax;
      } else {
        return true;
      }
    }),
  dpeMax: yup
    .string()
    .nullable()
    .test('dpeMaxLesserThandpeMin', DPE_GES('DPE').moreThan, (value, context) => {
      const { dpeMin } = context.parent;

      if (value && dpeMin) {
        return value <= dpeMin;
      } else {
        return true;
      }
    }),
  gesMin: yup
    .string()
    .nullable()
    .test('gesMinGreaterThanGesMax', DPE_GES('GES').lessThan, (value, context) => {
      const { gesMax } = context.parent;

      if (value && gesMax) {
        return value >= gesMax;
      } else {
        return true;
      }
    }),
  gesMax: yup
    .string()
    .nullable()
    .test('gesMaxLesserThanGesMin', DPE_GES('GES').moreThan, (value, context) => {
      const { gesMin } = context.parent;

      if (value && gesMin) {
        return value <= gesMin;
      } else {
        return true;
      }
    }),
});
