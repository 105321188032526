import Pagination from '@material-ui/lab/Pagination';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import AdThumb from '../../features/adThumb';
import { adActions } from '../../store/ads/actions';
import { activeResearchesActions } from '../activeResearch/actions';

interface IThumbContainerProps {
  ads: Ad[];
  type: 'ads' | 'activeResearches';
  pageQuantity: number;
  itemsPerPage: number;
  sort: string;
  newAds?: number[];
  onPageChange?: (value: number) => void;
}
const ThumbContainer = ({
  ads,
  type,
  pageQuantity,
  itemsPerPage,
  sort,
  newAds = [],
  onPageChange,
}: IThumbContainerProps) => {
  const { id } = useParams<{ id: string }>();
  const [activePage, setActivePage] = useState<number>(1);
  const dispatch = useDispatch();

  const generalDispatch = (page: number) => {
    switch (type) {
      case 'ads':
        dispatch(adActions.fetchAds.request({ page, itemsPerPage, sort }));
        break;
      case 'activeResearches':
        dispatch(
          activeResearchesActions.fetchCurrentActiveResearchAds.request({
            page,
            itemsPerPage,
            sort,
            activeResearchId: id,
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    generalDispatch(activePage);
  }, [itemsPerPage, sort]);

  const handlePageChange = (page: number) => {
    setActivePage(page);
    generalDispatch(page);
    if (onPageChange) onPageChange(page);
  };

  return (
    <div>
      <section className="piecework-cards">
        {ads?.map((ad) => (
          <AdThumb
            data={ad}
            dataRealId={ad.idIri}
            key={uuidv4()}
            type={type}
            // isNew={newAds.includes(ad.id)}
            isNew={false}
          />
        ))}
      </section>

      {pageQuantity > 1 && (
        <Pagination
          page={activePage}
          count={pageQuantity}
          onChange={(evt, page) => handlePageChange(page)}
          variant="outlined"
        />
      )}
    </div>
  );
};

export default ThumbContainer;
