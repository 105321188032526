import { take, call, put, select } from '@redux-saga/core/effects';
import { updateEntity } from '../../services/axiosInstanceService';
import { appManagerActions } from '../appManager/actions';
import { REQUEST } from '../common/actions';
import onErrorLogout from '../common/sagaCommon';
import { loaderActions } from '../loader/actions';
import { marketStudiesActions } from '../marketStudies/actions';
import { marketStudyResultNotifications } from '../marketStudies/notifications';
import { marketStudyApiToStore } from '../marketStudies/parser';
import { marketStudyCommentActions, UPDATE_MARKET_STUDY_COMMENT } from './actions';
import { getMarketStudyCommentState } from './reducer';

export function* handleUpdateMarketStudyComment() {
  while (true) {
    try {
      yield take(UPDATE_MARKET_STUDY_COMMENT[REQUEST]);
      const { idIri, comment } = yield select(getMarketStudyCommentState);

      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(updateEntity, {
        idIri,
        body: { comment: JSON.stringify(comment) },
      });

      if (response) {
        const parsedResponse = marketStudyApiToStore(response);
        yield put(loaderActions.loaderDisplay.hidden());
        yield put(
          appManagerActions.actionNotificationDisplay.visible(
            marketStudyResultNotifications.update().success()
          )
        );
        yield put(
          marketStudyCommentActions.updateMarketStudyComment.success(parsedResponse)
        );
      }
    } catch (error) {
      console.log('error updateMarketStudyResultComment', error);
      yield onErrorLogout(error);
      yield put(
        appManagerActions.actionNotificationDisplay.visible(
          marketStudyResultNotifications.update().failure()
        )
      );
      yield put(marketStudiesActions.updateMarketStudyResultComment.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
