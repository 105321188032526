import { distinctStringValues } from '../../utils/jsFunctions';
import { collaboratorApiToStore } from '../collaborators/parser';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  emptyKeyRemove,
  mapObjectParser,
} from '../common/parserCommon';

const registerFormMaping = {
  name: 'name',
  company: 'company',
  address: 'address',
  city: 'city',
  zipcode: 'zipCode',
  email: 'email',
  landline_phone: 'landlinePhone',
  mobile_phone: 'mobilePhone',
};

const companyStatsMapping = {
  user: 'user',
  event: 'event',
  total: 'total',
};

const eventMapping = {
  auth_success: 'connexion',
  ad_study: 'adStudy',
  comparative_study: 'comparativeStudy',
  procedure_event: 'procedureEvent',
  active_research: 'activeResearch',
};

export const registerFormToApi = (data) => {
  let parsedData = changeStoreKeyForApiKey(data, registerFormMaping);

  parsedData = emptyKeyRemove(parsedData);

  return parsedData;
};

export const companyStatsMapApiToStore = (data) => {
  let mapStats = mapObjectParser(data, companyStatsApiToStore);

  const final = distinctStringValues(mapStats.map((e) => e.user)).map((e) => ({
    user: e,
    events: {},
  }));

  mapStats.forEach((e) => {
    const temp = final.indexOf(final.find((u) => u.user === e.user));
    final[temp].events[e.event] = e.total === 0 ? '-' : e.total;
  });

  return final;
};
export const companyStatsApiToStore = (data) => {
  let parsedData = changeApiKeyForStoreKey(data, companyStatsMapping);

  parsedData.user = collaboratorApiToStore(parsedData.user).fullName;
  parsedData.event = eventMapping[parsedData.event];

  // console.log(parsedData);
  return parsedData;
};
