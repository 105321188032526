import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useActiveResearches from '../features/activeResearch/useActiveResearches';

import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import SectionTitle from '../components/Assets/sectionTitle';
import SelectInput from '../components/Forms/SelectInput';

import { nbDisplay } from '../lib/constants';
import { handleInputChange } from '../utils/forms';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  activeResearchesActionsValues,
  ActiveResearchesSelectDefaultValues,
  sortValues,
} from '../components/ActiveResearches/Form/constant';
import { activeResearchesActions } from '../features/activeResearch/actions';
import ActiveResearchResume from '../features/activeResearch/result/ActiveResearchResume';
import ThumbContainer from '../features/adThumb/ThumbContainer';
import { adActions } from '../store/ads/actions';
import { getAdsState } from '../store/ads/reducer';
import { adStudiesActions } from '../store/adStudies/actions';
import './styles/activeResearchResult.scss';

function ActiveSearchResultContent() {
  const { id } = useParams<{ id: string }>();

  const [input, setInput] = useState(ActiveResearchesSelectDefaultValues);
  const { selectedAds }: { selectedAds: Ad[] } = useSelector(getAdsState);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentActiveResearch,
    currentActiveResearchAds,
    adsQuantity,
    loadAdPage,
  } = useActiveResearches({ activeResearchId: id });

  useEffect(() => {
    return () => {
      dispatch(activeResearchesActions.resetCurrentActiveResearch.reset());
      dispatch(adActions.selectedAdsReset.success());
    };
  }, []);

  const handlePageChange = (value: number) => {
    loadAdPage(input.display, value, input.sort);
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    switch (value) {
      case 'study':
        dispatch(adStudiesActions.createAdStudies.modal());
        break;
      case 'delete':
        {
          dispatch(
            adActions.deleteAds.modal({
              display: true,
              quantity: selectedAds.length,
            })
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <main className="active-search-result">
      <ActionNotification />
      <header className="active-search-result-header">
        <div className="active-search-result-header-elt">
          <div className="active-search-result-ads">
            <span className="active-search-result-ads-nb">{adsQuantity}</span>
            <span>Annonces</span>
          </div>
          <SectionTitle
            mainTitle="Critères de recherche active"
            subTitle="Créez vos propres critères de recherches"
          />
        </div>
        <div className="active-search-result-header-elt">
          <div className="active-search-result-header-action">
            <SelectInput
              colorDisplay="reverse"
              name="action"
              value={input.action}
              nameKey="text"
              label="Action"
              handleChange={(evt) => handleChange(evt)}
              disabled={isEmpty(selectedAds)}
              items={activeResearchesActionsValues}
            />
            <p>{`${selectedAds.length} annonces selectionnées`}</p>
          </div>
          <Button
            btnType="button"
            btnContent="MODIFIER / AFFINER MES CRITERES"
            primary
            handleClick={() => history.push(`/recherche-active-modification/${id}`)}
          />

          <Button
            btnType="button"
            btnContent="PRÉCÉDENT"
            handleClick={() => history.push(`/recherches-actives`)}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
        </div>
      </header>

      {currentActiveResearch && (
        <ActiveResearchResume currentActiveResearch={currentActiveResearch} />
      )}
      <>
        <div className="active-search-result-sort">
          <SelectInput
            name="sort"
            value={input.sort}
            label="Trier par"
            nameKey="text"
            colorDisplay="reverse"
            handleChange={(evt) => handleInputChange(evt, input, setInput)}
            items={sortValues}
          />
          <SelectInput
            name="display"
            value={input.display}
            label="Affichage par page"
            colorDisplay="reverse"
            handleChange={(evt) => handleInputChange(evt, input, setInput)}
            items={nbDisplay}
          />
        </div>

        <ThumbContainer
          sort={input.sort}
          itemsPerPage={input.display}
          pageQuantity={Math.ceil(adsQuantity / input.display)}
          type="activeResearches"
          ads={currentActiveResearchAds}
          newAds={[]} // areNew
          onPageChange={handlePageChange}
        />
      </>
    </main>
  );
}

export default ActiveSearchResultContent;
