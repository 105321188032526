import React from 'react';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import { assistanceVideo } from './arrayDatas';

function VideoTab() {
  return (
    <section className="assistance">
      {assistanceVideo.map((video) => (
        <article className="assistance-elt" key={uuidv4()}>
          <div className="assistance-video">
            <ReactPlayer controls light width="100%" height="100%" url={video.url} />
          </div>
          <div className="assistance-elt-infos">
            <h3 className="assistance-video-title">{video.title}</h3>
            <p className="assistance-video-description">{video.description}</p>
          </div>
        </article>
      ))}
    </section>
  );
}

export default VideoTab;
