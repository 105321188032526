import { take, call, put } from '@redux-saga/core/effects';
import { REQUEST } from '../common/actions';
import { adSourcesActions, FETCH_AD_SOURCES } from './actions';
import { fetchDatas } from '../../services/axiosInstanceService';
import { adSourcesApiToStore } from './parser';
import { loaderActions } from '../loader/actions';

export function* handleFetchAdSources() {
  while (true) {
    try {
      yield take(FETCH_AD_SOURCES[REQUEST]);
      yield put(loaderActions.loaderDisplay.visible());
      const response = yield call(fetchDatas, '/ad-sources');

      if (response) {
        const parsedResponse = response['hydra:member'].map((e) =>
          adSourcesApiToStore(e)
        );
        console.log(parsedResponse);
        yield put(adSourcesActions.fetchAdSources.success(parsedResponse));
        yield put(loaderActions.loaderDisplay.hidden());
      }
    } catch (error) {
      yield put(adSourcesActions.fetchAdSources.failure(error));
      yield put(loaderActions.loaderDisplay.hidden());
    }
  }
}
