import React, { useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import useAdStudies from '../../App/hooks/useAdStudies';

import TextInput from '../Forms/TextInput';
import ValidationButton from './customComponants/ValidationButton';

import { adStudiesActions } from '../../store/adStudies/actions';
import { sellerContactInitialValues, sellerContactSchema } from './form';

function SellerContactForm(props, ref) {
  const {
    activeAdStudy: { sellerContact, idIri },
  } = useAdStudies();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: sellerContact ?? sellerContactInitialValues,
    validationSchema: sellerContactSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        adStudiesActions.updateAdStudy.request({
          body: values,
          type: 'sellerContact',
          idIri,
        })
      );
    },
  });

  const { values, touched, errors, dirty } = formik;

  useEffect(() => {
    dispatch(adStudiesActions.formTouchStates.set({ sellerContactForm: dirty }));
  }, [dirty]);

  return (
    <form className="my-folders-ad-creation-content-main-group-form" noValidate>
      <h4>Contact vendeur</h4>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Nom"
          name="sellerName"
          value={values.sellerName}
          handleChange={formik.handleChange}
          error={touched.sellerName && Boolean(errors.sellerName)}
          helperText={touched.sellerName && errors.sellerName}
        />
        <TextInput
          label="Téléphone"
          name="sellerPhone"
          value={values.sellerPhone}
          type="tel"
          handleChange={formik.handleChange}
          error={touched.sellerPhone && Boolean(errors.sellerPhone)}
          helperText={touched.sellerPhone && errors.sellerPhone}
        />
        <TextInput
          label="Email"
          name="sellerMail"
          value={values.sellerMail}
          type="email"
          handleChange={formik.handleChange}
          error={touched.sellerMail && Boolean(errors.sellerMail)}
          helperText={touched.sellerMail && errors.sellerMail}
        />
      </div>
      <div className="my-folders-ad-creation-content-main-group-form-row">
        <TextInput
          label="Infos"
          name="sellerInfo"
          value={values.sellerInfo}
          handleChange={formik.handleChange}
          error={touched.sellerInfo && Boolean(errors.sellerInfo)}
          helperText={touched.sellerInfo && errors.sellerInfo}
        />
      </div>
      <ValidationButton ref={ref} onClick={formik.handleSubmit} />
    </form>
  );
}

export default forwardRef(SellerContactForm);
