import React from 'react';
import { useDispatch } from 'react-redux';
import useAuth from '../../../App/hooks/useAuth';
import useCollaborators from '../../../App/hooks/useCollaborators';
import useSubscription from '../../../App/hooks/useSubscription';
import { modalsActions } from '../../../store/modals/actions';

import Button from '../../Assets/Button';

const AddCollaboratorButton = () => {
  const { isManager } = useAuth();
  const { totalAuthorizedUsers } = useSubscription(true);
  const { collaboratorsCount } = useCollaborators();

  const dispatch = useDispatch();

  const disabled = totalAuthorizedUsers <= collaboratorsCount + 1;

  return (
    <>
      {isManager && (
        <Button
          btnType="button"
          btnContent={
            disabled ? 'MAX COLLABORATEUR ATTEINT' : 'AJOUTER COLLABORATEUR'
          }
          tooltipContent={`Total des utilisateurs autorisés: ${totalAuthorizedUsers}`}
          handleClick={() => dispatch(modalsActions.newCollaborator.open())}
          disabled={disabled}
          primary
        />
      )}
    </>
  );
};

export default AddCollaboratorButton;
