import { memo } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { GoodTypeEnum } from '../../pages/MarketStudiesResultPage';

import { statTypeFieldValues } from './constant';
import Good from './Good';

interface IGoodTypeContainerProps {
  type: GoodTypeEnum;
}

const GoodContainer = ({ type }: IGoodTypeContainerProps) => {
  const { id } = useParams<{ id: string }>();

  const goodStatPerRoomLoop = () => {
    const tabToDisplay: React.ReactNode[] = [];

    for (let i = 1; i <= 6; i += 1) {
      tabToDisplay.push(
        <Good
          key={uuidv4()}
          goodType={GoodTypeEnum.CONSTRUCTION}
          partialLabel={i.toString()}
          id={id}
        />
      );
    }

    return tabToDisplay;
  };

  const goodStatPerGroundLoop = () => {
    const tabToDisplay: React.ReactNode[] = [];

    statTypeFieldValues.forEach((e) => {
      tabToDisplay.push(
        <Good
          key={uuidv4()}
          goodType={GoodTypeEnum.TERRAIN}
          partialLabel={e}
          id={id}
        />
      );
    });

    return tabToDisplay;
  };

  return (
    <>
      {type === GoodTypeEnum.TERRAIN
        ? goodStatPerGroundLoop()
        : goodStatPerRoomLoop()}
    </>
  );
};

export default memo(GoodContainer);
